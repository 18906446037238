@charset 'UTF-8';
/*

Theme: Quick – Website UI Kit
Version: 1.0.0
Product Page: https://themes.getbootstrap.com/product/quick-website-ui-kit/
License: https://themes.getbootstrap.com/licenses/
Author: Webpixels
Author URI: https://webpixels.io

---

Copyright 2019 Webpixels

*/
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700);
:root
{
    --blue: #0c66ff;
    --indigo: #6e00ff;
    --purple: #510fa8;
    --pink: #f074ad;
    --red: #ff5c75;
    --orange: #ffbe3d;
    --yellow: #fc0;
    --green: #2dca8c;
    --teal: #50b5ff;
    --cyan: #4bd6e5;
    --white: #fff;
    --gray: #c0ccda;
    --gray-dark: #3c4858;
    --primary: #0c66ff;
    --secondary: #eff2f7;
    --success: #2dca8c;
    --info: #50b5ff;
    --warning: #ffbe3d;
    --danger: #ff5c75;
    --light: #eff2f7;
    --dark: #273444;
    --neutral: #fff;
    --white: #fff;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after
{
    box-sizing: border-box;
}

html
{
    font-family: sans-serif;
    line-height: 1.15;

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(31, 45, 61, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section
{
    display: block;
}

body
{
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;

    margin: 0;

    text-align: left;

    color: #8492a6;
    background-color: #fff;
}

[tabindex='-1']:focus
{
    outline: 0 !important;
}

hr
{
    overflow: visible; 

    box-sizing: content-box;
    height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin-top: 0;
    margin-bottom: .66667rem;
}

p
{
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title]
{
    cursor: help;
            text-decoration: underline;
            text-decoration: underline dotted;

    border-bottom: 0;

    -webkit-text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

address
{
    font-style: normal;
    line-height: inherit; 

    margin-bottom: 1rem;
}

ol,
ul,
dl
{
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol
{
    margin-bottom: 0;
}

dt
{
    font-weight: 600;
}

dd
{
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote
{
    margin: 0 0 1rem;
}

b,
strong
{
    font-weight: 700;
}

small
{
    font-size: 80%;
}

sub,
sup
{
    font-size: 75%;
    line-height: 0;

    position: relative;

    vertical-align: baseline;
}

sub
{
    bottom: -.25em;
}

sup
{
    top: -.5em;
}

a
{
    text-decoration: none;

    color: #0c66ff;
    background-color: transparent;
}
a:hover
{
    text-decoration: none; 

    color: #0047bf;
}

a:not([href]):not([tabindex])
{
    text-decoration: none; 

    color: inherit;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus
{
    text-decoration: none; 

    color: inherit;
}
a:not([href]):not([tabindex]):focus
{
    outline: 0;
}

pre,
code,
kbd,
samp
{
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

pre
{
    overflow: auto; 

    margin-top: 0;
    margin-bottom: 1rem;
}

figure
{
    margin: 0 0 1rem;
}

img
{
    vertical-align: middle;

    border-style: none;
}

svg
{
    overflow: hidden;

    vertical-align: middle;
}

table
{
    border-collapse: collapse;
}

caption
{
    padding-top: 1rem;
    padding-bottom: 1rem;

    caption-side: bottom; 

    text-align: left;

    color: #8492a6;
}

th
{
    text-align: inherit;
}

label
{
    display: inline-block;

    margin-bottom: .5rem;
}

button
{
    border-radius: 0;
}

button:focus
{
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea
{
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; 

    margin: 0;
}

button,
input
{
    overflow: visible;
}

button,
select
{
    text-transform: none;
}

select
{
    word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit']
{
    -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled)
{
    cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner
{
    padding: 0;

    border-style: none;
}

input[type='radio'],
input[type='checkbox']
{
    box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month']
{
    -webkit-appearance: listbox;
}

textarea
{
    overflow: auto;

    resize: vertical;
}

fieldset
{
    min-width: 0;
    margin: 0;
    padding: 0;

    border: 0;
}

legend
{
    font-size: 1.5rem;
    line-height: inherit;

    display: block;

    width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0;

    white-space: normal; 

    color: inherit;
}
@media (max-width: 1200px)
{
    legend
    {
        font-size: calc(1.275rem + .3vw) ;
    }
}

progress
{
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button
{
    height: auto;
}

[type='search']
{
    outline-offset: -2px;

    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration
{
    -webkit-appearance: none;
}

::-webkit-file-upload-button
{
    font: inherit;

    -webkit-appearance: button;
}

output
{
    display: inline-block;
}

summary
{
    display: list-item;

    cursor: pointer;
}

template
{
    display: none;
}

[hidden]
{
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
{
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;

    margin-bottom: .66667rem;

    color: #3c4858;
}

h1,
.h1
{
    font-size: 2.5rem;
}
@media (max-width: 1200px)
{
    h1,
    .h1
    {
        font-size: calc(1.375rem + 1.5vw) ;
    }
}

h2,
.h2
{
    font-size: 2rem;
}
@media (max-width: 1200px)
{
    h2,
    .h2
    {
        font-size: calc(1.325rem + .9vw) ;
    }
}

h3,
.h3
{
    font-size: 1.75rem;
}
@media (max-width: 1200px)
{
    h3,
    .h3
    {
        font-size: calc(1.3rem + .6vw) ;
    }
}

h4,
.h4
{
    font-size: 1.5rem;
}
@media (max-width: 1200px)
{
    h4,
    .h4
    {
        font-size: calc(1.275rem + .3vw) ;
    }
}

h5,
.h5
{
    font-size: 1.25rem;
}

h6,
.h6
{
    font-size: 1rem;
}

.lead
{
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1
{
    font-size: 6rem;
    font-weight: 400;
    line-height: 1.3;
}
@media (max-width: 1200px)
{
    .display-1
    {
        font-size: calc(1.725rem + 5.7vw) ;
    }
}

.display-2
{
    font-size: 5rem;
    font-weight: 400;
    line-height: 1.3;
}
@media (max-width: 1200px)
{
    .display-2
    {
        font-size: calc(1.625rem + 4.5vw) ;
    }
}

.display-3
{
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.3;
}
@media (max-width: 1200px)
{
    .display-3
    {
        font-size: calc(1.525rem + 3.3vw) ;
    }
}

.display-4
{
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.3;
}
@media (max-width: 1200px)
{
    .display-4
    {
        font-size: calc(1.425rem + 2.1vw) ;
    }
}

hr
{
    margin-top: 2rem;
    margin-bottom: 2rem;

    border: 0;
    border-top: 1px solid #eff2f7;
}

small,
.small
{
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark
{
    padding: .2em;

    background-color: #fcf8e3;
}

.list-unstyled
{
    padding-left: 0;

    list-style: none;
}

.list-inline
{
    padding-left: 0;

    list-style: none;
}

.list-inline-item
{
    display: inline-block;
}
.list-inline-item:not(:last-child)
{
    margin-right: .5rem;
}

.initialism
{
    font-size: 90%;

    text-transform: uppercase;
}

.blockquote
{
    font-size: 1.25rem; 

    margin-bottom: 1rem;
}

.blockquote-footer
{
    font-size: 80%;

    display: block;

    color: #c0ccda;
}
.blockquote-footer::before
{
    content: '\2014\00A0';
}

.img-fluid
{
    max-width: 100%;
    height: auto;
}

.img-thumbnail
{
    max-width: 100%;
    height: auto; 
    padding: .25rem;

    border: 1px solid #e5e9f2;
    border-radius: .375rem;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(31, 45, 61, .075);
}

.figure
{
    display: inline-block;
}

.figure-img
{
    line-height: 1; 

    margin-bottom: .5rem;
}

.figure-caption
{
    font-size: 90%;

    color: #c0ccda;
}

code
{
    font-size: 87.5%;

    word-break: break-word; 

    color: #f074ad;
}
a > code
{
    color: inherit;
}

kbd
{
    font-size: 87.5%;

    padding: .2rem .4rem;

    color: #fff;
    border-radius: .2rem;
    background-color: #273444;
    box-shadow: inset 0 -.1rem 0 rgba(31, 45, 61, .25);
}
kbd kbd
{
    font-size: 100%;
    font-weight: 600;

    padding: 0;

    box-shadow: none;
}

pre
{
    font-size: 87.5%;

    display: block;

    color: #273444;
}
pre code
{
    font-size: inherit;

    word-break: normal; 

    color: inherit;
}

.pre-scrollable
{
    overflow-y: scroll; 

    max-height: 340px;
}

.container
{
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 576px)
{
    .container
    {
        max-width: 540px;
    }
}
@media (min-width: 768px)
{
    .container
    {
        max-width: 720px;
    }
}
@media (min-width: 992px)
{
    .container
    {
        max-width: 960px;
    }
}
@media (min-width: 1200px)
{
    .container
    {
        max-width: 1140px;
    }
}

.container-fluid
{
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}

.row
{
    display: flex;

    margin-right: -15px;
    margin-left: -15px; 

    flex-wrap: wrap;
}

.no-gutters
{
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-']
{
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto
{
    position: relative;

    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col
{
    max-width: 100%; 

    flex-basis: 0;
    flex-grow: 1;
}

.col-auto
{
    width: auto;
    max-width: 100%; 

    flex: 0 0 auto;
}

.col-1
{
    max-width: 8.33333%; 

    flex: 0 0 8.33333%;
}

.col-2
{
    max-width: 16.66667%; 

    flex: 0 0 16.66667%;
}

.col-3
{
    max-width: 25%; 

    flex: 0 0 25%;
}

.col-4
{
    max-width: 33.33333%; 

    flex: 0 0 33.33333%;
}

.col-5
{
    max-width: 41.66667%; 

    flex: 0 0 41.66667%;
}

.col-6
{
    max-width: 50%; 

    flex: 0 0 50%;
}

.col-7
{
    max-width: 58.33333%; 

    flex: 0 0 58.33333%;
}

.col-8
{
    max-width: 66.66667%; 

    flex: 0 0 66.66667%;
}

.col-9
{
    max-width: 75%; 

    flex: 0 0 75%;
}

.col-10
{
    max-width: 83.33333%; 

    flex: 0 0 83.33333%;
}

.col-11
{
    max-width: 91.66667%; 

    flex: 0 0 91.66667%;
}

.col-12
{
    max-width: 100%; 

    flex: 0 0 100%;
}

.order-first
{
    order: -1;
}

.order-last
{
    order: 13;
}

.order-0
{
    order: 0;
}

.order-1
{
    order: 1;
}

.order-2
{
    order: 2;
}

.order-3
{
    order: 3;
}

.order-4
{
    order: 4;
}

.order-5
{
    order: 5;
}

.order-6
{
    order: 6;
}

.order-7
{
    order: 7;
}

.order-8
{
    order: 8;
}

.order-9
{
    order: 9;
}

.order-10
{
    order: 10;
}

.order-11
{
    order: 11;
}

.order-12
{
    order: 12;
}

.offset-1
{
    margin-left: 8.33333%;
}

.offset-2
{
    margin-left: 16.66667%;
}

.offset-3
{
    margin-left: 25%;
}

.offset-4
{
    margin-left: 33.33333%;
}

.offset-5
{
    margin-left: 41.66667%;
}

.offset-6
{
    margin-left: 50%;
}

.offset-7
{
    margin-left: 58.33333%;
}

.offset-8
{
    margin-left: 66.66667%;
}

.offset-9
{
    margin-left: 75%;
}

.offset-10
{
    margin-left: 83.33333%;
}

.offset-11
{
    margin-left: 91.66667%;
}

@media (min-width: 576px)
{
    .col-sm
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-sm-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-sm-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-sm-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-sm-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-sm-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-sm-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-sm-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-sm-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-sm-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-sm-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-sm-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-sm-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-sm-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-sm-first
    {
        order: -1;
    }
    .order-sm-last
    {
        order: 13;
    }
    .order-sm-0
    {
        order: 0;
    }
    .order-sm-1
    {
        order: 1;
    }
    .order-sm-2
    {
        order: 2;
    }
    .order-sm-3
    {
        order: 3;
    }
    .order-sm-4
    {
        order: 4;
    }
    .order-sm-5
    {
        order: 5;
    }
    .order-sm-6
    {
        order: 6;
    }
    .order-sm-7
    {
        order: 7;
    }
    .order-sm-8
    {
        order: 8;
    }
    .order-sm-9
    {
        order: 9;
    }
    .order-sm-10
    {
        order: 10;
    }
    .order-sm-11
    {
        order: 11;
    }
    .order-sm-12
    {
        order: 12;
    }
    .offset-sm-0
    {
        margin-left: 0;
    }
    .offset-sm-1
    {
        margin-left: 8.33333%;
    }
    .offset-sm-2
    {
        margin-left: 16.66667%;
    }
    .offset-sm-3
    {
        margin-left: 25%;
    }
    .offset-sm-4
    {
        margin-left: 33.33333%;
    }
    .offset-sm-5
    {
        margin-left: 41.66667%;
    }
    .offset-sm-6
    {
        margin-left: 50%;
    }
    .offset-sm-7
    {
        margin-left: 58.33333%;
    }
    .offset-sm-8
    {
        margin-left: 66.66667%;
    }
    .offset-sm-9
    {
        margin-left: 75%;
    }
    .offset-sm-10
    {
        margin-left: 83.33333%;
    }
    .offset-sm-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px)
{
    .col-md
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-md-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-md-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-md-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-md-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-md-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-md-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-md-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-md-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-md-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-md-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-md-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-md-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-md-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-md-first
    {
        order: -1;
    }
    .order-md-last
    {
        order: 13;
    }
    .order-md-0
    {
        order: 0;
    }
    .order-md-1
    {
        order: 1;
    }
    .order-md-2
    {
        order: 2;
    }
    .order-md-3
    {
        order: 3;
    }
    .order-md-4
    {
        order: 4;
    }
    .order-md-5
    {
        order: 5;
    }
    .order-md-6
    {
        order: 6;
    }
    .order-md-7
    {
        order: 7;
    }
    .order-md-8
    {
        order: 8;
    }
    .order-md-9
    {
        order: 9;
    }
    .order-md-10
    {
        order: 10;
    }
    .order-md-11
    {
        order: 11;
    }
    .order-md-12
    {
        order: 12;
    }
    .offset-md-0
    {
        margin-left: 0;
    }
    .offset-md-1
    {
        margin-left: 8.33333%;
    }
    .offset-md-2
    {
        margin-left: 16.66667%;
    }
    .offset-md-3
    {
        margin-left: 25%;
    }
    .offset-md-4
    {
        margin-left: 33.33333%;
    }
    .offset-md-5
    {
        margin-left: 41.66667%;
    }
    .offset-md-6
    {
        margin-left: 50%;
    }
    .offset-md-7
    {
        margin-left: 58.33333%;
    }
    .offset-md-8
    {
        margin-left: 66.66667%;
    }
    .offset-md-9
    {
        margin-left: 75%;
    }
    .offset-md-10
    {
        margin-left: 83.33333%;
    }
    .offset-md-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px)
{
    .col-lg
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-lg-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-lg-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-lg-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-lg-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-lg-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-lg-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-lg-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-lg-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-lg-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-lg-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-lg-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-lg-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-lg-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-lg-first
    {
        order: -1;
    }
    .order-lg-last
    {
        order: 13;
    }
    .order-lg-0
    {
        order: 0;
    }
    .order-lg-1
    {
        order: 1;
    }
    .order-lg-2
    {
        order: 2;
    }
    .order-lg-3
    {
        order: 3;
    }
    .order-lg-4
    {
        order: 4;
    }
    .order-lg-5
    {
        order: 5;
    }
    .order-lg-6
    {
        order: 6;
    }
    .order-lg-7
    {
        order: 7;
    }
    .order-lg-8
    {
        order: 8;
    }
    .order-lg-9
    {
        order: 9;
    }
    .order-lg-10
    {
        order: 10;
    }
    .order-lg-11
    {
        order: 11;
    }
    .order-lg-12
    {
        order: 12;
    }
    .offset-lg-0
    {
        margin-left: 0;
    }
    .offset-lg-1
    {
        margin-left: 8.33333%;
    }
    .offset-lg-2
    {
        margin-left: 16.66667%;
    }
    .offset-lg-3
    {
        margin-left: 25%;
    }
    .offset-lg-4
    {
        margin-left: 33.33333%;
    }
    .offset-lg-5
    {
        margin-left: 41.66667%;
    }
    .offset-lg-6
    {
        margin-left: 50%;
    }
    .offset-lg-7
    {
        margin-left: 58.33333%;
    }
    .offset-lg-8
    {
        margin-left: 66.66667%;
    }
    .offset-lg-9
    {
        margin-left: 75%;
    }
    .offset-lg-10
    {
        margin-left: 83.33333%;
    }
    .offset-lg-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px)
{
    .col-xl
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-xl-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-xl-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-xl-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-xl-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-xl-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-xl-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-xl-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-xl-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-xl-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-xl-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-xl-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-xl-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-xl-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-xl-first
    {
        order: -1;
    }
    .order-xl-last
    {
        order: 13;
    }
    .order-xl-0
    {
        order: 0;
    }
    .order-xl-1
    {
        order: 1;
    }
    .order-xl-2
    {
        order: 2;
    }
    .order-xl-3
    {
        order: 3;
    }
    .order-xl-4
    {
        order: 4;
    }
    .order-xl-5
    {
        order: 5;
    }
    .order-xl-6
    {
        order: 6;
    }
    .order-xl-7
    {
        order: 7;
    }
    .order-xl-8
    {
        order: 8;
    }
    .order-xl-9
    {
        order: 9;
    }
    .order-xl-10
    {
        order: 10;
    }
    .order-xl-11
    {
        order: 11;
    }
    .order-xl-12
    {
        order: 12;
    }
    .offset-xl-0
    {
        margin-left: 0;
    }
    .offset-xl-1
    {
        margin-left: 8.33333%;
    }
    .offset-xl-2
    {
        margin-left: 16.66667%;
    }
    .offset-xl-3
    {
        margin-left: 25%;
    }
    .offset-xl-4
    {
        margin-left: 33.33333%;
    }
    .offset-xl-5
    {
        margin-left: 41.66667%;
    }
    .offset-xl-6
    {
        margin-left: 50%;
    }
    .offset-xl-7
    {
        margin-left: 58.33333%;
    }
    .offset-xl-8
    {
        margin-left: 66.66667%;
    }
    .offset-xl-9
    {
        margin-left: 75%;
    }
    .offset-xl-10
    {
        margin-left: 83.33333%;
    }
    .offset-xl-11
    {
        margin-left: 91.66667%;
    }
}

.table
{
    width: 100%;
    margin-bottom: 1rem;

    color: #8492a6;
    background-color: transparent;
}
.table th,
.table td
{
    padding: 1rem;

    vertical-align: top;

    border-top: 1px solid #eff2f7;
}
.table thead th
{
    vertical-align: bottom;

    border-bottom: 2px solid #eff2f7;
}
.table tbody + tbody
{
    border-top: 2px solid #eff2f7;
}

.table-sm th,
.table-sm td
{
    padding: .5rem;
}

.table-bordered
{
    border: 1px solid #eff2f7;
}
.table-bordered th,
.table-bordered td
{
    border: 1px solid #eff2f7;
}
.table-bordered thead th,
.table-bordered thead td
{
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody
{
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(250, 251, 254, .3);
}

.table-hover tbody tr:hover
{
    color: #8492a6;
    background-color: #fafbfe;
}

.table-primary,
.table-primary > th,
.table-primary > td
{
    background-color: #bbd4ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody
{
    border-color: #81afff;
}

.table-hover .table-primary:hover
{
    background-color: #a2c4ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th
{
    background-color: #a2c4ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td
{
    background-color: #fbfbfd;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody
{
    border-color: #f7f8fb;
}

.table-hover .table-secondary:hover
{
    background-color: #eaeaf5;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th
{
    background-color: #eaeaf5;
}

.table-success,
.table-success > th,
.table-success > td
{
    background-color: #c4f0df;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody
{
    border-color: #92e3c3;
}

.table-hover .table-success:hover
{
    background-color: #b0ebd4;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th
{
    background-color: #b0ebd4;
}

.table-info,
.table-info > th,
.table-info > td
{
    background-color: #ceeaff;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody
{
    border-color: #a4d9ff;
}

.table-hover .table-info:hover
{
    background-color: #b5dfff;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th
{
    background-color: #b5dfff;
}

.table-warning,
.table-warning > th,
.table-warning > td
{
    background-color: #ffedc9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody
{
    border-color: #ffdd9a;
}

.table-hover .table-warning:hover
{
    background-color: #ffe5b0;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th
{
    background-color: #ffe5b0;
}

.table-danger,
.table-danger > th,
.table-danger > td
{
    background-color: #ffd1d8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody
{
    border-color: #ffaab7;
}

.table-hover .table-danger:hover
{
    background-color: #ffb8c2;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th
{
    background-color: #ffb8c2;
}

.table-light,
.table-light > th,
.table-light > td
{
    background-color: #fbfbfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody
{
    border-color: #f7f8fb;
}

.table-hover .table-light:hover
{
    background-color: #eaeaf5;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th
{
    background-color: #eaeaf5;
}

.table-dark,
.table-dark > th,
.table-dark > td
{
    background-color: #c3c6cb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody
{
    border-color: #8f959e;
}

.table-hover .table-dark:hover
{
    background-color: #b5b9bf;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th
{
    background-color: #b5b9bf;
}

.table-neutral,
.table-neutral > th,
.table-neutral > td
{
    background-color: white;
}

.table-neutral th,
.table-neutral td,
.table-neutral thead th,
.table-neutral tbody + tbody
{
    border-color: white;
}

.table-hover .table-neutral:hover
{
    background-color: #f2f2f2;
}
.table-hover .table-neutral:hover > td,
.table-hover .table-neutral:hover > th
{
    background-color: #f2f2f2;
}

.table-white,
.table-white > th,
.table-white > td
{
    background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody
{
    border-color: white;
}

.table-hover .table-white:hover
{
    background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th
{
    background-color: #f2f2f2;
}

.table-active,
.table-active > th,
.table-active > td
{
    background-color: #fafbfe;
}

.table-hover .table-active:hover
{
    background-color: #e5eafa;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th
{
    background-color: #e5eafa;
}

.table .thead-dark th
{
    color: #fff;
    border-color: #34455b; 
    background-color: #273444;
}

.table .thead-light th
{
    color: #8492a6;
    border-color: #eff2f7; 
    background-color: transparent;
}

.table-dark
{
    color: #fff;
    background-color: #273444;
}
.table-dark th,
.table-dark td,
.table-dark thead th
{
    border-color: #34455b;
}
.table-dark.table-bordered
{
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(255, 255, 255, .05);
}
.table-dark.table-hover tbody tr:hover
{
    color: #fff;
    background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px)
{
    .table-responsive-sm
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 767.98px)
{
    .table-responsive-md
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 991.98px)
{
    .table-responsive-lg
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 1199.98px)
{
    .table-responsive-xl
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered
    {
        border: 0;
    }
}

.table-responsive
{
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered
{
    border: 0;
}

.form-control
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    display: block;

    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.25rem;

    transition: all .2s ease; 

    color: #8492a6;
    border: 1px solid #e0e6ed;
    border-radius: .375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075);
}
@media (prefers-reduced-motion: reduce)
{
    .form-control
    {
        transition: none;
    }
}
.form-control::-ms-expand
{
    border: 0; 
    background-color: transparent;
}
.form-control:focus
{
    color: #8492a6;
    border-color: rgba(12, 102, 255, .5);
    outline: 0;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075), 0 0 20px rgba(12, 102, 255, .1);
}
.form-control::-webkit-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.form-control::-moz-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.form-control:-ms-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.form-control::-ms-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.form-control::placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.form-control:disabled,
.form-control[readonly]
{
    opacity: 1; 
    background-color: #eff2f7;
}

select.form-control:focus::-ms-value
{
    color: #8492a6;
    background-color: #fff;
}

.form-control-file,
.form-control-range
{
    display: block;

    width: 100%;
}

.col-form-label
{
    font-size: inherit;
    line-height: 1.5; 

    margin-bottom: 0;
    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
}

.col-form-label-lg
{
    font-size: 1rem;
    line-height: 1.5; 

    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
}

.col-form-label-sm
{
    font-size: .875rem;
    line-height: 1.5; 

    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
}

.form-control-plaintext
{
    line-height: 1.5;

    display: block;

    width: 100%;
    margin-bottom: 0;
    padding-top: .75rem;
    padding-bottom: .75rem;

    color: #8492a6;
    border: solid transparent;
    border-width: 1px 0; 
    background-color: transparent;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg
{
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm
{
    font-size: .875rem;
    line-height: 1.5;

    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1.25rem;

    border-radius: .2rem;
}

.form-control-lg
{
    font-size: 1rem;
    line-height: 1.5;

    height: calc(1.5em + 2rem + 2px);
    padding: 1rem 1.875rem;

    border-radius: .5rem;
}

select.form-control[size],
select.form-control[multiple]
{
    height: auto;
}

textarea.form-control
{
    height: auto;
}

.form-group
{
    margin-bottom: 1rem;
}

.form-text
{
    display: block;

    margin-top: .25rem;
}

.form-row
{
    display: flex;

    margin-right: -5px;
    margin-left: -5px; 

    flex-wrap: wrap;
}
.form-row > .col,
.form-row > [class*='col-']
{
    padding-right: 5px;
    padding-left: 5px;
}

.form-check
{
    position: relative;

    display: block;

    padding-left: 1.25rem;
}

.form-check-input
{
    position: absolute;

    margin-top: .3rem;
    margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label
{
    color: #8492a6;
}

.form-check-label
{
    margin-bottom: 0;
}

.form-check-inline
{
    display: inline-flex;

    margin-right: .75rem; 
    padding-left: 0;

    align-items: center;
}
.form-check-inline .form-check-input
{
    position: static;

    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.valid-feedback
{
    font-size: 80%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #2dca8c;
}

.valid-tooltip
{
    font-size: .875rem;
    line-height: 1.7;

    position: absolute;
    z-index: 5;
    top: 100%;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: .375rem; 
    background-color: rgba(45, 202, 140, .9);
}

.was-validated .form-control:valid,
.form-control.is-valid
{
    padding-right: calc(1.5em + 1.5rem);

    border-color: #2dca8c;
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%232DCA8C\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .375rem);
    background-size: calc(.75em + .75rem) calc(.75em + .75rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus
{
    border-color: #2dca8c;
    box-shadow: 0 0 0 0 rgba(45, 202, 140, .25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid
{
    padding-right: calc(1.5em + 1.5rem);

    background-position: top calc(.375em + .375rem) right calc(.375em + .375rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid
{
    padding-right: calc((1em + 1.5rem) * 3 / 4 + 2.25rem);

    border-color: #2dca8c;
    background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%233C4858\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right 1.25rem center/8px 10px, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%232DCA8C\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e') #fff no-repeat center right 2.25rem/calc(.75em + .75rem) calc(.75em + .75rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus
{
    border-color: #2dca8c;
    box-shadow: 0 0 0 0 rgba(45, 202, 140, .25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label
{
    color: #2dca8c;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label
{
    color: #2dca8c;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before
{
    border-color: #2dca8c;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before
{
    border-color: #52d8a3;
    background-color: #52d8a3;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 0 rgba(45, 202, 140, .25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #2dca8c;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label
{
    border-color: #2dca8c;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label
{
    border-color: #2dca8c;
    box-shadow: 0 0 0 0 rgba(45, 202, 140, .25);
}

.invalid-feedback
{
    font-size: 80%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #ff5c75;
}

.invalid-tooltip
{
    font-size: .875rem;
    line-height: 1.7;

    position: absolute;
    z-index: 5;
    top: 100%;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: .375rem; 
    background-color: rgba(255, 92, 117, .9);
}

.was-validated .form-control:invalid,
.form-control.is-invalid
{
    padding-right: calc(1.5em + 1.5rem);

    border-color: #ff5c75;
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23FF5C75\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23FF5C75\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E');
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .375rem);
    background-size: calc(.75em + .75rem) calc(.75em + .75rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus
{
    border-color: #ff5c75;
    box-shadow: 0 0 0 0 rgba(255, 92, 117, .25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid
{
    padding-right: calc(1.5em + 1.5rem);

    background-position: top calc(.375em + .375rem) right calc(.375em + .375rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid
{
    padding-right: calc((1em + 1.5rem) * 3 / 4 + 2.25rem);

    border-color: #ff5c75;
    background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%233C4858\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right 1.25rem center/8px 10px, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23FF5C75\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23FF5C75\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E') #fff no-repeat center right 2.25rem/calc(.75em + .75rem) calc(.75em + .75rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus
{
    border-color: #ff5c75;
    box-shadow: 0 0 0 0 rgba(255, 92, 117, .25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label
{
    color: #ff5c75;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label
{
    color: #ff5c75;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before
{
    border-color: #ff5c75;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before
{
    border-color: #ff8fa0;
    background-color: #ff8fa0;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 0 rgba(255, 92, 117, .25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #ff5c75;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label
{
    border-color: #ff5c75;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label
{
    border-color: #ff5c75;
    box-shadow: 0 0 0 0 rgba(255, 92, 117, .25);
}

.form-inline
{
    display: flex;

    flex-flow: row wrap;
    align-items: center;
}
.form-inline .form-check
{
    width: 100%;
}
@media (min-width: 576px)
{
    .form-inline label
    {
        display: flex;

        margin-bottom: 0; 

        align-items: center;
        justify-content: center;
    }
    .form-inline .form-group
    {
        display: flex;

        margin-bottom: 0; 

        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
    }
    .form-inline .form-control
    {
        display: inline-block;

        width: auto;

        vertical-align: middle;
    }
    .form-inline .form-control-plaintext
    {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select
    {
        width: auto;
    }
    .form-inline .form-check
    {
        display: flex;

        width: auto;
        padding-left: 0; 

        align-items: center;
        justify-content: center;
    }
    .form-inline .form-check-input
    {
        position: relative;

        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0; 

        flex-shrink: 0;
    }
    .form-inline .custom-control
    {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label
    {
        margin-bottom: 0;
    }
}

.btn
{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;

    display: inline-block;

    padding: .75rem 1.75rem;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; 
    text-align: center;
    vertical-align: middle;

    color: #8492a6;
    border: 1px solid transparent;
    border-radius: .375rem;
    background-color: transparent;
}
@media (prefers-reduced-motion: reduce)
{
    .btn
    {
        transition: none;
    }
}
.btn:hover
{
    text-decoration: none; 

    color: #8492a6;
}
.btn:focus,
.btn.focus
{
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 89, 242, .25);
}
.btn.disabled,
.btn:disabled
{
    opacity: .65;
    box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus
{
    box-shadow: 0 0 0 rgba(0, 89, 242, .25), inset 0 1px 0 rgba(255, 255, 255, .15);
}

a.btn.disabled,
fieldset:disabled a.btn
{
    pointer-events: none;
}

.btn-primary
{
    color: #fff;
    border-color: #0c66ff;
    background-color: #0c66ff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-primary:hover
{
    color: #fff;
    border-color: #0050d8; 
    background-color: #0055e5;
}
.btn-primary:focus,
.btn-primary.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(12, 102, 255, .35);
}
.btn-primary.disabled,
.btn-primary:disabled
{
    color: #fff;
    border-color: #0c66ff; 
    background-color: #0c66ff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle
{
    color: #fff;
    border-color: #004bcb; 
    background-color: #0050d8;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(12, 102, 255, .35);
}

.btn-secondary
{
    color: #273444;
    border-color: #eff2f7;
    background-color: #eff2f7;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-secondary:hover
{
    color: #273444;
    border-color: #cdd6e6; 
    background-color: #d6ddea;
}
.btn-secondary:focus,
.btn-secondary.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}
.btn-secondary.disabled,
.btn-secondary:disabled
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle
{
    color: #273444;
    border-color: #c5cfe2; 
    background-color: #cdd6e6;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}

.btn-success
{
    color: #fff;
    border-color: #2dca8c;
    background-color: #2dca8c;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-success:hover
{
    color: #fff;
    border-color: #24a06f; 
    background-color: #26ab76;
}
.btn-success:focus,
.btn-success.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(45, 202, 140, .35);
}
.btn-success.disabled,
.btn-success:disabled
{
    color: #fff;
    border-color: #2dca8c; 
    background-color: #2dca8c;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle
{
    color: #fff;
    border-color: #219668; 
    background-color: #24a06f;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(45, 202, 140, .35);
}

.btn-info
{
    color: #fff;
    border-color: #50b5ff;
    background-color: #50b5ff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-info:hover
{
    color: #fff;
    border-color: #1d9fff; 
    background-color: #2aa5ff;
}
.btn-info:focus,
.btn-info.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(80, 181, 255, .35);
}
.btn-info.disabled,
.btn-info:disabled
{
    color: #fff;
    border-color: #50b5ff; 
    background-color: #50b5ff;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle
{
    color: #fff;
    border-color: #109aff; 
    background-color: #1d9fff;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(80, 181, 255, .35);
}

.btn-warning
{
    color: #273444;
    border-color: #ffbe3d;
    background-color: #ffbe3d;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-warning:hover
{
    color: #273444;
    border-color: #ffad0a; 
    background-color: #ffb117;
}
.btn-warning:focus,
.btn-warning.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 190, 61, .35);
}
.btn-warning.disabled,
.btn-warning:disabled
{
    color: #273444;
    border-color: #ffbe3d; 
    background-color: #ffbe3d;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle
{
    color: #fff;
    border-color: #fca800; 
    background-color: #ffad0a;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 190, 61, .35);
}

.btn-danger
{
    color: #fff;
    border-color: #ff5c75;
    background-color: #ff5c75;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-danger:hover
{
    color: #fff;
    border-color: #ff294a; 
    background-color: #ff3655;
}
.btn-danger:focus,
.btn-danger.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 92, 117, .35);
}
.btn-danger.disabled,
.btn-danger:disabled
{
    color: #fff;
    border-color: #ff5c75; 
    background-color: #ff5c75;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle
{
    color: #fff;
    border-color: #ff1c3f; 
    background-color: #ff294a;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 92, 117, .35);
}

.btn-light
{
    color: #273444;
    border-color: #eff2f7;
    background-color: #eff2f7;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-light:hover
{
    color: #273444;
    border-color: #cdd6e6; 
    background-color: #d6ddea;
}
.btn-light:focus,
.btn-light.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}
.btn-light.disabled,
.btn-light:disabled
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle
{
    color: #273444;
    border-color: #c5cfe2; 
    background-color: #cdd6e6;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}

.btn-dark
{
    color: #fff;
    border-color: #273444;
    background-color: #273444;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-dark:hover
{
    color: #fff;
    border-color: #141b24; 
    background-color: #19212c;
}
.btn-dark:focus,
.btn-dark.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(39, 52, 68, .35);
}
.btn-dark.disabled,
.btn-dark:disabled
{
    color: #fff;
    border-color: #273444; 
    background-color: #273444;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle
{
    color: #fff;
    border-color: #10151b; 
    background-color: #141b24;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(39, 52, 68, .35);
}

.btn-neutral
{
    color: #273444;
    border-color: #fff;
    background-color: #fff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-neutral:hover
{
    color: #273444;
    border-color: #e6e6e6; 
    background-color: #ececec;
}
.btn-neutral:focus,
.btn-neutral.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}
.btn-neutral.disabled,
.btn-neutral:disabled
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.show > .btn-neutral.dropdown-toggle
{
    color: #273444;
    border-color: #dfdfdf; 
    background-color: #e6e6e6;
}
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-neutral.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}

.btn-white
{
    color: #273444;
    border-color: #fff;
    background-color: #fff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-white:hover
{
    color: #273444;
    border-color: #e6e6e6; 
    background-color: #ececec;
}
.btn-white:focus,
.btn-white.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}
.btn-white.disabled,
.btn-white:disabled
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle
{
    color: #273444;
    border-color: #dfdfdf; 
    background-color: #e6e6e6;
}
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}

.btn-outline-primary
{
    color: #0c66ff;
    border-color: #0c66ff;
}
.btn-outline-primary:hover
{
    color: #fff;
    border-color: #0c66ff; 
    background-color: #0c66ff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(12, 102, 255, .35);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled
{
    color: #0c66ff;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle
{
    color: #fff;
    border-color: #0c66ff; 
    background-color: #0c66ff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(12, 102, 255, .35);
}

.btn-outline-secondary
{
    color: #eff2f7;
    border-color: #eff2f7;
}
.btn-outline-secondary:hover
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled
{
    color: #eff2f7;
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}

.btn-outline-success
{
    color: #2dca8c;
    border-color: #2dca8c;
}
.btn-outline-success:hover
{
    color: #fff;
    border-color: #2dca8c; 
    background-color: #2dca8c;
}
.btn-outline-success:focus,
.btn-outline-success.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(45, 202, 140, .35);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled
{
    color: #2dca8c;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle
{
    color: #fff;
    border-color: #2dca8c; 
    background-color: #2dca8c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(45, 202, 140, .35);
}

.btn-outline-info
{
    color: #50b5ff;
    border-color: #50b5ff;
}
.btn-outline-info:hover
{
    color: #fff;
    border-color: #50b5ff; 
    background-color: #50b5ff;
}
.btn-outline-info:focus,
.btn-outline-info.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(80, 181, 255, .35);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled
{
    color: #50b5ff;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle
{
    color: #fff;
    border-color: #50b5ff; 
    background-color: #50b5ff;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(80, 181, 255, .35);
}

.btn-outline-warning
{
    color: #ffbe3d;
    border-color: #ffbe3d;
}
.btn-outline-warning:hover
{
    color: #273444;
    border-color: #ffbe3d; 
    background-color: #ffbe3d;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 190, 61, .35);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled
{
    color: #ffbe3d;
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle
{
    color: #273444;
    border-color: #ffbe3d; 
    background-color: #ffbe3d;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 190, 61, .35);
}

.btn-outline-danger
{
    color: #ff5c75;
    border-color: #ff5c75;
}
.btn-outline-danger:hover
{
    color: #fff;
    border-color: #ff5c75; 
    background-color: #ff5c75;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 92, 117, .35);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled
{
    color: #ff5c75;
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle
{
    color: #fff;
    border-color: #ff5c75; 
    background-color: #ff5c75;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 92, 117, .35);
}

.btn-outline-light
{
    color: #eff2f7;
    border-color: #eff2f7;
}
.btn-outline-light:hover
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-outline-light:focus,
.btn-outline-light.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled
{
    color: #eff2f7;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(239, 242, 247, .35);
}

.btn-outline-dark
{
    color: #273444;
    border-color: #273444;
}
.btn-outline-dark:hover
{
    color: #fff;
    border-color: #273444; 
    background-color: #273444;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(39, 52, 68, .35);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled
{
    color: #273444;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle
{
    color: #fff;
    border-color: #273444; 
    background-color: #273444;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(39, 52, 68, .35);
}

.btn-outline-neutral
{
    color: #fff;
    border-color: #fff;
}
.btn-outline-neutral:hover
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-outline-neutral:focus,
.btn-outline-neutral.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}
.btn-outline-neutral.disabled,
.btn-outline-neutral:disabled
{
    color: #fff;
    background-color: transparent;
}
.btn-outline-neutral:not(:disabled):not(.disabled):active,
.btn-outline-neutral:not(:disabled):not(.disabled).active,
.show > .btn-outline-neutral.dropdown-toggle
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-outline-neutral:not(:disabled):not(.disabled):active:focus,
.btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-neutral.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}

.btn-outline-white
{
    color: #fff;
    border-color: #fff;
}
.btn-outline-white:hover
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-outline-white:focus,
.btn-outline-white.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled
{
    color: #fff;
    background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(255, 255, 255, .35);
}

.btn-link
{
    font-weight: 400;

    text-decoration: none; 

    color: #0c66ff;
}
.btn-link:hover
{
    text-decoration: none; 

    color: #0047bf;
}
.btn-link:focus,
.btn-link.focus
{
    text-decoration: none;

    box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled
{
    pointer-events: none; 

    color: #c0ccda;
}

.btn-lg,
.btn-group-lg > .btn
{
    font-size: 1rem;
    line-height: 1.5;

    padding: 1rem 1.875rem;

    border-radius: .5rem;
}

.btn-sm,
.btn-group-sm > .btn
{
    font-size: .875rem;
    line-height: 1.5;

    padding: .5rem 1.25rem;

    border-radius: .375rem;
}

.btn-block
{
    display: block;

    width: 100%;
}
.btn-block + .btn-block
{
    margin-top: .5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block
{
    width: 100%;
}

.fade
{
    transition: opacity .2s linear;
}
@media (prefers-reduced-motion: reduce)
{
    .fade
    {
        transition: none;
    }
}
.fade:not(.show)
{
    opacity: 0;
}

.collapse:not(.show)
{
    display: none;
}

.collapsing
{
    position: relative;

    overflow: hidden;

    height: 0;

    transition: height .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .collapsing
    {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft
{
    position: relative;
}

.dropdown-toggle
{
    white-space: nowrap;
}
.dropdown-toggle::after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: 10.2px;

    content: '\f107'; 

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropdown-menu
{
    font-size: .875rem;

    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    display: none;
    float: left;

    min-width: 12rem;
    margin: .125rem 0 0;
    padding: .35rem 0;

    list-style: none;

    text-align: left;

    color: #8492a6;
    border: 1px solid #eff2f7;
    border-radius: .5rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
}

.dropdown-menu-left
{
    right: auto;
    left: 0;
}

.dropdown-menu-right
{
    right: 0;
    left: auto;
}

@media (min-width: 576px)
{
    .dropdown-menu-sm-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px)
{
    .dropdown-menu-md-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px)
{
    .dropdown-menu-lg-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px)
{
    .dropdown-menu-xl-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right
    {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu
{
    top: auto;
    bottom: 100%;

    margin-top: 0;
    margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: 10.2px;

    content: '\f106'; 

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.dropup .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-menu
{
    top: 0;
    right: auto;
    left: 100%;

    margin-top: 0;
    margin-left: .125rem;
}

.dropright .dropdown-toggle::after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: 10.2px;

    content: '\f105'; 

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.dropright .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-toggle::after
{
    vertical-align: 0;
}

.dropleft .dropdown-menu
{
    top: 0;
    right: 100%;
    left: auto;

    margin-top: 0;
    margin-right: .125rem;
}

.dropleft .dropdown-toggle::after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700; 
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: 10.2px;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.dropleft .dropdown-toggle::after
{
    display: none;
}

.dropleft .dropdown-toggle::before
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;

    display: inline-block;

    margin-right: 10.2px;

    content: '\f104';
}

.dropleft .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropleft .dropdown-toggle::before
{
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left']
{
    right: auto;
    bottom: auto;
}

.dropdown-divider
{
    overflow: hidden;

    height: 0;
    margin: .5rem 0;

    border-top: 1px solid #eff2f7;
}

.dropdown-item
{
    font-weight: 400;

    display: block;
    clear: both;

    width: 100%;
    padding: .25rem 1rem;

    text-align: inherit;
    white-space: nowrap;

    color: #8492a6;
    border: 0; 
    background-color: transparent;
}
.dropdown-item:hover,
.dropdown-item:focus
{
    text-decoration: none;

    color: #0c66ff;
    background-color: transparent;
}
.dropdown-item.active,
.dropdown-item:active
{
    text-decoration: none;

    color: #0c66ff;
    background-color: transparent;
}
.dropdown-item.disabled,
.dropdown-item:disabled
{
    pointer-events: none;

    color: #c0ccda;
    background-color: transparent;
}

.dropdown-menu.show
{
    display: block;
}

.dropdown-header
{
    font-size: .875rem;

    display: block;

    margin-bottom: 0;
    padding: .35rem 1rem;

    white-space: nowrap; 

    color: #c0ccda;
}

.dropdown-item-text
{
    display: block;

    padding: .25rem 1rem;

    color: #8492a6;
}

.btn-group,
.btn-group-vertical
{
    position: relative;

    display: inline-flex;

    vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn
{
    position: relative;

    flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover
{
    z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active
{
    z-index: 1;
}

.btn-toolbar
{
    display: flex;

    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group
{
    width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child)
{
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split
{
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after
{
    margin-left: 0;
}
.dropleft .dropdown-toggle-split::before
{
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split
{
    padding-right: .9375rem;
    padding-left: .9375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split
{
    padding-right: 1.40625rem;
    padding-left: 1.40625rem;
}

.btn-group.show .dropdown-toggle
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-group.show .dropdown-toggle.btn-link
{
    box-shadow: none;
}

.btn-group-vertical
{
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group
{
    width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child)
{
    margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn
{
    margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox']
{
    position: absolute;

    clip: rect(0, 0, 0, 0);

    pointer-events: none;
}

.input-group
{
    position: relative;

    display: flex;

    width: 100%; 

    flex-wrap: wrap;
    align-items: stretch;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file
{
    position: relative;

    width: 1%;
    margin-bottom: 0; 

    flex: 1 1 auto;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file
{
    margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label
{
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus
{
    z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file
{
    display: flex;

    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append
{
    display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn
{
    position: relative;
    z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus
{
    z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn
{
    margin-left: -1px;
}

.input-group-prepend
{
    margin-right: -1px;
}

.input-group-append
{
    margin-left: -1px;
}

.input-group-text
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    display: flex;

    margin-bottom: 0;
    padding: .75rem 1.25rem;

    text-align: center;
    white-space: nowrap;

    color: #aabacd;
    border: 1px solid #e0e6ed;
    border-radius: .375rem; 
    background-color: #fafbfe;

    align-items: center;
}
.input-group-text input[type='radio'],
.input-group-text input[type='checkbox']
{
    margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select
{
    height: calc(1.5em + 2rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn
{
    font-size: 1rem;
    line-height: 1.5;

    padding: 1rem 1.875rem;

    border-radius: .5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select
{
    height: calc(1.5em + 1rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn
{
    font-size: .875rem;
    line-height: 1.5;

    padding: .5rem 1.25rem;

    border-radius: .2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select
{
    padding-right: 2.25rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control
{
    position: relative;

    display: block;

    min-height: 1.7rem;
    padding-left: 1.75rem;
}

.custom-control-inline
{
    display: inline-flex;

    margin-right: 1rem;
}

.custom-control-input
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before
{
    color: #fff;
    border-color: #0059f2;
    background-color: #0059f2;
    box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before
{
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075), 0 0 20px rgba(12, 102, 255, .1);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: rgba(12, 102, 255, .5);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before
{
    color: #fff;
    border-color: #a5c6ff;
    background-color: #a5c6ff;
    box-shadow: none;
}
.custom-control-input:disabled ~ .custom-control-label
{
    color: #c0ccda;
}
.custom-control-input:disabled ~ .custom-control-label::before
{
    background-color: #eff2f7;
}

.custom-control-label
{
    position: relative;

    margin-bottom: 0;

    vertical-align: top;
}
.custom-control-label::before
{
    position: absolute;
    top: .35rem;
    left: -1.75rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';
    pointer-events: none;

    border: #eff2f7 solid 0;
    background-color: #e5e9f2;
    box-shadow: none;
}
.custom-control-label::after
{
    position: absolute;
    top: .35rem;
    left: -1.75rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';

    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before
{
    border-radius: .375rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23FFF\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e');
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before
{
    border-color: #0059f2;
    background-color: #0059f2;
    box-shadow: none;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 4\'%3e%3cpath stroke=\'%23FFF\' d=\'M0 2h4\'/%3e%3c/svg%3e');
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(12, 102, 255, .5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before
{
    background-color: rgba(12, 102, 255, .5);
}

.custom-radio .custom-control-label::before
{
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'-4 -4 8 8\'%3e%3ccircle r=\'3\' fill=\'%23FFF\'/%3e%3c/svg%3e');
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(12, 102, 255, .5);
}

.custom-switch
{
    padding-left: 3.75rem;
}
.custom-switch .custom-control-label::before
{
    left: -3.75rem;

    width: 3rem;

    pointer-events: all;

    border-radius: .5625rem;
}
.custom-switch .custom-control-label::after
{
    top: calc(.35rem + 0);
    left: calc(-3.75rem + 0);

    width: 1.125rem;
    height: 1.125rem;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out; 

    border-radius: .5625rem;
    background-color: #eff2f7;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-switch .custom-control-label::after
    {
        transition: none;
    }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after
{
    -webkit-transform: translateX(2rem);
            transform: translateX(2rem); 

    background-color: #e5e9f2;
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(12, 102, 255, .5);
}

.custom-select
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    display: inline-block;

    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 2.25rem .75rem 1.25rem;

    vertical-align: middle;

    color: #8492a6;
    border: 1px solid #e0e6ed;
    border-radius: .375rem;
    background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%233C4858\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right 1.25rem center/8px 10px;
    background-color: #fff;
    box-shadow: inset 0 1px 2px rgba(31, 45, 61, .075);

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.custom-select:focus
{
    border-color: rgba(12, 102, 255, .5);
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(31, 45, 61, .075), 0 0 20px rgba(12, 102, 255, .1);
}
.custom-select:focus::-ms-value
{
    color: #8492a6;
    background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1'])
{
    height: auto;
    padding-right: 1.25rem;

    background-image: none;
}
.custom-select:disabled
{
    color: #c0ccda;
    background-color: #eff2f7;
}
.custom-select::-ms-expand
{
    display: none;
}

.custom-select-sm
{
    font-size: .875rem; 

    height: calc(1.5em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.25rem;
}

.custom-select-lg
{
    font-size: 1rem; 

    height: calc(1.5em + 2rem + 2px);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.875rem;
}

.custom-file
{
    position: relative;

    display: inline-block;

    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    margin-bottom: 0;
}

.custom-file-input
{
    position: relative;
    z-index: 2;

    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    margin: 0;

    opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label
{
    border-color: rgba(12, 102, 255, .5);
    box-shadow: 0 0 20px rgba(12, 102, 255, .1);
}
.custom-file-input:disabled ~ .custom-file-label
{
    background-color: #eff2f7;
}
.custom-file-input:lang(en) ~ .custom-file-label::after
{
    content: 'Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after
{
    content: attr(data-browse);
}

.custom-file-label
{
    font-weight: 400;
    line-height: 1.5;

    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;

    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.25rem;

    color: #8492a6;
    border: 1px solid #e0e6ed;
    border-radius: .375rem;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075);
}
.custom-file-label::after
{
    line-height: 1.5;

    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;

    height: calc(1.5em + 1.5rem);
    padding: .75rem 1.25rem;

    content: 'Browse';

    color: #8492a6;
    border-left: inherit;
    border-radius: 0 .375rem .375rem 0; 
    background-color: #fafbfe;
}

.custom-range
{
    width: 100%;
    height: calc(1rem + 0);
    padding: 0;

    background-color: transparent;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.custom-range:focus
{
    outline: none;
}
.custom-range:focus::-webkit-slider-thumb
{
    box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(12, 102, 255, .1);
}
.custom-range:focus::-moz-range-thumb
{
    box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(12, 102, 255, .1);
}
.custom-range:focus::-ms-thumb
{
    box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(12, 102, 255, .1);
}
.custom-range::-moz-focus-outer
{
    border: 0;
}
.custom-range::-webkit-slider-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #0059f2;
    box-shadow: 0 .1rem .25rem rgba(31, 45, 61, .1);

    -webkit-appearance: none;
            appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-webkit-slider-thumb
    {
        transition: none;
    }
}
.custom-range::-webkit-slider-thumb:active
{
    background-color: #a5c6ff;
}
.custom-range::-webkit-slider-runnable-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    background-color: #e5e9f2;
    box-shadow: inset 0 .25rem .25rem rgba(31, 45, 61, .1);
}
.custom-range::-moz-range-thumb
{
    width: 1rem;
    height: 1rem;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #0059f2;
    box-shadow: 0 .1rem .25rem rgba(31, 45, 61, .1);

    -moz-appearance: none;
         appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-moz-range-thumb
    {
        transition: none;
    }
}
.custom-range::-moz-range-thumb:active
{
    background-color: #a5c6ff;
}
.custom-range::-moz-range-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    background-color: #e5e9f2;
    box-shadow: inset 0 .25rem .25rem rgba(31, 45, 61, .1);
}
.custom-range::-ms-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #0059f2;
    box-shadow: 0 .1rem .25rem rgba(31, 45, 61, .1);

    appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-ms-thumb
    {
        transition: none;
    }
}
.custom-range::-ms-thumb:active
{
    background-color: #a5c6ff;
}
.custom-range::-ms-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-width: .5rem;
    border-color: transparent;
    background-color: transparent;
    box-shadow: inset 0 .25rem .25rem rgba(31, 45, 61, .1);
}
.custom-range::-ms-fill-lower
{
    border-radius: 1rem; 
    background-color: #e5e9f2;
}
.custom-range::-ms-fill-upper
{
    margin-right: 15px;

    border-radius: 1rem; 
    background-color: #e5e9f2;
}
.custom-range:disabled::-webkit-slider-thumb
{
    background-color: #d3dce6;
}
.custom-range:disabled::-webkit-slider-runnable-track
{
    cursor: default;
}
.custom-range:disabled::-moz-range-thumb
{
    background-color: #d3dce6;
}
.custom-range:disabled::-moz-range-track
{
    cursor: default;
}
.custom-range:disabled::-ms-thumb
{
    background-color: #d3dce6;
}

.custom-control-label::before,
.custom-file-label,
.custom-select
{
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-control-label::before,
    .custom-file-label,
    .custom-select
    {
        transition: none;
    }
}

.nav
{
    display: flex;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none; 

    flex-wrap: wrap;
}

.nav-link
{
    display: block;

    padding: .25rem 1rem;
}
.nav-link:hover,
.nav-link:focus
{
    text-decoration: none;
}
.nav-link.disabled
{
    cursor: default; 
    pointer-events: none;

    color: #c0ccda;
}

.nav-tabs
{
    border-bottom: 1px solid #eff2f7;
}
.nav-tabs .nav-item
{
    margin-bottom: -1px;
}
.nav-tabs .nav-link
{
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus
{
    border-color: transparent transparent transparent;
}
.nav-tabs .nav-link.disabled
{
    color: #c0ccda;
    border-color: transparent; 
    background-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
{
    color: #8492a6;
    border-color: transparent transparent #0c66ff; 
    background-color: transparent;
}
.nav-tabs .dropdown-menu
{
    margin-top: -1px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link
{
    border-radius: .375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link
{
    color: #fff;
    background-color: #0059f2;
}

.nav-fill .nav-item
{
    text-align: center; 

    flex: 1 1 auto;
}

.nav-justified .nav-item
{
    text-align: center; 

    flex-basis: 0;
    flex-grow: 1;
}

.tab-content > .tab-pane
{
    display: none;
}

.tab-content > .active
{
    display: block;
}

.navbar
{
    position: relative;

    display: flex;

    padding: .75rem 1rem; 

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar > .container,
.navbar > .container-fluid
{
    display: flex;

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand
{
    font-size: 1.25rem;
    line-height: inherit;

    display: inline-block;

    margin-right: 1rem;
    padding-top: .0375rem;
    padding-bottom: .0375rem;

    white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus
{
    text-decoration: none;
}

.navbar-nav
{
    display: flex;
    flex-direction: column;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
}
.navbar-nav .nav-link
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu
{
    position: static;

    float: none;
}

.navbar-text
{
    display: inline-block;

    padding-top: .25rem;
    padding-bottom: .25rem;
}

.navbar-collapse
{
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler
{
    font-size: 1.25rem;
    line-height: 1;

    padding: .25rem .75rem;

    border: 1px solid transparent;
    border-radius: .375rem; 
    background-color: transparent;
}
.navbar-toggler:hover,
.navbar-toggler:focus
{
    text-decoration: none;
}

.navbar-toggler-icon
{
    display: inline-block;

    width: 1.5em;
    height: 1.5em;

    content: '';
    vertical-align: middle;

    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link
    {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link
    {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link
    {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link
    {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler
    {
        display: none;
    }
}

.navbar-expand
{
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav
{
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu
{
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link
{
    padding-right: 1rem;
    padding-left: 1rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid
{
    flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse
{
    display: flex !important;

    flex-basis: auto;
}
.navbar-expand .navbar-toggler
{
    display: none;
}

.navbar-light .navbar-brand
{
    color: rgba(31, 45, 61, .9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus
{
    color: rgba(31, 45, 61, .9);
}

.navbar-light .navbar-nav .nav-link
{
    color: rgba(31, 45, 61, .5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus
{
    color: rgba(31, 45, 61, .7);
}
.navbar-light .navbar-nav .nav-link.disabled
{
    color: rgba(31, 45, 61, .3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active
{
    color: rgba(31, 45, 61, .9);
}

.navbar-light .navbar-toggler
{
    color: rgba(31, 45, 61, .5);
    border-color: transparent;
}

.navbar-light .navbar-toggler-icon
{
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'rgba(31, 45, 61, 0.5)\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E');
}

.navbar-light .navbar-text
{
    color: rgba(31, 45, 61, .5);
}
.navbar-light .navbar-text a
{
    color: rgba(31, 45, 61, .9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus
{
    color: rgba(31, 45, 61, .9);
}

.navbar-dark .navbar-brand
{
    color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus
{
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link
{
    color: rgba(255, 255, 255, .85);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus
{
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled
{
    color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active
{
    color: #fff;
}

.navbar-dark .navbar-toggler
{
    color: rgba(255, 255, 255, .85);
    border-color: transparent;
}

.navbar-dark .navbar-toggler-icon
{
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'rgba(255, 255, 255, 0.85)\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E');
}

.navbar-dark .navbar-text
{
    color: rgba(255, 255, 255, .85);
}
.navbar-dark .navbar-text a
{
    color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus
{
    color: #fff;
}

.card
{
    position: relative;

    display: flex;
    flex-direction: column;

    min-width: 0;

    word-wrap: break-word;

    border: 1px solid #eff2f7;
    border-radius: 1rem; 
    background-color: #fff;
    background-clip: border-box;
}
.card > hr
{
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child
{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.card > .list-group:last-child .list-group-item:last-child
{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.card-body
{
    padding: 1.5rem;

    color: #8492a6; 

    flex: 1 1 auto;
}

.card-title
{
    margin-bottom: 1.25rem;
}

.card-subtitle
{
    margin-top: -.625rem;
    margin-bottom: 0;
}

.card-text:last-child
{
    margin-bottom: 0;
}

.card-link:hover
{
    text-decoration: none;
}

.card-link + .card-link
{
    margin-left: 1.5rem;
}

.card-header
{
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;

    border-bottom: 1px solid #eff2f7; 
    background-color: transparent;
}
.card-header:first-child
{
    border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child
{
    border-top: 0;
}

.card-footer
{
    padding: 1.25rem 1.5rem;

    border-top: 1px solid #eff2f7; 
    background-color: transparent;
}
.card-footer:last-child
{
    border-radius: 0 0 calc(1rem - 1px) calc(1rem - 1px);
}

.card-header-tabs
{
    margin-right: -.75rem;
    margin-bottom: -1.25rem;
    margin-left: -.75rem;

    border-bottom: 0;
}

.card-header-pills
{
    margin-right: -.75rem;
    margin-left: -.75rem;
}

.card-img-overlay
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1.25rem;
}

.card-img
{
    width: 100%;

    border-radius: calc(1rem - 1px);
}

.card-img-top
{
    width: 100%;

    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
}

.card-img-bottom
{
    width: 100%;

    border-bottom-right-radius: calc(1rem - 1px);
    border-bottom-left-radius: calc(1rem - 1px);
}

.card-deck
{
    display: flex;
    flex-direction: column;
}
.card-deck .card
{
    margin-bottom: 15px;
}
@media (min-width: 576px)
{
    .card-deck
    {
        margin-right: -15px;
        margin-left: -15px; 

        flex-flow: row wrap;
    }
    .card-deck .card
    {
        display: flex;
        flex-direction: column;

        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; 

        flex: 1 0;
    }
}

.card-group
{
    display: flex;
    flex-direction: column;
}
.card-group > .card
{
    margin-bottom: 15px;
}
@media (min-width: 576px)
{
    .card-group
    {
        flex-flow: row wrap;
    }
    .card-group > .card
    {
        margin-bottom: 0; 

        flex: 1 0;
    }
    .card-group > .card + .card
    {
        margin-left: 0;

        border-left: 0;
    }
    .card-group > .card:not(:last-child)
    {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header
    {
        border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer
    {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child)
    {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header
    {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer
    {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card
{
    margin-bottom: 1.25rem;
}

@media (min-width: 576px)
{
    .card-columns
    {
        -webkit-column-count: 3;
           -moz-column-count: 3;
                column-count: 3;
        -webkit-column-gap: 1.25rem;
           -moz-column-gap: 1.25rem;
                column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card
    {
        display: inline-block;

        width: 100%;
    }
}

.accordion > .card
{
    overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child
{
    border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type)
{
    border-bottom: 0;
    border-radius: 0;
}
.accordion > .card:first-of-type
{
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion > .card .card-header
{
    margin-bottom: -1px;
}

.breadcrumb
{
    display: flex;

    margin-bottom: 0;
    padding: .75rem 1rem;

    list-style: none;

    border-radius: .375rem; 
    background-color: transparent;

    flex-wrap: wrap;
}

.breadcrumb-item + .breadcrumb-item
{
    padding-left: .5rem;
}
.breadcrumb-item + .breadcrumb-item::before
{
    display: inline-block;

    padding-right: .5rem;

    content: '/'; 

    color: #e0e6ed;
}

.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: none;
}

.breadcrumb-item.active
{
    color: #c0ccda;
}

.pagination
{
    display: flex;

    padding-left: 0;

    list-style: none;

    border-radius: .375rem;
}

.page-link
{
    line-height: 1.25;

    position: relative;

    display: block;

    margin-left: -1px;
    padding: .5rem .75rem;

    color: #c0ccda;
    border: 1px solid #e5e9f2; 
    background-color: #fff;
}
.page-link:hover
{
    z-index: 2;

    text-decoration: none;

    color: #c0ccda;
    border-color: #e5e9f2; 
    background-color: #e5e9f2;
}
.page-link:focus
{
    z-index: 2;

    outline: 0;
    box-shadow: 0 0 0 rgba(0, 89, 242, .25);
}

.page-item:first-child .page-link
{
    margin-left: 0;

    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.page-item:last-child .page-link
{
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
}

.page-item.active .page-link
{
    z-index: 1;

    color: #fff;
    border-color: #0059f2; 
    background-color: #0059f2;
}

.page-item.disabled .page-link
{
    cursor: auto;
    pointer-events: none;

    color: #c0ccda;
    border-color: #e5e9f2; 
    background-color: #fff;
}

.pagination-lg .page-link
{
    font-size: 1.25rem;
    line-height: 1.5; 

    padding: .75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link
{
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.pagination-lg .page-item:last-child .page-link
{
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.pagination-sm .page-link
{
    font-size: .875rem;
    line-height: 1.5; 

    padding: .25rem .5rem;
}

.pagination-sm .page-item:first-child .page-link
{
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link
{
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
}

.badge
{
    font-size: 80%;
    font-weight: 700;
    line-height: 1;

    display: inline-block;

    padding: .25rem .5rem;

    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; 
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;

    border-radius: .375rem;
}
@media (prefers-reduced-motion: reduce)
{
    .badge
    {
        transition: none;
    }
}
a.badge:hover,
a.badge:focus
{
    text-decoration: none;
}
.badge:empty
{
    display: none;
}

.btn .badge
{
    position: relative;
    top: -1px;
}

.badge-pill
{
    padding-right: .875em;
    padding-left: .875em;

    border-radius: 50rem;
}

.badge-primary
{
    color: #fff;
    background-color: #0c66ff;
}
a.badge-primary:hover,
a.badge-primary:focus
{
    color: #fff;
    background-color: #0050d8;
}
a.badge-primary:focus,
a.badge-primary.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(12, 102, 255, .5);
}

.badge-secondary
{
    color: #273444;
    background-color: #eff2f7;
}
a.badge-secondary:hover,
a.badge-secondary:focus
{
    color: #273444;
    background-color: #cdd6e6;
}
a.badge-secondary:focus,
a.badge-secondary.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(239, 242, 247, .5);
}

.badge-success
{
    color: #fff;
    background-color: #2dca8c;
}
a.badge-success:hover,
a.badge-success:focus
{
    color: #fff;
    background-color: #24a06f;
}
a.badge-success:focus,
a.badge-success.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(45, 202, 140, .5);
}

.badge-info
{
    color: #fff;
    background-color: #50b5ff;
}
a.badge-info:hover,
a.badge-info:focus
{
    color: #fff;
    background-color: #1d9fff;
}
a.badge-info:focus,
a.badge-info.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(80, 181, 255, .5);
}

.badge-warning
{
    color: #273444;
    background-color: #ffbe3d;
}
a.badge-warning:hover,
a.badge-warning:focus
{
    color: #273444;
    background-color: #ffad0a;
}
a.badge-warning:focus,
a.badge-warning.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 190, 61, .5);
}

.badge-danger
{
    color: #fff;
    background-color: #ff5c75;
}
a.badge-danger:hover,
a.badge-danger:focus
{
    color: #fff;
    background-color: #ff294a;
}
a.badge-danger:focus,
a.badge-danger.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 92, 117, .5);
}

.badge-light
{
    color: #273444;
    background-color: #eff2f7;
}
a.badge-light:hover,
a.badge-light:focus
{
    color: #273444;
    background-color: #cdd6e6;
}
a.badge-light:focus,
a.badge-light.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(239, 242, 247, .5);
}

.badge-dark
{
    color: #fff;
    background-color: #273444;
}
a.badge-dark:hover,
a.badge-dark:focus
{
    color: #fff;
    background-color: #141b24;
}
a.badge-dark:focus,
a.badge-dark.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(39, 52, 68, .5);
}

.badge-neutral
{
    color: #273444;
    background-color: #fff;
}
a.badge-neutral:hover,
a.badge-neutral:focus
{
    color: #273444;
    background-color: #e6e6e6;
}
a.badge-neutral:focus,
a.badge-neutral.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .5);
}

.badge-white
{
    color: #273444;
    background-color: #fff;
}
a.badge-white:hover,
a.badge-white:focus
{
    color: #273444;
    background-color: #e6e6e6;
}
a.badge-white:focus,
a.badge-white.focus
{
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .5);
}

.jumbotron
{
    margin-bottom: 2rem;
    padding: 2rem 1rem;

    border-radius: .5rem; 
    background-color: #fff;
}
@media (min-width: 576px)
{
    .jumbotron
    {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid
{
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
}

.alert
{
    position: relative;

    margin-bottom: 1rem;
    padding: .875rem .875rem;

    border: 1px solid transparent;
    border-radius: .375rem;
}

.alert-heading
{
    color: inherit;
}

.alert-link
{
    font-weight: 600;
}

.alert-dismissible
{
    padding-right: 3rem;
}
.alert-dismissible .close
{
    position: absolute;
    top: 0;
    right: 0;

    padding: .875rem .875rem;

    color: inherit;
}

.alert-primary
{
    color: #fff;
    border-color: #0c66ff; 
    background-color: #0c66ff;
}
.alert-primary hr
{
    border-top-color: #0059f2;
}
.alert-primary .alert-link
{
    font-weight: 600;

    color: #fff;
    border-bottom: 1px dashed #fff;
}
.alert-primary .close > span:not(.sr-only)
{
    color: #fff;
}

.alert-secondary
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.alert-secondary hr
{
    border-top-color: #dee4ef;
}
.alert-secondary .alert-link
{
    font-weight: 600;

    color: #273444;
    border-bottom: 1px dashed #273444;
}
.alert-secondary .close > span:not(.sr-only)
{
    color: #273444;
}

.alert-success
{
    color: #fff;
    border-color: #2dca8c; 
    background-color: #2dca8c;
}
.alert-success hr
{
    border-top-color: #28b57e;
}
.alert-success .alert-link
{
    font-weight: 600;

    color: #fff;
    border-bottom: 1px dashed #fff;
}
.alert-success .close > span:not(.sr-only)
{
    color: #fff;
}

.alert-info
{
    color: #fff;
    border-color: #50b5ff; 
    background-color: #50b5ff;
}
.alert-info hr
{
    border-top-color: #37aaff;
}
.alert-info .alert-link
{
    font-weight: 600;

    color: #fff;
    border-bottom: 1px dashed #fff;
}
.alert-info .close > span:not(.sr-only)
{
    color: #fff;
}

.alert-warning
{
    color: #273444;
    border-color: #ffbe3d; 
    background-color: #ffbe3d;
}
.alert-warning hr
{
    border-top-color: #ffb524;
}
.alert-warning .alert-link
{
    font-weight: 600;

    color: #273444;
    border-bottom: 1px dashed #273444;
}
.alert-warning .close > span:not(.sr-only)
{
    color: #273444;
}

.alert-danger
{
    color: #fff;
    border-color: #ff5c75; 
    background-color: #ff5c75;
}
.alert-danger hr
{
    border-top-color: #ff435f;
}
.alert-danger .alert-link
{
    font-weight: 600;

    color: #fff;
    border-bottom: 1px dashed #fff;
}
.alert-danger .close > span:not(.sr-only)
{
    color: #fff;
}

.alert-light
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.alert-light hr
{
    border-top-color: #dee4ef;
}
.alert-light .alert-link
{
    font-weight: 600;

    color: #273444;
    border-bottom: 1px dashed #273444;
}
.alert-light .close > span:not(.sr-only)
{
    color: #273444;
}

.alert-dark
{
    color: #fff;
    border-color: #273444; 
    background-color: #273444;
}
.alert-dark hr
{
    border-top-color: #1e2834;
}
.alert-dark .alert-link
{
    font-weight: 600;

    color: #fff;
    border-bottom: 1px dashed #fff;
}
.alert-dark .close > span:not(.sr-only)
{
    color: #fff;
}

.alert-neutral
{
    color: #273444;
    border-color: white; 
    background-color: white;
}
.alert-neutral hr
{
    border-top-color: #f2f2f2;
}
.alert-neutral .alert-link
{
    font-weight: 600;

    color: #273444;
    border-bottom: 1px dashed #273444;
}
.alert-neutral .close > span:not(.sr-only)
{
    color: #273444;
}

.alert-white
{
    color: #273444;
    border-color: white; 
    background-color: white;
}
.alert-white hr
{
    border-top-color: #f2f2f2;
}
.alert-white .alert-link
{
    font-weight: 600;

    color: #273444;
    border-bottom: 1px dashed #273444;
}
.alert-white .close > span:not(.sr-only)
{
    color: #273444;
}

@-webkit-keyframes progress-bar-stripes
{
    from
    {
        background-position: .5rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes
{
    from
    {
        background-position: .5rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

.progress
{
    font-size: .75rem;

    display: flex;
    overflow: hidden;

    height: .5rem;

    border-radius: 50rem;
    background-color: #eff2f7;
    box-shadow: inset 0 .1rem .1rem rgba(31, 45, 61, .1);
}

.progress-bar
{
    display: flex;
    flex-direction: column;

    transition: width .6s ease; 
    text-align: center;
    white-space: nowrap;

    color: #fff;
    background-color: #0c66ff;

    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .progress-bar
    {
        transition: none;
    }
}

.progress-bar-striped
{
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: .5rem .5rem;
}

.progress-bar-animated
{
    -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce)
{
    .progress-bar-animated
    {
        -webkit-animation: none;
                animation: none;
    }
}

.media
{
    display: flex;

    align-items: flex-start;
}

.media-body
{
    flex: 1 1;
}

.list-group
{
    display: flex;
    flex-direction: column;

    margin-bottom: 0; 
    padding-left: 0;
}

.list-group-item-action
{
    width: 100%;

    text-align: inherit; 

    color: #8492a6;
}
.list-group-item-action:hover,
.list-group-item-action:focus
{
    z-index: 1;

    text-decoration: none;

    color: #8492a6;
    background-color: #fafbfe;
}
.list-group-item-action:active
{
    color: #8492a6;
    background-color: #eff2f7;
}

.list-group-item
{
    position: relative;

    display: block;

    margin-bottom: -1px;
    padding: 1rem 1.5rem;

    border: 1px solid #eff2f7; 
    background-color: #fff;
}
.list-group-item:first-child
{
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}
.list-group-item:last-child
{
    margin-bottom: 0;

    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
}
.list-group-item.disabled,
.list-group-item:disabled
{
    pointer-events: none;

    color: #c0ccda;
    background-color: #fff;
}
.list-group-item.active
{
    z-index: 2;

    color: #8492a6;
    border-color: #eff2f7; 
    background-color: #fafbfe;
}

.list-group-horizontal
{
    flex-direction: row;
}
.list-group-horizontal .list-group-item
{
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child
{
    border-top-left-radius: .375rem;
    border-top-right-radius: 0; 
    border-bottom-left-radius: .375rem;
}
.list-group-horizontal .list-group-item:last-child
{
    margin-right: 0;

    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: 0;
}

@media (min-width: 576px)
{
    .list-group-horizontal-sm
    {
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-sm .list-group-item:first-child
    {
        border-top-left-radius: .375rem;
        border-top-right-radius: 0; 
        border-bottom-left-radius: .375rem;
    }
    .list-group-horizontal-sm .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px)
{
    .list-group-horizontal-md
    {
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-md .list-group-item:first-child
    {
        border-top-left-radius: .375rem;
        border-top-right-radius: 0; 
        border-bottom-left-radius: .375rem;
    }
    .list-group-horizontal-md .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 992px)
{
    .list-group-horizontal-lg
    {
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-lg .list-group-item:first-child
    {
        border-top-left-radius: .375rem;
        border-top-right-radius: 0; 
        border-bottom-left-radius: .375rem;
    }
    .list-group-horizontal-lg .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1200px)
{
    .list-group-horizontal-xl
    {
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-xl .list-group-item:first-child
    {
        border-top-left-radius: .375rem;
        border-top-right-radius: 0; 
        border-bottom-left-radius: .375rem;
    }
    .list-group-horizontal-xl .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0;
    }
}

.list-group-flush .list-group-item
{
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.list-group-flush .list-group-item:last-child
{
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child
{
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child
{
    margin-bottom: 0;

    border-bottom: 0;
}

.list-group-item-primary
{
    color: #154ba2;
    background-color: #bbd4ff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus
{
    color: #154ba2;
    background-color: #a2c4ff;
}
.list-group-item-primary.list-group-item-action.active
{
    color: #fff;
    border-color: #154ba2; 
    background-color: #154ba2;
}

.list-group-item-secondary
{
    color: #8b939e;
    background-color: #fbfbfd;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus
{
    color: #8b939e;
    background-color: #eaeaf5;
}
.list-group-item-secondary.list-group-item-action.active
{
    color: #fff;
    border-color: #8b939e; 
    background-color: #8b939e;
}

.list-group-item-success
{
    color: #267f66;
    background-color: #c4f0df;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus
{
    color: #267f66;
    background-color: #b0ebd4;
}
.list-group-item-success.list-group-item-action.active
{
    color: #fff;
    border-color: #267f66; 
    background-color: #267f66;
}

.list-group-item-info
{
    color: #3874a2;
    background-color: #ceeaff;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus
{
    color: #3874a2;
    background-color: #b5dfff;
}
.list-group-item-info.list-group-item-action.active
{
    color: #fff;
    border-color: #3874a2; 
    background-color: #3874a2;
}

.list-group-item-warning
{
    color: #93783d;
    background-color: #ffedc9;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus
{
    color: #93783d;
    background-color: #ffe5b0;
}
.list-group-item-warning.list-group-item-action.active
{
    color: #fff;
    border-color: #93783d; 
    background-color: #93783d;
}

.list-group-item-danger
{
    color: #93455a;
    background-color: #ffd1d8;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus
{
    color: #93455a;
    background-color: #ffb8c2;
}
.list-group-item-danger.list-group-item-action.active
{
    color: #fff;
    border-color: #93455a; 
    background-color: #93455a;
}

.list-group-item-light
{
    color: #8b939e;
    background-color: #fbfbfd;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus
{
    color: #8b939e;
    background-color: #eaeaf5;
}
.list-group-item-light.list-group-item-action.active
{
    color: #fff;
    border-color: #8b939e; 
    background-color: #8b939e;
}

.list-group-item-dark
{
    color: #233141;
    background-color: #c3c6cb;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus
{
    color: #233141;
    background-color: #b5b9bf;
}
.list-group-item-dark.list-group-item-action.active
{
    color: #fff;
    border-color: #233141; 
    background-color: #233141;
}

.list-group-item-neutral
{
    color: #939aa2;
    background-color: white;
}
.list-group-item-neutral.list-group-item-action:hover,
.list-group-item-neutral.list-group-item-action:focus
{
    color: #939aa2;
    background-color: #f2f2f2;
}
.list-group-item-neutral.list-group-item-action.active
{
    color: #fff;
    border-color: #939aa2; 
    background-color: #939aa2;
}

.list-group-item-white
{
    color: #939aa2;
    background-color: white;
}
.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus
{
    color: #939aa2;
    background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active
{
    color: #fff;
    border-color: #939aa2; 
    background-color: #939aa2;
}

.close
{
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;

    float: right;

    opacity: .5; 
    color: rgba(255, 255, 255, .6);
    text-shadow: none;
}
.close:hover
{
    text-decoration: none; 

    color: rgba(255, 255, 255, .6);
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus
{
    opacity: .75;
}

button.close
{
    padding: 0;

    border: 0;
    background-color: transparent;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

a.close.disabled
{
    pointer-events: none;
}

.toast
{
    font-size: .875rem;

    overflow: hidden;

    max-width: 350px;

    opacity: 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem; 
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    box-shadow: 0 .25rem .75rem rgba(31, 45, 61, .1);

    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}
.toast:not(:last-child)
{
    margin-bottom: .75rem;
}
.toast.showing
{
    opacity: 1;
}
.toast.show
{
    display: block;

    opacity: 1;
}
.toast.hide
{
    display: none;
}

.toast-header
{
    display: flex;

    padding: .25rem .75rem;

    color: #c0ccda;
    border-bottom: 1px solid rgba(0, 0, 0, .05); 
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;

    align-items: center;
}

.toast-body
{
    padding: .75rem;
}

.modal-open
{
    overflow: hidden;
}
.modal-open .modal
{
    overflow-x: hidden;
    overflow-y: auto;
}

.modal
{
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;

    display: none;
    overflow: hidden;

    width: 100%;
    height: 100%;

    outline: 0;
}

.modal-dialog
{
    position: relative;

    width: auto;
    margin: .5rem;

    pointer-events: none;
}
.modal.fade .modal-dialog
{
    transition: -webkit-transform .3s ease-out;
    transition:         transform .3s ease-out;
    transition:         transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce)
{
    .modal.fade .modal-dialog
    {
        transition: none;
    }
}
.modal.show .modal-dialog
{
    -webkit-transform: none;
            transform: none;
}

.modal-dialog-scrollable
{
    display: flex;

    max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content
{
    overflow: hidden; 

    max-height: calc(100vh - 1rem);
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer
{
    flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body
{
    overflow-y: auto;
}

.modal-dialog-centered
{
    display: flex;

    min-height: calc(100% - 1rem); 

    align-items: center;
}
.modal-dialog-centered::before
{
    display: block;

    height: calc(100vh - 1rem);

    content: '';
}
.modal-dialog-centered.modal-dialog-scrollable
{
    flex-direction: column;

    height: 100%; 

    justify-content: center;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content
{
    max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before
{
    content: none;
}

.modal-content
{
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    pointer-events: auto;

    border: 1px solid rgba(31, 45, 61, .2);
    border-radius: 1rem;
    outline: 0; 
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 .25rem .5rem rgba(31, 45, 61, .3);
}

.modal-backdrop
{
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #1f2d3d;
}
.modal-backdrop.fade
{
    opacity: 0;
}
.modal-backdrop.show
{
    opacity: .4;
}

.modal-header
{
    display: flex;

    padding: 1.25rem;

    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; 

    align-items: flex-start;
    justify-content: space-between;
}
.modal-header .close
{
    margin: -1rem -1rem -1rem auto; 
    padding: 1.25rem;
}

.modal-title
{
    line-height: 1.7; 

    margin-bottom: 0;
}

.modal-body
{
    position: relative;

    padding: 1.5rem; 

    flex: 1 1 auto;
}

.modal-footer
{
    display: flex;

    padding: 1.5rem;

    border-top: 1px solid #eff2f7;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem; 

    align-items: center;
    justify-content: flex-end;
}
.modal-footer > :not(:first-child)
{
    margin-left: .25rem;
}
.modal-footer > :not(:last-child)
{
    margin-right: .25rem;
}

.modal-scrollbar-measure
{
    position: absolute;
    top: -9999px;

    overflow: scroll; 

    width: 50px;
    height: 50px;
}

@media (min-width: 576px)
{
    .modal-dialog
    {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable
    {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content
    {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered
    {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before
    {
        height: calc(100vh - 3.5rem);
    }
    .modal-content
    {
        box-shadow: 0 .5rem 1rem rgba(31, 45, 61, .3);
    }
    .modal-sm
    {
        max-width: 300px;
    }
}

@media (min-width: 992px)
{
    .modal-lg,
    .modal-xl
    {
        max-width: 800px;
    }
}

@media (min-width: 1200px)
{
    .modal-xl
    {
        max-width: 1140px;
    }
}

.tooltip
{
    font-family: 'Nunito Sans', sans-serif;
    font-size: .875rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.7;

    position: absolute;
    z-index: 1070;

    display: block;

    margin: 0;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    opacity: 0; 
    text-shadow: none;

    line-break: auto;
}
.tooltip.show
{
    opacity: .9;
}
.tooltip .arrow
{
    position: absolute;

    display: block;

    width: .8rem;
    height: .4rem;
}
.tooltip .arrow::before
{
    position: absolute;

    content: '';

    border-style: solid; 
    border-color: transparent;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top']
{
    padding: .4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow
{
    bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before
{
    top: 0;

    border-width: .4rem .4rem 0;
    border-top-color: #1f2d3d;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right']
{
    padding: 0 .4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow
{
    left: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before
{
    right: 0;

    border-width: .4rem .4rem .4rem 0;
    border-right-color: #1f2d3d;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom']
{
    padding: .4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow
{
    top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before
{
    bottom: 0;

    border-width: 0 .4rem .4rem;
    border-bottom-color: #1f2d3d;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left']
{
    padding: 0 .4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow
{
    right: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before
{
    left: 0;

    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #1f2d3d;
}

.tooltip-inner
{
    max-width: 200px;
    padding: .25rem .5rem;

    text-align: center;

    color: #fff;
    border-radius: .375rem; 
    background-color: #1f2d3d;
}

.popover
{
    font-family: 'Nunito Sans', sans-serif;
    font-size: .875rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.7;

    position: absolute;
    z-index: 1060;
    top: 0;
    left: 0;

    display: block;

    max-width: 276px;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    border: 1px solid rgba(31, 45, 61, .05);
    border-radius: .5rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 .5rem 2rem 0 rgba(31, 45, 61, .2); 
    text-shadow: none;

    line-break: auto;
}
.popover .arrow
{
    position: absolute;

    display: block;

    width: 1rem;
    height: .5rem;
    margin: 0 .5rem;
}
.popover .arrow::before,
.popover .arrow::after
{
    position: absolute;

    display: block;

    content: '';

    border-style: solid; 
    border-color: transparent;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top']
{
    margin-bottom: .5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow
{
    bottom: calc((.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before
{
    bottom: 0;

    border-width: .5rem .5rem 0;
    border-top-color: transparent;
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after
{
    bottom: 1px;

    border-width: .5rem .5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right']
{
    margin-left: .5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow
{
    left: calc((.5rem + 1px) * -1);

    width: .5rem;
    height: 1rem;
    margin: .5rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before
{
    left: 0;

    border-width: .5rem .5rem .5rem 0;
    border-right-color: transparent;
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after
{
    left: 1px;

    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom']
{
    margin-top: .5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow
{
    top: calc((.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before
{
    top: 0;

    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: transparent;
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after
{
    top: 1px;

    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before
{
    position: absolute;
    top: 0;
    left: 50%;

    display: block;

    width: 1rem;
    margin-left: -.5rem;

    content: '';

    border-bottom: 1px solid #fff;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left']
{
    margin-right: .5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow
{
    right: calc((.5rem + 1px) * -1);

    width: .5rem;
    height: 1rem;
    margin: .5rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before
{
    right: 0;

    border-width: .5rem 0 .5rem .5rem;
    border-left-color: transparent;
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after
{
    right: 1px;

    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff;
}

.popover-header
{
    font-size: 1rem;

    margin-bottom: 0;
    padding: .75rem .75rem;

    color: #3c4858;
    border-bottom: 1px solid #f2f2f2;
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px); 
    background-color: #fff;
}
.popover-header:empty
{
    display: none;
}

.popover-body
{
    padding: .75rem .75rem;

    color: #8492a6;
}

.carousel
{
    position: relative;
}

.carousel.pointer-event
{
    touch-action: pan-y;
}

.carousel-inner
{
    position: relative;

    overflow: hidden; 

    width: 100%;
}
.carousel-inner::after
{
    display: block;
    clear: both;

    content: '';
}

.carousel-item
{
    position: relative;

    display: none;
    float: left;

    width: 100%;
    margin-right: -100%;

    transition: -webkit-transform .6s ease-in-out;
    transition:         transform .6s ease-in-out;
    transition:         transform .6s ease-in-out, -webkit-transform .6s ease-in-out; 

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-item
    {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev
{
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right
{
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left
{
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.carousel-fade .carousel-item
{
    transition-property: opacity;
    -webkit-transform: none;
            transform: none; 

    opacity: 0;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right
{
    z-index: 1;

    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right
{
    z-index: 0;

    transition: 0s .6s opacity; 

    opacity: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right
    {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next
{
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;

    display: flex;

    width: 15%;

    transition: opacity .15s ease; 
    text-align: center;

    opacity: .5;
    color: #fff;

    align-items: center;
    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-control-prev,
    .carousel-control-next
    {
        transition: none;
    }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus
{
    text-decoration: none;

    opacity: .9; 
    color: #fff;
    outline: 0;
}

.carousel-control-prev
{
    left: 0;
}

.carousel-control-next
{
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon
{
    display: inline-block;

    width: 20px;
    height: 20px;

    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23FFF\' viewBox=\'0 0 8 8\'%3e%3cpath d=\'M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z\'/%3e%3c/svg%3e');
}

.carousel-control-next-icon
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23FFF\' viewBox=\'0 0 8 8\'%3e%3cpath d=\'M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z\'/%3e%3c/svg%3e');
}

.carousel-indicators
{
    position: absolute;
    z-index: 15;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;

    list-style: none; 

    justify-content: center;
}
.carousel-indicators li
{
    box-sizing: content-box;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;

    cursor: pointer;
    transition: opacity .6s ease; 
    text-indent: -999px;

    opacity: .5;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-color: #fff;
    background-clip: padding-box;

    flex: 0 1 auto;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-indicators li
    {
        transition: none;
    }
}
.carousel-indicators .active
{
    opacity: 1;
}

.carousel-caption
{
    position: absolute;
    z-index: 10;
    right: 15%;
    bottom: 20px;
    left: 15%;

    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center; 

    color: #fff;
}

@-webkit-keyframes spinner-border
{
    to
    {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spinner-border
{
    to
    {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.spinner-border
{
    display: inline-block;

    width: 2rem;
    height: 2rem;

    -webkit-animation: spinner-border .75s linear infinite;
            animation: spinner-border .75s linear infinite; 
    vertical-align: text-bottom;

    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
}

.spinner-border-sm
{
    width: 1rem;
    height: 1rem;

    border-width: .2em;
}

@-webkit-keyframes spinner-grow
{
    0%
    {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    50%
    {
        opacity: 1;
    }
}

@keyframes spinner-grow
{
    0%
    {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    50%
    {
        opacity: 1;
    }
}

.spinner-grow
{
    display: inline-block;

    width: 2rem;
    height: 2rem;

    -webkit-animation: spinner-grow .75s linear infinite;
            animation: spinner-grow .75s linear infinite; 
    vertical-align: text-bottom;

    opacity: 0;
    border-radius: 50%;
    background-color: currentColor;
}

.spinner-grow-sm
{
    width: 1rem;
    height: 1rem;
}

.align-baseline
{
    vertical-align: baseline !important;
}

.align-top
{
    vertical-align: top !important;
}

.align-middle
{
    vertical-align: middle !important;
}

.align-bottom
{
    vertical-align: bottom !important;
}

.align-text-bottom
{
    vertical-align: text-bottom !important;
}

.align-text-top
{
    vertical-align: text-top !important;
}

.bg-primary
{
    background-color: #0c66ff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus
{
    background-color: #0050d8 !important;
}

.bg-secondary
{
    background-color: #eff2f7 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus
{
    background-color: #cdd6e6 !important;
}

.bg-success
{
    background-color: #2dca8c !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus
{
    background-color: #24a06f !important;
}

.bg-info
{
    background-color: #50b5ff !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus
{
    background-color: #1d9fff !important;
}

.bg-warning
{
    background-color: #ffbe3d !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus
{
    background-color: #ffad0a !important;
}

.bg-danger
{
    background-color: #ff5c75 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus
{
    background-color: #ff294a !important;
}

.bg-light
{
    background-color: #eff2f7 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus
{
    background-color: #cdd6e6 !important;
}

.bg-dark
{
    background-color: #273444 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus
{
    background-color: #141b24 !important;
}

.bg-neutral
{
    background-color: #fff !important;
}

a.bg-neutral:hover,
a.bg-neutral:focus,
button.bg-neutral:hover,
button.bg-neutral:focus
{
    background-color: #e6e6e6 !important;
}

.bg-white
{
    background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus
{
    background-color: #e6e6e6 !important;
}

.bg-white
{
    background-color: #fff !important;
}

.bg-transparent
{
    background-color: transparent !important;
}

.border
{
    border: 1px solid #eff2f7 !important;
}

.border-top
{
    border-top: 1px solid #eff2f7 !important;
}

.border-right
{
    border-right: 1px solid #eff2f7 !important;
}

.border-bottom
{
    border-bottom: 1px solid #eff2f7 !important;
}

.border-left
{
    border-left: 1px solid #eff2f7 !important;
}

.border-0
{
    border: 0 !important;
}

.border-top-0
{
    border-top: 0 !important;
}

.border-right-0
{
    border-right: 0 !important;
}

.border-bottom-0
{
    border-bottom: 0 !important;
}

.border-left-0
{
    border-left: 0 !important;
}

.border-primary
{
    border-color: #0c66ff !important;
}

.border-secondary
{
    border-color: #eff2f7 !important;
}

.border-success
{
    border-color: #2dca8c !important;
}

.border-info
{
    border-color: #50b5ff !important;
}

.border-warning
{
    border-color: #ffbe3d !important;
}

.border-danger
{
    border-color: #ff5c75 !important;
}

.border-light
{
    border-color: #eff2f7 !important;
}

.border-dark
{
    border-color: #273444 !important;
}

.border-neutral
{
    border-color: #fff !important;
}

.border-white
{
    border-color: #fff !important;
}

.border-white
{
    border-color: #fff !important;
}

.rounded-sm
{
    border-radius: .2rem !important;
}

.rounded
{
    border-radius: .375rem !important;
}

.rounded-top
{
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
}

.rounded-right
{
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
}

.rounded-bottom
{
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
}

.rounded-left
{
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
}

.rounded-lg
{
    border-radius: .5rem !important;
}

.rounded-circle
{
    border-radius: 50% !important;
}

.rounded-pill
{
    border-radius: 50rem !important;
}

.rounded-0
{
    border-radius: 0 !important;
}

.clearfix::after
{
    display: block;
    clear: both;

    content: '';
}

.d-none
{
    display: none !important;
}

.d-inline
{
    display: inline !important;
}

.d-inline-block
{
    display: inline-block !important;
}

.d-block
{
    display: block !important;
}

.d-table
{
    display: table !important;
}

.d-table-row
{
    display: table-row !important;
}

.d-table-cell
{
    display: table-cell !important;
}

.d-flex
{
    display: flex !important;
}

.d-inline-flex
{
    display: inline-flex !important;
}

@media (min-width: 576px)
{
    .d-sm-none
    {
        display: none !important;
    }
    .d-sm-inline
    {
        display: inline !important;
    }
    .d-sm-inline-block
    {
        display: inline-block !important;
    }
    .d-sm-block
    {
        display: block !important;
    }
    .d-sm-table
    {
        display: table !important;
    }
    .d-sm-table-row
    {
        display: table-row !important;
    }
    .d-sm-table-cell
    {
        display: table-cell !important;
    }
    .d-sm-flex
    {
        display: flex !important;
    }
    .d-sm-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 768px)
{
    .d-md-none
    {
        display: none !important;
    }
    .d-md-inline
    {
        display: inline !important;
    }
    .d-md-inline-block
    {
        display: inline-block !important;
    }
    .d-md-block
    {
        display: block !important;
    }
    .d-md-table
    {
        display: table !important;
    }
    .d-md-table-row
    {
        display: table-row !important;
    }
    .d-md-table-cell
    {
        display: table-cell !important;
    }
    .d-md-flex
    {
        display: flex !important;
    }
    .d-md-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 992px)
{
    .d-lg-none
    {
        display: none !important;
    }
    .d-lg-inline
    {
        display: inline !important;
    }
    .d-lg-inline-block
    {
        display: inline-block !important;
    }
    .d-lg-block
    {
        display: block !important;
    }
    .d-lg-table
    {
        display: table !important;
    }
    .d-lg-table-row
    {
        display: table-row !important;
    }
    .d-lg-table-cell
    {
        display: table-cell !important;
    }
    .d-lg-flex
    {
        display: flex !important;
    }
    .d-lg-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px)
{
    .d-xl-none
    {
        display: none !important;
    }
    .d-xl-inline
    {
        display: inline !important;
    }
    .d-xl-inline-block
    {
        display: inline-block !important;
    }
    .d-xl-block
    {
        display: block !important;
    }
    .d-xl-table
    {
        display: table !important;
    }
    .d-xl-table-row
    {
        display: table-row !important;
    }
    .d-xl-table-cell
    {
        display: table-cell !important;
    }
    .d-xl-flex
    {
        display: flex !important;
    }
    .d-xl-inline-flex
    {
        display: inline-flex !important;
    }
}

@media print
{
    .d-print-none
    {
        display: none !important;
    }
    .d-print-inline
    {
        display: inline !important;
    }
    .d-print-inline-block
    {
        display: inline-block !important;
    }
    .d-print-block
    {
        display: block !important;
    }
    .d-print-table
    {
        display: table !important;
    }
    .d-print-table-row
    {
        display: table-row !important;
    }
    .d-print-table-cell
    {
        display: table-cell !important;
    }
    .d-print-flex
    {
        display: flex !important;
    }
    .d-print-inline-flex
    {
        display: inline-flex !important;
    }
}

.embed-responsive
{
    position: relative;

    display: block;
    overflow: hidden; 

    width: 100%;
    padding: 0;
}
.embed-responsive::before
{
    display: block;

    content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 0;
}

.embed-responsive-21by9::before
{
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before
{
    padding-top: 56.25%;
}

.embed-responsive-4by3::before
{
    padding-top: 75%;
}

.embed-responsive-1by1::before
{
    padding-top: 100%;
}

.flex-row
{
    flex-direction: row !important;
}

.flex-column
{
    flex-direction: column !important;
}

.flex-row-reverse
{
    flex-direction: row-reverse !important;
}

.flex-column-reverse
{
    flex-direction: column-reverse !important;
}

.flex-wrap
{
    flex-wrap: wrap !important;
}

.flex-nowrap
{
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse
{
    flex-wrap: wrap-reverse !important;
}

.flex-fill
{
    flex: 1 1 auto !important;
}

.flex-grow-0
{
    flex-grow: 0 !important;
}

.flex-grow-1
{
    flex-grow: 1 !important;
}

.flex-shrink-0
{
    flex-shrink: 0 !important;
}

.flex-shrink-1
{
    flex-shrink: 1 !important;
}

.justify-content-start
{
    justify-content: flex-start !important;
}

.justify-content-end
{
    justify-content: flex-end !important;
}

.justify-content-center
{
    justify-content: center !important;
}

.justify-content-between
{
    justify-content: space-between !important;
}

.justify-content-around
{
    justify-content: space-around !important;
}

.align-items-start
{
    align-items: flex-start !important;
}

.align-items-end
{
    align-items: flex-end !important;
}

.align-items-center
{
    align-items: center !important;
}

.align-items-baseline
{
    align-items: baseline !important;
}

.align-items-stretch
{
    align-items: stretch !important;
}

.align-content-start
{
    align-content: flex-start !important;
}

.align-content-end
{
    align-content: flex-end !important;
}

.align-content-center
{
    align-content: center !important;
}

.align-content-between
{
    align-content: space-between !important;
}

.align-content-around
{
    align-content: space-around !important;
}

.align-content-stretch
{
    align-content: stretch !important;
}

.align-self-auto
{
    align-self: auto !important;
}

.align-self-start
{
    align-self: flex-start !important;
}

.align-self-end
{
    align-self: flex-end !important;
}

.align-self-center
{
    align-self: center !important;
}

.align-self-baseline
{
    align-self: baseline !important;
}

.align-self-stretch
{
    align-self: stretch !important;
}

@media (min-width: 576px)
{
    .flex-sm-row
    {
        flex-direction: row !important;
    }
    .flex-sm-column
    {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center
    {
        justify-content: center !important;
    }
    .justify-content-sm-between
    {
        justify-content: space-between !important;
    }
    .justify-content-sm-around
    {
        justify-content: space-around !important;
    }
    .align-items-sm-start
    {
        align-items: flex-start !important;
    }
    .align-items-sm-end
    {
        align-items: flex-end !important;
    }
    .align-items-sm-center
    {
        align-items: center !important;
    }
    .align-items-sm-baseline
    {
        align-items: baseline !important;
    }
    .align-items-sm-stretch
    {
        align-items: stretch !important;
    }
    .align-content-sm-start
    {
        align-content: flex-start !important;
    }
    .align-content-sm-end
    {
        align-content: flex-end !important;
    }
    .align-content-sm-center
    {
        align-content: center !important;
    }
    .align-content-sm-between
    {
        align-content: space-between !important;
    }
    .align-content-sm-around
    {
        align-content: space-around !important;
    }
    .align-content-sm-stretch
    {
        align-content: stretch !important;
    }
    .align-self-sm-auto
    {
        align-self: auto !important;
    }
    .align-self-sm-start
    {
        align-self: flex-start !important;
    }
    .align-self-sm-end
    {
        align-self: flex-end !important;
    }
    .align-self-sm-center
    {
        align-self: center !important;
    }
    .align-self-sm-baseline
    {
        align-self: baseline !important;
    }
    .align-self-sm-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 768px)
{
    .flex-md-row
    {
        flex-direction: row !important;
    }
    .flex-md-column
    {
        flex-direction: column !important;
    }
    .flex-md-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-md-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-md-center
    {
        justify-content: center !important;
    }
    .justify-content-md-between
    {
        justify-content: space-between !important;
    }
    .justify-content-md-around
    {
        justify-content: space-around !important;
    }
    .align-items-md-start
    {
        align-items: flex-start !important;
    }
    .align-items-md-end
    {
        align-items: flex-end !important;
    }
    .align-items-md-center
    {
        align-items: center !important;
    }
    .align-items-md-baseline
    {
        align-items: baseline !important;
    }
    .align-items-md-stretch
    {
        align-items: stretch !important;
    }
    .align-content-md-start
    {
        align-content: flex-start !important;
    }
    .align-content-md-end
    {
        align-content: flex-end !important;
    }
    .align-content-md-center
    {
        align-content: center !important;
    }
    .align-content-md-between
    {
        align-content: space-between !important;
    }
    .align-content-md-around
    {
        align-content: space-around !important;
    }
    .align-content-md-stretch
    {
        align-content: stretch !important;
    }
    .align-self-md-auto
    {
        align-self: auto !important;
    }
    .align-self-md-start
    {
        align-self: flex-start !important;
    }
    .align-self-md-end
    {
        align-self: flex-end !important;
    }
    .align-self-md-center
    {
        align-self: center !important;
    }
    .align-self-md-baseline
    {
        align-self: baseline !important;
    }
    .align-self-md-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 992px)
{
    .flex-lg-row
    {
        flex-direction: row !important;
    }
    .flex-lg-column
    {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center
    {
        justify-content: center !important;
    }
    .justify-content-lg-between
    {
        justify-content: space-between !important;
    }
    .justify-content-lg-around
    {
        justify-content: space-around !important;
    }
    .align-items-lg-start
    {
        align-items: flex-start !important;
    }
    .align-items-lg-end
    {
        align-items: flex-end !important;
    }
    .align-items-lg-center
    {
        align-items: center !important;
    }
    .align-items-lg-baseline
    {
        align-items: baseline !important;
    }
    .align-items-lg-stretch
    {
        align-items: stretch !important;
    }
    .align-content-lg-start
    {
        align-content: flex-start !important;
    }
    .align-content-lg-end
    {
        align-content: flex-end !important;
    }
    .align-content-lg-center
    {
        align-content: center !important;
    }
    .align-content-lg-between
    {
        align-content: space-between !important;
    }
    .align-content-lg-around
    {
        align-content: space-around !important;
    }
    .align-content-lg-stretch
    {
        align-content: stretch !important;
    }
    .align-self-lg-auto
    {
        align-self: auto !important;
    }
    .align-self-lg-start
    {
        align-self: flex-start !important;
    }
    .align-self-lg-end
    {
        align-self: flex-end !important;
    }
    .align-self-lg-center
    {
        align-self: center !important;
    }
    .align-self-lg-baseline
    {
        align-self: baseline !important;
    }
    .align-self-lg-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px)
{
    .flex-xl-row
    {
        flex-direction: row !important;
    }
    .flex-xl-column
    {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center
    {
        justify-content: center !important;
    }
    .justify-content-xl-between
    {
        justify-content: space-between !important;
    }
    .justify-content-xl-around
    {
        justify-content: space-around !important;
    }
    .align-items-xl-start
    {
        align-items: flex-start !important;
    }
    .align-items-xl-end
    {
        align-items: flex-end !important;
    }
    .align-items-xl-center
    {
        align-items: center !important;
    }
    .align-items-xl-baseline
    {
        align-items: baseline !important;
    }
    .align-items-xl-stretch
    {
        align-items: stretch !important;
    }
    .align-content-xl-start
    {
        align-content: flex-start !important;
    }
    .align-content-xl-end
    {
        align-content: flex-end !important;
    }
    .align-content-xl-center
    {
        align-content: center !important;
    }
    .align-content-xl-between
    {
        align-content: space-between !important;
    }
    .align-content-xl-around
    {
        align-content: space-around !important;
    }
    .align-content-xl-stretch
    {
        align-content: stretch !important;
    }
    .align-self-xl-auto
    {
        align-self: auto !important;
    }
    .align-self-xl-start
    {
        align-self: flex-start !important;
    }
    .align-self-xl-end
    {
        align-self: flex-end !important;
    }
    .align-self-xl-center
    {
        align-self: center !important;
    }
    .align-self-xl-baseline
    {
        align-self: baseline !important;
    }
    .align-self-xl-stretch
    {
        align-self: stretch !important;
    }
}

.float-left
{
    float: left !important;
}

.float-right
{
    float: right !important;
}

.float-none
{
    float: none !important;
}

@media (min-width: 576px)
{
    .float-sm-left
    {
        float: left !important;
    }
    .float-sm-right
    {
        float: right !important;
    }
    .float-sm-none
    {
        float: none !important;
    }
}

@media (min-width: 768px)
{
    .float-md-left
    {
        float: left !important;
    }
    .float-md-right
    {
        float: right !important;
    }
    .float-md-none
    {
        float: none !important;
    }
}

@media (min-width: 992px)
{
    .float-lg-left
    {
        float: left !important;
    }
    .float-lg-right
    {
        float: right !important;
    }
    .float-lg-none
    {
        float: none !important;
    }
}

@media (min-width: 1200px)
{
    .float-xl-left
    {
        float: left !important;
    }
    .float-xl-right
    {
        float: right !important;
    }
    .float-xl-none
    {
        float: none !important;
    }
}

.overflow-auto
{
    overflow: auto !important;
}

.overflow-hidden
{
    overflow: hidden !important;
}

.position-static
{
    position: static !important;
}

.position-relative
{
    position: relative !important;
}

.position-absolute
{
    position: absolute !important;
}

.position-fixed
{
    position: fixed !important;
}

.position-sticky
{
    position: -webkit-sticky !important;
    position:         sticky !important;
}

.fixed-top
{
    position: fixed;
    z-index: 1030; 
    top: 0;
    right: 0;
    left: 0;
}

.fixed-bottom
{
    position: fixed;
    z-index: 1030; 
    right: 0;
    bottom: 0;
    left: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky))
{
    .sticky-top
    {
        position: -webkit-sticky;
        position:         sticky;
        z-index: 1020; 
        top: 0;
    }
}

.sr-only
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    padding: 0;

    white-space: nowrap;

    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus
{
    position: static;

    overflow: visible;
    clip: auto;

    width: auto;
    height: auto;

    white-space: normal;
}

.shadow-sm
{
    box-shadow: 0 .125rem .25rem rgba(31, 45, 61, .08) !important;
}

.shadow
{
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08) !important;
}

.shadow-lg
{
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125) !important;
}

.shadow-none
{
    box-shadow: none !important;
}

.w-25
{
    width: 25% !important;
}

.w-50
{
    width: 50% !important;
}

.w-75
{
    width: 75% !important;
}

.w-100
{
    width: 100% !important;
}

.w-auto
{
    width: auto !important;
}

.w-10
{
    width: 10% !important;
}

.w-15
{
    width: 15% !important;
}

.h-25
{
    height: 25% !important;
}

.h-50
{
    height: 50% !important;
}

.h-75
{
    height: 75% !important;
}

.h-100
{
    height: 100% !important;
}

.h-auto
{
    height: auto !important;
}

.h-10
{
    height: 10% !important;
}

.h-15
{
    height: 15% !important;
}

.mw-100
{
    max-width: 100% !important;
}

.mh-100
{
    max-height: 100% !important;
}

.min-vw-100
{
    min-width: 100vw !important;
}

.min-vh-100
{
    min-height: 100vh !important;
}

.vw-100
{
    width: 100vw !important;
}

.vh-100
{
    height: 100vh !important;
}

.stretched-link::after
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: auto;

    background-color: rgba(0, 0, 0, 0);
}

.m-0
{
    margin: 0 !important;
}

.mt-0,
.my-0
{
    margin-top: 0 !important;
}

.mr-0,
.mx-0
{
    margin-right: 0 !important;
}

.mb-0,
.my-0
{
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0
{
    margin-left: 0 !important;
}

.m-1
{
    margin: .25rem !important;
}

.mt-1,
.my-1
{
    margin-top: .25rem !important;
}

.mr-1,
.mx-1
{
    margin-right: .25rem !important;
}

.mb-1,
.my-1
{
    margin-bottom: .25rem !important;
}

.ml-1,
.mx-1
{
    margin-left: .25rem !important;
}

.m-2
{
    margin: .5rem !important;
}

.mt-2,
.my-2
{
    margin-top: .5rem !important;
}

.mr-2,
.mx-2
{
    margin-right: .5rem !important;
}

.mb-2,
.my-2
{
    margin-bottom: .5rem !important;
}

.ml-2,
.mx-2
{
    margin-left: .5rem !important;
}

.m-3
{
    margin: 1rem !important;
}

.mt-3,
.my-3
{
    margin-top: 1rem !important;
}

.mr-3,
.mx-3
{
    margin-right: 1rem !important;
}

.mb-3,
.my-3
{
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3
{
    margin-left: 1rem !important;
}

.m-4
{
    margin: 1.5rem !important;
}

.mt-4,
.my-4
{
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4
{
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4
{
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4
{
    margin-left: 1.5rem !important;
}

.m-5
{
    margin: 3rem !important;
}

.mt-5,
.my-5
{
    margin-top: 3rem !important;
}

.mr-5,
.mx-5
{
    margin-right: 3rem !important;
}

.mb-5,
.my-5
{
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5
{
    margin-left: 3rem !important;
}

.m-6
{
    margin: 4.5rem !important;
}

.mt-6,
.my-6
{
    margin-top: 4.5rem !important;
}

.mr-6,
.mx-6
{
    margin-right: 4.5rem !important;
}

.mb-6,
.my-6
{
    margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6
{
    margin-left: 4.5rem !important;
}

.m-7
{
    margin: 6rem !important;
}

.mt-7,
.my-7
{
    margin-top: 6rem !important;
}

.mr-7,
.mx-7
{
    margin-right: 6rem !important;
}

.mb-7,
.my-7
{
    margin-bottom: 6rem !important;
}

.ml-7,
.mx-7
{
    margin-left: 6rem !important;
}

.m-8
{
    margin: 7.5rem !important;
}

.mt-8,
.my-8
{
    margin-top: 7.5rem !important;
}

.mr-8,
.mx-8
{
    margin-right: 7.5rem !important;
}

.mb-8,
.my-8
{
    margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8
{
    margin-left: 7.5rem !important;
}

.m-9
{
    margin: 9rem !important;
}

.mt-9,
.my-9
{
    margin-top: 9rem !important;
}

.mr-9,
.mx-9
{
    margin-right: 9rem !important;
}

.mb-9,
.my-9
{
    margin-bottom: 9rem !important;
}

.ml-9,
.mx-9
{
    margin-left: 9rem !important;
}

.m-10
{
    margin: 10.5rem !important;
}

.mt-10,
.my-10
{
    margin-top: 10.5rem !important;
}

.mr-10,
.mx-10
{
    margin-right: 10.5rem !important;
}

.mb-10,
.my-10
{
    margin-bottom: 10.5rem !important;
}

.ml-10,
.mx-10
{
    margin-left: 10.5rem !important;
}

.m-17
{
    margin: 21rem !important;
}

.mt-17,
.my-17
{
    margin-top: 21rem !important;
}

.mr-17,
.mx-17
{
    margin-right: 21rem !important;
}

.mb-17,
.my-17
{
    margin-bottom: 21rem !important;
}

.ml-17,
.mx-17
{
    margin-left: 21rem !important;
}

.p-0
{
    padding: 0 !important;
}

.pt-0,
.py-0
{
    padding-top: 0 !important;
}

.pr-0,
.px-0
{
    padding-right: 0 !important;
}

.pb-0,
.py-0
{
    padding-bottom: 0 !important;
}

.pl-0,
.px-0
{
    padding-left: 0 !important;
}

.p-1
{
    padding: .25rem !important;
}

.pt-1,
.py-1
{
    padding-top: .25rem !important;
}

.pr-1,
.px-1
{
    padding-right: .25rem !important;
}

.pb-1,
.py-1
{
    padding-bottom: .25rem !important;
}

.pl-1,
.px-1
{
    padding-left: .25rem !important;
}

.p-2
{
    padding: .5rem !important;
}

.pt-2,
.py-2
{
    padding-top: .5rem !important;
}

.pr-2,
.px-2
{
    padding-right: .5rem !important;
}

.pb-2,
.py-2
{
    padding-bottom: .5rem !important;
}

.pl-2,
.px-2
{
    padding-left: .5rem !important;
}

.p-3
{
    padding: 1rem !important;
}

.pt-3,
.py-3
{
    padding-top: 1rem !important;
}

.pr-3,
.px-3
{
    padding-right: 1rem !important;
}

.pb-3,
.py-3
{
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3
{
    padding-left: 1rem !important;
}

.p-4
{
    padding: 1.5rem !important;
}

.pt-4,
.py-4
{
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4
{
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4
{
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4
{
    padding-left: 1.5rem !important;
}

.p-5
{
    padding: 3rem !important;
}

.pt-5,
.py-5
{
    padding-top: 3rem !important;
}

.pr-5,
.px-5
{
    padding-right: 3rem !important;
}

.pb-5,
.py-5
{
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5
{
    padding-left: 3rem !important;
}

.p-6
{
    padding: 4.5rem !important;
}

.pt-6,
.py-6
{
    padding-top: 4.5rem !important;
}

.pr-6,
.px-6
{
    padding-right: 4.5rem !important;
}

.pb-6,
.py-6
{
    padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6
{
    padding-left: 4.5rem !important;
}

.p-7
{
    padding: 6rem !important;
}

.pt-7,
.py-7
{
    padding-top: 6rem !important;
}

.pr-7,
.px-7
{
    padding-right: 6rem !important;
}

.pb-7,
.py-7
{
    padding-bottom: 6rem !important;
}

.pl-7,
.px-7
{
    padding-left: 6rem !important;
}

.p-8
{
    padding: 7.5rem !important;
}

.pt-8,
.py-8
{
    padding-top: 7.5rem !important;
}

.pr-8,
.px-8
{
    padding-right: 7.5rem !important;
}

.pb-8,
.py-8
{
    padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8
{
    padding-left: 7.5rem !important;
}

.p-9
{
    padding: 9rem !important;
}

.pt-9,
.py-9
{
    padding-top: 9rem !important;
}

.pr-9,
.px-9
{
    padding-right: 9rem !important;
}

.pb-9,
.py-9
{
    padding-bottom: 9rem !important;
}

.pl-9,
.px-9
{
    padding-left: 9rem !important;
}

.p-10
{
    padding: 10.5rem !important;
}

.pt-10,
.py-10
{
    padding-top: 10.5rem !important;
}

.pr-10,
.px-10
{
    padding-right: 10.5rem !important;
}

.pb-10,
.py-10
{
    padding-bottom: 10.5rem !important;
}

.pl-10,
.px-10
{
    padding-left: 10.5rem !important;
}

.p-17
{
    padding: 21rem !important;
}

.pt-17,
.py-17
{
    padding-top: 21rem !important;
}

.pr-17,
.px-17
{
    padding-right: 21rem !important;
}

.pb-17,
.py-17
{
    padding-bottom: 21rem !important;
}

.pl-17,
.px-17
{
    padding-left: 21rem !important;
}

.m-n1
{
    margin: -.25rem !important;
}

.mt-n1,
.my-n1
{
    margin-top: -.25rem !important;
}

.mr-n1,
.mx-n1
{
    margin-right: -.25rem !important;
}

.mb-n1,
.my-n1
{
    margin-bottom: -.25rem !important;
}

.ml-n1,
.mx-n1
{
    margin-left: -.25rem !important;
}

.m-n2
{
    margin: -.5rem !important;
}

.mt-n2,
.my-n2
{
    margin-top: -.5rem !important;
}

.mr-n2,
.mx-n2
{
    margin-right: -.5rem !important;
}

.mb-n2,
.my-n2
{
    margin-bottom: -.5rem !important;
}

.ml-n2,
.mx-n2
{
    margin-left: -.5rem !important;
}

.m-n3
{
    margin: -1rem !important;
}

.mt-n3,
.my-n3
{
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3
{
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3
{
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3
{
    margin-left: -1rem !important;
}

.m-n4
{
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4
{
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4
{
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4
{
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4
{
    margin-left: -1.5rem !important;
}

.m-n5
{
    margin: -3rem !important;
}

.mt-n5,
.my-n5
{
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5
{
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5
{
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5
{
    margin-left: -3rem !important;
}

.m-n6
{
    margin: -4.5rem !important;
}

.mt-n6,
.my-n6
{
    margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6
{
    margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6
{
    margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6
{
    margin-left: -4.5rem !important;
}

.m-n7
{
    margin: -6rem !important;
}

.mt-n7,
.my-n7
{
    margin-top: -6rem !important;
}

.mr-n7,
.mx-n7
{
    margin-right: -6rem !important;
}

.mb-n7,
.my-n7
{
    margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7
{
    margin-left: -6rem !important;
}

.m-n8
{
    margin: -7.5rem !important;
}

.mt-n8,
.my-n8
{
    margin-top: -7.5rem !important;
}

.mr-n8,
.mx-n8
{
    margin-right: -7.5rem !important;
}

.mb-n8,
.my-n8
{
    margin-bottom: -7.5rem !important;
}

.ml-n8,
.mx-n8
{
    margin-left: -7.5rem !important;
}

.m-n9
{
    margin: -9rem !important;
}

.mt-n9,
.my-n9
{
    margin-top: -9rem !important;
}

.mr-n9,
.mx-n9
{
    margin-right: -9rem !important;
}

.mb-n9,
.my-n9
{
    margin-bottom: -9rem !important;
}

.ml-n9,
.mx-n9
{
    margin-left: -9rem !important;
}

.m-n10
{
    margin: -10.5rem !important;
}

.mt-n10,
.my-n10
{
    margin-top: -10.5rem !important;
}

.mr-n10,
.mx-n10
{
    margin-right: -10.5rem !important;
}

.mb-n10,
.my-n10
{
    margin-bottom: -10.5rem !important;
}

.ml-n10,
.mx-n10
{
    margin-left: -10.5rem !important;
}

.m-n17
{
    margin: -21rem !important;
}

.mt-n17,
.my-n17
{
    margin-top: -21rem !important;
}

.mr-n17,
.mx-n17
{
    margin-right: -21rem !important;
}

.mb-n17,
.my-n17
{
    margin-bottom: -21rem !important;
}

.ml-n17,
.mx-n17
{
    margin-left: -21rem !important;
}

.m-auto
{
    margin: auto !important;
}

.mt-auto,
.my-auto
{
    margin-top: auto !important;
}

.mr-auto,
.mx-auto
{
    margin-right: auto !important;
}

.mb-auto,
.my-auto
{
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto
{
    margin-left: auto !important;
}

@media (min-width: 576px)
{
    .m-sm-0
    {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0
    {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0
    {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0
    {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0
    {
        margin-left: 0 !important;
    }
    .m-sm-1
    {
        margin: .25rem !important;
    }
    .mt-sm-1,
    .my-sm-1
    {
        margin-top: .25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1
    {
        margin-right: .25rem !important;
    }
    .mb-sm-1,
    .my-sm-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1
    {
        margin-left: .25rem !important;
    }
    .m-sm-2
    {
        margin: .5rem !important;
    }
    .mt-sm-2,
    .my-sm-2
    {
        margin-top: .5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2
    {
        margin-right: .5rem !important;
    }
    .mb-sm-2,
    .my-sm-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2
    {
        margin-left: .5rem !important;
    }
    .m-sm-3
    {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3
    {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3
    {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3
    {
        margin-left: 1rem !important;
    }
    .m-sm-4
    {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4
    {
        margin-left: 1.5rem !important;
    }
    .m-sm-5
    {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5
    {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5
    {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5
    {
        margin-left: 3rem !important;
    }
    .m-sm-6
    {
        margin: 4.5rem !important;
    }
    .mt-sm-6,
    .my-sm-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-sm-6,
    .mx-sm-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-sm-6,
    .my-sm-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-sm-6,
    .mx-sm-6
    {
        margin-left: 4.5rem !important;
    }
    .m-sm-7
    {
        margin: 6rem !important;
    }
    .mt-sm-7,
    .my-sm-7
    {
        margin-top: 6rem !important;
    }
    .mr-sm-7,
    .mx-sm-7
    {
        margin-right: 6rem !important;
    }
    .mb-sm-7,
    .my-sm-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-sm-7,
    .mx-sm-7
    {
        margin-left: 6rem !important;
    }
    .m-sm-8
    {
        margin: 7.5rem !important;
    }
    .mt-sm-8,
    .my-sm-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-sm-8,
    .mx-sm-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-sm-8,
    .my-sm-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-sm-8,
    .mx-sm-8
    {
        margin-left: 7.5rem !important;
    }
    .m-sm-9
    {
        margin: 9rem !important;
    }
    .mt-sm-9,
    .my-sm-9
    {
        margin-top: 9rem !important;
    }
    .mr-sm-9,
    .mx-sm-9
    {
        margin-right: 9rem !important;
    }
    .mb-sm-9,
    .my-sm-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-sm-9,
    .mx-sm-9
    {
        margin-left: 9rem !important;
    }
    .m-sm-10
    {
        margin: 10.5rem !important;
    }
    .mt-sm-10,
    .my-sm-10
    {
        margin-top: 10.5rem !important;
    }
    .mr-sm-10,
    .mx-sm-10
    {
        margin-right: 10.5rem !important;
    }
    .mb-sm-10,
    .my-sm-10
    {
        margin-bottom: 10.5rem !important;
    }
    .ml-sm-10,
    .mx-sm-10
    {
        margin-left: 10.5rem !important;
    }
    .m-sm-17
    {
        margin: 21rem !important;
    }
    .mt-sm-17,
    .my-sm-17
    {
        margin-top: 21rem !important;
    }
    .mr-sm-17,
    .mx-sm-17
    {
        margin-right: 21rem !important;
    }
    .mb-sm-17,
    .my-sm-17
    {
        margin-bottom: 21rem !important;
    }
    .ml-sm-17,
    .mx-sm-17
    {
        margin-left: 21rem !important;
    }
    .p-sm-0
    {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0
    {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0
    {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0
    {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0
    {
        padding-left: 0 !important;
    }
    .p-sm-1
    {
        padding: .25rem !important;
    }
    .pt-sm-1,
    .py-sm-1
    {
        padding-top: .25rem !important;
    }
    .pr-sm-1,
    .px-sm-1
    {
        padding-right: .25rem !important;
    }
    .pb-sm-1,
    .py-sm-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-sm-1,
    .px-sm-1
    {
        padding-left: .25rem !important;
    }
    .p-sm-2
    {
        padding: .5rem !important;
    }
    .pt-sm-2,
    .py-sm-2
    {
        padding-top: .5rem !important;
    }
    .pr-sm-2,
    .px-sm-2
    {
        padding-right: .5rem !important;
    }
    .pb-sm-2,
    .py-sm-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-sm-2,
    .px-sm-2
    {
        padding-left: .5rem !important;
    }
    .p-sm-3
    {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3
    {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3
    {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3
    {
        padding-left: 1rem !important;
    }
    .p-sm-4
    {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4
    {
        padding-left: 1.5rem !important;
    }
    .p-sm-5
    {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5
    {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5
    {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5
    {
        padding-left: 3rem !important;
    }
    .p-sm-6
    {
        padding: 4.5rem !important;
    }
    .pt-sm-6,
    .py-sm-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-sm-6,
    .px-sm-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-sm-6,
    .py-sm-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-sm-6,
    .px-sm-6
    {
        padding-left: 4.5rem !important;
    }
    .p-sm-7
    {
        padding: 6rem !important;
    }
    .pt-sm-7,
    .py-sm-7
    {
        padding-top: 6rem !important;
    }
    .pr-sm-7,
    .px-sm-7
    {
        padding-right: 6rem !important;
    }
    .pb-sm-7,
    .py-sm-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-sm-7,
    .px-sm-7
    {
        padding-left: 6rem !important;
    }
    .p-sm-8
    {
        padding: 7.5rem !important;
    }
    .pt-sm-8,
    .py-sm-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-sm-8,
    .px-sm-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-sm-8,
    .py-sm-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-sm-8,
    .px-sm-8
    {
        padding-left: 7.5rem !important;
    }
    .p-sm-9
    {
        padding: 9rem !important;
    }
    .pt-sm-9,
    .py-sm-9
    {
        padding-top: 9rem !important;
    }
    .pr-sm-9,
    .px-sm-9
    {
        padding-right: 9rem !important;
    }
    .pb-sm-9,
    .py-sm-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-sm-9,
    .px-sm-9
    {
        padding-left: 9rem !important;
    }
    .p-sm-10
    {
        padding: 10.5rem !important;
    }
    .pt-sm-10,
    .py-sm-10
    {
        padding-top: 10.5rem !important;
    }
    .pr-sm-10,
    .px-sm-10
    {
        padding-right: 10.5rem !important;
    }
    .pb-sm-10,
    .py-sm-10
    {
        padding-bottom: 10.5rem !important;
    }
    .pl-sm-10,
    .px-sm-10
    {
        padding-left: 10.5rem !important;
    }
    .p-sm-17
    {
        padding: 21rem !important;
    }
    .pt-sm-17,
    .py-sm-17
    {
        padding-top: 21rem !important;
    }
    .pr-sm-17,
    .px-sm-17
    {
        padding-right: 21rem !important;
    }
    .pb-sm-17,
    .py-sm-17
    {
        padding-bottom: 21rem !important;
    }
    .pl-sm-17,
    .px-sm-17
    {
        padding-left: 21rem !important;
    }
    .m-sm-n1
    {
        margin: -.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1
    {
        margin-left: -.25rem !important;
    }
    .m-sm-n2
    {
        margin: -.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2
    {
        margin-left: -.5rem !important;
    }
    .m-sm-n3
    {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3
    {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3
    {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3
    {
        margin-left: -1rem !important;
    }
    .m-sm-n4
    {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5
    {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5
    {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5
    {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5
    {
        margin-left: -3rem !important;
    }
    .m-sm-n6
    {
        margin: -4.5rem !important;
    }
    .mt-sm-n6,
    .my-sm-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-sm-n6,
    .mx-sm-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-sm-n6,
    .my-sm-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-sm-n6,
    .mx-sm-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-sm-n7
    {
        margin: -6rem !important;
    }
    .mt-sm-n7,
    .my-sm-n7
    {
        margin-top: -6rem !important;
    }
    .mr-sm-n7,
    .mx-sm-n7
    {
        margin-right: -6rem !important;
    }
    .mb-sm-n7,
    .my-sm-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-sm-n7,
    .mx-sm-n7
    {
        margin-left: -6rem !important;
    }
    .m-sm-n8
    {
        margin: -7.5rem !important;
    }
    .mt-sm-n8,
    .my-sm-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-sm-n8,
    .mx-sm-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-sm-n8,
    .my-sm-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-sm-n8,
    .mx-sm-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-sm-n9
    {
        margin: -9rem !important;
    }
    .mt-sm-n9,
    .my-sm-n9
    {
        margin-top: -9rem !important;
    }
    .mr-sm-n9,
    .mx-sm-n9
    {
        margin-right: -9rem !important;
    }
    .mb-sm-n9,
    .my-sm-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-sm-n9,
    .mx-sm-n9
    {
        margin-left: -9rem !important;
    }
    .m-sm-n10
    {
        margin: -10.5rem !important;
    }
    .mt-sm-n10,
    .my-sm-n10
    {
        margin-top: -10.5rem !important;
    }
    .mr-sm-n10,
    .mx-sm-n10
    {
        margin-right: -10.5rem !important;
    }
    .mb-sm-n10,
    .my-sm-n10
    {
        margin-bottom: -10.5rem !important;
    }
    .ml-sm-n10,
    .mx-sm-n10
    {
        margin-left: -10.5rem !important;
    }
    .m-sm-n17
    {
        margin: -21rem !important;
    }
    .mt-sm-n17,
    .my-sm-n17
    {
        margin-top: -21rem !important;
    }
    .mr-sm-n17,
    .mx-sm-n17
    {
        margin-right: -21rem !important;
    }
    .mb-sm-n17,
    .my-sm-n17
    {
        margin-bottom: -21rem !important;
    }
    .ml-sm-n17,
    .mx-sm-n17
    {
        margin-left: -21rem !important;
    }
    .m-sm-auto
    {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto
    {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto
    {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto
    {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 768px)
{
    .m-md-0
    {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0
    {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0
    {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0
    {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0
    {
        margin-left: 0 !important;
    }
    .m-md-1
    {
        margin: .25rem !important;
    }
    .mt-md-1,
    .my-md-1
    {
        margin-top: .25rem !important;
    }
    .mr-md-1,
    .mx-md-1
    {
        margin-right: .25rem !important;
    }
    .mb-md-1,
    .my-md-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-md-1,
    .mx-md-1
    {
        margin-left: .25rem !important;
    }
    .m-md-2
    {
        margin: .5rem !important;
    }
    .mt-md-2,
    .my-md-2
    {
        margin-top: .5rem !important;
    }
    .mr-md-2,
    .mx-md-2
    {
        margin-right: .5rem !important;
    }
    .mb-md-2,
    .my-md-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-md-2,
    .mx-md-2
    {
        margin-left: .5rem !important;
    }
    .m-md-3
    {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3
    {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3
    {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3
    {
        margin-left: 1rem !important;
    }
    .m-md-4
    {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4
    {
        margin-left: 1.5rem !important;
    }
    .m-md-5
    {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5
    {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5
    {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5
    {
        margin-left: 3rem !important;
    }
    .m-md-6
    {
        margin: 4.5rem !important;
    }
    .mt-md-6,
    .my-md-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-md-6,
    .mx-md-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-md-6,
    .my-md-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-md-6,
    .mx-md-6
    {
        margin-left: 4.5rem !important;
    }
    .m-md-7
    {
        margin: 6rem !important;
    }
    .mt-md-7,
    .my-md-7
    {
        margin-top: 6rem !important;
    }
    .mr-md-7,
    .mx-md-7
    {
        margin-right: 6rem !important;
    }
    .mb-md-7,
    .my-md-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-md-7,
    .mx-md-7
    {
        margin-left: 6rem !important;
    }
    .m-md-8
    {
        margin: 7.5rem !important;
    }
    .mt-md-8,
    .my-md-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-md-8,
    .mx-md-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-md-8,
    .my-md-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-md-8,
    .mx-md-8
    {
        margin-left: 7.5rem !important;
    }
    .m-md-9
    {
        margin: 9rem !important;
    }
    .mt-md-9,
    .my-md-9
    {
        margin-top: 9rem !important;
    }
    .mr-md-9,
    .mx-md-9
    {
        margin-right: 9rem !important;
    }
    .mb-md-9,
    .my-md-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-md-9,
    .mx-md-9
    {
        margin-left: 9rem !important;
    }
    .m-md-10
    {
        margin: 10.5rem !important;
    }
    .mt-md-10,
    .my-md-10
    {
        margin-top: 10.5rem !important;
    }
    .mr-md-10,
    .mx-md-10
    {
        margin-right: 10.5rem !important;
    }
    .mb-md-10,
    .my-md-10
    {
        margin-bottom: 10.5rem !important;
    }
    .ml-md-10,
    .mx-md-10
    {
        margin-left: 10.5rem !important;
    }
    .m-md-17
    {
        margin: 21rem !important;
    }
    .mt-md-17,
    .my-md-17
    {
        margin-top: 21rem !important;
    }
    .mr-md-17,
    .mx-md-17
    {
        margin-right: 21rem !important;
    }
    .mb-md-17,
    .my-md-17
    {
        margin-bottom: 21rem !important;
    }
    .ml-md-17,
    .mx-md-17
    {
        margin-left: 21rem !important;
    }
    .p-md-0
    {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0
    {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0
    {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0
    {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0
    {
        padding-left: 0 !important;
    }
    .p-md-1
    {
        padding: .25rem !important;
    }
    .pt-md-1,
    .py-md-1
    {
        padding-top: .25rem !important;
    }
    .pr-md-1,
    .px-md-1
    {
        padding-right: .25rem !important;
    }
    .pb-md-1,
    .py-md-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-md-1,
    .px-md-1
    {
        padding-left: .25rem !important;
    }
    .p-md-2
    {
        padding: .5rem !important;
    }
    .pt-md-2,
    .py-md-2
    {
        padding-top: .5rem !important;
    }
    .pr-md-2,
    .px-md-2
    {
        padding-right: .5rem !important;
    }
    .pb-md-2,
    .py-md-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-md-2,
    .px-md-2
    {
        padding-left: .5rem !important;
    }
    .p-md-3
    {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3
    {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3
    {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3
    {
        padding-left: 1rem !important;
    }
    .p-md-4
    {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4
    {
        padding-left: 1.5rem !important;
    }
    .p-md-5
    {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5
    {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5
    {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5
    {
        padding-left: 3rem !important;
    }
    .p-md-6
    {
        padding: 4.5rem !important;
    }
    .pt-md-6,
    .py-md-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-md-6,
    .px-md-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-md-6,
    .py-md-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-md-6,
    .px-md-6
    {
        padding-left: 4.5rem !important;
    }
    .p-md-7
    {
        padding: 6rem !important;
    }
    .pt-md-7,
    .py-md-7
    {
        padding-top: 6rem !important;
    }
    .pr-md-7,
    .px-md-7
    {
        padding-right: 6rem !important;
    }
    .pb-md-7,
    .py-md-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-md-7,
    .px-md-7
    {
        padding-left: 6rem !important;
    }
    .p-md-8
    {
        padding: 7.5rem !important;
    }
    .pt-md-8,
    .py-md-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-md-8,
    .px-md-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-md-8,
    .py-md-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-md-8,
    .px-md-8
    {
        padding-left: 7.5rem !important;
    }
    .p-md-9
    {
        padding: 9rem !important;
    }
    .pt-md-9,
    .py-md-9
    {
        padding-top: 9rem !important;
    }
    .pr-md-9,
    .px-md-9
    {
        padding-right: 9rem !important;
    }
    .pb-md-9,
    .py-md-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-md-9,
    .px-md-9
    {
        padding-left: 9rem !important;
    }
    .p-md-10
    {
        padding: 10.5rem !important;
    }
    .pt-md-10,
    .py-md-10
    {
        padding-top: 10.5rem !important;
    }
    .pr-md-10,
    .px-md-10
    {
        padding-right: 10.5rem !important;
    }
    .pb-md-10,
    .py-md-10
    {
        padding-bottom: 10.5rem !important;
    }
    .pl-md-10,
    .px-md-10
    {
        padding-left: 10.5rem !important;
    }
    .p-md-17
    {
        padding: 21rem !important;
    }
    .pt-md-17,
    .py-md-17
    {
        padding-top: 21rem !important;
    }
    .pr-md-17,
    .px-md-17
    {
        padding-right: 21rem !important;
    }
    .pb-md-17,
    .py-md-17
    {
        padding-bottom: 21rem !important;
    }
    .pl-md-17,
    .px-md-17
    {
        padding-left: 21rem !important;
    }
    .m-md-n1
    {
        margin: -.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1
    {
        margin-left: -.25rem !important;
    }
    .m-md-n2
    {
        margin: -.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2
    {
        margin-left: -.5rem !important;
    }
    .m-md-n3
    {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3
    {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3
    {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3
    {
        margin-left: -1rem !important;
    }
    .m-md-n4
    {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-md-n5
    {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5
    {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5
    {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5
    {
        margin-left: -3rem !important;
    }
    .m-md-n6
    {
        margin: -4.5rem !important;
    }
    .mt-md-n6,
    .my-md-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-md-n6,
    .mx-md-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-md-n6,
    .my-md-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-md-n6,
    .mx-md-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-md-n7
    {
        margin: -6rem !important;
    }
    .mt-md-n7,
    .my-md-n7
    {
        margin-top: -6rem !important;
    }
    .mr-md-n7,
    .mx-md-n7
    {
        margin-right: -6rem !important;
    }
    .mb-md-n7,
    .my-md-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-md-n7,
    .mx-md-n7
    {
        margin-left: -6rem !important;
    }
    .m-md-n8
    {
        margin: -7.5rem !important;
    }
    .mt-md-n8,
    .my-md-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-md-n8,
    .mx-md-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-md-n8,
    .my-md-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-md-n8,
    .mx-md-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-md-n9
    {
        margin: -9rem !important;
    }
    .mt-md-n9,
    .my-md-n9
    {
        margin-top: -9rem !important;
    }
    .mr-md-n9,
    .mx-md-n9
    {
        margin-right: -9rem !important;
    }
    .mb-md-n9,
    .my-md-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-md-n9,
    .mx-md-n9
    {
        margin-left: -9rem !important;
    }
    .m-md-n10
    {
        margin: -10.5rem !important;
    }
    .mt-md-n10,
    .my-md-n10
    {
        margin-top: -10.5rem !important;
    }
    .mr-md-n10,
    .mx-md-n10
    {
        margin-right: -10.5rem !important;
    }
    .mb-md-n10,
    .my-md-n10
    {
        margin-bottom: -10.5rem !important;
    }
    .ml-md-n10,
    .mx-md-n10
    {
        margin-left: -10.5rem !important;
    }
    .m-md-n17
    {
        margin: -21rem !important;
    }
    .mt-md-n17,
    .my-md-n17
    {
        margin-top: -21rem !important;
    }
    .mr-md-n17,
    .mx-md-n17
    {
        margin-right: -21rem !important;
    }
    .mb-md-n17,
    .my-md-n17
    {
        margin-bottom: -21rem !important;
    }
    .ml-md-n17,
    .mx-md-n17
    {
        margin-left: -21rem !important;
    }
    .m-md-auto
    {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto
    {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto
    {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto
    {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 992px)
{
    .m-lg-0
    {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0
    {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0
    {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0
    {
        margin-left: 0 !important;
    }
    .m-lg-1
    {
        margin: .25rem !important;
    }
    .mt-lg-1,
    .my-lg-1
    {
        margin-top: .25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1
    {
        margin-right: .25rem !important;
    }
    .mb-lg-1,
    .my-lg-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1
    {
        margin-left: .25rem !important;
    }
    .m-lg-2
    {
        margin: .5rem !important;
    }
    .mt-lg-2,
    .my-lg-2
    {
        margin-top: .5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2
    {
        margin-right: .5rem !important;
    }
    .mb-lg-2,
    .my-lg-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2
    {
        margin-left: .5rem !important;
    }
    .m-lg-3
    {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3
    {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3
    {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3
    {
        margin-left: 1rem !important;
    }
    .m-lg-4
    {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4
    {
        margin-left: 1.5rem !important;
    }
    .m-lg-5
    {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5
    {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5
    {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5
    {
        margin-left: 3rem !important;
    }
    .m-lg-6
    {
        margin: 4.5rem !important;
    }
    .mt-lg-6,
    .my-lg-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-lg-6,
    .mx-lg-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-lg-6,
    .my-lg-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-lg-6,
    .mx-lg-6
    {
        margin-left: 4.5rem !important;
    }
    .m-lg-7
    {
        margin: 6rem !important;
    }
    .mt-lg-7,
    .my-lg-7
    {
        margin-top: 6rem !important;
    }
    .mr-lg-7,
    .mx-lg-7
    {
        margin-right: 6rem !important;
    }
    .mb-lg-7,
    .my-lg-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-lg-7,
    .mx-lg-7
    {
        margin-left: 6rem !important;
    }
    .m-lg-8
    {
        margin: 7.5rem !important;
    }
    .mt-lg-8,
    .my-lg-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-lg-8,
    .mx-lg-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-lg-8,
    .my-lg-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-lg-8,
    .mx-lg-8
    {
        margin-left: 7.5rem !important;
    }
    .m-lg-9
    {
        margin: 9rem !important;
    }
    .mt-lg-9,
    .my-lg-9
    {
        margin-top: 9rem !important;
    }
    .mr-lg-9,
    .mx-lg-9
    {
        margin-right: 9rem !important;
    }
    .mb-lg-9,
    .my-lg-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-lg-9,
    .mx-lg-9
    {
        margin-left: 9rem !important;
    }
    .m-lg-10
    {
        margin: 10.5rem !important;
    }
    .mt-lg-10,
    .my-lg-10
    {
        margin-top: 10.5rem !important;
    }
    .mr-lg-10,
    .mx-lg-10
    {
        margin-right: 10.5rem !important;
    }
    .mb-lg-10,
    .my-lg-10
    {
        margin-bottom: 10.5rem !important;
    }
    .ml-lg-10,
    .mx-lg-10
    {
        margin-left: 10.5rem !important;
    }
    .m-lg-17
    {
        margin: 21rem !important;
    }
    .mt-lg-17,
    .my-lg-17
    {
        margin-top: 21rem !important;
    }
    .mr-lg-17,
    .mx-lg-17
    {
        margin-right: 21rem !important;
    }
    .mb-lg-17,
    .my-lg-17
    {
        margin-bottom: 21rem !important;
    }
    .ml-lg-17,
    .mx-lg-17
    {
        margin-left: 21rem !important;
    }
    .p-lg-0
    {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0
    {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0
    {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0
    {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0
    {
        padding-left: 0 !important;
    }
    .p-lg-1
    {
        padding: .25rem !important;
    }
    .pt-lg-1,
    .py-lg-1
    {
        padding-top: .25rem !important;
    }
    .pr-lg-1,
    .px-lg-1
    {
        padding-right: .25rem !important;
    }
    .pb-lg-1,
    .py-lg-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-lg-1,
    .px-lg-1
    {
        padding-left: .25rem !important;
    }
    .p-lg-2
    {
        padding: .5rem !important;
    }
    .pt-lg-2,
    .py-lg-2
    {
        padding-top: .5rem !important;
    }
    .pr-lg-2,
    .px-lg-2
    {
        padding-right: .5rem !important;
    }
    .pb-lg-2,
    .py-lg-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-lg-2,
    .px-lg-2
    {
        padding-left: .5rem !important;
    }
    .p-lg-3
    {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3
    {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3
    {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3
    {
        padding-left: 1rem !important;
    }
    .p-lg-4
    {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4
    {
        padding-left: 1.5rem !important;
    }
    .p-lg-5
    {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5
    {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5
    {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5
    {
        padding-left: 3rem !important;
    }
    .p-lg-6
    {
        padding: 4.5rem !important;
    }
    .pt-lg-6,
    .py-lg-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-lg-6,
    .px-lg-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-lg-6,
    .py-lg-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-lg-6,
    .px-lg-6
    {
        padding-left: 4.5rem !important;
    }
    .p-lg-7
    {
        padding: 6rem !important;
    }
    .pt-lg-7,
    .py-lg-7
    {
        padding-top: 6rem !important;
    }
    .pr-lg-7,
    .px-lg-7
    {
        padding-right: 6rem !important;
    }
    .pb-lg-7,
    .py-lg-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-lg-7,
    .px-lg-7
    {
        padding-left: 6rem !important;
    }
    .p-lg-8
    {
        padding: 7.5rem !important;
    }
    .pt-lg-8,
    .py-lg-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-lg-8,
    .px-lg-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-lg-8,
    .py-lg-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-lg-8,
    .px-lg-8
    {
        padding-left: 7.5rem !important;
    }
    .p-lg-9
    {
        padding: 9rem !important;
    }
    .pt-lg-9,
    .py-lg-9
    {
        padding-top: 9rem !important;
    }
    .pr-lg-9,
    .px-lg-9
    {
        padding-right: 9rem !important;
    }
    .pb-lg-9,
    .py-lg-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-lg-9,
    .px-lg-9
    {
        padding-left: 9rem !important;
    }
    .p-lg-10
    {
        padding: 10.5rem !important;
    }
    .pt-lg-10,
    .py-lg-10
    {
        padding-top: 10.5rem !important;
    }
    .pr-lg-10,
    .px-lg-10
    {
        padding-right: 10.5rem !important;
    }
    .pb-lg-10,
    .py-lg-10
    {
        padding-bottom: 10.5rem !important;
    }
    .pl-lg-10,
    .px-lg-10
    {
        padding-left: 10.5rem !important;
    }
    .p-lg-17
    {
        padding: 21rem !important;
    }
    .pt-lg-17,
    .py-lg-17
    {
        padding-top: 21rem !important;
    }
    .pr-lg-17,
    .px-lg-17
    {
        padding-right: 21rem !important;
    }
    .pb-lg-17,
    .py-lg-17
    {
        padding-bottom: 21rem !important;
    }
    .pl-lg-17,
    .px-lg-17
    {
        padding-left: 21rem !important;
    }
    .m-lg-n1
    {
        margin: -.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1
    {
        margin-left: -.25rem !important;
    }
    .m-lg-n2
    {
        margin: -.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2
    {
        margin-left: -.5rem !important;
    }
    .m-lg-n3
    {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3
    {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3
    {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3
    {
        margin-left: -1rem !important;
    }
    .m-lg-n4
    {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5
    {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5
    {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5
    {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5
    {
        margin-left: -3rem !important;
    }
    .m-lg-n6
    {
        margin: -4.5rem !important;
    }
    .mt-lg-n6,
    .my-lg-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-lg-n6,
    .mx-lg-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-lg-n6,
    .my-lg-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-lg-n6,
    .mx-lg-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-lg-n7
    {
        margin: -6rem !important;
    }
    .mt-lg-n7,
    .my-lg-n7
    {
        margin-top: -6rem !important;
    }
    .mr-lg-n7,
    .mx-lg-n7
    {
        margin-right: -6rem !important;
    }
    .mb-lg-n7,
    .my-lg-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-lg-n7,
    .mx-lg-n7
    {
        margin-left: -6rem !important;
    }
    .m-lg-n8
    {
        margin: -7.5rem !important;
    }
    .mt-lg-n8,
    .my-lg-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-lg-n8,
    .mx-lg-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-lg-n8,
    .my-lg-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-lg-n8,
    .mx-lg-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-lg-n9
    {
        margin: -9rem !important;
    }
    .mt-lg-n9,
    .my-lg-n9
    {
        margin-top: -9rem !important;
    }
    .mr-lg-n9,
    .mx-lg-n9
    {
        margin-right: -9rem !important;
    }
    .mb-lg-n9,
    .my-lg-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-lg-n9,
    .mx-lg-n9
    {
        margin-left: -9rem !important;
    }
    .m-lg-n10
    {
        margin: -10.5rem !important;
    }
    .mt-lg-n10,
    .my-lg-n10
    {
        margin-top: -10.5rem !important;
    }
    .mr-lg-n10,
    .mx-lg-n10
    {
        margin-right: -10.5rem !important;
    }
    .mb-lg-n10,
    .my-lg-n10
    {
        margin-bottom: -10.5rem !important;
    }
    .ml-lg-n10,
    .mx-lg-n10
    {
        margin-left: -10.5rem !important;
    }
    .m-lg-n17
    {
        margin: -21rem !important;
    }
    .mt-lg-n17,
    .my-lg-n17
    {
        margin-top: -21rem !important;
    }
    .mr-lg-n17,
    .mx-lg-n17
    {
        margin-right: -21rem !important;
    }
    .mb-lg-n17,
    .my-lg-n17
    {
        margin-bottom: -21rem !important;
    }
    .ml-lg-n17,
    .mx-lg-n17
    {
        margin-left: -21rem !important;
    }
    .m-lg-auto
    {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto
    {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto
    {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto
    {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px)
{
    .m-xl-0
    {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0
    {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0
    {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0
    {
        margin-left: 0 !important;
    }
    .m-xl-1
    {
        margin: .25rem !important;
    }
    .mt-xl-1,
    .my-xl-1
    {
        margin-top: .25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1
    {
        margin-right: .25rem !important;
    }
    .mb-xl-1,
    .my-xl-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1
    {
        margin-left: .25rem !important;
    }
    .m-xl-2
    {
        margin: .5rem !important;
    }
    .mt-xl-2,
    .my-xl-2
    {
        margin-top: .5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2
    {
        margin-right: .5rem !important;
    }
    .mb-xl-2,
    .my-xl-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2
    {
        margin-left: .5rem !important;
    }
    .m-xl-3
    {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3
    {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3
    {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3
    {
        margin-left: 1rem !important;
    }
    .m-xl-4
    {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4
    {
        margin-left: 1.5rem !important;
    }
    .m-xl-5
    {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5
    {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5
    {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5
    {
        margin-left: 3rem !important;
    }
    .m-xl-6
    {
        margin: 4.5rem !important;
    }
    .mt-xl-6,
    .my-xl-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-xl-6,
    .mx-xl-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-xl-6,
    .my-xl-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-xl-6,
    .mx-xl-6
    {
        margin-left: 4.5rem !important;
    }
    .m-xl-7
    {
        margin: 6rem !important;
    }
    .mt-xl-7,
    .my-xl-7
    {
        margin-top: 6rem !important;
    }
    .mr-xl-7,
    .mx-xl-7
    {
        margin-right: 6rem !important;
    }
    .mb-xl-7,
    .my-xl-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-xl-7,
    .mx-xl-7
    {
        margin-left: 6rem !important;
    }
    .m-xl-8
    {
        margin: 7.5rem !important;
    }
    .mt-xl-8,
    .my-xl-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-xl-8,
    .mx-xl-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-xl-8,
    .my-xl-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-xl-8,
    .mx-xl-8
    {
        margin-left: 7.5rem !important;
    }
    .m-xl-9
    {
        margin: 9rem !important;
    }
    .mt-xl-9,
    .my-xl-9
    {
        margin-top: 9rem !important;
    }
    .mr-xl-9,
    .mx-xl-9
    {
        margin-right: 9rem !important;
    }
    .mb-xl-9,
    .my-xl-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-xl-9,
    .mx-xl-9
    {
        margin-left: 9rem !important;
    }
    .m-xl-10
    {
        margin: 10.5rem !important;
    }
    .mt-xl-10,
    .my-xl-10
    {
        margin-top: 10.5rem !important;
    }
    .mr-xl-10,
    .mx-xl-10
    {
        margin-right: 10.5rem !important;
    }
    .mb-xl-10,
    .my-xl-10
    {
        margin-bottom: 10.5rem !important;
    }
    .ml-xl-10,
    .mx-xl-10
    {
        margin-left: 10.5rem !important;
    }
    .m-xl-17
    {
        margin: 21rem !important;
    }
    .mt-xl-17,
    .my-xl-17
    {
        margin-top: 21rem !important;
    }
    .mr-xl-17,
    .mx-xl-17
    {
        margin-right: 21rem !important;
    }
    .mb-xl-17,
    .my-xl-17
    {
        margin-bottom: 21rem !important;
    }
    .ml-xl-17,
    .mx-xl-17
    {
        margin-left: 21rem !important;
    }
    .p-xl-0
    {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0
    {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0
    {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0
    {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0
    {
        padding-left: 0 !important;
    }
    .p-xl-1
    {
        padding: .25rem !important;
    }
    .pt-xl-1,
    .py-xl-1
    {
        padding-top: .25rem !important;
    }
    .pr-xl-1,
    .px-xl-1
    {
        padding-right: .25rem !important;
    }
    .pb-xl-1,
    .py-xl-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-xl-1,
    .px-xl-1
    {
        padding-left: .25rem !important;
    }
    .p-xl-2
    {
        padding: .5rem !important;
    }
    .pt-xl-2,
    .py-xl-2
    {
        padding-top: .5rem !important;
    }
    .pr-xl-2,
    .px-xl-2
    {
        padding-right: .5rem !important;
    }
    .pb-xl-2,
    .py-xl-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-xl-2,
    .px-xl-2
    {
        padding-left: .5rem !important;
    }
    .p-xl-3
    {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3
    {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3
    {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3
    {
        padding-left: 1rem !important;
    }
    .p-xl-4
    {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4
    {
        padding-left: 1.5rem !important;
    }
    .p-xl-5
    {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5
    {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5
    {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5
    {
        padding-left: 3rem !important;
    }
    .p-xl-6
    {
        padding: 4.5rem !important;
    }
    .pt-xl-6,
    .py-xl-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-xl-6,
    .px-xl-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-xl-6,
    .py-xl-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-xl-6,
    .px-xl-6
    {
        padding-left: 4.5rem !important;
    }
    .p-xl-7
    {
        padding: 6rem !important;
    }
    .pt-xl-7,
    .py-xl-7
    {
        padding-top: 6rem !important;
    }
    .pr-xl-7,
    .px-xl-7
    {
        padding-right: 6rem !important;
    }
    .pb-xl-7,
    .py-xl-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-xl-7,
    .px-xl-7
    {
        padding-left: 6rem !important;
    }
    .p-xl-8
    {
        padding: 7.5rem !important;
    }
    .pt-xl-8,
    .py-xl-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-xl-8,
    .px-xl-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-xl-8,
    .py-xl-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-xl-8,
    .px-xl-8
    {
        padding-left: 7.5rem !important;
    }
    .p-xl-9
    {
        padding: 9rem !important;
    }
    .pt-xl-9,
    .py-xl-9
    {
        padding-top: 9rem !important;
    }
    .pr-xl-9,
    .px-xl-9
    {
        padding-right: 9rem !important;
    }
    .pb-xl-9,
    .py-xl-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-xl-9,
    .px-xl-9
    {
        padding-left: 9rem !important;
    }
    .p-xl-10
    {
        padding: 10.5rem !important;
    }
    .pt-xl-10,
    .py-xl-10
    {
        padding-top: 10.5rem !important;
    }
    .pr-xl-10,
    .px-xl-10
    {
        padding-right: 10.5rem !important;
    }
    .pb-xl-10,
    .py-xl-10
    {
        padding-bottom: 10.5rem !important;
    }
    .pl-xl-10,
    .px-xl-10
    {
        padding-left: 10.5rem !important;
    }
    .p-xl-17
    {
        padding: 21rem !important;
    }
    .pt-xl-17,
    .py-xl-17
    {
        padding-top: 21rem !important;
    }
    .pr-xl-17,
    .px-xl-17
    {
        padding-right: 21rem !important;
    }
    .pb-xl-17,
    .py-xl-17
    {
        padding-bottom: 21rem !important;
    }
    .pl-xl-17,
    .px-xl-17
    {
        padding-left: 21rem !important;
    }
    .m-xl-n1
    {
        margin: -.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1
    {
        margin-left: -.25rem !important;
    }
    .m-xl-n2
    {
        margin: -.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2
    {
        margin-left: -.5rem !important;
    }
    .m-xl-n3
    {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3
    {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3
    {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3
    {
        margin-left: -1rem !important;
    }
    .m-xl-n4
    {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5
    {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5
    {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5
    {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5
    {
        margin-left: -3rem !important;
    }
    .m-xl-n6
    {
        margin: -4.5rem !important;
    }
    .mt-xl-n6,
    .my-xl-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-xl-n6,
    .mx-xl-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-xl-n6,
    .my-xl-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-xl-n6,
    .mx-xl-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-xl-n7
    {
        margin: -6rem !important;
    }
    .mt-xl-n7,
    .my-xl-n7
    {
        margin-top: -6rem !important;
    }
    .mr-xl-n7,
    .mx-xl-n7
    {
        margin-right: -6rem !important;
    }
    .mb-xl-n7,
    .my-xl-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-xl-n7,
    .mx-xl-n7
    {
        margin-left: -6rem !important;
    }
    .m-xl-n8
    {
        margin: -7.5rem !important;
    }
    .mt-xl-n8,
    .my-xl-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-xl-n8,
    .mx-xl-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-xl-n8,
    .my-xl-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-xl-n8,
    .mx-xl-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-xl-n9
    {
        margin: -9rem !important;
    }
    .mt-xl-n9,
    .my-xl-n9
    {
        margin-top: -9rem !important;
    }
    .mr-xl-n9,
    .mx-xl-n9
    {
        margin-right: -9rem !important;
    }
    .mb-xl-n9,
    .my-xl-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-xl-n9,
    .mx-xl-n9
    {
        margin-left: -9rem !important;
    }
    .m-xl-n10
    {
        margin: -10.5rem !important;
    }
    .mt-xl-n10,
    .my-xl-n10
    {
        margin-top: -10.5rem !important;
    }
    .mr-xl-n10,
    .mx-xl-n10
    {
        margin-right: -10.5rem !important;
    }
    .mb-xl-n10,
    .my-xl-n10
    {
        margin-bottom: -10.5rem !important;
    }
    .ml-xl-n10,
    .mx-xl-n10
    {
        margin-left: -10.5rem !important;
    }
    .m-xl-n17
    {
        margin: -21rem !important;
    }
    .mt-xl-n17,
    .my-xl-n17
    {
        margin-top: -21rem !important;
    }
    .mr-xl-n17,
    .mx-xl-n17
    {
        margin-right: -21rem !important;
    }
    .mb-xl-n17,
    .my-xl-n17
    {
        margin-bottom: -21rem !important;
    }
    .ml-xl-n17,
    .mx-xl-n17
    {
        margin-left: -21rem !important;
    }
    .m-xl-auto
    {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto
    {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto
    {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto
    {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto
    {
        margin-left: auto !important;
    }
}

.text-monospace
{
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify
{
    text-align: justify !important;
}

.text-wrap
{
    white-space: normal !important;
}

.text-nowrap
{
    white-space: nowrap !important;
}

.text-truncate
{
    overflow: hidden;

    white-space: nowrap; 
    text-overflow: ellipsis;
}

.text-left
{
    text-align: left !important;
}

.text-right
{
    text-align: right !important;
}

.text-center
{
    text-align: center !important;
}

@media (min-width: 576px)
{
    .text-sm-left
    {
        text-align: left !important;
    }
    .text-sm-right
    {
        text-align: right !important;
    }
    .text-sm-center
    {
        text-align: center !important;
    }
}

@media (min-width: 768px)
{
    .text-md-left
    {
        text-align: left !important;
    }
    .text-md-right
    {
        text-align: right !important;
    }
    .text-md-center
    {
        text-align: center !important;
    }
}

@media (min-width: 992px)
{
    .text-lg-left
    {
        text-align: left !important;
    }
    .text-lg-right
    {
        text-align: right !important;
    }
    .text-lg-center
    {
        text-align: center !important;
    }
}

@media (min-width: 1200px)
{
    .text-xl-left
    {
        text-align: left !important;
    }
    .text-xl-right
    {
        text-align: right !important;
    }
    .text-xl-center
    {
        text-align: center !important;
    }
}

.text-lowercase
{
    text-transform: lowercase !important;
}

.text-uppercase
{
    text-transform: uppercase !important;
}

.text-capitalize
{
    text-transform: capitalize !important;
}

.font-weight-light
{
    font-weight: 300 !important;
}

.font-weight-lighter
{
    font-weight: lighter !important;
}

.font-weight-normal
{
    font-weight: 400 !important;
}

.font-weight-bold
{
    font-weight: 600 !important;
}

.font-weight-bolder
{
    font-weight: 700 !important;
}

.font-italic
{
    font-style: italic !important;
}

.text-white
{
    color: #fff !important;
}

.text-primary
{
    color: #0c66ff !important;
}

a.text-primary:hover,
a.text-primary:focus
{
    color: #0047bf !important;
}

.text-secondary
{
    color: #eff2f7 !important;
}

a.text-secondary:hover,
a.text-secondary:focus
{
    color: #bcc9de !important;
}

.text-success
{
    color: #2dca8c !important;
}

a.text-success:hover,
a.text-success:focus
{
    color: #1f8b61 !important;
}

.text-info
{
    color: #50b5ff !important;
}

a.text-info:hover,
a.text-info:focus
{
    color: #0495ff !important;
}

.text-warning
{
    color: #ffbe3d !important;
}

a.text-warning:hover,
a.text-warning:focus
{
    color: #f09f00 !important;
}

.text-danger
{
    color: #ff5c75 !important;
}

a.text-danger:hover,
a.text-danger:focus
{
    color: #ff1034 !important;
}

.text-light
{
    color: #eff2f7 !important;
}

a.text-light:hover,
a.text-light:focus
{
    color: #bcc9de !important;
}

.text-dark
{
    color: #273444 !important;
}

a.text-dark:hover,
a.text-dark:focus
{
    color: #0b0f13 !important;
}

.text-neutral
{
    color: #fff !important;
}

a.text-neutral:hover,
a.text-neutral:focus
{
    color: #d9d9d9 !important;
}

.text-white
{
    color: #fff !important;
}

a.text-white:hover,
a.text-white:focus
{
    color: #d9d9d9 !important;
}

.text-body
{
    color: #8492a6 !important;
}

.text-muted
{
    color: #8492a6 !important;
}

.text-black-50
{
    color: rgba(31, 45, 61, .5) !important;
}

.text-white-50
{
    color: rgba(255, 255, 255, .5) !important;
}

.text-hide
{
    font: 0/0 a;

    color: transparent;
    border: 0; 
    background-color: transparent;
    text-shadow: none;
}

.text-decoration-none
{
    text-decoration: none !important;
}

.text-break
{
    word-break: break-word !important;

    overflow-wrap: break-word !important;
}

.text-reset
{
    color: inherit !important;
}

.visible
{
    visibility: visible !important;
}

.invisible
{
    visibility: hidden !important;
}

@media print
{
    *,
    *::before,
    *::after
    {
        box-shadow: none !important; 
        text-shadow: none !important;
    }
    a:not(.btn)
    {
        text-decoration: underline;
    }
    abbr[title]::after
    {
        content: ' (' attr(title) ')';
    }
    pre
    {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote
    {
        border: 1px solid #d3dce6;

        page-break-inside: avoid;
    }
    thead
    {
        display: table-header-group;
    }
    tr,
    img
    {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3
    {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3
    {
        page-break-after: avoid;
    }
    @page
    {
        size: a3;
    }
    body
    {
        min-width: 992px !important;
    }
    .container
    {
        min-width: 992px !important;
    }
    .navbar
    {
        display: none;
    }
    .badge
    {
        border: 1px solid #1f2d3d;
    }
    .table
    {
        border-collapse: collapse !important;
    }
    .table td,
    .table th
    {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td
    {
        border: 1px solid #e5e9f2 !important;
    }
    .table-dark
    {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody
    {
        border-color: #eff2f7;
    }
    .table .thead-dark th
    {
        color: inherit;
        border-color: #eff2f7;
    }
}

[data-animate-hover] .animate-this
{
    position: relative;
    top: 0;

    transition: all .2s ease; 
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
@media (prefers-reduced-motion: reduce)
{
    [data-animate-hover] .animate-this
    {
        transition: none;
    }
}

[data-animate-hover='1']:hover .animate-this
{
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);

    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
}

[data-animate-hover='2']:hover .animate-this
{
    z-index: 10;

    -webkit-transform: scale(1.1);
            transform: scale(1.1);

    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
}

[data-animate-items] .animate-item,
[data-animate-items] .animate-item--visible
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    [data-animate-items] .animate-item,
    [data-animate-items] .animate-item--visible
    {
        transition: none;
    }
}

[data-animate-items] .animate-item
{
    display: none;

    -webkit-animation: hide_item .2s ease forwards;
            animation: hide_item .2s ease forwards;
    -webkit-animation-delay: .5s;
            animation-delay: .5s; 

    opacity: 0;
}

[data-animate-items]:hover .animate-item
{
    display: block;

    -webkit-animation: show_item .2s ease forwards;
            animation: show_item .2s ease forwards;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

@-webkit-keyframes text_slide
{
    0%
    {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%
    {
        -webkit-transform: translateX(300px);
                transform: translateX(300px);
    }
}

@keyframes text_slide
{
    0%
    {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%
    {
        -webkit-transform: translateX(300px);
                transform: translateX(300px);
    }
}

@-webkit-keyframes show_item
{
    from
    {
        -webkit-transform: translateY(300px);
                transform: translateY(300px); 

        opacity: 0;
    }
    to
    {
        -webkit-transform: translateY(0);
                transform: translateY(0); 

        opacity: 1;
    }
}

@keyframes show_item
{
    from
    {
        -webkit-transform: translateY(300px);
                transform: translateY(300px); 

        opacity: 0;
    }
    to
    {
        -webkit-transform: translateY(0);
                transform: translateY(0); 

        opacity: 1;
    }
}

@-webkit-keyframes hide_item
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0); 

        opacity: 1;
    }
    100%
    {
        -webkit-transform: translateY(300px);
                transform: translateY(300px); 

        opacity: 0;
    }
}

@keyframes hide_item
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0); 

        opacity: 1;
    }
    100%
    {
        -webkit-transform: translateY(300px);
                transform: translateY(300px); 

        opacity: 0;
    }
}

.hover-blurable
{
    position: relative;
}
.hover-blurable .blurable-item
{
    transition: 1s cubic-bezier(.19, 1, .22, 1);

    opacity: 1; 

    -webkit-filter: blur(0);
            filter: blur(0);
}
.hover-blurable .blurable-hidden
{
    position: absolute;
    top: calc(50% + 7px);
    left: 50%;

    transition: all .2s ease; 
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    opacity: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .hover-blurable .blurable-hidden
    {
        transition: none;
    }
}
.hover-blurable:hover .blurable-item
{
    -webkit-transform: scale(.95);
            transform: scale(.95); 

    opacity: .8;

    -webkit-filter: blur(10px);
            filter: blur(10px);
}
.hover-blurable:hover .blurable-hidden
{
    top: 50%; 

    opacity: 1;
}

.bg-primary-light
{
    background-color: #3f86ff !important;
}

a.bg-primary-light:hover,
a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus
{
    background-color: #0c66ff !important;
}

.bg-primary-dark
{
    background-color: #0050d8 !important;
}

a.bg-primary-dark:hover,
a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus
{
    background-color: #003da5 !important;
}

.bg-secondary-light
{
    background-color: white !important;
}

a.bg-secondary-light:hover,
a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus
{
    background-color: #e6e6e6 !important;
}

.bg-secondary-dark
{
    background-color: #cdd6e6 !important;
}

a.bg-secondary-dark:hover,
a.bg-secondary-dark:focus,
button.bg-secondary-dark:hover,
button.bg-secondary-dark:focus
{
    background-color: #abbbd5 !important;
}

.bg-success-light
{
    background-color: #52d8a3 !important;
}

a.bg-success-light:hover,
a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus
{
    background-color: #2dca8c !important;
}

.bg-success-dark
{
    background-color: #24a06f !important;
}

a.bg-success-dark:hover,
a.bg-success-dark:focus,
button.bg-success-dark:hover,
button.bg-success-dark:focus
{
    background-color: #1a7752 !important;
}

.bg-info-light
{
    background-color: #83cbff !important;
}

a.bg-info-light:hover,
a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus
{
    background-color: #50b5ff !important;
}

.bg-info-dark
{
    background-color: #1d9fff !important;
}

a.bg-info-dark:hover,
a.bg-info-dark:focus,
button.bg-info-dark:hover,
button.bg-info-dark:focus
{
    background-color: #0086e9 !important;
}

.bg-warning-light
{
    background-color: #ffcf70 !important;
}

a.bg-warning-light:hover,
a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus
{
    background-color: #ffbe3d !important;
}

.bg-warning-dark
{
    background-color: #ffad0a !important;
}

a.bg-warning-dark:hover,
a.bg-warning-dark:focus,
button.bg-warning-dark:hover,
button.bg-warning-dark:focus
{
    background-color: #d68e00 !important;
}

.bg-danger-light
{
    background-color: #ff8fa0 !important;
}

a.bg-danger-light:hover,
a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus
{
    background-color: #ff5c75 !important;
}

.bg-danger-dark
{
    background-color: #ff294a !important;
}

a.bg-danger-dark:hover,
a.bg-danger-dark:focus,
button.bg-danger-dark:hover,
button.bg-danger-dark:focus
{
    background-color: #f50026 !important;
}

.bg-light-light
{
    background-color: white !important;
}

a.bg-light-light:hover,
a.bg-light-light:focus,
button.bg-light-light:hover,
button.bg-light-light:focus
{
    background-color: #e6e6e6 !important;
}

.bg-light-dark
{
    background-color: #cdd6e6 !important;
}

a.bg-light-dark:hover,
a.bg-light-dark:focus,
button.bg-light-dark:hover,
button.bg-light-dark:focus
{
    background-color: #abbbd5 !important;
}

.bg-dark-light
{
    background-color: #3a4d64 !important;
}

a.bg-dark-light:hover,
a.bg-dark-light:focus,
button.bg-dark-light:hover,
button.bg-dark-light:focus
{
    background-color: #273444 !important;
}

.bg-dark-dark
{
    background-color: #141b24 !important;
}

a.bg-dark-dark:hover,
a.bg-dark-dark:focus,
button.bg-dark-dark:hover,
button.bg-dark-dark:focus
{
    background-color: #020203 !important;
}

.bg-neutral-light
{
    background-color: white !important;
}

a.bg-neutral-light:hover,
a.bg-neutral-light:focus,
button.bg-neutral-light:hover,
button.bg-neutral-light:focus
{
    background-color: #e6e6e6 !important;
}

.bg-neutral-dark
{
    background-color: #e6e6e6 !important;
}

a.bg-neutral-dark:hover,
a.bg-neutral-dark:focus,
button.bg-neutral-dark:hover,
button.bg-neutral-dark:focus
{
    background-color: #ccc !important;
}

.bg-white-light
{
    background-color: white !important;
}

a.bg-white-light:hover,
a.bg-white-light:focus,
button.bg-white-light:hover,
button.bg-white-light:focus
{
    background-color: #e6e6e6 !important;
}

.bg-white-dark
{
    background-color: #e6e6e6 !important;
}

a.bg-white-dark:hover,
a.bg-white-dark:focus,
button.bg-white-dark:hover,
button.bg-white-dark:focus
{
    background-color: #ccc !important;
}

.bg-gradient-primary
{
    background: linear-gradient(50deg, #0c66ff 0, #0c1dff 100%) !important;
}

.bg-gradient-secondary
{
    background: linear-gradient(50deg, #eff2f7 0, #eff0f7 100%) !important;
}

.bg-gradient-success
{
    background: linear-gradient(50deg, #2dca8c 0, #2dcabb 100%) !important;
}

.bg-gradient-info
{
    background: linear-gradient(50deg, #50b5ff 0, #5081ff 100%) !important;
}

.bg-gradient-warning
{
    background: linear-gradient(50deg, #ffbe3d 0, #fff83d 100%) !important;
}

.bg-gradient-danger
{
    background: linear-gradient(50deg, #ff5c75 0, #ff745c 100%) !important;
}

.bg-gradient-light
{
    background: linear-gradient(50deg, #eff2f7 0, #eff0f7 100%) !important;
}

.bg-gradient-dark
{
    background: linear-gradient(50deg, #273444 0, #272b44 100%) !important;
}

.bg-gradient-neutral
{
    background: linear-gradient(50deg, #fff 0, white 100%) !important;
}

.bg-gradient-white
{
    background: linear-gradient(50deg, #fff 0, white 100%) !important;
}

.bg-translucent-primary
{
    background-color: rgba(0, 86, 231, .6) !important;
}

a.bg-translucent-primary:hover,
a.bg-translucent-primary:focus,
button.bg-translucent-primary:hover,
button.bg-translucent-primary:focus
{
    background-color: rgba(0, 76, 206, .6) !important;
}

.bg-translucent-secondary
{
    background-color: rgba(215, 223, 235, .6) !important;
}

a.bg-translucent-secondary:hover,
a.bg-translucent-secondary:focus,
button.bg-translucent-secondary:hover,
button.bg-translucent-secondary:focus
{
    background-color: rgba(198, 209, 227, .6) !important;
}

.bg-translucent-success
{
    background-color: rgba(38, 173, 120, .6) !important;
}

a.bg-translucent-success:hover,
a.bg-translucent-success:focus,
button.bg-translucent-success:hover,
button.bg-translucent-success:focus
{
    background-color: rgba(34, 152, 105, .6) !important;
}

.bg-translucent-info
{
    background-color: rgba(44, 166, 255, .6) !important;
}

a.bg-translucent-info:hover,
a.bg-translucent-info:focus,
button.bg-translucent-info:hover,
button.bg-translucent-info:focus
{
    background-color: rgba(19, 155, 255, .6) !important;
}

.bg-translucent-warning
{
    background-color: rgba(255, 178, 25, .6) !important;
}

a.bg-translucent-warning:hover,
a.bg-translucent-warning:focus,
button.bg-translucent-warning:hover,
button.bg-translucent-warning:focus
{
    background-color: rgba(255, 169, 0, .6) !important;
}

.bg-translucent-danger
{
    background-color: rgba(255, 56, 87, .6) !important;
}

a.bg-translucent-danger:hover,
a.bg-translucent-danger:focus,
button.bg-translucent-danger:hover,
button.bg-translucent-danger:focus
{
    background-color: rgba(255, 31, 65, .6) !important;
}

.bg-translucent-light
{
    background-color: rgba(215, 223, 235, .6) !important;
}

a.bg-translucent-light:hover,
a.bg-translucent-light:focus,
button.bg-translucent-light:hover,
button.bg-translucent-light:focus
{
    background-color: rgba(198, 209, 227, .6) !important;
}

.bg-translucent-dark
{
    background-color: rgba(26, 35, 45, .6) !important;
}

a.bg-translucent-dark:hover,
a.bg-translucent-dark:focus,
button.bg-translucent-dark:hover,
button.bg-translucent-dark:focus
{
    background-color: rgba(17, 22, 29, .6) !important;
}

.bg-translucent-neutral
{
    background-color: rgba(237, 237, 237, .6) !important;
}

a.bg-translucent-neutral:hover,
a.bg-translucent-neutral:focus,
button.bg-translucent-neutral:hover,
button.bg-translucent-neutral:focus
{
    background-color: rgba(224, 224, 224, .6) !important;
}

.bg-translucent-white
{
    background-color: rgba(237, 237, 237, .6) !important;
}

a.bg-translucent-white:hover,
a.bg-translucent-white:focus,
button.bg-translucent-white:hover,
button.bg-translucent-white:focus
{
    background-color: rgba(224, 224, 224, .6) !important;
}

.bg-soft-primary
{
    background-color: #e2edff;
}

.bg-soft-secondary
{
    background-color: #fdfdfe;
}

.bg-soft-success
{
    background-color: #e6f9f1;
}

.bg-soft-info
{
    background-color: #eaf6ff;
}

.bg-soft-warning
{
    background-color: #fff7e8;
}

.bg-soft-danger
{
    background-color: #ffebee;
}

.bg-soft-light
{
    background-color: #fdfdfe;
}

.bg-soft-dark
{
    background-color: #e5e7e9;
}

.bg-soft-neutral
{
    background-color: white;
}

.bg-soft-white
{
    background-color: white;
}

.bg-section-primary
{
    background-color: #fff !important;
}

.bg-section-secondary
{
    background-color: #fafbfe !important;
}

.bg-section-light
{
    background-color: #e0e6ed !important;
}

.bg-section-dark
{
    background-color: #1a232d !important;
}

.bg-brand-facebook
{
    background-color: #3b5999 !important;
}

a.bg-brand-facebook:hover,
a.bg-brand-facebook:focus,
button.bg-brand-facebook:hover,
button.bg-brand-facebook:focus
{
    background-color: #2d4474 !important;
}

.bg-brand-twitter
{
    background-color: #1da1f2 !important;
}

a.bg-brand-twitter:hover,
a.bg-brand-twitter:focus,
button.bg-brand-twitter:hover,
button.bg-brand-twitter:focus
{
    background-color: #0c85d0 !important;
}

.bg-brand-google-plus
{
    background-color: #dd4b39 !important;
}

a.bg-brand-google-plus:hover,
a.bg-brand-google-plus:focus,
button.bg-brand-google-plus:hover,
button.bg-brand-google-plus:focus
{
    background-color: #c23321 !important;
}

.bg-brand-instagram
{
    background-color: #e4405f !important;
}

a.bg-brand-instagram:hover,
a.bg-brand-instagram:focus,
button.bg-brand-instagram:hover,
button.bg-brand-instagram:focus
{
    background-color: #d31e40 !important;
}

.bg-brand-pinterest
{
    background-color: #bd081c !important;
}

a.bg-brand-pinterest:hover,
a.bg-brand-pinterest:focus,
button.bg-brand-pinterest:hover,
button.bg-brand-pinterest:focus
{
    background-color: #8c0615 !important;
}

.bg-brand-youtube
{
    background-color: #cd201f !important;
}

a.bg-brand-youtube:hover,
a.bg-brand-youtube:focus,
button.bg-brand-youtube:hover,
button.bg-brand-youtube:focus
{
    background-color: #a11918 !important;
}

.bg-brand-slack
{
    background-color: #3aaf85 !important;
}

a.bg-brand-slack:hover,
a.bg-brand-slack:focus,
button.bg-brand-slack:hover,
button.bg-brand-slack:focus
{
    background-color: #2d8968 !important;
}

.bg-brand-dribbble
{
    background-color: #ea4c89 !important;
}

a.bg-brand-dribbble:hover,
a.bg-brand-dribbble:focus,
button.bg-brand-dribbble:hover,
button.bg-brand-dribbble:focus
{
    background-color: #e51e6b !important;
}

.bg-brand-github
{
    background-color: #222 !important;
}

a.bg-brand-github:hover,
a.bg-brand-github:focus,
button.bg-brand-github:hover,
button.bg-brand-github:focus
{
    background-color: #090909 !important;
}

.bg-brand-vimeo
{
    background-color: #04a0f0 !important;
}

a.bg-brand-vimeo:hover,
a.bg-brand-vimeo:focus,
button.bg-brand-vimeo:hover,
button.bg-brand-vimeo:focus
{
    background-color: #037fbe !important;
}

.bg-gray-100
{
    background-color: #fafbfe !important;
}

a.bg-gray-100:hover,
a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus
{
    background-color: #d0d9f6 !important;
}

.bg-gray-200
{
    background-color: #eff2f7 !important;
}

a.bg-gray-200:hover,
a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus
{
    background-color: #cdd6e6 !important;
}

.bg-gray-300
{
    background-color: #e5e9f2 !important;
}

a.bg-gray-300:hover,
a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus
{
    background-color: #c3cce1 !important;
}

.bg-gray-400
{
    background-color: #e0e6ed !important;
}

a.bg-gray-400:hover,
a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus
{
    background-color: #c0ccda !important;
}

.bg-gray-500
{
    background-color: #d3dce6 !important;
}

a.bg-gray-500:hover,
a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus
{
    background-color: #b2c2d4 !important;
}

.bg-gray-600
{
    background-color: #c0ccda !important;
}

a.bg-gray-600:hover,
a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus
{
    background-color: #a0b2c7 !important;
}

.bg-gray-700
{
    background-color: #8492a6 !important;
}

a.bg-gray-700:hover,
a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus
{
    background-color: #68788f !important;
}

.bg-gray-800
{
    background-color: #3c4858 !important;
}

a.bg-gray-800:hover,
a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus
{
    background-color: #272f3a !important;
}

.bg-gray-900
{
    background-color: #273444 !important;
}

a.bg-gray-900:hover,
a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus
{
    background-color: #141b24 !important;
}

.bg-black
{
    background-color: #1f2d3d !important;
}

a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus
{
    background-color: #0e141b !important;
}

.fill-primary
{
    fill: #0c66ff !important;
}

.fill-soft-primary
{
    fill: #e2edff;
}

.fill-secondary
{
    fill: #eff2f7 !important;
}

.fill-soft-secondary
{
    fill: #fdfdfe;
}

.fill-success
{
    fill: #2dca8c !important;
}

.fill-soft-success
{
    fill: #e6f9f1;
}

.fill-info
{
    fill: #50b5ff !important;
}

.fill-soft-info
{
    fill: #eaf6ff;
}

.fill-warning
{
    fill: #ffbe3d !important;
}

.fill-soft-warning
{
    fill: #fff7e8;
}

.fill-danger
{
    fill: #ff5c75 !important;
}

.fill-soft-danger
{
    fill: #ffebee;
}

.fill-light
{
    fill: #eff2f7 !important;
}

.fill-soft-light
{
    fill: #fdfdfe;
}

.fill-dark
{
    fill: #273444 !important;
}

.fill-soft-dark
{
    fill: #e5e7e9;
}

.fill-neutral
{
    fill: #fff !important;
}

.fill-soft-neutral
{
    fill: white;
}

.fill-white
{
    fill: #fff !important;
}

.fill-soft-white
{
    fill: white;
}

.fill-blue
{
    fill: #0c66ff !important;
}

.stroke-blue
{
    stroke: #0c66ff !important;
}

.fill-indigo
{
    fill: #6e00ff !important;
}

.stroke-indigo
{
    stroke: #6e00ff !important;
}

.fill-purple
{
    fill: #510fa8 !important;
}

.stroke-purple
{
    stroke: #510fa8 !important;
}

.fill-pink
{
    fill: #f074ad !important;
}

.stroke-pink
{
    stroke: #f074ad !important;
}

.fill-red
{
    fill: #ff5c75 !important;
}

.stroke-red
{
    stroke: #ff5c75 !important;
}

.fill-orange
{
    fill: #ffbe3d !important;
}

.stroke-orange
{
    stroke: #ffbe3d !important;
}

.fill-yellow
{
    fill: #fc0 !important;
}

.stroke-yellow
{
    stroke: #fc0 !important;
}

.fill-green
{
    fill: #2dca8c !important;
}

.stroke-green
{
    stroke: #2dca8c !important;
}

.fill-teal
{
    fill: #50b5ff !important;
}

.stroke-teal
{
    stroke: #50b5ff !important;
}

.fill-cyan
{
    fill: #4bd6e5 !important;
}

.stroke-cyan
{
    stroke: #4bd6e5 !important;
}

.fill-white
{
    fill: #fff !important;
}

.stroke-white
{
    stroke: #fff !important;
}

.fill-gray
{
    fill: #c0ccda !important;
}

.stroke-gray
{
    stroke: #c0ccda !important;
}

.fill-gray-dark
{
    fill: #3c4858 !important;
}

.stroke-gray-dark
{
    stroke: #3c4858 !important;
}

.fill-primary
{
    fill: #0c66ff !important;
}

.stroke-primary
{
    stroke: #0c66ff !important;
}

.fill-secondary
{
    fill: #eff2f7 !important;
}

.stroke-secondary
{
    stroke: #eff2f7 !important;
}

.fill-success
{
    fill: #2dca8c !important;
}

.stroke-success
{
    stroke: #2dca8c !important;
}

.fill-info
{
    fill: #50b5ff !important;
}

.stroke-info
{
    stroke: #50b5ff !important;
}

.fill-warning
{
    fill: #ffbe3d !important;
}

.stroke-warning
{
    stroke: #ffbe3d !important;
}

.fill-danger
{
    fill: #ff5c75 !important;
}

.stroke-danger
{
    stroke: #ff5c75 !important;
}

.fill-light
{
    fill: #eff2f7 !important;
}

.stroke-light
{
    stroke: #eff2f7 !important;
}

.fill-dark
{
    fill: #273444 !important;
}

.stroke-dark
{
    stroke: #273444 !important;
}

.fill-neutral
{
    fill: #fff !important;
}

.stroke-neutral
{
    stroke: #fff !important;
}

.fill-white
{
    fill: #fff !important;
}

.stroke-white
{
    stroke: #fff !important;
}

.fill-primary-100
{
    fill: white !important;
}

.fill-primary-200
{
    fill: #d8e6ff !important;
}

.fill-primary-300
{
    fill: #a5c6ff !important;
}

.fill-primary-400
{
    fill: #72a6ff !important;
}

.fill-primary-500
{
    fill: #3f86ff !important;
}

.fill-primary-600
{
    fill: #2676ff !important;
}

.stroke-primary-100
{
    stroke: white !important;
}

.stroke-primary-200
{
    stroke: #d8e6ff !important;
}

.stroke-primary-300
{
    stroke: #a5c6ff !important;
}

.stroke-primary-400
{
    stroke: #72a6ff !important;
}

.stroke-primary-500
{
    stroke: #3f86ff !important;
}

.stroke-primary-600
{
    stroke: #2676ff !important;
}

.fill-section-primary
{
    fill: #fff !important;
}

.stroke-section-primary
{
    stroke: #fff !important;
}

.fill-section-secondary
{
    fill: #fafbfe !important;
}

.stroke-section-secondary
{
    stroke: #fafbfe !important;
}

.fill-section-light
{
    fill: #e0e6ed !important;
}

.stroke-section-light
{
    stroke: #e0e6ed !important;
}

.fill-section-dark
{
    fill: #1a232d !important;
}

.stroke-section-dark
{
    stroke: #1a232d !important;
}

.bg-cover
{
    position: relative;

    background-repeat: no-repeat;
}

.bg-absolute-cover
{
    position: absolute;
    z-index: 100; 
    top: 0;
    left: 0;

    overflow: hidden;

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
}
.bg-absolute-cover svg
{
    height: 1000px;

    pointer-events: none;
}

@media (max-width: 1199.98px)
{
    .bg-lg-cover
    {
        background-image: none !important;
    }
}

@media (max-width: 991.98px)
{
    .bg-md-cover
    {
        background-image: none !important;
    }
}

.bg-size--cover
{
    background: no-repeat center center/cover;
}

.bg-size--contain
{
    background-size: contain;
}

.bg-text
{
    font-size: 40rem;
    font-weight: 700; 

    position: absolute;
    top: 50%;
    left: 0;

    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    white-space: nowrap;
    text-transform: uppercase;

    color: rgba(255, 255, 255, .2);
}

.bg-inner--text
{
    line-height: 1em;

    display: block;
    display: inline-block; 

    width: auto;
    max-width: none;
    height: 1em;

    transition: 2s 0s;
    -webkit-transform: none;
            transform: none;
    -webkit-animation: text_slide 40s linear infinite;
            animation: text_slide 40s linear infinite;
    white-space: nowrap;

    opacity: 1;
}

@media (max-width: 991.98px)
{
    .bg-img-holder
    {
        background-image: none !important;
    }
    .bg-img-holder img
    {
        max-width: 100%;
    }
}

@media (min-width: 992px)
{
    .bg-img-holder
    {
        position: absolute;
        z-index: -1; 

        height: 100%;
        min-height: 20rem;

        background-repeat: no-repeat;
    }
    .bg-img-holder img
    {
        display: none;
    }
}

.img-bg:after
{
    position: absolute;
    z-index: 1;
    top: -168px;
    left: 31px;

    width: 783px;
    height: 725px;

    content: '';

    background: none no-repeat 50%;
    background-image: none;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width=\'783\' height=\'726\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M606.14 725.179c144.64-.87 225.447-587.194 144.64-694.046-73.915-97.74-579.852 50.733-716.756 222.443-12.763 16.009-22.32 32.22-28.019 48.451-66.83 190.337 442.822 424.098 600.136 423.152z\' fill=\'%23EFF2F7\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); 
    background-size: auto auto;
    background-size: contain;
}

.img-bg img
{
    position: relative;
    z-index: 2;
}

.img-as-bg
{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    -o-object-fit: cover;
       object-fit: cover;
}

.blend-mode--multiply
{
    mix-blend-mode: multiply;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
{
    .blend-mode--multiply:after
    {
        position: absolute;
        top: 0;
        bottom: 0;

        width: 100%; 

        content: '';

        background: rgba(0, 0, 214, .5);
    }
}

.blend-mode--color-burn
{
    mix-blend-mode: color-burn;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
{
    .blend-mode--color-burn:after
    {
        position: absolute;
        top: 0;
        bottom: 0;

        width: 100%; 

        content: '';

        background: rgba(0, 0, 214, .5);
    }
}

.border-sm
{
    border-width: 2px !important;
}

.border-md
{
    border-width: 3px !important;
}

.border-dashed
{
    border-style: dashed !important;
}

.border-dotted
{
    border-style: dotted !important;
}

.border-groove
{
    border-style: groove !important;
}

.rounded-left-pill
{
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
}

.rounded-right-pill
{
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-left
{
    border-bottom-left-radius: 90px;
}

.delimiter-top,
.delimiter-bottom
{
    position: relative;

    border: 0;
}

.delimiter-top:before
{
    position: absolute;
    top: 0;
    left: 50%;

    display: block;

    width: 80%;
    height: 1px;
    margin-left: -40%;

    content: '';

    background: radial-gradient(ellipse at center, #dee4ef 0, rgba(255, 255, 255, 0) 75%);
}

.delimiter-bottom:after
{
    position: absolute;
    bottom: 0;
    left: 50%;

    display: block;

    width: 80%;
    height: 1px;
    margin-left: -40%;

    content: '';

    background: radial-gradient(ellipse at center, #dee4ef 0, rgba(255, 255, 255, 0) 75%);
}

.delimiter-dark:before,
.delimiter-dark:after
{
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, .2) 0, rgba(31, 45, 61, 0) 75%);
}

.floating
{
    -webkit-animation: floating 3s ease infinite;
            animation: floating 3s ease infinite;

    will-change: transform;
}
.floating:hover
{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}

.floating-lg
{
    -webkit-animation: floating-lg 3s ease infinite;
            animation: floating-lg 3s ease infinite;
}

.floating-sm
{
    -webkit-animation: floating-sm 3s ease infinite;
            animation: floating-sm 3s ease infinite;
}

@-webkit-keyframes floating-lg
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes floating-lg
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@-webkit-keyframes floating
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes floating
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@-webkit-keyframes floating-sm
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes floating-sm
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

.floatfix:before,
.floatfix:after
{
    display: table; 

    content: '';
}

.floatfix:after
{
    clear: both;
}

.overflow-visible
{
    overflow: visible !important;
}

.overflow-hidden
{
    overflow: hidden !important;
}

.overflow-hidden-x
{
    overflow-x: hidden !important;
}

.overflow-hidden-y
{
    overflow-y: hidden !important;
}

[class*='alpha']
{
    transition: all .2s ease;
}

[class*='faded']
{
    position: relative;
}
[class*='faded']:after
{
    position: absolute;

    width: 100%;
    height: 30%; 

    content: '';
}

.faded-top:after
{
    top: 0;

    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, .6) 100%);

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
}

.faded-bottom:after
{
    bottom: 0;

    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .6) 100%);

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
}

[class*='hover-']
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    [class*='hover-']
    {
        transition: none;
    }
}

.img-center
{
    display: block;

    margin-right: auto; 
    margin-left: auto;
}

.img-grayscale
{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.img-saturate
{
    -webkit-filter: saturate(150%);
            filter: saturate(150%);
}

.img-back-shape img
{
    background: url(../img/svg/backgrounds/bg-circles-1.svg) no-repeat scroll center center/100% 100% border-box;
}

.opacity-0,
.hover-opacity-0:hover,
.opacity-container:hover .hover-opacity-0
{
    opacity: 0;
}

.opacity-1,
.hover-opacity-1:hover,
.opacity-container:hover .hover-opacity-1
{
    opacity: .1;
}

.opacity-2,
.hover-opacity-2:hover,
.opacity-container:hover .hover-opacity-2
{
    opacity: .2;
}

.opacity-3,
.hover-opacity-3:hover,
.opacity-container:hover .hover-opacity-3
{
    opacity: .3;
}

.opacity-4,
.hover-opacity-4:hover,
.opacity-container:hover .hover-opacity-4
{
    opacity: .4;
}

.opacity-5,
.hover-opacity-5:hover,
.opacity-container:hover .hover-opacity-5
{
    opacity: .5;
}

.opacity-6,
.hover-opacity-6:hover,
.opacity-container:hover .hover-opacity-6
{
    opacity: .6;
}

.opacity-7,
.hover-opacity-7:hover,
.opacity-container:hover .hover-opacity-7
{
    opacity: .7;
}

.opacity-8,
.hover-opacity-8:hover,
.opacity-container:hover .hover-opacity-8
{
    opacity: .8;
}

.opacity-9,
.hover-opacity-9:hover,
.opacity-container:hover .hover-opacity-9
{
    opacity: .9;
}

.opacity-10,
.hover-opacity-10:hover,
.opacity-container:hover .hover-opacity-10
{
    opacity: 1;
}

.position-absolute
{
    position: absolute !important;
}

.position-relative
{
    position: relative !important;
}

.position-static
{
    position: static !important;
}

@media (min-width: 576px)
{
    .position-sm-absolute
    {
        position: absolute !important;
    }
    .position-sm-relative
    {
        position: relative !important;
    }
    .position-sm-static
    {
        position: static !important;
    }
}

@media (min-width: 768px)
{
    .position-md-absolute
    {
        position: absolute !important;
    }
    .position-md-relative
    {
        position: relative !important;
    }
    .position-md-static
    {
        position: static !important;
    }
}

@media (min-width: 992px)
{
    .position-lg-absolute
    {
        position: absolute !important;
    }
    .position-lg-relative
    {
        position: relative !important;
    }
    .position-lg-static
    {
        position: static !important;
    }
}

@media (min-width: 1200px)
{
    .position-xl-absolute
    {
        position: absolute !important;
    }
    .position-xl-relative
    {
        position: relative !important;
    }
    .position-xl-static
    {
        position: static !important;
    }
}

@media (max-width: 991.98px)
{
    [data-separator='rounded'][data-separator-orientation='bottom']
    {
        border-radius: 0 0 3rem 3rem;
    }
    [data-separator='rounded'][data-separator-orientation='top']
    {
        border-radius: 3rem 3rem 0 0;
    }
    [data-separator='rounded-left'][data-separator-orientation='bottom']
    {
        border-radius: 0 0 0 3rem;
    }
    [data-separator='rounded-left'][data-separator-orientation='top']
    {
        border-radius: 3rem 0 0 0;
    }
    [data-separator='rounded-right'][data-separator-orientation='bottom']
    {
        border-radius: 0 0 3rem 0;
    }
    [data-separator='rounded-right'][data-separator-orientation='top']
    {
        border-radius: 0 3rem 0 0;
    }
}

@media (min-width: 768px)
{
    [data-separator='rounded'][data-separator-orientation='bottom']
    {
        border-radius: 0 0 5rem 5rem;
    }
    [data-separator='rounded'][data-separator-orientation='top']
    {
        border-radius: 5rem 5rem 0 0;
    }
    [data-separator='rounded-left'][data-separator-orientation='bottom']
    {
        border-radius: 0 0 0 5rem;
    }
    [data-separator='rounded-left'][data-separator-orientation='top']
    {
        border-radius: 5rem 0 0 0;
    }
    [data-separator='rounded-right'][data-separator-orientation='bottom']
    {
        border-radius: 0 0 5rem 0;
    }
    [data-separator='rounded-right'][data-separator-orientation='top']
    {
        border-radius: 0 5rem 0 0;
    }
}

[data-separator='rounded-continuous']
{
    position: relative;

    margin-bottom: 5rem;

    border-radius: 0 0 0 5rem;
}
[data-separator='rounded-continuous']:before,
[data-separator='rounded-continuous']:after
{
    position: absolute;
    left: 0;

    height: 5rem; 

    content: '';
    pointer-events: none;
}
[data-separator='rounded-continuous']:before
{
    top: 100%;

    width: 100%;

    border-radius: 0 5rem 0 0;
}
[data-separator='rounded-continuous']:after
{
    z-index: -1;
    bottom: 0;

    width: 50%;
}
[data-separator='rounded-continuous'] + section
{
    padding-bottom: 5rem;
}

[data-separator-bg='primary']:before,
[data-separator-bg='primary']:after
{
    background: #fff;
}

[data-separator-bg='secondary']:before,
[data-separator-bg='secondary']:after
{
    background: #fafbfe;
}

[data-separator-bg='light']:before,
[data-separator-bg='light']:after
{
    background: #e0e6ed;
}

[data-separator-bg='dark']:before,
[data-separator-bg='dark']:after
{
    background: #1a232d;
}

.shape-container
{
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;

    overflow: hidden; 

    width: 100%;

    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}
.shape-container svg
{
    vertical-align: baseline; 
    pointer-events: none;

    fill: #fff;
}

.shape-position-top
{
    top: 0;

    margin-top: -1px;
}

.shape-position-bottom
{
    bottom: 0;

    margin-bottom: -1px;
}

.shape-orientation-inverse
{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.shape-line
{
    min-height: 19px;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)
{
    .ie-shape-line
    {
        height: 100px;
    }
    .ie-shape-wave-1
    {
        height: 504px;
    }
    .ie-shape-wave-3
    {
        height: 231px;
    }
    .ie-shape-clouds
    {
        height: 400px;
    }
}

.h-100vh
{
    height: 100vh !important;
}

.row-grid + .row-grid
{
    margin-top: 3rem;
}

.row-grid > [class*='col-'] + [class*='col-']
{
    margin-top: 3rem;
}

@media (min-width: 992px)
{
    .row.row-grid > [class*='col-lg-'] + [class*='col-lg-']
    {
        margin-top: 0;
    }
}

@media (min-width: 768px)
{
    .row.row-grid > [class*='col-md-'] + [class*='col-md-']
    {
        margin-top: 0;
    }
}

@media (min-width: 576px)
{
    .row.row-grid > [class*='col-sm-'] + [class*='col-sm-']
    {
        margin-top: 0;
    }
}

.top-0
{
    top: 0 !important;
}

.right-0
{
    right: 0 !important;
}

.bottom-0
{
    bottom: 0 !important;
}

.left-0
{
    left: 0 !important;
}

.top-n0
{
    top: 0 !important;
}

.right-n0
{
    right: 0 !important;
}

.bottom-n0
{
    bottom: 0 !important;
}

.left-n0
{
    left: 0 !important;
}

.top-1
{
    top: .25rem !important;
}

.right-1
{
    right: .25rem !important;
}

.bottom-1
{
    bottom: .25rem !important;
}

.left-1
{
    left: .25rem !important;
}

.top-n1
{
    top: -.25rem !important;
}

.right-n1
{
    right: -.25rem !important;
}

.bottom-n1
{
    bottom: -.25rem !important;
}

.left-n1
{
    left: -.25rem !important;
}

.top-2
{
    top: .5rem !important;
}

.right-2
{
    right: .5rem !important;
}

.bottom-2
{
    bottom: .5rem !important;
}

.left-2
{
    left: .5rem !important;
}

.top-n2
{
    top: -.5rem !important;
}

.right-n2
{
    right: -.5rem !important;
}

.bottom-n2
{
    bottom: -.5rem !important;
}

.left-n2
{
    left: -.5rem !important;
}

.top-3
{
    top: 1rem !important;
}

.right-3
{
    right: 1rem !important;
}

.bottom-3
{
    bottom: 1rem !important;
}

.left-3
{
    left: 1rem !important;
}

.top-n3
{
    top: -1rem !important;
}

.right-n3
{
    right: -1rem !important;
}

.bottom-n3
{
    bottom: -1rem !important;
}

.left-n3
{
    left: -1rem !important;
}

.top-4
{
    top: 1.5rem !important;
}

.right-4
{
    right: 1.5rem !important;
}

.bottom-4
{
    bottom: 1.5rem !important;
}

.left-4
{
    left: 1.5rem !important;
}

.top-n4
{
    top: -1.5rem !important;
}

.right-n4
{
    right: -1.5rem !important;
}

.bottom-n4
{
    bottom: -1.5rem !important;
}

.left-n4
{
    left: -1.5rem !important;
}

.top-5
{
    top: 3rem !important;
}

.right-5
{
    right: 3rem !important;
}

.bottom-5
{
    bottom: 3rem !important;
}

.left-5
{
    left: 3rem !important;
}

.top-n5
{
    top: -3rem !important;
}

.right-n5
{
    right: -3rem !important;
}

.bottom-n5
{
    bottom: -3rem !important;
}

.left-n5
{
    left: -3rem !important;
}

.top-6
{
    top: 4.5rem !important;
}

.right-6
{
    right: 4.5rem !important;
}

.bottom-6
{
    bottom: 4.5rem !important;
}

.left-6
{
    left: 4.5rem !important;
}

.top-n6
{
    top: -4.5rem !important;
}

.right-n6
{
    right: -4.5rem !important;
}

.bottom-n6
{
    bottom: -4.5rem !important;
}

.left-n6
{
    left: -4.5rem !important;
}

.top-7
{
    top: 6rem !important;
}

.right-7
{
    right: 6rem !important;
}

.bottom-7
{
    bottom: 6rem !important;
}

.left-7
{
    left: 6rem !important;
}

.top-n7
{
    top: -6rem !important;
}

.right-n7
{
    right: -6rem !important;
}

.bottom-n7
{
    bottom: -6rem !important;
}

.left-n7
{
    left: -6rem !important;
}

.top-8
{
    top: 7.5rem !important;
}

.right-8
{
    right: 7.5rem !important;
}

.bottom-8
{
    bottom: 7.5rem !important;
}

.left-8
{
    left: 7.5rem !important;
}

.top-n8
{
    top: -7.5rem !important;
}

.right-n8
{
    right: -7.5rem !important;
}

.bottom-n8
{
    bottom: -7.5rem !important;
}

.left-n8
{
    left: -7.5rem !important;
}

.top-9
{
    top: 9rem !important;
}

.right-9
{
    right: 9rem !important;
}

.bottom-9
{
    bottom: 9rem !important;
}

.left-9
{
    left: 9rem !important;
}

.top-n9
{
    top: -9rem !important;
}

.right-n9
{
    right: -9rem !important;
}

.bottom-n9
{
    bottom: -9rem !important;
}

.left-n9
{
    left: -9rem !important;
}

.top-10
{
    top: 10.5rem !important;
}

.right-10
{
    right: 10.5rem !important;
}

.bottom-10
{
    bottom: 10.5rem !important;
}

.left-10
{
    left: 10.5rem !important;
}

.top-n10
{
    top: -10.5rem !important;
}

.right-n10
{
    right: -10.5rem !important;
}

.bottom-n10
{
    bottom: -10.5rem !important;
}

.left-n10
{
    left: -10.5rem !important;
}

.top-17
{
    top: 21rem !important;
}

.right-17
{
    right: 21rem !important;
}

.bottom-17
{
    bottom: 21rem !important;
}

.left-17
{
    left: 21rem !important;
}

.top-n17
{
    top: -21rem !important;
}

.right-n17
{
    right: -21rem !important;
}

.bottom-n17
{
    bottom: -21rem !important;
}

.left-n17
{
    left: -21rem !important;
}

@media (min-width: 576px)
{
    .top-sm-0
    {
        top: 0 !important;
    }
    .right-sm-0
    {
        right: 0 !important;
    }
    .bottom-sm-0
    {
        bottom: 0 !important;
    }
    .left-sm-0
    {
        left: 0 !important;
    }
    .top-sm-n0
    {
        top: 0 !important;
    }
    .right-sm-n0
    {
        right: 0 !important;
    }
    .bottom-sm-n0
    {
        bottom: 0 !important;
    }
    .left-sm-n0
    {
        left: 0 !important;
    }
    .top-sm-1
    {
        top: .25rem !important;
    }
    .right-sm-1
    {
        right: .25rem !important;
    }
    .bottom-sm-1
    {
        bottom: .25rem !important;
    }
    .left-sm-1
    {
        left: .25rem !important;
    }
    .top-sm-n1
    {
        top: -.25rem !important;
    }
    .right-sm-n1
    {
        right: -.25rem !important;
    }
    .bottom-sm-n1
    {
        bottom: -.25rem !important;
    }
    .left-sm-n1
    {
        left: -.25rem !important;
    }
    .top-sm-2
    {
        top: .5rem !important;
    }
    .right-sm-2
    {
        right: .5rem !important;
    }
    .bottom-sm-2
    {
        bottom: .5rem !important;
    }
    .left-sm-2
    {
        left: .5rem !important;
    }
    .top-sm-n2
    {
        top: -.5rem !important;
    }
    .right-sm-n2
    {
        right: -.5rem !important;
    }
    .bottom-sm-n2
    {
        bottom: -.5rem !important;
    }
    .left-sm-n2
    {
        left: -.5rem !important;
    }
    .top-sm-3
    {
        top: 1rem !important;
    }
    .right-sm-3
    {
        right: 1rem !important;
    }
    .bottom-sm-3
    {
        bottom: 1rem !important;
    }
    .left-sm-3
    {
        left: 1rem !important;
    }
    .top-sm-n3
    {
        top: -1rem !important;
    }
    .right-sm-n3
    {
        right: -1rem !important;
    }
    .bottom-sm-n3
    {
        bottom: -1rem !important;
    }
    .left-sm-n3
    {
        left: -1rem !important;
    }
    .top-sm-4
    {
        top: 1.5rem !important;
    }
    .right-sm-4
    {
        right: 1.5rem !important;
    }
    .bottom-sm-4
    {
        bottom: 1.5rem !important;
    }
    .left-sm-4
    {
        left: 1.5rem !important;
    }
    .top-sm-n4
    {
        top: -1.5rem !important;
    }
    .right-sm-n4
    {
        right: -1.5rem !important;
    }
    .bottom-sm-n4
    {
        bottom: -1.5rem !important;
    }
    .left-sm-n4
    {
        left: -1.5rem !important;
    }
    .top-sm-5
    {
        top: 3rem !important;
    }
    .right-sm-5
    {
        right: 3rem !important;
    }
    .bottom-sm-5
    {
        bottom: 3rem !important;
    }
    .left-sm-5
    {
        left: 3rem !important;
    }
    .top-sm-n5
    {
        top: -3rem !important;
    }
    .right-sm-n5
    {
        right: -3rem !important;
    }
    .bottom-sm-n5
    {
        bottom: -3rem !important;
    }
    .left-sm-n5
    {
        left: -3rem !important;
    }
    .top-sm-6
    {
        top: 4.5rem !important;
    }
    .right-sm-6
    {
        right: 4.5rem !important;
    }
    .bottom-sm-6
    {
        bottom: 4.5rem !important;
    }
    .left-sm-6
    {
        left: 4.5rem !important;
    }
    .top-sm-n6
    {
        top: -4.5rem !important;
    }
    .right-sm-n6
    {
        right: -4.5rem !important;
    }
    .bottom-sm-n6
    {
        bottom: -4.5rem !important;
    }
    .left-sm-n6
    {
        left: -4.5rem !important;
    }
    .top-sm-7
    {
        top: 6rem !important;
    }
    .right-sm-7
    {
        right: 6rem !important;
    }
    .bottom-sm-7
    {
        bottom: 6rem !important;
    }
    .left-sm-7
    {
        left: 6rem !important;
    }
    .top-sm-n7
    {
        top: -6rem !important;
    }
    .right-sm-n7
    {
        right: -6rem !important;
    }
    .bottom-sm-n7
    {
        bottom: -6rem !important;
    }
    .left-sm-n7
    {
        left: -6rem !important;
    }
    .top-sm-8
    {
        top: 7.5rem !important;
    }
    .right-sm-8
    {
        right: 7.5rem !important;
    }
    .bottom-sm-8
    {
        bottom: 7.5rem !important;
    }
    .left-sm-8
    {
        left: 7.5rem !important;
    }
    .top-sm-n8
    {
        top: -7.5rem !important;
    }
    .right-sm-n8
    {
        right: -7.5rem !important;
    }
    .bottom-sm-n8
    {
        bottom: -7.5rem !important;
    }
    .left-sm-n8
    {
        left: -7.5rem !important;
    }
    .top-sm-9
    {
        top: 9rem !important;
    }
    .right-sm-9
    {
        right: 9rem !important;
    }
    .bottom-sm-9
    {
        bottom: 9rem !important;
    }
    .left-sm-9
    {
        left: 9rem !important;
    }
    .top-sm-n9
    {
        top: -9rem !important;
    }
    .right-sm-n9
    {
        right: -9rem !important;
    }
    .bottom-sm-n9
    {
        bottom: -9rem !important;
    }
    .left-sm-n9
    {
        left: -9rem !important;
    }
    .top-sm-10
    {
        top: 10.5rem !important;
    }
    .right-sm-10
    {
        right: 10.5rem !important;
    }
    .bottom-sm-10
    {
        bottom: 10.5rem !important;
    }
    .left-sm-10
    {
        left: 10.5rem !important;
    }
    .top-sm-n10
    {
        top: -10.5rem !important;
    }
    .right-sm-n10
    {
        right: -10.5rem !important;
    }
    .bottom-sm-n10
    {
        bottom: -10.5rem !important;
    }
    .left-sm-n10
    {
        left: -10.5rem !important;
    }
    .top-sm-17
    {
        top: 21rem !important;
    }
    .right-sm-17
    {
        right: 21rem !important;
    }
    .bottom-sm-17
    {
        bottom: 21rem !important;
    }
    .left-sm-17
    {
        left: 21rem !important;
    }
    .top-sm-n17
    {
        top: -21rem !important;
    }
    .right-sm-n17
    {
        right: -21rem !important;
    }
    .bottom-sm-n17
    {
        bottom: -21rem !important;
    }
    .left-sm-n17
    {
        left: -21rem !important;
    }
}

@media (min-width: 768px)
{
    .top-md-0
    {
        top: 0 !important;
    }
    .right-md-0
    {
        right: 0 !important;
    }
    .bottom-md-0
    {
        bottom: 0 !important;
    }
    .left-md-0
    {
        left: 0 !important;
    }
    .top-md-n0
    {
        top: 0 !important;
    }
    .right-md-n0
    {
        right: 0 !important;
    }
    .bottom-md-n0
    {
        bottom: 0 !important;
    }
    .left-md-n0
    {
        left: 0 !important;
    }
    .top-md-1
    {
        top: .25rem !important;
    }
    .right-md-1
    {
        right: .25rem !important;
    }
    .bottom-md-1
    {
        bottom: .25rem !important;
    }
    .left-md-1
    {
        left: .25rem !important;
    }
    .top-md-n1
    {
        top: -.25rem !important;
    }
    .right-md-n1
    {
        right: -.25rem !important;
    }
    .bottom-md-n1
    {
        bottom: -.25rem !important;
    }
    .left-md-n1
    {
        left: -.25rem !important;
    }
    .top-md-2
    {
        top: .5rem !important;
    }
    .right-md-2
    {
        right: .5rem !important;
    }
    .bottom-md-2
    {
        bottom: .5rem !important;
    }
    .left-md-2
    {
        left: .5rem !important;
    }
    .top-md-n2
    {
        top: -.5rem !important;
    }
    .right-md-n2
    {
        right: -.5rem !important;
    }
    .bottom-md-n2
    {
        bottom: -.5rem !important;
    }
    .left-md-n2
    {
        left: -.5rem !important;
    }
    .top-md-3
    {
        top: 1rem !important;
    }
    .right-md-3
    {
        right: 1rem !important;
    }
    .bottom-md-3
    {
        bottom: 1rem !important;
    }
    .left-md-3
    {
        left: 1rem !important;
    }
    .top-md-n3
    {
        top: -1rem !important;
    }
    .right-md-n3
    {
        right: -1rem !important;
    }
    .bottom-md-n3
    {
        bottom: -1rem !important;
    }
    .left-md-n3
    {
        left: -1rem !important;
    }
    .top-md-4
    {
        top: 1.5rem !important;
    }
    .right-md-4
    {
        right: 1.5rem !important;
    }
    .bottom-md-4
    {
        bottom: 1.5rem !important;
    }
    .left-md-4
    {
        left: 1.5rem !important;
    }
    .top-md-n4
    {
        top: -1.5rem !important;
    }
    .right-md-n4
    {
        right: -1.5rem !important;
    }
    .bottom-md-n4
    {
        bottom: -1.5rem !important;
    }
    .left-md-n4
    {
        left: -1.5rem !important;
    }
    .top-md-5
    {
        top: 3rem !important;
    }
    .right-md-5
    {
        right: 3rem !important;
    }
    .bottom-md-5
    {
        bottom: 3rem !important;
    }
    .left-md-5
    {
        left: 3rem !important;
    }
    .top-md-n5
    {
        top: -3rem !important;
    }
    .right-md-n5
    {
        right: -3rem !important;
    }
    .bottom-md-n5
    {
        bottom: -3rem !important;
    }
    .left-md-n5
    {
        left: -3rem !important;
    }
    .top-md-6
    {
        top: 4.5rem !important;
    }
    .right-md-6
    {
        right: 4.5rem !important;
    }
    .bottom-md-6
    {
        bottom: 4.5rem !important;
    }
    .left-md-6
    {
        left: 4.5rem !important;
    }
    .top-md-n6
    {
        top: -4.5rem !important;
    }
    .right-md-n6
    {
        right: -4.5rem !important;
    }
    .bottom-md-n6
    {
        bottom: -4.5rem !important;
    }
    .left-md-n6
    {
        left: -4.5rem !important;
    }
    .top-md-7
    {
        top: 6rem !important;
    }
    .right-md-7
    {
        right: 6rem !important;
    }
    .bottom-md-7
    {
        bottom: 6rem !important;
    }
    .left-md-7
    {
        left: 6rem !important;
    }
    .top-md-n7
    {
        top: -6rem !important;
    }
    .right-md-n7
    {
        right: -6rem !important;
    }
    .bottom-md-n7
    {
        bottom: -6rem !important;
    }
    .left-md-n7
    {
        left: -6rem !important;
    }
    .top-md-8
    {
        top: 7.5rem !important;
    }
    .right-md-8
    {
        right: 7.5rem !important;
    }
    .bottom-md-8
    {
        bottom: 7.5rem !important;
    }
    .left-md-8
    {
        left: 7.5rem !important;
    }
    .top-md-n8
    {
        top: -7.5rem !important;
    }
    .right-md-n8
    {
        right: -7.5rem !important;
    }
    .bottom-md-n8
    {
        bottom: -7.5rem !important;
    }
    .left-md-n8
    {
        left: -7.5rem !important;
    }
    .top-md-9
    {
        top: 9rem !important;
    }
    .right-md-9
    {
        right: 9rem !important;
    }
    .bottom-md-9
    {
        bottom: 9rem !important;
    }
    .left-md-9
    {
        left: 9rem !important;
    }
    .top-md-n9
    {
        top: -9rem !important;
    }
    .right-md-n9
    {
        right: -9rem !important;
    }
    .bottom-md-n9
    {
        bottom: -9rem !important;
    }
    .left-md-n9
    {
        left: -9rem !important;
    }
    .top-md-10
    {
        top: 10.5rem !important;
    }
    .right-md-10
    {
        right: 10.5rem !important;
    }
    .bottom-md-10
    {
        bottom: 10.5rem !important;
    }
    .left-md-10
    {
        left: 10.5rem !important;
    }
    .top-md-n10
    {
        top: -10.5rem !important;
    }
    .right-md-n10
    {
        right: -10.5rem !important;
    }
    .bottom-md-n10
    {
        bottom: -10.5rem !important;
    }
    .left-md-n10
    {
        left: -10.5rem !important;
    }
    .top-md-17
    {
        top: 21rem !important;
    }
    .right-md-17
    {
        right: 21rem !important;
    }
    .bottom-md-17
    {
        bottom: 21rem !important;
    }
    .left-md-17
    {
        left: 21rem !important;
    }
    .top-md-n17
    {
        top: -21rem !important;
    }
    .right-md-n17
    {
        right: -21rem !important;
    }
    .bottom-md-n17
    {
        bottom: -21rem !important;
    }
    .left-md-n17
    {
        left: -21rem !important;
    }
}

@media (min-width: 992px)
{
    .top-lg-0
    {
        top: 0 !important;
    }
    .right-lg-0
    {
        right: 0 !important;
    }
    .bottom-lg-0
    {
        bottom: 0 !important;
    }
    .left-lg-0
    {
        left: 0 !important;
    }
    .top-lg-n0
    {
        top: 0 !important;
    }
    .right-lg-n0
    {
        right: 0 !important;
    }
    .bottom-lg-n0
    {
        bottom: 0 !important;
    }
    .left-lg-n0
    {
        left: 0 !important;
    }
    .top-lg-1
    {
        top: .25rem !important;
    }
    .right-lg-1
    {
        right: .25rem !important;
    }
    .bottom-lg-1
    {
        bottom: .25rem !important;
    }
    .left-lg-1
    {
        left: .25rem !important;
    }
    .top-lg-n1
    {
        top: -.25rem !important;
    }
    .right-lg-n1
    {
        right: -.25rem !important;
    }
    .bottom-lg-n1
    {
        bottom: -.25rem !important;
    }
    .left-lg-n1
    {
        left: -.25rem !important;
    }
    .top-lg-2
    {
        top: .5rem !important;
    }
    .right-lg-2
    {
        right: .5rem !important;
    }
    .bottom-lg-2
    {
        bottom: .5rem !important;
    }
    .left-lg-2
    {
        left: .5rem !important;
    }
    .top-lg-n2
    {
        top: -.5rem !important;
    }
    .right-lg-n2
    {
        right: -.5rem !important;
    }
    .bottom-lg-n2
    {
        bottom: -.5rem !important;
    }
    .left-lg-n2
    {
        left: -.5rem !important;
    }
    .top-lg-3
    {
        top: 1rem !important;
    }
    .right-lg-3
    {
        right: 1rem !important;
    }
    .bottom-lg-3
    {
        bottom: 1rem !important;
    }
    .left-lg-3
    {
        left: 1rem !important;
    }
    .top-lg-n3
    {
        top: -1rem !important;
    }
    .right-lg-n3
    {
        right: -1rem !important;
    }
    .bottom-lg-n3
    {
        bottom: -1rem !important;
    }
    .left-lg-n3
    {
        left: -1rem !important;
    }
    .top-lg-4
    {
        top: 1.5rem !important;
    }
    .right-lg-4
    {
        right: 1.5rem !important;
    }
    .bottom-lg-4
    {
        bottom: 1.5rem !important;
    }
    .left-lg-4
    {
        left: 1.5rem !important;
    }
    .top-lg-n4
    {
        top: -1.5rem !important;
    }
    .right-lg-n4
    {
        right: -1.5rem !important;
    }
    .bottom-lg-n4
    {
        bottom: -1.5rem !important;
    }
    .left-lg-n4
    {
        left: -1.5rem !important;
    }
    .top-lg-5
    {
        top: 3rem !important;
    }
    .right-lg-5
    {
        right: 3rem !important;
    }
    .bottom-lg-5
    {
        bottom: 3rem !important;
    }
    .left-lg-5
    {
        left: 3rem !important;
    }
    .top-lg-n5
    {
        top: -3rem !important;
    }
    .right-lg-n5
    {
        right: -3rem !important;
    }
    .bottom-lg-n5
    {
        bottom: -3rem !important;
    }
    .left-lg-n5
    {
        left: -3rem !important;
    }
    .top-lg-6
    {
        top: 4.5rem !important;
    }
    .right-lg-6
    {
        right: 4.5rem !important;
    }
    .bottom-lg-6
    {
        bottom: 4.5rem !important;
    }
    .left-lg-6
    {
        left: 4.5rem !important;
    }
    .top-lg-n6
    {
        top: -4.5rem !important;
    }
    .right-lg-n6
    {
        right: -4.5rem !important;
    }
    .bottom-lg-n6
    {
        bottom: -4.5rem !important;
    }
    .left-lg-n6
    {
        left: -4.5rem !important;
    }
    .top-lg-7
    {
        top: 6rem !important;
    }
    .right-lg-7
    {
        right: 6rem !important;
    }
    .bottom-lg-7
    {
        bottom: 6rem !important;
    }
    .left-lg-7
    {
        left: 6rem !important;
    }
    .top-lg-n7
    {
        top: -6rem !important;
    }
    .right-lg-n7
    {
        right: -6rem !important;
    }
    .bottom-lg-n7
    {
        bottom: -6rem !important;
    }
    .left-lg-n7
    {
        left: -6rem !important;
    }
    .top-lg-8
    {
        top: 7.5rem !important;
    }
    .right-lg-8
    {
        right: 7.5rem !important;
    }
    .bottom-lg-8
    {
        bottom: 7.5rem !important;
    }
    .left-lg-8
    {
        left: 7.5rem !important;
    }
    .top-lg-n8
    {
        top: -7.5rem !important;
    }
    .right-lg-n8
    {
        right: -7.5rem !important;
    }
    .bottom-lg-n8
    {
        bottom: -7.5rem !important;
    }
    .left-lg-n8
    {
        left: -7.5rem !important;
    }
    .top-lg-9
    {
        top: 9rem !important;
    }
    .right-lg-9
    {
        right: 9rem !important;
    }
    .bottom-lg-9
    {
        bottom: 9rem !important;
    }
    .left-lg-9
    {
        left: 9rem !important;
    }
    .top-lg-n9
    {
        top: -9rem !important;
    }
    .right-lg-n9
    {
        right: -9rem !important;
    }
    .bottom-lg-n9
    {
        bottom: -9rem !important;
    }
    .left-lg-n9
    {
        left: -9rem !important;
    }
    .top-lg-10
    {
        top: 10.5rem !important;
    }
    .right-lg-10
    {
        right: 10.5rem !important;
    }
    .bottom-lg-10
    {
        bottom: 10.5rem !important;
    }
    .left-lg-10
    {
        left: 10.5rem !important;
    }
    .top-lg-n10
    {
        top: -10.5rem !important;
    }
    .right-lg-n10
    {
        right: -10.5rem !important;
    }
    .bottom-lg-n10
    {
        bottom: -10.5rem !important;
    }
    .left-lg-n10
    {
        left: -10.5rem !important;
    }
    .top-lg-17
    {
        top: 21rem !important;
    }
    .right-lg-17
    {
        right: 21rem !important;
    }
    .bottom-lg-17
    {
        bottom: 21rem !important;
    }
    .left-lg-17
    {
        left: 21rem !important;
    }
    .top-lg-n17
    {
        top: -21rem !important;
    }
    .right-lg-n17
    {
        right: -21rem !important;
    }
    .bottom-lg-n17
    {
        bottom: -21rem !important;
    }
    .left-lg-n17
    {
        left: -21rem !important;
    }
}

@media (min-width: 1200px)
{
    .top-xl-0
    {
        top: 0 !important;
    }
    .right-xl-0
    {
        right: 0 !important;
    }
    .bottom-xl-0
    {
        bottom: 0 !important;
    }
    .left-xl-0
    {
        left: 0 !important;
    }
    .top-xl-n0
    {
        top: 0 !important;
    }
    .right-xl-n0
    {
        right: 0 !important;
    }
    .bottom-xl-n0
    {
        bottom: 0 !important;
    }
    .left-xl-n0
    {
        left: 0 !important;
    }
    .top-xl-1
    {
        top: .25rem !important;
    }
    .right-xl-1
    {
        right: .25rem !important;
    }
    .bottom-xl-1
    {
        bottom: .25rem !important;
    }
    .left-xl-1
    {
        left: .25rem !important;
    }
    .top-xl-n1
    {
        top: -.25rem !important;
    }
    .right-xl-n1
    {
        right: -.25rem !important;
    }
    .bottom-xl-n1
    {
        bottom: -.25rem !important;
    }
    .left-xl-n1
    {
        left: -.25rem !important;
    }
    .top-xl-2
    {
        top: .5rem !important;
    }
    .right-xl-2
    {
        right: .5rem !important;
    }
    .bottom-xl-2
    {
        bottom: .5rem !important;
    }
    .left-xl-2
    {
        left: .5rem !important;
    }
    .top-xl-n2
    {
        top: -.5rem !important;
    }
    .right-xl-n2
    {
        right: -.5rem !important;
    }
    .bottom-xl-n2
    {
        bottom: -.5rem !important;
    }
    .left-xl-n2
    {
        left: -.5rem !important;
    }
    .top-xl-3
    {
        top: 1rem !important;
    }
    .right-xl-3
    {
        right: 1rem !important;
    }
    .bottom-xl-3
    {
        bottom: 1rem !important;
    }
    .left-xl-3
    {
        left: 1rem !important;
    }
    .top-xl-n3
    {
        top: -1rem !important;
    }
    .right-xl-n3
    {
        right: -1rem !important;
    }
    .bottom-xl-n3
    {
        bottom: -1rem !important;
    }
    .left-xl-n3
    {
        left: -1rem !important;
    }
    .top-xl-4
    {
        top: 1.5rem !important;
    }
    .right-xl-4
    {
        right: 1.5rem !important;
    }
    .bottom-xl-4
    {
        bottom: 1.5rem !important;
    }
    .left-xl-4
    {
        left: 1.5rem !important;
    }
    .top-xl-n4
    {
        top: -1.5rem !important;
    }
    .right-xl-n4
    {
        right: -1.5rem !important;
    }
    .bottom-xl-n4
    {
        bottom: -1.5rem !important;
    }
    .left-xl-n4
    {
        left: -1.5rem !important;
    }
    .top-xl-5
    {
        top: 3rem !important;
    }
    .right-xl-5
    {
        right: 3rem !important;
    }
    .bottom-xl-5
    {
        bottom: 3rem !important;
    }
    .left-xl-5
    {
        left: 3rem !important;
    }
    .top-xl-n5
    {
        top: -3rem !important;
    }
    .right-xl-n5
    {
        right: -3rem !important;
    }
    .bottom-xl-n5
    {
        bottom: -3rem !important;
    }
    .left-xl-n5
    {
        left: -3rem !important;
    }
    .top-xl-6
    {
        top: 4.5rem !important;
    }
    .right-xl-6
    {
        right: 4.5rem !important;
    }
    .bottom-xl-6
    {
        bottom: 4.5rem !important;
    }
    .left-xl-6
    {
        left: 4.5rem !important;
    }
    .top-xl-n6
    {
        top: -4.5rem !important;
    }
    .right-xl-n6
    {
        right: -4.5rem !important;
    }
    .bottom-xl-n6
    {
        bottom: -4.5rem !important;
    }
    .left-xl-n6
    {
        left: -4.5rem !important;
    }
    .top-xl-7
    {
        top: 6rem !important;
    }
    .right-xl-7
    {
        right: 6rem !important;
    }
    .bottom-xl-7
    {
        bottom: 6rem !important;
    }
    .left-xl-7
    {
        left: 6rem !important;
    }
    .top-xl-n7
    {
        top: -6rem !important;
    }
    .right-xl-n7
    {
        right: -6rem !important;
    }
    .bottom-xl-n7
    {
        bottom: -6rem !important;
    }
    .left-xl-n7
    {
        left: -6rem !important;
    }
    .top-xl-8
    {
        top: 7.5rem !important;
    }
    .right-xl-8
    {
        right: 7.5rem !important;
    }
    .bottom-xl-8
    {
        bottom: 7.5rem !important;
    }
    .left-xl-8
    {
        left: 7.5rem !important;
    }
    .top-xl-n8
    {
        top: -7.5rem !important;
    }
    .right-xl-n8
    {
        right: -7.5rem !important;
    }
    .bottom-xl-n8
    {
        bottom: -7.5rem !important;
    }
    .left-xl-n8
    {
        left: -7.5rem !important;
    }
    .top-xl-9
    {
        top: 9rem !important;
    }
    .right-xl-9
    {
        right: 9rem !important;
    }
    .bottom-xl-9
    {
        bottom: 9rem !important;
    }
    .left-xl-9
    {
        left: 9rem !important;
    }
    .top-xl-n9
    {
        top: -9rem !important;
    }
    .right-xl-n9
    {
        right: -9rem !important;
    }
    .bottom-xl-n9
    {
        bottom: -9rem !important;
    }
    .left-xl-n9
    {
        left: -9rem !important;
    }
    .top-xl-10
    {
        top: 10.5rem !important;
    }
    .right-xl-10
    {
        right: 10.5rem !important;
    }
    .bottom-xl-10
    {
        bottom: 10.5rem !important;
    }
    .left-xl-10
    {
        left: 10.5rem !important;
    }
    .top-xl-n10
    {
        top: -10.5rem !important;
    }
    .right-xl-n10
    {
        right: -10.5rem !important;
    }
    .bottom-xl-n10
    {
        bottom: -10.5rem !important;
    }
    .left-xl-n10
    {
        left: -10.5rem !important;
    }
    .top-xl-17
    {
        top: 21rem !important;
    }
    .right-xl-17
    {
        right: 21rem !important;
    }
    .bottom-xl-17
    {
        bottom: 21rem !important;
    }
    .left-xl-17
    {
        left: 21rem !important;
    }
    .top-xl-n17
    {
        top: -21rem !important;
    }
    .right-xl-n17
    {
        right: -21rem !important;
    }
    .bottom-xl-n17
    {
        bottom: -21rem !important;
    }
    .left-xl-n17
    {
        left: -21rem !important;
    }
}

.center
{
    left: 50%;

    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.middle
{
    top: 50%;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

[data-negative-margin].is_stuck
{
    margin: 0 !important;
}

[class*='shadow']
{
    transition: all .2s ease;
}

.hover-shadow-sm:hover
{
    box-shadow: 0 .125rem .25rem rgba(31, 45, 61, .08) !important;
}

.hover-shadow:hover
{
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08) !important;
}

.hover-shadow-lg:hover
{
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125) !important;
}

.shadow-none,
.hover-shadow-none:hover
{
    box-shadow: none !important;
}

.shadow-primary-sm
{
    box-shadow: 0 .125rem .25rem rgba(12, 102, 255, .4) !important;
}

.shadow-primary
{
    box-shadow: 0 .5rem 1rem rgba(12, 102, 255, .4) !important;
}

.shadow-primary-lg
{
    box-shadow: 0 1rem 3rem rgba(12, 102, 255, .4) !important;
}

.shadow-secondary-sm
{
    box-shadow: 0 .125rem .25rem rgba(239, 242, 247, .4) !important;
}

.shadow-secondary
{
    box-shadow: 0 .5rem 1rem rgba(239, 242, 247, .4) !important;
}

.shadow-secondary-lg
{
    box-shadow: 0 1rem 3rem rgba(239, 242, 247, .4) !important;
}

.shadow-success-sm
{
    box-shadow: 0 .125rem .25rem rgba(45, 202, 140, .4) !important;
}

.shadow-success
{
    box-shadow: 0 .5rem 1rem rgba(45, 202, 140, .4) !important;
}

.shadow-success-lg
{
    box-shadow: 0 1rem 3rem rgba(45, 202, 140, .4) !important;
}

.shadow-info-sm
{
    box-shadow: 0 .125rem .25rem rgba(80, 181, 255, .4) !important;
}

.shadow-info
{
    box-shadow: 0 .5rem 1rem rgba(80, 181, 255, .4) !important;
}

.shadow-info-lg
{
    box-shadow: 0 1rem 3rem rgba(80, 181, 255, .4) !important;
}

.shadow-warning-sm
{
    box-shadow: 0 .125rem .25rem rgba(255, 190, 61, .4) !important;
}

.shadow-warning
{
    box-shadow: 0 .5rem 1rem rgba(255, 190, 61, .4) !important;
}

.shadow-warning-lg
{
    box-shadow: 0 1rem 3rem rgba(255, 190, 61, .4) !important;
}

.shadow-danger-sm
{
    box-shadow: 0 .125rem .25rem rgba(255, 92, 117, .4) !important;
}

.shadow-danger
{
    box-shadow: 0 .5rem 1rem rgba(255, 92, 117, .4) !important;
}

.shadow-danger-lg
{
    box-shadow: 0 1rem 3rem rgba(255, 92, 117, .4) !important;
}

.shadow-light-sm
{
    box-shadow: 0 .125rem .25rem rgba(239, 242, 247, .4) !important;
}

.shadow-light
{
    box-shadow: 0 .5rem 1rem rgba(239, 242, 247, .4) !important;
}

.shadow-light-lg
{
    box-shadow: 0 1rem 3rem rgba(239, 242, 247, .4) !important;
}

.shadow-dark-sm
{
    box-shadow: 0 .125rem .25rem rgba(39, 52, 68, .4) !important;
}

.shadow-dark
{
    box-shadow: 0 .5rem 1rem rgba(39, 52, 68, .4) !important;
}

.shadow-dark-lg
{
    box-shadow: 0 1rem 3rem rgba(39, 52, 68, .4) !important;
}

.shadow-neutral-sm
{
    box-shadow: 0 .125rem .25rem rgba(255, 255, 255, .4) !important;
}

.shadow-neutral
{
    box-shadow: 0 .5rem 1rem rgba(255, 255, 255, .4) !important;
}

.shadow-neutral-lg
{
    box-shadow: 0 1rem 3rem rgba(255, 255, 255, .4) !important;
}

.shadow-white-sm
{
    box-shadow: 0 .125rem .25rem rgba(255, 255, 255, .4) !important;
}

.shadow-white
{
    box-shadow: 0 .5rem 1rem rgba(255, 255, 255, .4) !important;
}

.shadow-white-lg
{
    box-shadow: 0 1rem 3rem rgba(255, 255, 255, .4) !important;
}

.font-weight-300
{
    font-weight: 300 !important;
}

.font-weight-400
{
    font-weight: 400 !important;
}

.font-weight-500
{
    font-weight: 500 !important;
}

.font-weight-600
{
    font-weight: 600 !important;
}

.font-weight-700
{
    font-weight: 700 !important;
}

.font-weight-800
{
    font-weight: 800 !important;
}

.font-weight-900
{
    font-weight: 900 !important;
}

.font-weight-bolder
{
    font-weight: 700 !important;
}

.text-underline
{
    text-decoration: underline !important;
}
.text-underline--dashed
{
    border-bottom: 1px dashed !important;
}

.text-line-through
{
    text-decoration: line-through !important;
}

a.text-muted:hover
{
    color: #68788f !important;
}

.text-xs
{
    font-size: .75rem !important;
}

.text-sm
{
    font-size: .875rem !important;
}

.text-lg
{
    font-size: 1.25rem !important;
}

.text-xl
{
    font-size: 1.5rem !important;
}

.lh-100
{
    line-height: 1 !important;
}

.lh-110
{
    line-height: 1.1 !important;
}

.lh-120
{
    line-height: 1.2 !important;
}

.lh-130
{
    line-height: 1.3 !important;
}

.lh-140
{
    line-height: 1.4 !important;
}

.lh-150
{
    line-height: 1.5 !important;
}

.lh-160
{
    line-height: 1.6 !important;
}

.lh-170
{
    line-height: 1.7 !important;
}

.lh-180
{
    line-height: 1.8 !important;
}

.lh-190
{
    line-height: 1.9 !important;
}

.lh-200
{
    line-height: 2 !important;
}

.ls-1
{
    letter-spacing: .0625rem !important;
}

.ls-15
{
    letter-spacing: .09375rem !important;
}

.ls-2
{
    letter-spacing: .125rem !important;
}

.text-limit
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.transform-none
{
    -webkit-transform: none !important;
            transform: none !important;
}

.translate-y-n3,
.hover-translate-y-n3:hover
{
    -webkit-transform: translateY(-3px) !important;
            transform: translateY(-3px) !important;
}

.translate-y-n10,
.hover-translate-y-n10:hover
{
    -webkit-transform: translateY(-10px) !important;
            transform: translateY(-10px) !important;
}

.translate-x-50,
.hover-translate-x-50:hover
{
    -webkit-transform: translateX(50%) !important;
            transform: translateX(50%) !important;
}

.translate-y-50
{
    -webkit-transform: translateY(50%) !important;
            transform: translateY(50%) !important;
}

.translate-x-100
{
    -webkit-transform: translateX(100%) !important;
            transform: translateX(100%) !important;
}

.translate-y-100
{
    -webkit-transform: translateY(100%) !important;
            transform: translateY(100%) !important;
}

.translate-x-n50
{
    -webkit-transform: translateX(-50%) !important;
            transform: translateX(-50%) !important;
}

.translate-y-n50
{
    -webkit-transform: translateY(-50%) !important;
            transform: translateY(-50%) !important;
}

.translate-x-n100
{
    -webkit-transform: translateX(-100%) !important;
            transform: translateX(-100%) !important;
}

.translate-y-n100
{
    -webkit-transform: translateY(-100%) !important;
            transform: translateY(-100%) !important;
}

.scale-90
{
    -webkit-transform: scale(.9) !important;
            transform: scale(.9) !important;
}

.scale-x-90
{
    -webkit-transform: scaleX(.9) !important;
            transform: scaleX(.9) !important;
}

.scale-y-90
{
    -webkit-transform: scaleY(.9) !important;
            transform: scaleY(.9) !important;
}

.scale-100
{
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
}

.scale-x-100
{
    -webkit-transform: scaleX(1) !important;
            transform: scaleX(1) !important;
}

.scale-y-100
{
    -webkit-transform: scaleY(1) !important;
            transform: scaleY(1) !important;
}

.scale-105,
.hover-scale-105:hover
{
    -webkit-transform: scale(1.05) !important;
            transform: scale(1.05) !important;
}

.scale-x-105,
.hover-scale-x-105:hover
{
    -webkit-transform: scaleX(1.05) !important;
            transform: scaleX(1.05) !important;
}

.scale-y-105
{
    -webkit-transform: scaleY(1.05) !important;
            transform: scaleY(1.05) !important;
}

.scale-110,
.hover-scale-110:hover
{
    -webkit-transform: scale(1.1) !important;
            transform: scale(1.1) !important;
}

.scale-x-110,
.hover-scale-x-110:hover
{
    -webkit-transform: scaleX(1.1) !important;
            transform: scaleX(1.1) !important;
}

.scale-y-110
{
    -webkit-transform: scaleY(1.1) !important;
            transform: scaleY(1.1) !important;
}

.rotate-90,
.hover-rotate-90:hover
{
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
}

.rotate-180,
.hover-rotate-180:hover
{
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
}

.rotate-270,
.hover-rotate-270:hover
{
    -webkit-transform: rotate(270deg) !important;
            transform: rotate(270deg) !important;
}

.rotate-360,
.hover-rotate-360:hover
{
    -webkit-transform: rotate(360deg) !important;
            transform: rotate(360deg) !important;
}

.rotate-n90,
.hover-rotate-n90:hover
{
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
}

.skew-x-5
{
    -webkit-transform: skewX(5deg) !important;
            transform: skewX(5deg) !important;
}

.skew-y-5
{
    -webkit-transform: skewY(5deg) !important;
            transform: skewY(5deg) !important;
}

.skew-x-n10
{
    -webkit-transform: skewX(-10deg) !important;
            transform: skewX(-10deg) !important;
}

.skew-y-n10
{
    -webkit-transform: skewY(-10deg) !important;
            transform: skewY(-10deg) !important;
}

.perspective-left
{
    -webkit-transform: scale(1) perspective(2000px) rotateY(11deg) rotateX(2deg) rotate(-2deg);
            transform: scale(1) perspective(2000px) rotateY(11deg) rotateX(2deg) rotate(-2deg);
}

.perspective-right
{
    -webkit-transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
            transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
}

.transform-origin-t
{
    -webkit-transform-origin: 50% 0 !important;
            transform-origin: 50% 0 !important;
}

.transform-origin-r
{
    -webkit-transform-origin: 100% 50% !important;
            transform-origin: 100% 50% !important;
}

.transform-origin-b
{
    -webkit-transform-origin: 50% 100% !important;
            transform-origin: 50% 100% !important;
}

.transform-origin-l
{
    -webkit-transform-origin: 0 50% !important;
            transform-origin: 0 50% !important;
}

.zindex-100
{
    z-index: 100 !important;
}

.zindex-101
{
    z-index: 101 !important;
}

.zindex-102
{
    z-index: 102 !important;
}

:root
{
    --primary-dark: #0050d8;
    --primary-light: #3f86ff;
    --secondary-dark: #cdd6e6;
    --secondary-light: white;
    --success-dark: #24a06f;
    --success-light: #52d8a3;
    --info-dark: #1d9fff;
    --info-light: #83cbff;
    --warning-dark: #ffad0a;
    --warning-light: #ffcf70;
    --danger-dark: #ff294a;
    --danger-light: #ff8fa0;
    --light-dark: #cdd6e6;
    --light-light: white;
    --dark-dark: #141b24;
    --dark-light: #3a4d64;
    --neutral-dark: #e6e6e6;
    --neutral-light: white;
    --white-dark: #e6e6e6;
    --white-light: white;
}

body,
html
{
    overflow-x: hidden;
}

iframe
{
    border: 0;
}

figcaption,
figure,
main
{
    display: block;

    margin: 0;
}

figure
{
    max-width: 100%;
    min-height: 1px;
}

main
{
    position: relative;
}

button:focus
{
    outline: none;
}

svg
{
    overflow: hidden; 

    vertical-align: middle;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    img[src$='.svg']
    {
        width: 100%;
    }
}

.action-item
{
    font-size: .875rem;

    display: inline-block;

    padding: .25rem .25rem;

    cursor: pointer;
    transition: color .3s; 

    color: #8492a6;
    border: 0;
    background: transparent;
}
@media (prefers-reduced-motion: reduce)
{
    .action-item
    {
        transition: none;
    }
}
.action-item:hover
{
    color: #a2acbb;
}
.action-item span
{
    display: inline-block; 

    padding-left: .35rem;
}

.actions-dark .action-item
{
    color: rgba(255, 255, 255, .7);
}
.actions-dark .action-item:hover
{
    color: white;
}

.action-favorite
{
    color: #fc0;
}
.action-favorite:hover,
.action-favorite.active
{
    color: #dbaf00;
}

.action-love
{
    color: #ff5c75;
}
.action-love:hover,
.action-love.active
{
    color: #ff3857;
}

.action-like
{
    color: #0c66ff;
}
.action-like:hover,
.action-like.active
{
    color: #0056e7;
}

.action-item-lg
{
    font-size: 1.25rem;
}

.card > .actions
{
    position: absolute;
    top: 1rem; 
    right: .875rem;
}

.card-stats .actions
{
    top: .5rem; 
    right: .75rem;
}

.actions-toolbar
{
    position: relative;
}

.actions-search
{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    display: none;

    width: 100%;
    height: 100%;

    transition: all .2s ease; 

    opacity: 0;
    border-color: #eff2f7;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);

    align-items: center;
}
@media (prefers-reduced-motion: reduce)
{
    .actions-search
    {
        transition: none;
    }
}
.actions-search.show
{
    display: flex;

    opacity: 1;
}

.alert
{
    font-size: .875rem;
}
.alert strong
{
    font-weight: 600;
}
.alert hr
{
    margin: .875rem 0;
}
.alert p:last-child
{
    margin-bottom: 0;
}

.alert-heading
{
    font-size: 1.5rem;
    font-weight: 600;

    margin-top: .15rem;
}

.alert-flush
{
    border-right: 0; 
    border-left: 0;
    border-radius: 0;
}

.alert-modern
{
    display: inline-block;

    padding: .275rem 1rem .375rem .375rem;

    border-radius: 50rem;
}
.alert-modern::after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: 10.2px;

    content: '\f105'; 

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.alert-modern:empty::after
{
    margin-left: 0;
}
.alert-modern .badge + .alert-content
{
    margin-left: .5rem;
}
@media (max-width: 575.98px)
{
    .alert-modern
    {
        text-align: center;
    }
    .alert-modern:after
    {
        content: '';
    }
    .alert-modern .alert-content
    {
        display: block;

        margin-top: .5rem;
    }
}

.alert-dark
{
    color: #fff;
    border: 0; 
    background-color: #273444;
}
.alert-dark .alert-link
{
    color: #fff;
}
.alert-dark .close > span:not(.sr-only)
{
    color: white;
}
.alert-dark .close:hover > span:not(.sr-only),
.alert-dark .close:focus > span:not(.sr-only)
{
    color: #fff;
}
.alert-dark hr
{
    border-color: rgba(216, 203, 187, .1);
}

[class*='alert-outline-']
{
    border-right: 1px solid;
}
[class*='alert-outline-'] .alert-group-prepend
{
    padding-right: .875rem;
}

.alert-outline-primary
{
    color: #154ba2;
    border-color: #0c66ff; 
    background: transparent;
}
.alert-outline-primary hr
{
    border-top-color: #2676ff;
}
.alert-outline-primary .alert-link
{
    color: #154ba2;
}
.alert-outline-primary .alert-group-prepend
{
    border-right: 1px solid #0c66ff;
}

.alert-outline-secondary
{
    color: #8b939e;
    border-color: #eff2f7; 
    background: transparent;
}
.alert-outline-secondary hr
{
    border-top-color: white;
}
.alert-outline-secondary .alert-link
{
    color: #8b939e;
}
.alert-outline-secondary .alert-group-prepend
{
    border-right: 1px solid #eff2f7;
}

.alert-outline-success
{
    color: #267f66;
    border-color: #2dca8c; 
    background: transparent;
}
.alert-outline-success hr
{
    border-top-color: #3dd498;
}
.alert-outline-success .alert-link
{
    color: #267f66;
}
.alert-outline-success .alert-group-prepend
{
    border-right: 1px solid #2dca8c;
}

.alert-outline-info
{
    color: #3874a2;
    border-color: #50b5ff; 
    background: transparent;
}
.alert-outline-info hr
{
    border-top-color: #6ac0ff;
}
.alert-outline-info .alert-link
{
    color: #3874a2;
}
.alert-outline-info .alert-group-prepend
{
    border-right: 1px solid #50b5ff;
}

.alert-outline-warning
{
    color: #93783d;
    border-color: #ffbe3d; 
    background: transparent;
}
.alert-outline-warning hr
{
    border-top-color: #ffc757;
}
.alert-outline-warning .alert-link
{
    color: #93783d;
}
.alert-outline-warning .alert-group-prepend
{
    border-right: 1px solid #ffbe3d;
}

.alert-outline-danger
{
    color: #93455a;
    border-color: #ff5c75; 
    background: transparent;
}
.alert-outline-danger hr
{
    border-top-color: #ff768b;
}
.alert-outline-danger .alert-link
{
    color: #93455a;
}
.alert-outline-danger .alert-group-prepend
{
    border-right: 1px solid #ff5c75;
}

.alert-outline-light
{
    color: #8b939e;
    border-color: #eff2f7; 
    background: transparent;
}
.alert-outline-light hr
{
    border-top-color: white;
}
.alert-outline-light .alert-link
{
    color: #8b939e;
}
.alert-outline-light .alert-group-prepend
{
    border-right: 1px solid #eff2f7;
}

.alert-outline-dark
{
    color: #233141;
    border-color: #273444; 
    background: transparent;
}
.alert-outline-dark hr
{
    border-top-color: #304054;
}
.alert-outline-dark .alert-link
{
    color: #233141;
}
.alert-outline-dark .alert-group-prepend
{
    border-right: 1px solid #273444;
}

.alert-outline-neutral
{
    color: #939aa2;
    border-color: white; 
    background: transparent;
}
.alert-outline-neutral hr
{
    border-top-color: white;
}
.alert-outline-neutral .alert-link
{
    color: #939aa2;
}
.alert-outline-neutral .alert-group-prepend
{
    border-right: 1px solid white;
}

.alert-outline-white
{
    color: #939aa2;
    border-color: white; 
    background: transparent;
}
.alert-outline-white hr
{
    border-top-color: white;
}
.alert-outline-white .alert-link
{
    color: #939aa2;
}
.alert-outline-white .alert-group-prepend
{
    border-right: 1px solid white;
}

.alert-messages:not(:last-child)
{
    margin-bottom: 1.25rem;
}

.alert-notify
{
    display: flex !important;

    width: calc(100% - 30px);
    max-width: 600px;
    padding-right: 80px;

    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
}
.alert-notify:hover
{
    z-index: 1081 !important;
}

.alert-group
{
    display: flex;

    padding-top: 0;
    padding-bottom: 0; 

    align-items: center;
}
.alert-group .alert-group-prepend
{
    display: inline-flex;

    margin-right: .5rem; 

    vertical-align: middle;
}
.alert-group .alert-group-prepend,
.alert-group .alert-content,
.alert-group .alert-action
{
    padding-top: .875rem;
    padding-bottom: .875rem;
}
.alert-group .alert-action
{
    margin-left: auto;
}
.alert-group .btn
{
    font-size: .875rem;
    line-height: 1.6; 

    padding: .25rem .75rem;
}
.alert-group[class*='alert-outline'] .alert-group-prepend
{
    margin-right: .875rem;
}

.alert-group-icon
{
    display: inline-block;

    min-width: 26px;

    text-align: center;
}

.alert-dismissible .alert-group-prepend
{
    display: block;
}

.alert-dismissible .close
{
    top: 50%;
    right: .875rem;

    padding: 0; 

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.avatar
{
    font-size: 1rem;
    font-weight: 600;

    position: relative;

    display: inline-flex;

    width: 3.125rem;
    height: 3.125rem;

    vertical-align: middle;

    color: #fff;
    border-radius: .375rem; 

    align-items: center;
    justify-content: center;
}
.avatar img
{
    width: 100%;

    border-radius: .375rem;
}
.avatar.rounded-circle img
{
    border-radius: 50%;
}
.avatar span
{
    background-color: #0c66ff;
}
.avatar + .avatar
{
    margin-left: .25rem;
}
.avatar + .avatar-content
{
    display: inline-block;

    margin-left: .75rem;
}

.avatar-xl
{
    font-size: 1.375rem; 

    width: 6rem;
    height: 6rem;
}

.avatar-lg
{
    font-size: 1.25rem; 

    width: 4rem;
    height: 4rem;
}

.avatar-sm
{
    font-size: .75rem;

    width: 2.4375rem;
    height: 2.4375rem;

    border-radius: .2rem;
}

.avatar-group
{
    line-height: 1; 

    display: inline-block;
}
.avatar-group .avatar
{
    z-index: 1;

    transition: margin .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce)
{
    .avatar-group .avatar
    {
        transition: none;
    }
}
.avatar-group .avatar img
{
    border: 2px solid #fff;
}
.avatar-group .avatar:hover
{
    z-index: 2;
}
.avatar-group .avatar + .avatar
{
    margin-left: -1.25rem;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.avatar-group .avatar-sm + .avatar-sm
{
    margin-left: -1rem;
}
.avatar-group:hover .avatar
{
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
}
.avatar-group:hover .avatar-sm
{
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.hover-avatar-ungroup:hover .avatar:not(:first-child)
{
    margin-left: 0;
}

.avatar-parent-child
{
    position: relative; 

    display: inline-block;
}

.avatar-child
{
    position: absolute;
    right: 0;
    bottom: 0;

    border: 2px solid #fff;
    border-radius: .2rem; 
    background-color: #fff;
}

.avatar.rounded-circle + .avatar-child
{
    border-radius: 50%;
}

.avatar + .avatar-child
{
    width: 20px;
    height: 20px;
}

.avatar-lg + .avatar-child
{
    width: 24px;
    height: 24px;
}

.avatar-sm + .avatar-child
{
    width: 16px;
    height: 16px;
}

.avatar + .avatar-badge
{
    right: -6px;
    bottom: 15px; 

    width: 14px;
    height: 14px;
}

.avatar-lg + .avatar-badge
{
    right: -5px;
    bottom: 20px; 

    width: 16px;
    height: 16px;
}

.avatar-sm + .badge
{
    right: -6px;
    bottom: 10px; 

    width: 12px;
    height: 12px;
}

.avatar-connect
{
    position: relative;
}
.avatar-connect:before
{
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;

    content: '';

    border-bottom: 2px dashed #eff2f7;
}
.avatar-connect ul
{
    margin: 0;
    padding: 0;

    list-style: none;
}

.badge
{
    display: inline;

    text-transform: none;
}
.badge i:not(:last-child),
.badge svg
{
    margin-right: .5rem;
}

.badge-group .badge
{
    display: inline-block;

    margin-top: .125rem;
    margin-right: .25rem; 
    margin-bottom: .125rem;
}

.badge-xs
{
    font-size: 60%; 

    padding: .25em .5em;
}

.badge-md
{
    padding: .65em 1em;
}

.badge-lg
{
    padding: .85em 1.375em;
}

.badge-inline
{
    margin-right: .625rem;
}
.badge-inline + span
{
    position: relative; 
    top: 2px;
}
.badge-inline + span > a
{
    text-decoration: underline;
}

.btn .badge:not(:first-child)
{
    margin-left: .5rem;
}

.btn .badge:not(:last-child)
{
    margin-right: .5rem;
}

.badge-soft-primary
{
    color: #0c66ff;
    background-color: #cee0ff;
}
.badge-soft-primary[href]:hover,
.badge-soft-primary[href]:focus
{
    text-decoration: none;

    color: #0c66ff;
    background-color: #b5d0ff;
}

.badge-soft-secondary
{
    color: #eff2f7;
    background-color: #fcfcfd;
}
.badge-soft-secondary[href]:hover,
.badge-soft-secondary[href]:focus
{
    text-decoration: none;

    color: #eff2f7;
    background-color: #ededf3;
}

.badge-soft-success
{
    color: #2dca8c;
    background-color: #d5f4e8;
}
.badge-soft-success[href]:hover,
.badge-soft-success[href]:focus
{
    text-decoration: none;

    color: #2dca8c;
    background-color: #c1efdd;
}

.badge-soft-info
{
    color: #50b5ff;
    background-color: #dcf0ff;
}
.badge-soft-info[href]:hover,
.badge-soft-info[href]:focus
{
    text-decoration: none;

    color: #50b5ff;
    background-color: #c3e5ff;
}

.badge-soft-warning
{
    color: #ffbe3d;
    background-color: #fff2d8;
}
.badge-soft-warning[href]:hover,
.badge-soft-warning[href]:focus
{
    text-decoration: none;

    color: #ffbe3d;
    background-color: #ffeabf;
}

.badge-soft-danger
{
    color: #ff5c75;
    background-color: #ffdee3;
}
.badge-soft-danger[href]:hover,
.badge-soft-danger[href]:focus
{
    text-decoration: none;

    color: #ff5c75;
    background-color: #ffc5cd;
}

.badge-soft-light
{
    color: #eff2f7;
    background-color: #fcfcfd;
}
.badge-soft-light[href]:hover,
.badge-soft-light[href]:focus
{
    text-decoration: none;

    color: #eff2f7;
    background-color: #ededf3;
}

.badge-soft-dark
{
    color: #273444;
    background-color: #d4d6da;
}
.badge-soft-dark[href]:hover,
.badge-soft-dark[href]:focus
{
    text-decoration: none;

    color: #273444;
    background-color: #c6c9ce;
}

.badge-soft-neutral
{
    color: #fff;
    background-color: white;
}
.badge-soft-neutral[href]:hover,
.badge-soft-neutral[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #f2f2f2;
}

.badge-soft-white
{
    color: #fff;
    background-color: white;
}
.badge-soft-white[href]:hover,
.badge-soft-white[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #f2f2f2;
}

.badge-dot
{
    font-size: .875rem; 

    padding-right: 0;
    padding-left: 0;

    color: #8492a6;
    background: transparent;
}
.badge-dot i
{
    display: inline-block;

    width: .375rem;
    height: .375rem;
    margin-right: .375rem; 

    vertical-align: middle;

    border-radius: 50%;
}
.badge-dot.badge-md i
{
    width: .5rem;
    height: .5rem;
}
.badge-dot.badge-lg i
{
    width: .625rem;
    height: .625rem;
}

.badge-circle
{
    font-size: .75rem;
    font-weight: 600; 

    display: inline-flex;

    width: 1.25rem;
    height: 1.25rem;
    padding: 0 !important;

    text-align: center;
    vertical-align: middle;

    border-radius: 50%;

    align-items: center;
    justify-content: center;
}
.badge-circle.badge-md
{
    width: 1.5rem;
    height: 1.5rem;
}
.badge-circle.badge-lg
{
    width: 2rem;
    height: 2rem;
}
.badge-circle svg
{
    margin: 0;
}

.btn .badge-floating
{
    position: absolute;
    top: -50%;
    right: .5rem;

    margin: 0;
    padding-right: .5rem; 
    padding-left: .5rem;

    -webkit-transform: translate(50%, 50%);
            transform: translate(50%, 50%);

    border: 3px solid;
}
.btn .badge-floating.badge-circle
{
    right: 0;
}

.breadcrumb
{
    border: 1px solid #eff2f7;
}

.breadcrumb-sm
{
    font-size: .875rem;
}

.breadcrumb-links
{
    border: 0;
    border-radius: 0; 
    background-color: transparent;
}
.breadcrumb-links .breadcrumb-item + .breadcrumb-item::before
{
    width: .3rem;
    height: .6rem;
    margin-right: .5rem;

    content: '';

    background: #e0e6ed; 

    -webkit-mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMCAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8ZyBpZD0iY2hldnJvbi1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDIuMDAwMDAwKSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIuNSI+ICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgcG9pbnRzPSIwIDEyIDYgNiAwIDAiPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) no-repeat 50% 50%;
            mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMCAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8ZyBpZD0iY2hldnJvbi1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDIuMDAwMDAwKSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIuNSI+ICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgcG9pbnRzPSIwIDEyIDYgNiAwIDAiPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) no-repeat 50% 50%;
    -webkit-mask-size: contain;
            mask-size: contain;
}

.breadcrumb-dark .breadcrumb-item a
{
    color: rgba(255, 255, 255, .8);
}
.breadcrumb-dark .breadcrumb-item a:hover
{
    color: white;
}

.breadcrumb-dark .breadcrumb-item + .breadcrumb-item::before
{
    color: rgba(255, 255, 255, .8);
}

.breadcrumb-light .breadcrumb-item a
{
    color: rgba(39, 52, 68, .8);
}
.breadcrumb-light .breadcrumb-item a:hover
{
    color: #273444;
}

.breadcrumb-light .breadcrumb-item + .breadcrumb-item::before
{
    color: rgba(39, 52, 68, .8);
}

.btn
{
    position: relative;

    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .btn
    {
        transition: none;
    }
}
.btn:not(:last-child)
{
    margin-right: .5rem;
}

.btn-group > .btn
{
    margin: 0;
}

.btn-neutral
{
    color: #273444;
    border: 1px solid #eff2f7; 
    background-color: #fff;
}
.btn-neutral:hover
{
    border-color: #e5eaf2;
    background-color: #f7f7f7;
}
.btn-neutral:not(:disabled):not(.disabled).active
{
    color: #0c66ff;
    border-color: #0c66ff;
    background-color: #fff;
}

.btn-xs
{
    font-size: .75rem;
    line-height: 1.5;

    padding: .375rem 1rem;

    border-radius: .375rem;
}

.btn-xl
{
    font-size: 1.5rem;
    line-height: 1.5;

    padding: 1.5rem 2rem;

    border-radius: .75rem;
}
@media (max-width: 1200px)
{
    .btn-xl
    {
        font-size: calc(1.275rem + .3vw) ;
    }
}

.btn-nobg
{
    padding: 0;

    background: transparent !important;
}
.btn-nobg:hover
{
    border-color: transparent !important; 
    background: transparent !important;
}

.btn-nobg--hover:hover
{
    background-color: transparent;
}

.btn-soft-primary
{
    color: #0c66ff;
    border-color: #cee0ff;
    background-color: #cee0ff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-primary:hover
{
    color: #fff;
    border-color: #0c66ff;
    background-color: #0c66ff;
    box-shadow: 0 4px 11px rgba(206, 224, 255, .35);
}
.btn-soft-primary:focus,
.btn-soft-primary.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(16, 95, 227, .5);
}
.btn-soft-primary.disabled,
.btn-soft-primary:disabled
{
    color: #0c66ff;
    border-color: #cee0ff; 
    background-color: #cee0ff;
}
.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled).active,
.show > .btn-soft-primary.dropdown-toggle
{
    color: #fff;
    border-color: #0c66ff; 
    background-color: #0c66ff;
}
.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-primary.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(16, 95, 227, .5);
}

.btn-soft-secondary
{
    color: #eff2f7;
    border-color: #fcfcfd;
    background-color: #fcfcfd;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-secondary:hover
{
    color: #273444;
    border-color: #eff2f7;
    background-color: #eff2f7;
    box-shadow: 0 4px 11px rgba(252, 252, 253, .35);
}
.btn-soft-secondary:focus,
.btn-soft-secondary.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(209, 214, 220, .5);
}
.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled
{
    color: #eff2f7;
    border-color: #fcfcfd; 
    background-color: #fcfcfd;
}
.btn-soft-secondary:not(:disabled):not(.disabled):active,
.btn-soft-secondary:not(:disabled):not(.disabled).active,
.show > .btn-soft-secondary.dropdown-toggle
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-soft-secondary:not(:disabled):not(.disabled):active:focus,
.btn-soft-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-secondary.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(209, 214, 220, .5);
}

.btn-soft-success
{
    color: #2dca8c;
    border-color: #d5f4e8;
    background-color: #d5f4e8;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-success:hover
{
    color: #fff;
    border-color: #2dca8c;
    background-color: #2dca8c;
    box-shadow: 0 4px 11px rgba(213, 244, 232, .35);
}
.btn-soft-success:focus,
.btn-soft-success.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(44, 180, 129, .5);
}
.btn-soft-success.disabled,
.btn-soft-success:disabled
{
    color: #2dca8c;
    border-color: #d5f4e8; 
    background-color: #d5f4e8;
}
.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled).active,
.show > .btn-soft-success.dropdown-toggle
{
    color: #fff;
    border-color: #2dca8c; 
    background-color: #2dca8c;
}
.btn-soft-success:not(:disabled):not(.disabled):active:focus,
.btn-soft-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-success.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(44, 180, 129, .5);
}

.btn-soft-info
{
    color: #50b5ff;
    border-color: #dcf0ff;
    background-color: #dcf0ff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-info:hover
{
    color: #fff;
    border-color: #50b5ff;
    background-color: #50b5ff;
    box-shadow: 0 4px 11px rgba(220, 240, 255, .35);
}
.btn-soft-info:focus,
.btn-soft-info.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(74, 162, 227, .5);
}
.btn-soft-info.disabled,
.btn-soft-info:disabled
{
    color: #50b5ff;
    border-color: #dcf0ff; 
    background-color: #dcf0ff;
}
.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled).active,
.show > .btn-soft-info.dropdown-toggle
{
    color: #fff;
    border-color: #50b5ff; 
    background-color: #50b5ff;
}
.btn-soft-info:not(:disabled):not(.disabled):active:focus,
.btn-soft-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-info.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(74, 162, 227, .5);
}

.btn-soft-warning
{
    color: #ffbe3d;
    border-color: #fff2d8;
    background-color: #fff2d8;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-warning:hover
{
    color: #273444;
    border-color: #ffbe3d;
    background-color: #ffbe3d;
    box-shadow: 0 4px 11px rgba(255, 242, 216, .35);
}
.btn-soft-warning:focus,
.btn-soft-warning.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 169, 62, .5);
}
.btn-soft-warning.disabled,
.btn-soft-warning:disabled
{
    color: #ffbe3d;
    border-color: #fff2d8; 
    background-color: #fff2d8;
}
.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled).active,
.show > .btn-soft-warning.dropdown-toggle
{
    color: #273444;
    border-color: #ffbe3d; 
    background-color: #ffbe3d;
}
.btn-soft-warning:not(:disabled):not(.disabled):active:focus,
.btn-soft-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-warning.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 169, 62, .5);
}

.btn-soft-danger
{
    color: #ff5c75;
    border-color: #ffdee3;
    background-color: #ffdee3;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-danger:hover
{
    color: #fff;
    border-color: #ff5c75;
    background-color: #ff5c75;
    box-shadow: 0 4px 11px rgba(255, 222, 227, .35);
}
.btn-soft-danger:focus,
.btn-soft-danger.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 86, 110, .5);
}
.btn-soft-danger.disabled,
.btn-soft-danger:disabled
{
    color: #ff5c75;
    border-color: #ffdee3; 
    background-color: #ffdee3;
}
.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled).active,
.show > .btn-soft-danger.dropdown-toggle
{
    color: #fff;
    border-color: #ff5c75; 
    background-color: #ff5c75;
}
.btn-soft-danger:not(:disabled):not(.disabled):active:focus,
.btn-soft-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-danger.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 86, 110, .5);
}

.btn-soft-light
{
    color: #eff2f7;
    border-color: #fcfcfd;
    background-color: #fcfcfd;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-light:hover
{
    color: #273444;
    border-color: #eff2f7;
    background-color: #eff2f7;
    box-shadow: 0 4px 11px rgba(252, 252, 253, .35);
}
.btn-soft-light:focus,
.btn-soft-light.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(209, 214, 220, .5);
}
.btn-soft-light.disabled,
.btn-soft-light:disabled
{
    color: #eff2f7;
    border-color: #fcfcfd; 
    background-color: #fcfcfd;
}
.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled).active,
.show > .btn-soft-light.dropdown-toggle
{
    color: #273444;
    border-color: #eff2f7; 
    background-color: #eff2f7;
}
.btn-soft-light:not(:disabled):not(.disabled):active:focus,
.btn-soft-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-light.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(209, 214, 220, .5);
}

.btn-soft-dark
{
    color: #273444;
    border-color: #d4d6da;
    background-color: #d4d6da;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-dark:hover
{
    color: #fff;
    border-color: #273444;
    background-color: #273444;
    box-shadow: 0 4px 11px rgba(212, 214, 218, .35);
}
.btn-soft-dark:focus,
.btn-soft-dark.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(39, 52, 68, .5);
}
.btn-soft-dark.disabled,
.btn-soft-dark:disabled
{
    color: #273444;
    border-color: #d4d6da; 
    background-color: #d4d6da;
}
.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark.dropdown-toggle
{
    color: #fff;
    border-color: #273444; 
    background-color: #273444;
}
.btn-soft-dark:not(:disabled):not(.disabled):active:focus,
.btn-soft-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-dark.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(39, 52, 68, .5);
}

.btn-soft-neutral
{
    color: #fff;
    border-color: white;
    background-color: white;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-neutral:hover
{
    color: #273444;
    border-color: #fff;
    background-color: #fff;
    box-shadow: 0 4px 11px rgba(255, 255, 255, .35);
}
.btn-soft-neutral:focus,
.btn-soft-neutral.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 225, 227, .5);
}
.btn-soft-neutral.disabled,
.btn-soft-neutral:disabled
{
    color: #fff;
    border-color: white; 
    background-color: white;
}
.btn-soft-neutral:not(:disabled):not(.disabled):active,
.btn-soft-neutral:not(:disabled):not(.disabled).active,
.show > .btn-soft-neutral.dropdown-toggle
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-soft-neutral:not(:disabled):not(.disabled):active:focus,
.btn-soft-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-neutral.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 225, 227, .5);
}

.btn-soft-white
{
    color: #fff;
    border-color: white;
    background-color: white;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-soft-white:hover
{
    color: #273444;
    border-color: #fff;
    background-color: #fff;
    box-shadow: 0 4px 11px rgba(255, 255, 255, .35);
}
.btn-soft-white:focus,
.btn-soft-white.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 225, 227, .5);
}
.btn-soft-white.disabled,
.btn-soft-white:disabled
{
    color: #fff;
    border-color: white; 
    background-color: white;
}
.btn-soft-white:not(:disabled):not(.disabled):active,
.btn-soft-white:not(:disabled):not(.disabled).active,
.show > .btn-soft-white.dropdown-toggle
{
    color: #273444;
    border-color: #fff; 
    background-color: #fff;
}
.btn-soft-white:not(:disabled):not(.disabled):active:focus,
.btn-soft-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-white.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 0 rgba(223, 225, 227, .5);
}

.btn-facebook
{
    color: #fff;
    border-color: #3b5999;
    background-color: #3b5999;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-facebook:hover
{
    color: #fff;
    border-color: #2d4474; 
    background-color: #30497d;
}
.btn-facebook:focus,
.btn-facebook.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(59, 89, 153, .35);
}
.btn-facebook.disabled,
.btn-facebook:disabled
{
    color: #fff;
    border-color: #3b5999; 
    background-color: #3b5999;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle
{
    color: #fff;
    border-color: #293e6b; 
    background-color: #2d4474;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(59, 89, 153, .35);
}

.btn-twitter
{
    color: #fff;
    border-color: #1da1f2;
    background-color: #1da1f2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-twitter:hover
{
    color: #fff;
    border-color: #0c85d0; 
    background-color: #0d8ddc;
}
.btn-twitter:focus,
.btn-twitter.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(29, 161, 242, .35);
}
.btn-twitter.disabled,
.btn-twitter:disabled
{
    color: #fff;
    border-color: #1da1f2; 
    background-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle
{
    color: #fff;
    border-color: #0b7ec4; 
    background-color: #0c85d0;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(29, 161, 242, .35);
}

.btn-google-plus
{
    color: #fff;
    border-color: #dd4b39;
    background-color: #dd4b39;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-google-plus:hover
{
    color: #fff;
    border-color: #c23321; 
    background-color: #cd3623;
}
.btn-google-plus:focus,
.btn-google-plus.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(221, 75, 57, .35);
}
.btn-google-plus.disabled,
.btn-google-plus:disabled
{
    color: #fff;
    border-color: #dd4b39; 
    background-color: #dd4b39;
}
.btn-google-plus:not(:disabled):not(.disabled):active,
.btn-google-plus:not(:disabled):not(.disabled).active,
.show > .btn-google-plus.dropdown-toggle
{
    color: #fff;
    border-color: #b7301f; 
    background-color: #c23321;
}
.btn-google-plus:not(:disabled):not(.disabled):active:focus,
.btn-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .btn-google-plus.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(221, 75, 57, .35);
}

.btn-instagram
{
    color: #fff;
    border-color: #e4405f;
    background-color: #e4405f;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-instagram:hover
{
    color: #fff;
    border-color: #d31e40; 
    background-color: #de1f44;
}
.btn-instagram:focus,
.btn-instagram.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(228, 64, 95, .35);
}
.btn-instagram.disabled,
.btn-instagram:disabled
{
    color: #fff;
    border-color: #e4405f; 
    background-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle
{
    color: #fff;
    border-color: #c81c3d; 
    background-color: #d31e40;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(228, 64, 95, .35);
}

.btn-pinterest
{
    color: #fff;
    border-color: #bd081c;
    background-color: #bd081c;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-pinterest:hover
{
    color: #fff;
    border-color: #8c0615; 
    background-color: #980617;
}
.btn-pinterest:focus,
.btn-pinterest.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(189, 8, 28, .35);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled
{
    color: #fff;
    border-color: #bd081c; 
    background-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle
{
    color: #fff;
    border-color: #800513; 
    background-color: #8c0615;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(189, 8, 28, .35);
}

.btn-youtube
{
    color: #fff;
    border-color: #cd201f;
    background-color: #cd201f;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-youtube:hover
{
    color: #fff;
    border-color: #a11918; 
    background-color: #ac1b1a;
}
.btn-youtube:focus,
.btn-youtube.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(205, 32, 31, .35);
}
.btn-youtube.disabled,
.btn-youtube:disabled
{
    color: #fff;
    border-color: #cd201f; 
    background-color: #cd201f;
}
.btn-youtube:not(:disabled):not(.disabled):active,
.btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn-youtube.dropdown-toggle
{
    color: #fff;
    border-color: #961717; 
    background-color: #a11918;
}
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .btn-youtube.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(205, 32, 31, .35);
}

.btn-slack
{
    color: #fff;
    border-color: #3aaf85;
    background-color: #3aaf85;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-slack:hover
{
    color: #fff;
    border-color: #2d8968; 
    background-color: #30926f;
}
.btn-slack:focus,
.btn-slack.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(58, 175, 133, .35);
}
.btn-slack.disabled,
.btn-slack:disabled
{
    color: #fff;
    border-color: #3aaf85; 
    background-color: #3aaf85;
}
.btn-slack:not(:disabled):not(.disabled):active,
.btn-slack:not(:disabled):not(.disabled).active,
.show > .btn-slack.dropdown-toggle
{
    color: #fff;
    border-color: #2a7f61; 
    background-color: #2d8968;
}
.btn-slack:not(:disabled):not(.disabled):active:focus,
.btn-slack:not(:disabled):not(.disabled).active:focus,
.show > .btn-slack.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(58, 175, 133, .35);
}

.btn-dribbble
{
    color: #fff;
    border-color: #ea4c89;
    background-color: #ea4c89;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-dribbble:hover
{
    color: #fff;
    border-color: #e51e6b; 
    background-color: #e62a72;
}
.btn-dribbble:focus,
.btn-dribbble.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(234, 76, 137, .35);
}
.btn-dribbble.disabled,
.btn-dribbble:disabled
{
    color: #fff;
    border-color: #ea4c89; 
    background-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active,
.btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle
{
    color: #fff;
    border-color: #dc1a65; 
    background-color: #e51e6b;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(234, 76, 137, .35);
}

.btn-github
{
    color: #fff;
    border-color: #222;
    background-color: #222;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-github:hover
{
    color: #fff;
    border-color: #090909; 
    background-color: #0f0f0f;
}
.btn-github:focus,
.btn-github.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(34, 34, 34, .35);
}
.btn-github.disabled,
.btn-github:disabled
{
    color: #fff;
    border-color: #222; 
    background-color: #222;
}
.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle
{
    color: #fff;
    border-color: #020202; 
    background-color: #090909;
}
.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(34, 34, 34, .35);
}

.btn-vimeo
{
    color: #fff;
    border-color: #04a0f0;
    background-color: #04a0f0;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
}
.btn-vimeo:hover
{
    color: #fff;
    border-color: #037fbe; 
    background-color: #0387ca;
}
.btn-vimeo:focus,
.btn-vimeo.focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(4, 160, 240, .35);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled
{
    color: #fff;
    border-color: #04a0f0; 
    background-color: #04a0f0;
}
.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle
{
    color: #fff;
    border-color: #0376b1; 
    background-color: #037fbe;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus
{
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 0 0 rgba(4, 160, 240, .35);
}

.btn svg:not(:first-child),
.btn i:not(:first-child)
{
    margin-left: .5rem;
}

.btn svg:not(:last-child),
.btn i:not(:last-child)
{
    margin-right: .5rem;
}

.btn-icon .btn-inner--text:not(:first-child)
{
    margin-left: .75em;
}

.btn-icon .btn-inner--text:not(:last-child)
{
    margin-right: .75em;
}

.btn-icon .btn-inner--icon img
{
    width: 20px;
}

.btn-icon-only
{
    display: inline-flex;

    width: 3.125rem;
    height: 3.125rem;
    padding: 0; 

    align-items: center;
    justify-content: center;
}
.btn-icon-only.btn-xs
{
    width: 2rem;
    height: 2rem;
}
.btn-icon-only.btn-sm,
.btn-group-sm > .btn-icon-only.btn
{
    width: 2.4375rem;
    height: 2.4375rem;
}
.btn-icon-only.btn-lg,
.btn-group-lg > .btn-icon-only.btn
{
    width: 4rem;
    height: 4rem;
}

.btn-square-lg
{
    width: 150px !important;
    height: 150px !important;
}

.btn-square
{
    display: flex;
    flex-direction: column; 

    width: 108px !important;
    height: 108px !important;
    padding: 1rem;

    border-radius: 1rem;

    justify-content: center;
}

.btn-square-sm
{
    width: 50px !important;
    height: 50px !important;
}

.btn-square-xs
{
    width: 25px !important;
    height: 25px !important;
}

.btn-animated
{
    position: relative;

    overflow: hidden;
}
.btn-animated .btn-inner--visible
{
    position: relative;
}
.btn-animated .btn-inner--hidden
{
    position: absolute;
    top: 50%;

    width: 100%;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.btn-animated-x .btn-inner--visible
{
    right: 0;

    transition: right .3s ease 0s;
}
@media (prefers-reduced-motion: reduce)
{
    .btn-animated-x .btn-inner--visible
    {
        transition: none;
    }
}

.btn-animated-x .btn-inner--hidden
{
    right: -100%;

    transition: right .3s ease 0s;
}
@media (prefers-reduced-motion: reduce)
{
    .btn-animated-x .btn-inner--hidden
    {
        transition: none;
    }
}

.btn-animated-x:hover .btn-inner--hidden
{
    right: 0;
}

.btn-animated-x:hover .btn-inner--visible
{
    right: 150%;
}

.btn-animated-y .btn-inner--visible
{
    top: 0;

    transition: top .3s ease 0s;
}
@media (prefers-reduced-motion: reduce)
{
    .btn-animated-y .btn-inner--visible
    {
        transition: none;
    }
}

.btn-animated-y .btn-inner--hidden
{
    top: -100%;
    left: 0;

    transition: top .3s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .btn-animated-y .btn-inner--hidden
    {
        transition: none;
    }
}

.btn-animated-y:hover .btn-inner--hidden
{
    top: 50%;
}

.btn-animated-y:hover .btn-inner--visible
{
    top: 100px;
}

.btn-action-label .btn-label:hover
{
    color: #3c4858; 
    background: transparent;
}

.btn-action-label .btn-secondary:hover
{
    color: #0c66ff;
}

.btn-icon-label
{
    position: relative;
}
.btn-icon-label .btn-inner--icon
{
    line-height: 1;

    position: absolute;

    display: flex;

    width: 3em;
    height: 100%;
    margin: 0;

    text-align: center;

    border-radius: 0;
    background-color: rgba(0, 0, 0, .1); 

    align-items: center;
    justify-content: center;
}
.btn-icon-label .btn-inner--icon:not(:first-child)
{
    top: 0;
    right: 0;

    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}
.btn-icon-label .btn-inner--icon:not(:last-child)
{
    top: 0;
    left: 0;

    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}
.btn-icon-label .btn-inner--text:not(:first-child)
{
    padding-left: 3em;
}
.btn-icon-label .btn-inner--text:not(:last-child)
{
    padding-right: 3em;
}

.btn-app-store
{
    position: relative;

    padding-right: 1.5rem; 
    padding-left: 4.5rem;

    color: white;
    background: black;
}
.btn-app-store i,
.btn-app-store svg
{
    font-size: 2.5rem;

    position: absolute;
    top: 50%;
    left: 1.25rem;

    width: 1em;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.btn-app-store .btn-inner--text
{
    font-size: .75rem;
    line-height: .75rem;

    display: block;

    margin-bottom: .125rem;
}
.btn-app-store .btn-inner--brand
{
    font-size: 21px;
    line-height: 21px; 

    display: block;

    text-align: left;
}
.btn-app-store:hover
{
    color: #fff; 
    background-color: #0056e7;
}

.btn-group-options .btn
{
    display: flex;

    min-height: 4.8rem;

    align-items: center;
}

[data-toggle='buttons']:not(.btn-group-colors) > .btn
{
    margin: 0; 

    cursor: pointer;

    border: 0;
    background-color: #fafbfe;
    box-shadow: none;
}
[data-toggle='buttons']:not(.btn-group-colors) > .btn:not(.active)
{
    color: #8492a6;
}
[data-toggle='buttons']:not(.btn-group-colors) > .btn.active
{
    color: #fff; 
    background-color: #0c66ff;
}

.btn-group-colors > .btn
{
    position: relative; 

    width: 30px;
    height: 30px;
    margin-right: .5rem;
    margin-bottom: .25rem;
    padding: 0;

    border-radius: 50% !important;
    box-shadow: none;
}
.btn-group-colors > .btn:not([class*='bg-'])
{
    border-color: #fafbfe !important;
}
.btn-group-colors > .btn:before
{
    font-family: 'Font Awesome 5 Free';
    font-size: 12px;
    line-height: 28px;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    transition: opacity 200ms, -webkit-transform 200ms;
    transition: transform 200ms, opacity 200ms;
    transition: transform 200ms, opacity 200ms, -webkit-transform 200ms; 
    -webkit-transform: scale(0);
            transform: scale(0);

    opacity: 0;
    color: #fff;
}
@media (prefers-reduced-motion: reduce)
{
    .btn-group-colors > .btn:before
    {
        transition: none;
    }
}
.btn-group-colors > .btn.btn:not([class*='bg-'])
{
    border: 1px solid #d0d9e4;
}
.btn-group-colors > .btn.btn:not([class*='bg-']):before
{
    color: #8492a6;
}
.btn-group-colors > .btn.active:before
{
    -webkit-transform: scale(1);
            transform: scale(1);

    opacity: 1;
}

.card
{
    position: relative;

    margin-bottom: 30px;

    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}
.card.hover-scale-110:hover
{
    z-index: 2;

    border-radius: 1rem !important;
}
.card.rounded-top-right
{
    border-radius: 1rem 5rem 5rem 1rem 1rem !important;
}
.card.rounded-top-left
{
    border-radius: 5rem 1rem 1rem 1rem !important;
}
.card.rounded-bottom-right
{
    border-radius: 1rem 1rem 5rem 1rem !important;
}
.card.rounded-bottom-left
{
    border-radius: 1rem 1rem 1rem 5rem !important;
}
.card.rounded-diagonal-left
{
    border-radius: 5rem 1rem 5rem 1rem !important;
}
.card.rounded-diagonal-right
{
    border-radius: 1rem 5rem 1rem 5rem !important;
}

.card-fluid
{
    height: calc(100% - 30px);
}

.card-header
{
    padding-bottom: .625rem; 

    border-bottom: 0;
}
.card-header .action-item
{
    padding-top: 0;
    padding-bottom: 0;
}

.card-btn
{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.card-deck + .row
{
    margin-top: 30px;
}

.card-wrapper .card:last-child
{
    margin-bottom: 0 !important;
}

.card-img-bg
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: all .2s ease; 

    border-radius: 1rem;
    background-size: cover;

    -webkit-backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce)
{
    .card-img-bg
    {
        transition: none;
    }
}
.card-img-bg ~ .card-body
{
    position: relative;
}

.card:hover .card-img-bg
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.card .card-image,
.card .animate-this,
.card > a
{
    min-height: 1px;
}

.card-overlay .card-img-overlay
{
    color: #fff;
}

.card-overlay .card-footer
{
    border: 0; 
    background-color: #fff;
}

.card-hover-overlay
{
    overflow: hidden;
}
.card-hover-overlay .card-img-overlay
{
    transition: opacity .35s; 

    opacity: 0;
    background-color: rgba(39, 52, 68, .4);

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce)
{
    .card-hover-overlay .card-img-overlay
    {
        transition: none;
    }
}
.card-hover-overlay .card-img-overlay:before,
.card-hover-overlay .card-img-overlay:after
{
    pointer-events: none;
}
.card-hover-overlay .card-img-overlay .overlay-text,
.card-hover-overlay .card-img-overlay .overlay-actions,
.card-hover-overlay .card-img-overlay .actions .action-item
{
    transition: -webkit-transform .35s;
    transition:         transform .35s;
    transition:         transform .35s, -webkit-transform .35s; 
    -webkit-transform: translate3d(0, 200%, 0);
            transform: translate3d(0, 200%, 0);
}
@media (prefers-reduced-motion: reduce)
{
    .card-hover-overlay .card-img-overlay .overlay-text,
    .card-hover-overlay .card-img-overlay .overlay-actions,
    .card-hover-overlay .card-img-overlay .actions .action-item
    {
        transition: none;
    }
}
.card-hover-overlay:hover .card-img-overlay
{
    opacity: 1;
}
.card-hover-overlay:hover .card-img-overlay .overlay-text,
.card-hover-overlay:hover .card-img-overlay .overlay-actions,
.card-hover-overlay:hover .card-img-overlay .actions .action-item
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.card-hover-overlay:hover .card-img-overlay .actions .action-item:first-child
{
    transition-delay: .1s;
}
.card-hover-overlay:hover .card-img-overlay .actions .action-item:nth-child(2)
{
    transition-delay: .15s;
}
.card-hover-overlay:hover .card-img-overlay .actions .action-item:nth-child(3)
{
    transition-delay: .2s;
}

.card-group .card .mask
{
    border-radius: 0;
}

.card-columns-2
{
    -webkit-column-count: 2 !important;
       -moz-column-count: 2 !important;
            column-count: 2 !important;
}

@media (min-width: 992px)
{
    .card-columns-3
    {
        -webkit-column-count: 3 !important;
           -moz-column-count: 3 !important;
                column-count: 3 !important;
    }
}

@media (max-width: 1199.98px)
{
    .card-columns-3
    {
        -webkit-column-count: 2 !important;
           -moz-column-count: 2 !important;
                column-count: 2 !important;
    }
}

@media (max-width: 767.98px)
{
    .card-columns-3
    {
        -webkit-column-count: 1 !important;
           -moz-column-count: 1 !important;
                column-count: 1 !important;
    }
}

@media (min-width: 992px)
{
    .card-columns-4
    {
        -webkit-column-count: 4 !important;
           -moz-column-count: 4 !important;
                column-count: 4 !important;
    }
}

.card-pricing .card-header
{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.card-pricing .list-unstyled li
{
    padding: .5rem 0;
}

.card-pricing.popular
{
    z-index: 1;
}

.card-pricing.scale-110
{
    border-radius: 1rem !important;
}

@media (max-width: 991.98px)
{
    .card-pricing.scale-110
    {
        -webkit-transform: scale(1) !important;
                transform: scale(1) !important;
    }
}

.close > span:not(.sr-only)
{
    color: rgba(31, 45, 61, .5);
}

.close:hover > span:not(.sr-only),
.close:focus > span:not(.sr-only)
{
    color: rgba(31, 45, 61, .8);
}

.custom-control-label
{
    line-height: 1.8;
}

.checklist .custom-control-input:checked ~ .custom-control-label
{
    text-decoration: line-through;
}

.checklist .card
{
    margin-bottom: .5rem;
}
.checklist .card:last-child
{
    margin-bottom: 0;
}

.custom-color
{
    list-style: none;
}
.custom-color:after,
.custom-color:before
{
    display: table; 

    content: '';
}
.custom-color:after
{
    clear: both;
}
.custom-color label
{
    display: block;
    float: left;

    width: 2.25rem;
    height: 2.25rem;
    margin-right: .375rem;
    margin-bottom: 0;
    padding: .375rem;

    transition: all .2s ease;
    -webkit-transform: scale(.95);
            transform: scale(.95); 
    text-align: center;

    opacity: .7;
    border: 1px solid transparent;
    border-radius: .375rem;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-color label
    {
        transition: none;
    }
}
.custom-color label:hover
{
    cursor: pointer;

    opacity: 1;
}
.custom-color label i,
.custom-color label svg
{
    opacity: 0;
    color: rgba(255, 255, 255, .7);
}
.custom-color input
{
    position: absolute; 
    left: -9999px;
}
.custom-color input:checked + label
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

    opacity: 1;
}
.custom-color input:checked + label i,
.custom-color input:checked + label svg
{
    opacity: 1;
}

.custom-color-sm label
{
    width: 1.25rem;
    height: 1.25rem;
}

.checkbox-alphanumeric
{
    list-style: none;
}
.checkbox-alphanumeric + .checkbox-alphanumeric
{
    margin-left: .25rem;
}
.checkbox-alphanumeric:after,
.checkbox-alphanumeric:before
{
    display: table; 

    content: '';
}
.checkbox-alphanumeric:after
{
    clear: both;
}
.checkbox-alphanumeric > :not(:last-child) label
{
    margin-right: .375rem;
}
.checkbox-alphanumeric label
{
    font-size: .875rem;
    font-weight: 400;

    display: block;
    float: left;

    width: 2.25rem;
    height: 2.25rem;
    margin-bottom: 0;
    padding: .375rem 0;

    transition: all .3s ease;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    text-align: center;
    text-transform: uppercase;

    color: #c0ccda;
    border: 0 solid #eff2f7;
    border-radius: .375rem; 
    background: transparent;
    background: #e5e9f2;
}
.checkbox-alphanumeric label img
{
    max-width: 100%;
}
.checkbox-alphanumeric label:hover
{
    cursor: pointer;

    border-color: #e5e9f2;
}
.checkbox-alphanumeric input
{
    position: absolute; 
    left: -9999px;
}
.checkbox-alphanumeric input:checked ~ label
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 

    color: #0059f2;
    border-color: #0059f2;
}

.checkbox-alphanumeric-sm label
{
    width: 1.25rem;
    height: 1.25rem;
}

.custom-input-file
{
    position: absolute;
    z-index: -1; 

    overflow: hidden;

    width: .1px;
    height: .1px;

    opacity: 0;
    outline: none;
}
.custom-input-file + label
{
    display: block;
    overflow: hidden;

    margin: 0; 
    padding: .625rem 1.25rem;

    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #8492a6;
    border: 1px solid #e0e6ed;
    border-radius: .375rem;
    outline: none;
    background-color: #fff;
}
.custom-input-file + label i
{
    width: 1em;
    height: 1em;
    margin-top: -.25em;
    margin-right: .5em; 

    vertical-align: middle;

    fill: currentColor;
}
.custom-input-file + label:hover,
.custom-input-file.has-focus + label,
.custom-input-file:focus + label
{
    background-color: #fff;
}

.no-js .custom-input-file + label
{
    display: none;
}

/* Custom file input -- Style 2 */
.custom-input-file--2 + label
{
    color: #fff;
    border-color: #0c66ff;
    background: #0c66ff;
}

.custom-input-file--2 + label:hover,
.custom-input-file--2.has-focus + label,
.custom-input-file--2:focus + label
{
    color: #fff;
    border-color: #0050d8;
    background: #0050d8;
}

.custom-input-file-link + label
{
    font-size: .875rem;
    font-weight: 600; 

    padding: 0;

    color: #0c66ff;
    border: 0;
    background: transparent;
}

.custom-input-file-link + label:hover,
.custom-input-file-link.has-focus + label,
.custom-input-file-link:focus + label
{
    background-color: transparent;
}

.custom-switch
{
    min-height: 1.5rem;
}
.custom-switch .custom-control-label::before
{
    top: 0;

    height: 1.5rem;

    border-radius: .75rem;
}
.custom-switch .custom-control-label::after
{
    top: .1875rem;
    left: -3.5625rem;

    background-color: #fff;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after
{
    -webkit-transform: translateX(1.5rem);
            transform: translateX(1.5rem); 

    background-color: #fff;
}

.dropdown,
.dropup,
.dropright,
.dropleft
{
    display: inline-block;
}

.dropdown-menu
{
    font-size: .875rem;
}
.dropdown-menu .dropdown-item > i,
.dropdown-menu .dropdown-item > svg
{
    font-size: 1rem; 

    margin-right: 1rem;
}
.dropdown-menu .dropdown-item img,
.dropdown-menu .dropdown-item svg
{
    position: relative;
    top: -1px; 

    height: 14px;
    margin-right: .5rem;
}
.dropdown-menu .dropdown-item .icon-flag
{
    width: 19px;
}
.dropdown-menu .list-group-item .media p
{
    line-height: 1.4; 

    color: #8492a6;
}
.dropdown-menu .list-group-item .media:hover .heading
{
    color: #0c66ff;
}

.dropdown-menu-dark
{
    border: 0; 
    background: #282f37;
}
.dropdown-menu-dark .dropdown-item
{
    color: rgba(255, 255, 255, .9);
}
.dropdown-menu-dark .dropdown-item:active,
.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover
{
    color: white;
    background: rgba(60, 72, 88, .1);
}
.dropdown-menu-dark .dropdown-divider
{
    background: rgba(31, 45, 61, .2);
}

@media (min-width: 992px)
{
    .dropdown-menu-arrow:before
    {
        position: absolute;
        z-index: -5;
        bottom: 100%;
        left: 1.25rem;

        display: block;

        width: 16px;
        height: 16px;

        content: '';
        -webkit-transform: rotate(-45deg) translateY(1rem);
                transform: rotate(-45deg) translateY(1rem);

        border-radius: .2rem; 
        background: #fff;
        box-shadow: none;
    }
    .dropdown-menu-arrow[x-placement='bottom-end']:before
    {
        bottom: 100%;

        -webkit-transform: rotate(-45deg) translateY(1rem);
                transform: rotate(-45deg) translateY(1rem);
    }
    .dropdown-menu-arrow[x-placement='top-end']:before
    {
        bottom: 0;

        -webkit-transform: rotate(-45deg) translateY(.5rem);
                transform: rotate(-45deg) translateY(.5rem);
    }
    .dropdown-menu-arrow.dropdown-menu-right:before
    {
        right: 2.5rem;
        left: auto;
    }
    .dropdown-menu-sm
    {
        min-width: 10rem !important;

        border: .2rem;
    }
    .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before
    {
        right: 2rem;
        left: auto;
    }
    .dropdown-menu-md
    {
        min-width: 22rem !important;

        border-radius: .375rem;
    }
    .dropdown-menu-lg
    {
        min-width: 32rem !important;

        border-radius: .375rem;
    }
    .dropdown-menu-xl
    {
        min-width: 46rem !important;

        border-radius: .375rem;
    }
}

@media (min-width: 992px)
{
    .dropdown-fluid
    {
        position: static;
    }
    .dropdown-fluid .dropdown-menu
    {
        width: calc(100% - 30px);
        max-width: 1200px;
    }
    .dropdown-fluid .dropdown-menu
    {
        right: auto; 
        left: 15px;
    }
    .dropdown-fluid .dropdown-menu-right
    {
        right: 15px;
        left: auto;
    }
}

@media (min-width: 992px)
{
    .dropdown-animate > .dropdown-menu
    {
        position: absolute;

        display: block;

        pointer-events: none; 

        opacity: 0;
    }
    .dropdown-animate:not([data-toggle='hover']) .dropdown-menu.show
    {
        -webkit-animation: show-dropdown .3s ease forwards;
                animation: show-dropdown .3s ease forwards; 
        pointer-events: auto;
    }
    .dropdown-animate:not([data-toggle='hover']) .dropdown-menu.hide
    {
        display: block;

        -webkit-animation: hide-dropdown .3s ease backwards;
                animation: hide-dropdown .3s ease backwards;
    }
    .dropdown-animate[data-toggle='hover'] > .dropdown-menu
    {
        left: 50%;

        display: block;

        margin: 0;

        transition: all .2s ease; 
        -webkit-transform: translateX(-50%) translateY(-3px) scale(.97);
                transform: translateX(-50%) translateY(-3px) scale(.97);
    }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce)
{
    .dropdown-animate[data-toggle='hover'] > .dropdown-menu
    {
        transition: none;
    }
}

@media (min-width: 992px)
{
    .dropdown-animate[data-toggle='hover']:hover > .dropdown-menu
    {
        display: block;
        visibility: visible;

        -webkit-transform: translateX(-50%) translateY(-3px) scale(1);
                transform: translateX(-50%) translateY(-3px) scale(1); 
        pointer-events: auto;

        opacity: 1;
    }
}

@-webkit-keyframes show-dropdown
{
    0%
    {
        transition: all .3s ease; 
        -webkit-transform: translateX(0) translateY(-3px) scale(.97);
                transform: translateX(0) translateY(-3px) scale(.97);

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(0) translateY(-3px) scale(1);
                transform: translateX(0) translateY(-3px) scale(1);

        opacity: 1;
    }
}

@keyframes show-dropdown
{
    0%
    {
        transition: all .3s ease; 
        -webkit-transform: translateX(0) translateY(-3px) scale(.97);
                transform: translateX(0) translateY(-3px) scale(.97);

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(0) translateY(-3px) scale(1);
                transform: translateX(0) translateY(-3px) scale(1);

        opacity: 1;
    }
}

@-webkit-keyframes hide-dropdown
{
    from
    {
        transition: all .3s ease; 
        -webkit-transform: translateX(-16px) translateY(-3px) scale(1);
                transform: translateX(-16px) translateY(-3px) scale(1);

        opacity: 1;
    }
    to
    {
        -webkit-transform: translateX(-16px) translateY(-3px) scale(.97);
                transform: translateX(-16px) translateY(-3px) scale(.97); 

        opacity: 0;
    }
}

@keyframes hide-dropdown
{
    from
    {
        transition: all .3s ease; 
        -webkit-transform: translateX(-16px) translateY(-3px) scale(1);
                transform: translateX(-16px) translateY(-3px) scale(1);

        opacity: 1;
    }
    to
    {
        -webkit-transform: translateX(-16px) translateY(-3px) scale(.97);
                transform: translateX(-16px) translateY(-3px) scale(.97); 

        opacity: 0;
    }
}

.dropdown-menu .dropdown-menu
{
    top: 0; 
    right: auto;
    left: 100%;

    margin: 0;
}

.dropdown-submenu
{
    position: relative; 

    display: block;
}
.dropdown-submenu > .dropdown-menu::after
{
    border: 0 !important;
}
.dropdown-submenu > .dropdown-item::after,
.dropdown-submenu > .list-group-item::after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: 10.2px;

    content: '\f105'; 

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.dropdown-submenu > .dropdown-item:empty::after,
.dropdown-submenu > .list-group-item:empty::after
{
    margin-left: 0;
}
.dropdown-submenu > .dropdown-item:after,
.dropdown-submenu > .list-group-item:after
{
    font-size: .75rem;

    position: absolute;
    top: 50%;
    right: 1rem;

    margin-left: 0;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.dropdown-submenu[data-toggle='hover'] > .dropdown-menu
{
    left: 100%;

    -webkit-transform: translateX(0) translateY(-3px) scale(.97);
            transform: translateX(0) translateY(-3px) scale(.97);
}
.dropdown-submenu[data-toggle='hover']:hover > .dropdown-menu
{
    -webkit-transform: translateX(0) translateY(-3px) scale(1);
            transform: translateX(0) translateY(-3px) scale(1);
}

@media (max-width: 991.98px)
{
    .dropdown-submenu .dropdown-menu
    {
        top: 0; 

        padding: 0;

        box-shadow: none;
    }
    .dropdown-submenu .dropdown-item
    {
        padding-left: 3rem;
    }
}

.dropdown-extend
{
    position: static;
}
.dropdown-extend .dropdown-menu
{
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 576px)
{
    .dropdown-extend .dropdown-menu
    {
        max-width: 540px;
    }
}
@media (min-width: 768px)
{
    .dropdown-extend .dropdown-menu
    {
        max-width: 720px;
    }
}
@media (min-width: 992px)
{
    .dropdown-extend .dropdown-menu
    {
        max-width: 960px;
    }
}
@media (min-width: 1200px)
{
    .dropdown-extend .dropdown-menu
    {
        max-width: 1140px;
    }
}

.footer
{
    padding-top: 3rem; 

    background-color: #fff;
}
.footer .nav-link
{
    font-size: .875rem;
}
.footer .list-unstyled li a
{
    display: inline-block;

    padding: .125rem 0;
}

.footer-light p
{
    color: #8492a6;
}

.footer-light .heading
{
    color: #3c4858;
}

.footer-light .list-unstyled li a
{
    color: #8492a6;
}
.footer-light .list-unstyled li a:hover
{
    text-decoration: underline; 

    color: #8492a6;
}

.footer-light .copyright a
{
    color: #8492a6;
}
.footer-light .copyright a:hover
{
    color: #0c66ff;
}

.footer-dark p
{
    color: rgba(255, 255, 255, .8);
}

.footer-dark .heading
{
    color: white;
}

.footer-dark .list-unstyled li a
{
    color: rgba(255, 255, 255, .8);
}
.footer-dark .list-unstyled li a:hover
{
    color: rgba(255, 255, 255, .9);
}

.footer-dark .copyright a
{
    color: rgba(255, 255, 255, .9);
}
.footer-dark .copyright a:hover
{
    color: white;
}

.footer-dark
{
    color: #d3dce6; 
    background-color: #273444;
}
.footer-dark .heading
{
    color: #eff2f7;
}
.footer-dark .nav .nav-item .nav-link,
.footer-dark .list-unstyled li a,
.footer-dark .footer-link
{
    color: #d3dce6;
}
.footer-dark .nav .nav-item .nav-link:hover,
.footer-dark .list-unstyled li a:hover,
.footer-dark .footer-link:hover
{
    color: #fff;
}
.footer-dark hr
{
    border-color: rgba(216, 203, 187, .1);
}

.form-control:focus::-webkit-input-placeholder
{
    color: #d3dce6;
}

.form-control:focus::-moz-placeholder
{
    color: #d3dce6;
}

.form-control:focus:-ms-input-placeholder
{
    color: #d3dce6;
}

.form-control:focus::-ms-input-placeholder
{
    color: #d3dce6;
}

.form-control:focus::placeholder
{
    color: #d3dce6;
}

.form-control-muted
{
    border-color: #edf0f5; 
    background-color: #edf0f5;
}
.form-control-muted:focus
{
    background-color: #edf0f5;
}

.form-control-emphasized
{
    border-color: #fff;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
}
.form-control-emphasized:focus
{
    border-color: transparent;
    background-color: #fff;
    box-shadow: 0 0 0 2px #0c66ff;
}

.form-control-flush
{
    padding: 0;

    border-width: 0;
    background-color: transparent;
    box-shadow: none;
}
.form-control-flush:focus
{
    border-width: 0; 
    background-color: transparent;
    box-shadow: none;
}

textarea[resize='none']
{
    resize: none !important;
}

textarea[resize='both']
{
    resize: both !important;
}

textarea[resize='vertical']
{
    resize: vertical !important;
}

textarea[resize='horizontal']
{
    resize: horizontal !important;
}

.form-control-xl
{
    font-size: 1.5rem;
    line-height: 1.5;

    padding: 1.5rem 2rem;

    border-radius: .75rem;
}

.form-primary .form-control
{
    color: #fff;
    border: 1px solid #004cce; 
    background-color: #004cce;
}
.form-primary .form-control:focus
{
    border: 1px solid #0045b9; 
    background-color: #0045b9;
}
.form-primary .form-control::-webkit-input-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-primary .form-control::-moz-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-primary .form-control:-ms-input-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-primary .form-control::-ms-input-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-primary .form-control::placeholder
{
    color: rgba(255, 255, 255, .8);
}

.form-danger .form-control
{
    color: #fff;
    border: 1px solid #ff1f41; 
    background-color: #ff1f41;
}
.form-danger .form-control:focus
{
    border: 1px solid #ff0a30; 
    background-color: #ff0a30;
}
.form-danger .form-control::-webkit-input-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-danger .form-control::-moz-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-danger .form-control:-ms-input-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-danger .form-control::-ms-input-placeholder
{
    color: rgba(255, 255, 255, .8);
}
.form-danger .form-control::placeholder
{
    color: rgba(255, 255, 255, .8);
}

.form-control-label
{
    font-size: .675rem;
    font-weight: 600;

    text-transform: uppercase; 

    color: #3c4858;
}

.has-floating-label
{
    position: relative;
}

.has-floating-label .control-label
{
    font-size: 1rem;

    position: absolute;
    z-index: 1; 
    top: 50%;

    margin: 0 .625rem;
    padding: 0 .625rem;

    transition: .3s;
    -webkit-transform: translateY(-50%) scale(1);
            transform: translateY(-50%) scale(1);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    pointer-events: none;

    color: #aabacd;
}

.has-floating-label .input-group .control-label
{
    left: 2rem;
}

.has-floating-label.focused .control-label
{
    top: -1.25rem;
    left: 0;

    margin: 0;
    padding: 0;

    -webkit-transform: translateY(0) scale(.85);
            transform: translateY(0) scale(.85);
}

.has-floating-label .control-label-xl
{
    font-size: 1.5rem;

    margin: 0 1rem; 
    padding: 0 1rem;
}

.has-floating-label .control-label-lg
{
    font-size: 1.25rem;

    margin: 0 .9375rem; 
    padding: 0 .9375rem;
}

.has-floating-label .control-label-sm
{
    font-size: .875rem;

    margin: 0 .625rem; 
    padding: 0 .625rem;
}

.form-stacked .form-control
{
    position: relative;

    box-sizing: border-box;
}
.form-stacked .form-control:first-of-type
{
    margin-bottom: -1px;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-stacked .form-control:not(:first-of-type):not(:last-of-type)
{
    margin-bottom: -1px;

    border-radius: 0;
}
.form-stacked .form-control:last-of-type
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.form-stacked .form-control:focus
{
    z-index: 2;
}

#header-main + section
{
    padding-top: 7rem !important;
}

#header-main + .header-1
{
    padding-top: 14rem !important;
}

@media (min-width: 768px)
{
    .header-account-page
    {
        height: 350px;
    }
}

@media (max-width: 991.98px)
{
    .header-account-page
    {
        padding-top: 9rem;
    }
}

.header-account-page .btn-group-nav
{
    position: relative;
    z-index: 1;

    -webkit-transform: translateY(50%);
            transform: translateY(50%);
}

@media (min-width: 768px)
{
    .header-web-app .row:last-of-type
    {
        position: relative; 
        z-index: 2;

        margin-bottom: -12rem;
    }
    .header-web-app + section
    {
        padding-top: 12rem;
    }
}

.icon i,
.icon svg
{
    font-size: 1.71429rem;
}

.icon:not(.icon-shape) svg
{
    width: 3rem;
    height: 3rem;
}

.icon + .icon-text
{
    width: calc(100% - 3rem - 1); 
    padding-left: 1rem;
}

.icon-xl i,
.icon-xl svg
{
    font-size: 2.85714rem;
}

.icon-xl:not(.icon-shape) svg
{
    width: 5rem;
    height: 5rem;
}

.icon-xl + .icon-text
{
    width: calc(100% - 5rem - 1);
}

.icon-lg i,
.icon-lg svg
{
    font-size: 2.28571rem;
}

.icon-lg:not(.icon-shape) svg
{
    width: 4rem;
    height: 4rem;
}

.icon-lg + .icon-text
{
    width: calc(100% - 4rem - 1);
}

.icon-sm i,
.icon-sm svg
{
    font-size: 1.14286rem;
}

.icon-sm:not(.icon-shape) svg
{
    width: 2rem;
    height: 2rem;
}

.icon-sm + .icon-text
{
    width: calc(100% - 2rem - 1);
}

.icon-xs i,
.icon-xs svg
{
    font-size: .57143rem;
}

.icon-xs:not(.icon-shape) svg
{
    width: 1rem;
    height: 1rem;
}

.icon-xs + .icon-text
{
    width: calc(100% - 1rem - 1);
}

.icon-shape
{
    display: inline-flex;

    width: 3rem;
    height: 3rem; 

    text-align: center;
    vertical-align: middle;

    border-radius: .375rem;

    align-items: center;
    justify-content: center;
}
.icon-shape i,
.icon-shape svg
{
    font-size: 1.125rem;
}
.icon-shape.icon-xl
{
    width: 5rem;
    height: 5rem;
}
.icon-shape.icon-xl i,
.icon-shape.icon-xl svg
{
    font-size: 2.25rem;
}
.icon-shape.icon-lg
{
    width: 4rem;
    height: 4rem;
}
.icon-shape.icon-lg i,
.icon-shape.icon-lg svg
{
    font-size: 1.75rem;
}
.icon-shape.icon-sm
{
    width: 2rem;
    height: 2rem;
}
.icon-shape.icon-sm i,
.icon-shape.icon-sm svg
{
    font-size: 1rem;
}
.icon-shape.icon-xs
{
    width: 1rem;
    height: 1rem;
}
.icon-shape.icon-xs i,
.icon-shape.icon-xs svg
{
    font-size: .75rem;
}

.icon-primary
{
    color: #0c66ff;
    background-color: #cee0ff;
}

.icon-outline-primary
{
    color: #0048c4;
    border: 3px solid rgba(63, 134, 255, .5);
}

.icon-secondary
{
    color: #eff2f7;
    background-color: #fcfcfd;
}

.icon-outline-secondary
{
    color: #bdcbe2;
    border: 3px solid rgba(255, 255, 255, .5);
}

.icon-success
{
    color: #2dca8c;
    background-color: #d5f4e8;
}

.icon-outline-success
{
    color: #1c9464;
    border: 3px solid rgba(82, 216, 163, .5);
}

.icon-info
{
    color: #50b5ff;
    background-color: #dcf0ff;
}

.icon-outline-info
{
    color: #0997ff;
    border: 3px solid rgba(131, 203, 255, .5);
}

.icon-warning
{
    color: #ffbe3d;
    background-color: #fff2d8;
}

.icon-outline-warning
{
    color: #f5a300;
    border: 3px solid rgba(255, 207, 112, .5);
}

.icon-danger
{
    color: #ff5c75;
    background-color: #ffdee3;
}

.icon-outline-danger
{
    color: #ff1539;
    border: 3px solid rgba(255, 143, 160, .5);
}

.icon-light
{
    color: #eff2f7;
    background-color: #fcfcfd;
}

.icon-outline-light
{
    color: #bdcbe2;
    border: 3px solid rgba(255, 255, 255, .5);
}

.icon-dark
{
    color: #273444;
    background-color: #d4d6da;
}

.icon-outline-dark
{
    color: #0c1118;
    border: 3px solid rgba(58, 77, 100, .5);
}

.icon-neutral
{
    color: #fff;
    background-color: white;
}

.icon-outline-neutral
{
    color: #dddada;
    border: 3px solid rgba(255, 255, 255, .5);
}

.icon-white
{
    color: #fff;
    background-color: white;
}

.icon-outline-white
{
    color: #dddada;
    border: 3px solid rgba(255, 255, 255, .5);
}

.icon-actions > a
{
    font-size: .875rem; 

    display: inline-block;

    margin-right: .75rem;

    color: #c0ccda;
}
.icon-actions > a:last-of-type
{
    margin-right: 0;
}
.icon-actions > a span
{
    font-weight: 600;

    margin-left: .1875rem;

    color: #8492a6;
}
.icon-actions > a:hover span
{
    color: #68788f;
}

.icon-actions > a,
.icon-actions > a:hover,
.icon-actions > a.active
{
    color: #3c4858;
}

.icon-actions > .favorite:hover,
.icon-actions > .favorite.active
{
    color: #fc0;
}

.icon-actions > .love:hover,
.icon-actions > .love.active
{
    color: #ff5c75;
}

.icon-actions > .like:hover,
.icon-actions > .like.active
{
    color: #0c66ff;
}

.icon-actions-lg a
{
    font-size: 1.25rem;

    margin-right: .875rem;
}

.has-floating-items
{
    padding-bottom: 18rem !important;
}
.has-floating-items svg
{
    height: auto;
}

@media (min-width: 768px)
{
    .has-floating-items
    {
        padding-bottom: 18rem !important;
    }
}

.floating-items
{
    position: relative;
    z-index: 10; 
    left: 50%;

    margin-left: -570px;
}

.icon-floating
{
    position: absolute;

    text-align: center;

    border-radius: 50%; 
    box-shadow: 0 30px 40px 0 rgba(31, 45, 61, .1);
}
.icon-floating svg
{
    width: 50px;
}
.icon-floating span
{
    height: 100%;
}
.icon-floating img
{
    max-width: 100%;
}
.icon-floating span,
.icon-floating img
{
    display: inline-block;

    vertical-align: middle;
}

.icon-floating:nth-child(1)
{
    top: 140px;
    left: -60px;

    width: 120px;
    height: 120px;
}

.icon-floating:nth-child(2)
{
    top: 200px;
    left: 180px;

    width: 160px;
    height: 160px;

    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

.icon-floating:nth-child(3)
{
    top: 120px;
    left: 480px;

    width: 80px;
    height: 80px;

    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.icon-floating:nth-child(4)
{
    top: 210px;
    left: 680px;

    width: 140px;
    height: 140px;

    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}

.icon-floating:nth-child(5)
{
    top: 100px;
    left: 920px;

    width: 100px;
    height: 100px;

    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.icon-floating:nth-child(6)
{
    top: 200px;
    left: 1120px;

    width: 80px;
    height: 80px;

    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
}

.icon-floating.icon-sm svg
{
    width: 36px;
}

.icon-floating.icon-lg svg
{
    width: 76px;
}

@media (min-width: 992px) and (max-width: 1199.98px)
{
    .icon-floating:nth-child(1),
    .icon-floating:nth-child(6)
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .icon-floating:nth-child(1),
    .icon-floating:nth-child(2),
    .icon-floating:nth-child(3)
    {
        display: none;
    }
    .icon-floating:nth-child(4),
    .icon-floating:nth-child(5),
    .icon-floating:nth-child(6)
    {
        top: 80px; 

        width: 100px;
        height: 100px;
    }
    .icon-floating:nth-child(4)
    {
        left: 300px;
    }
    .icon-floating:nth-child(5)
    {
        left: 500px;
    }
    .icon-floating:nth-child(6)
    {
        left: 700px;
    }
    .icon-floating svg
    {
        width: 40px;
    }
}

@media (max-width: 767.98px)
{
    .icon-floating:nth-child(4),
    .icon-floating:nth-child(5),
    .icon-floating:nth-child(6)
    {
        top: 80px; 

        width: 80px;
        height: 80px;
    }
    .icon-floating:nth-child(4)
    {
        left: 400px;
    }
    .icon-floating:nth-child(5)
    {
        left: 530px;
    }
    .icon-floating:nth-child(6)
    {
        left: 660px;
    }
}

.floating-color
{
    position: absolute;

    -webkit-animation: floating 3s ease infinite;
            animation: floating 3s ease infinite; 
    text-align: center;

    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 30px 40px 0 rgba(0, 0, 0, .1);
}
.floating-color span
{
    font-size: .875rem;

    position: relative;
    top: 50%;

    display: inline-block;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    color: rgba(255, 255, 255, .5); 
    border-radius: 20px;
}

.floating-color:nth-child(1)
{
    top: 140px;
    left: -60px;

    width: 120px;
    height: 120px;
}

.floating-color:nth-child(2)
{
    top: 200px;
    left: 180px;

    width: 160px;
    height: 160px;

    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

.floating-color:nth-child(3)
{
    top: 120px;
    left: 480px;

    width: 80px;
    height: 80px;

    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.floating-color:nth-child(4)
{
    top: 210px;
    left: 680px;

    width: 140px;
    height: 140px;

    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}

.floating-color:nth-child(5)
{
    top: 100px;
    left: 920px;

    width: 100px;
    height: 100px;

    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.floating-color:nth-child(6)
{
    top: 200px;
    left: 1120px;

    width: 80px;
    height: 80px;

    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
}

.input-group-text
{
    transition: all .2s ease;
}

.input-group-flush .form-control,
.input-group-flush .input-group-text
{
    border: 0 !important;
}

.focused .input-group
{
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075), 0 0 20px rgba(12, 102, 255, .1);
}
.focused .input-group .form-control
{
    box-shadow: none !important;
}

.focused .input-group-text
{
    color: #0c66ff;
    border-color: rgba(12, 102, 255, .5); 
    background-color: #eff2f7;
}

.input-group-merge .input-group-text
{
    color: #aabacd;
    border: 1px solid #e0e6ed; 
    background-color: #fff;
}

.input-group-merge .form-control
{
    box-shadow: none;
}
.input-group-merge .form-control:not(:first-child):not(.is-valid):not(.is-invalid)
{
    padding-left: 0; 

    border-left: 0;
}
.input-group-merge .form-control:not(:last-child):not(.is-valid):not(.is-invalid)
{
    padding-right: 0; 

    border-right: 0;
}

.input-group-merge.rounded-pill .form-control:first-child
{
    padding-left: 1.5rem;

    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
}

.input-group-merge.rounded-pill .input-group-append > .btn
{
    padding-right: 1.5rem;

    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
}

.focused .input-group-merge .input-group-text
{
    border-color: rgba(12, 102, 255, .5); 
    background-color: #fff;
}

.jumbotron
{
    position: relative;
}
.jumbotron:not([class*='rounded-'])
{
    border-radius: 5rem;
}
.jumbotron:not([class*='rounded-']) > img
{
    border-radius: 5rem;
}
.jumbotron.rounded-right
{
    border-radius: 0 5rem 5rem 0 !important;
}
.jumbotron.rounded-left
{
    border-radius: 5rem 0 0 5rem !important;
}
.jumbotron.rounded-top-right
{
    border-radius: 0 5rem 0 0 !important;
}
.jumbotron.rounded-top-left
{
    border-radius: 5rem 0 0 0 !important;
}
.jumbotron.rounded-bottom-right
{
    border-radius: 0 0 5rem 0 !important;
}
.jumbotron.rounded-bottom-left
{
    border-radius: 0 0 0 5rem !important;
}
.jumbotron.rounded-diagonal-left
{
    border-radius: 5rem 0 5rem 0 !important;
}
.jumbotron.rounded-diagonal-right
{
    border-radius: 0 5rem 0 5rem !important;
}

.docs .header .navbar
{
    position: fixed;
    z-index: 1000;
}

@media (min-width: 576px)
{
    .docs .container-fluid
    {
        padding-right: 30px; 
        padding-left: 30px;
    }
}

@media (max-width: 767.98px)
{
    .docs .container-fluid
    {
        padding-right: 15px; 
        padding-left: 15px;
    }
}

.docs .container-docs
{
    display: flex;

    flex: 1 1;
    align-items: stretch;
}

.docs .main-content
{
    display: flex;

    min-width: 0;
    max-width: 100%;
    height: 100vh; 
    margin: 0;
    padding: 0;

    flex: 1 1 auto;
}
@media (min-width: 992px)
{
    .docs .main-content
    {
        padding-left: 250px;
    }
}
.docs .main-content .container,
.docs .main-content .container-fluid
{
    position: relative;
}

.docs .main-content-title + .row,
.docs .main-content-title + .card
{
    margin-top: 30px;
}

.docs .docs-content
{
    margin-top: 80px;
}
@media (min-width: 992px)
{
    .docs .docs-content
    {
        padding: 0 70px;
    }
}
@media (min-width: 768px)
{
    .docs .docs-content
    {
        padding: 0 50px;
    }
}
@media (max-width: 991.98px)
{
    .docs .docs-content
    {
        padding: 0 20px;
    }
}

.docs .docs-title
{
    margin: 2.5rem 0;
    padding: 2.5rem;

    border: 2px solid #eff2f7;
    border-radius: .5rem;
}

.docs .sidenav
{
    position: fixed;
    z-index: 900;
    top: 0;

    overflow: auto;

    height: 100vh;
    margin-bottom: 0;

    transition: all .2s ease; 

    border: 0;
    border-radius: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .docs .sidenav
    {
        transition: none;
    }
}
@media (min-width: 992px)
{
    .docs .sidenav
    {
        max-width: 250px !important;
        height: 100vh;

        -webkit-transform: translateX(0);
                transform: translateX(0);

        border-right: 1px solid #eff2f7;
        border-radius: 0;
    }
}
@media (max-width: 991.98px)
{
    .docs .sidenav
    {
        position: fixed;
        top: 0;
        left: 0;

        max-width: 0;
        height: 100%;

        -webkit-transform: translateX(-150%);
                transform: translateX(-150%);

        border: 0;
    }
}
.docs .sidenav.show
{
    z-index: 900;

    max-width: 250px !important;

    -webkit-transform: translateX(0);
            transform: translateX(0);

    border-right: 1px solid #eff2f7;
    border-radius: 0;
}

.docs .sidenav-header
{
    margin-bottom: 1.5rem; 
    padding: 1.5rem 0;
}
.docs .sidenav-header .navbar-brand img
{
    height: 1.5rem;
}

.docs .main-content-title + .nav-tabs
{
    margin-bottom: 30px;
}

.side-content:before
{
    position: absolute;
    z-index: -1;

    height: 100%;

    content: '';

    background-color: #fafbfe;
}
@media (min-width: 576px)
{
    .side-content:before
    {
        width: 2000px;

        border-radius: 4rem 0 0 4rem !important;
    }
}
@media (max-width: 575.98px)
{
    .side-content:before
    {
        left: 0; 

        width: 100%;
    }
}

@media (min-width: 576px)
{
    .side-content-inner
    {
        padding: 2rem 0 2.5rem 2.5rem;
    }
}

@media (max-width: 575.98px)
{
    .side-content-inner
    {
        padding: 2rem 0;
    }
}

.sidenav-toggler
{
    cursor: pointer; 

    border: 0;
    background: transparent;
}

.sidenav-toggler-inner,
.sidenav-toggler-line
{
    width: 18px;

    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .sidenav-toggler-inner,
    .sidenav-toggler-line
    {
        transition: none;
    }
}

.sidenav-toggler-inner
{
    position: relative;
}
.sidenav-toggler-inner:before
{
    position: absolute;
    top: -14px;
    left: -11px;

    width: 40px;
    height: 40px;

    content: '';
    transition: all .2s cubic-bezier(.77, 0, .2, 2.25); 
    -webkit-transform: scale(0);
            transform: scale(0);

    border-radius: 50%;
}
@media (prefers-reduced-motion: reduce)
{
    .sidenav-toggler-inner:before
    {
        transition: none;
    }
}

.sidenav-toggler-line
{
    position: relative; 

    display: block;

    height: 2px;

    background-color: rgba(31, 45, 61, .5);
}
.sidenav-toggler-line:not(:last-child)
{
    margin-bottom: 3px;
}

.sidenav-toggler.active .sidenav-toggler-inner:before
{
    -webkit-transform: scale(1);
            transform: scale(1);
}

.sidenav-toggler.active .sidenav-toggler-line:first-child
{
    width: 13px;

    -webkit-transform: translateX(5px);
            transform: translateX(5px);
}

.sidenav-toggler.active .sidenav-toggler-line:last-child
{
    width: 13px;

    -webkit-transform: translateX(5px);
            transform: translateX(5px);
}

.navbar-dark .sidenav-toggler-line,
.sidenav-toggler-dark .sidenav-toggler-line
{
    background-color: rgba(255, 255, 255, .85) !important;
}

.list-group
{
    list-style-type: none;
}

.list-group-space .list-group-item
{
    margin-bottom: 1.5rem;

    border-radius: .375rem;
}

.list-group-item
{
    font-size: .875rem;
}

.list-group-img
{
    width: 3rem;
    height: 3rem;
    margin: -.1rem 1.2rem 0 -.2rem; 

    vertical-align: top;

    border-radius: 50%;
}

.list-group-content
{
    min-width: 0; 

    flex: 1 1;
}
.list-group-content p
{
    line-height: 1.5;

    margin: .2rem 0 0;
}

.list-group-heading
{
    color: #3c4858;
}
.list-group-heading > small
{
    font-weight: 500; 

    float: right;

    color: #d3dce6;
}

.list-group-sm .list-group-item
{
    font-size: .875rem; 

    padding: .675rem 1.25rem;
}

.list-icon > i,
.list-icon > svg
{
    font-size: .875rem;

    position: relative; 

    margin-right: 1rem;
}

.list-bullet > i,
.list-bullet > svg
{
    font-size: .875rem;

    position: relative; 

    margin-right: 1rem;
}

.list-unstyled .list-link
{
    display: block;

    padding-top: .25rem;
    padding-bottom: .25rem; 

    color: #8492a6;
}
.list-unstyled .list-link:hover
{
    color: #0c66ff;
}
.list-unstyled .list-link > i
{
    font-size: .75rem; 

    margin-right: .5rem;
}

.map-canvas
{
    position: relative;

    width: 100%;
    height: 400px;

    border-radius: .375rem;
}

.mask
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .mask
    {
        transition: none;
    }
}
.mask + .container
{
    position: relative;
}

.card .mask
{
    border-radius: 1rem;
}

.card .hover-mask
{
    transition-delay: 150ms; 

    opacity: 0;
}

.card:hover .hover-mask
{
    opacity: .4;
}

.mask-body
{
    position: fixed;
    z-index: 1040; 
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
}

.mask-body-dark
{
    background: rgba(31, 45, 61, .3);
}

.mask-body-light
{
    background: rgba(255, 255, 255, .3);
}

.mask-fade-bottom
{
    background: linear-gradient(0deg, #1f2d3d 0%, black 0%, rgba(31, 45, 61, 0) 60%);
}

.media > i
{
    position: relative;
    top: 4px;
}

.media-pill
{
    position: relative;

    padding: 2px;
    padding-right: 1rem;

    transition: all .2s ease; 

    border-radius: 50rem;
    background-color: rgba(31, 45, 61, .15);
}
@media (prefers-reduced-motion: reduce)
{
    .media-pill
    {
        transition: none;
    }
}
.media-pill:hover
{
    background-color: rgba(31, 45, 61, .3);
}
.media-pill .avatar
{
    display: inline;
}

.show .media-pill
{
    background-color: rgba(31, 45, 61, .3);
}

.modal-content
{
    border: 0;
    border-radius: 1rem;
}

.modal-fluid .modal-dialog
{
    margin-top: 0;
    margin-bottom: 0;
}

.modal-fluid .modal-content
{
    border-radius: 0;
}

.modal-dialog-aside
{
    position: absolute;

    margin: 0;
}

.modal-primary .modal-title
{
    color: #fff;
}

.modal-primary .modal-header,
.modal-primary .modal-footer
{
    border-color: rgba(255, 255, 255, .075);
}

.modal-primary .modal-content
{
    color: #fff; 
    background-color: #0c66ff;
}
.modal-primary .modal-content .heading
{
    color: #fff;
}

.modal-primary .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-secondary .modal-title
{
    color: #273444;
}

.modal-secondary .modal-header,
.modal-secondary .modal-footer
{
    border-color: rgba(39, 52, 68, .075);
}

.modal-secondary .modal-content
{
    color: #273444; 
    background-color: #eff2f7;
}
.modal-secondary .modal-content .heading
{
    color: #273444;
}

.modal-secondary .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-success .modal-title
{
    color: #fff;
}

.modal-success .modal-header,
.modal-success .modal-footer
{
    border-color: rgba(255, 255, 255, .075);
}

.modal-success .modal-content
{
    color: #fff; 
    background-color: #2dca8c;
}
.modal-success .modal-content .heading
{
    color: #fff;
}

.modal-success .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-info .modal-title
{
    color: #fff;
}

.modal-info .modal-header,
.modal-info .modal-footer
{
    border-color: rgba(255, 255, 255, .075);
}

.modal-info .modal-content
{
    color: #fff; 
    background-color: #50b5ff;
}
.modal-info .modal-content .heading
{
    color: #fff;
}

.modal-info .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-warning .modal-title
{
    color: #273444;
}

.modal-warning .modal-header,
.modal-warning .modal-footer
{
    border-color: rgba(39, 52, 68, .075);
}

.modal-warning .modal-content
{
    color: #273444; 
    background-color: #ffbe3d;
}
.modal-warning .modal-content .heading
{
    color: #273444;
}

.modal-warning .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-danger .modal-title
{
    color: #fff;
}

.modal-danger .modal-header,
.modal-danger .modal-footer
{
    border-color: rgba(255, 255, 255, .075);
}

.modal-danger .modal-content
{
    color: #fff; 
    background-color: #ff5c75;
}
.modal-danger .modal-content .heading
{
    color: #fff;
}

.modal-danger .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-light .modal-title
{
    color: #273444;
}

.modal-light .modal-header,
.modal-light .modal-footer
{
    border-color: rgba(39, 52, 68, .075);
}

.modal-light .modal-content
{
    color: #273444; 
    background-color: #eff2f7;
}
.modal-light .modal-content .heading
{
    color: #273444;
}

.modal-light .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-dark .modal-title
{
    color: #fff;
}

.modal-dark .modal-header,
.modal-dark .modal-footer
{
    border-color: rgba(255, 255, 255, .075);
}

.modal-dark .modal-content
{
    color: #fff; 
    background-color: #273444;
}
.modal-dark .modal-content .heading
{
    color: #fff;
}

.modal-dark .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-neutral .modal-title
{
    color: #273444;
}

.modal-neutral .modal-header,
.modal-neutral .modal-footer
{
    border-color: rgba(39, 52, 68, .075);
}

.modal-neutral .modal-content
{
    color: #273444; 
    background-color: #fff;
}
.modal-neutral .modal-content .heading
{
    color: #273444;
}

.modal-neutral .close > span:not(.sr-only)
{
    color: #fff;
}

.modal-white .modal-title
{
    color: #273444;
}

.modal-white .modal-header,
.modal-white .modal-footer
{
    border-color: rgba(39, 52, 68, .075);
}

.modal-white .modal-content
{
    color: #273444; 
    background-color: #fff;
}
.modal-white .modal-content .heading
{
    color: #273444;
}

.modal-white .close > span:not(.sr-only)
{
    color: #fff;
}

.nav-link
{
    font-size: .875rem; 

    color: #8492a6;
}
.nav-link:hover,
.nav-link.active
{
    color: #0c66ff;
}

.nav-pills-icon .nav-link:not(.active)
{
    background-color: #eff2f7;
}

.nav-pills-icon .nav-link:not(:last-child)
{
    margin-right: .5rem;
}

@media (min-width: 768px)
{
    .nav-pills-circle
    {
        padding: 0 50px;
    }
    .nav-pills-circle .nav-item
    {
        position: relative; 

        min-width: 200px;
        padding: 0;

        border: 0;
        background: transparent;
    }
    .nav-pills-circle .nav-link
    {
        position: relative;
        z-index: 10; 

        display: flex;

        width: 80px;
        height: 80px;
        margin: auto;

        color: #273444;
        border-radius: 50%;
        background: #eff2f7;

        align-items: center;
        justify-content: center;
    }
    .nav-pills-circle.nav-pills-connect .nav-item:not(:last-child)::before
    {
        position: relative;
        z-index: 0; 
        top: 50%;
        left: 50%;

        display: block;

        width: 50%;
        height: 1px;

        content: '';
        -webkit-transform: translate(50%, 50%);
                transform: translate(50%, 50%);

        background: #eff2f7;
    }
}

.nav-pills-contained
{
    padding: 3px;

    border-radius: .375rem; 
    background-color: #eff2f7;
}
.nav-pills-contained .nav-link.active
{
    color: #8492a6; 
    background: #fff;
}

.nav-tabs .nav-item
{
    margin-right: 1rem; 
    margin-left: 1rem;
}

.nav-tabs .nav-link
{
    padding: 15px 0;

    border-top-width: 0; 
    border-right-width: 0;
    border-bottom: 1px solid transparent;
    border-left-width: 0;
}
.nav-tabs .nav-link:not(.active)
{
    color: #c0ccda;
}
.nav-tabs .nav-link:not(.active):hover
{
    color: #8492a6;
}

.nav-tabs .nav-item:first-child
{
    margin-left: 0;
}

.nav-tabs .nav-item:last-child
{
    margin-right: 0;
}

.nav-tabs .nav-item.show .nav-link
{
    border-color: transparent;
}

.nav-tabs.nav-dark
{
    border-color: rgba(255, 255, 255, .2);
}
.nav-tabs.nav-dark .nav-link:not(.active)
{
    color: rgba(255, 255, 255, .6);
}
.nav-tabs.nav-dark .nav-link:not(.active):hover
{
    color: rgba(255, 255, 255, .8);
}
.nav-tabs.nav-dark .nav-link.active
{
    color: #fff;
    border-color: #fff;
}

.navbar
{
    position: relative;
    z-index: 101;
}
.navbar .container
{
    position: relative;
}

.navbar-nav .nav-link
{
    font-size: 1rem;
    font-weight: 600;

    transition: all .15s linear; 
    letter-spacing: 0;
    text-transform: none;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-nav .nav-link
    {
        transition: none;
    }
}
.navbar-nav .nav-link i,
.navbar-nav .nav-link svg,
.navbar-nav .nav-link img
{
    margin-right: .5rem;
}

.navbar-nav .media-pill .avatar
{
    width: 36px;
    height: 36px;
}

.nav-link > img,
.nav-link > svg
{
    position: relative;
    top: -1px; 

    height: 14px;
    margin-right: .5rem;
}

.nav-link .icon-flag
{
    width: 19px;
}

.navbar-text
{
    font-size: 1rem;
}

.navbar-user
{
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;
}

.navbar-shadow
{
    box-shadow: 0 0 10px rgba(31, 45, 61, .03);
}

.header,
.navbar,
.navbar-top
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .header,
    .navbar,
    .navbar-top
    {
        transition: none;
    }
}

.navbar-border.navbar-dark
{
    border-bottom: 1px solid rgba(255, 255, 255, .1) !important;
}
.navbar-border.navbar-dark .navbar-collapse.collapsing,
.navbar-border.navbar-dark .navbar-collapse.show
{
    border-top: 1px solid rgba(255, 255, 255, .1) !important;
}

.navbar-border.navbar-light
{
    border-bottom: 1px solid rgba(31, 45, 61, .04) !important;
}
.navbar-border.navbar-light .navbar-collapse.collapsing,
.navbar-border.navbar-light .navbar-collapse.show
{
    border-top: 1px solid rgba(31, 45, 61, .04) !important;
}

.header-transparent
{
    position: absolute;
    z-index: 1030; 
    top: 0;
    left: 0;

    width: 100%;
}
.header-transparent .navbar-top
{
    padding-right: 1rem; 
    padding-left: 1rem;

    border: 0;
    background-color: transparent !important;
}
.header-transparent .navbar:not(.sticky)
{
    border: 0;
    background-color: transparent !important;
    box-shadow: none;
}
.header-transparent + main section:first-child:not([data-spotlight]) .container
{
    padding-top: 5rem;
}

.header-collapse-show
{
    z-index: 1000; 
    top: 0;

    width: 100%;
    min-height: 100%;
}

.navbar-sticky
{
    position: -webkit-sticky;
}
.navbar-sticky.sticky
{
    position: fixed;
    z-index: 1030;
    top: -100px;
    left: 0;

    width: 100%;

    transition: all .2s ease; 
    -webkit-transform: translateY(100px);
            transform: translateY(100px);

    border-top: 0;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-sticky.sticky
    {
        transition: none;
    }
}

.navbar-top
{
    position: relative;

    display: flex;

    align-items: center;
}
.navbar-top .navbar-nav
{
    flex-direction: row;
}
.navbar-top .navbar-nav .nav-item:last-child
{
    margin-right: 0;
}
.navbar-top .navbar-nav .nav-link
{
    padding: .5rem .5rem;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-sm .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-sm .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing,
    .navbar-expand-sm .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-sm .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-sm .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-sm .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-sm.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-sm.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-sm .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-sm .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-sm .navbar-collapse .collapse-header
    {
        display: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-md .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-md .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing,
    .navbar-expand-md .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-md .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-md .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-md .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-md.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-md.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-md .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-md .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-md .navbar-collapse .collapse-header
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-lg .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-lg .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing,
    .navbar-expand-lg .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-lg .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-lg .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-lg .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-lg.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-lg.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-lg .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-lg .navbar-collapse .collapse-header
    {
        display: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-xl .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-xl .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing,
    .navbar-expand-xl .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-xl .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-xl .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-xl .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-xl.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-xl.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-xl .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-xl .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-xl .navbar-collapse .collapse-header
    {
        display: none;
    }
}

.navbar-expand .navbar-brand img
{
    height: 1.5rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after
{
    font-size: 1rem;

    float: none;

    opacity: .6;
}

.navbar-expand .navbar-brand.dropdown-toggle:hover:after
{
    opacity: 1;
}

.navbar-expand .navbar-nav:not(.flex-row) .nav-link
{
    padding: .625rem 0;
}

.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu
{
    min-width: auto; 

    box-shadow: none;
}
.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu .media svg
{
    width: 30px;
}

.navbar-expand .navbar-nav.flex-row .nav-link
{
    padding-right: .675rem; 
    padding-left: .675rem;
}

.navbar-expand .navbar-nav.flex-row .dropdown
{
    position: static;
}

.navbar-expand .navbar-nav.flex-row .dropdown-menu
{
    position: absolute;
    top: 60px;
    right: auto; 
    left: 15px;

    width: calc(100% - 30px);
}

.navbar-expand .navbar-collapse-fade
{
    z-index: 1050;

    height: auto !important;

    opacity: 0;
}
.navbar-expand .navbar-collapse-fade.collapsing,
.navbar-expand .navbar-collapse-fade.show
{
    -webkit-animation: show-navbar-collapse .2s ease forwards;
            animation: show-navbar-collapse .2s ease forwards;
    -webkit-animation-delay: .15s;
            animation-delay: .15s;
}
.navbar-expand .navbar-collapse-fade.collapsing-out
{
    transition: opacity .3s linear;
    -webkit-animation: none;
            animation: none; 
    -webkit-animation-delay: 0;
            animation-delay: 0;

    opacity: 0;
}

.navbar-expand .navbar-collapse-overlay
{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    overflow-y: scroll;

    height: 100vh !important;

    transition: all .2s ease-in-out;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;

    opacity: 0;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

    -webkit-overflow-scrolling: touch;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .navbar-collapse-overlay
    {
        transition: none;
    }
}
.navbar-expand .navbar-collapse-overlay.show
{
    transition: all .2s ease-in-out; 
    -webkit-transform: scale(1);
            transform: scale(1);

    opacity: 1;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .navbar-collapse-overlay.show
    {
        transition: none;
    }
}
.navbar-expand .navbar-collapse-overlay .nav-item
{
    padding: 0 1.5rem;
}
.navbar-expand .navbar-collapse-overlay .collapse-header
{
    display: flex;

    padding: 1rem .5rem;
}
.navbar-expand .navbar-collapse-overlay .collapse-header .navbar-toggler
{
    margin-right: -1rem;
}

.navbar-expand.navbar-dark .navbar-collapse-overlay
{
    background-color: #273444;
}

.navbar-expand.navbar-light .navbar-collapse-overlay
{
    background-color: #fff;
}

.navbar-expand .navbar-brand img
{
    height: 2rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after
{
    display: none;
}

.navbar-expand .navbar-nav .nav-item
{
    margin-right: .5rem;
}
.navbar-expand .navbar-nav .nav-item:last-child
{
    margin-right: 0;
}

.navbar-expand .navbar-nav .nav-link
{
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
}

.navbar-expand .navbar-nav .nav-link-icon
{
    padding-right: .75rem;
    padding-left: .75rem;

    border-radius: 0;
}
.navbar-expand .navbar-nav .nav-link-icon i
{
    margin-right: 0;
}

.navbar-expand .navbar-collapse .collapse-header
{
    display: none;
}

.navbar-toggler-icon
{
    width: 1.25em;
    height: 1.25em;
}

@-webkit-keyframes show-navbar-collapse
{
    0%
    {
        -webkit-transform: translateX(30px);
                transform: translateX(30px); 

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0); 

        opacity: 1;
    }
}

@keyframes show-navbar-collapse
{
    0%
    {
        -webkit-transform: translateX(30px);
                transform: translateX(30px); 

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0); 

        opacity: 1;
    }
}

.pagination
{
    margin-bottom: 0;
}

.page-item .page-link,
.page-item > span
{
    margin: 0 3px;

    text-align: center; 

    border-radius: .2rem;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item > span
{
    display: block;

    width: 36px;
    height: 36px; 
    margin: 0 5px;

    border-radius: 50% !important;
}

.pagination-circle.pagination-lg .page-item .page-link,
.pagination-circle.pagination-lg .page-item > span
{
    display: block;

    width: 52px;
    height: 52px; 
    margin: 0 5px;

    border-radius: 50% !important;
}

.popover
{
    border: 0;
}

.popover-header
{
    font-weight: 600;
}

.popover-primary
{
    background-color: #0c66ff;
}
.popover-primary .popover-header
{
    color: #fff; 
    background-color: #0c66ff;
}
.popover-primary .popover-body
{
    color: #fff;
}
.popover-primary .popover-header
{
    border-color: rgba(255, 255, 255, .2);
}
.popover-primary.bs-popover-top .arrow::after,
.popover-primary.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #0c66ff;
}
.popover-primary.bs-popover-right .arrow::after,
.popover-primary.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #0c66ff;
}
.popover-primary.bs-popover-bottom .arrow::after,
.popover-primary.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #0c66ff;
}
.popover-primary.bs-popover-left .arrow::after,
.popover-primary.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #0c66ff;
}

.popover-secondary
{
    background-color: #eff2f7;
}
.popover-secondary .popover-header
{
    color: #273444; 
    background-color: #eff2f7;
}
.popover-secondary .popover-body
{
    color: #273444;
}
.popover-secondary .popover-header
{
    border-color: rgba(39, 52, 68, .2);
}
.popover-secondary.bs-popover-top .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #eff2f7;
}
.popover-secondary.bs-popover-right .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #eff2f7;
}
.popover-secondary.bs-popover-bottom .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #eff2f7;
}
.popover-secondary.bs-popover-left .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #eff2f7;
}

.popover-success
{
    background-color: #2dca8c;
}
.popover-success .popover-header
{
    color: #fff; 
    background-color: #2dca8c;
}
.popover-success .popover-body
{
    color: #fff;
}
.popover-success .popover-header
{
    border-color: rgba(255, 255, 255, .2);
}
.popover-success.bs-popover-top .arrow::after,
.popover-success.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #2dca8c;
}
.popover-success.bs-popover-right .arrow::after,
.popover-success.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #2dca8c;
}
.popover-success.bs-popover-bottom .arrow::after,
.popover-success.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #2dca8c;
}
.popover-success.bs-popover-left .arrow::after,
.popover-success.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #2dca8c;
}

.popover-info
{
    background-color: #50b5ff;
}
.popover-info .popover-header
{
    color: #fff; 
    background-color: #50b5ff;
}
.popover-info .popover-body
{
    color: #fff;
}
.popover-info .popover-header
{
    border-color: rgba(255, 255, 255, .2);
}
.popover-info.bs-popover-top .arrow::after,
.popover-info.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #50b5ff;
}
.popover-info.bs-popover-right .arrow::after,
.popover-info.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #50b5ff;
}
.popover-info.bs-popover-bottom .arrow::after,
.popover-info.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #50b5ff;
}
.popover-info.bs-popover-left .arrow::after,
.popover-info.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #50b5ff;
}

.popover-warning
{
    background-color: #ffbe3d;
}
.popover-warning .popover-header
{
    color: #273444; 
    background-color: #ffbe3d;
}
.popover-warning .popover-body
{
    color: #273444;
}
.popover-warning .popover-header
{
    border-color: rgba(39, 52, 68, .2);
}
.popover-warning.bs-popover-top .arrow::after,
.popover-warning.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #ffbe3d;
}
.popover-warning.bs-popover-right .arrow::after,
.popover-warning.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #ffbe3d;
}
.popover-warning.bs-popover-bottom .arrow::after,
.popover-warning.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #ffbe3d;
}
.popover-warning.bs-popover-left .arrow::after,
.popover-warning.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #ffbe3d;
}

.popover-danger
{
    background-color: #ff5c75;
}
.popover-danger .popover-header
{
    color: #fff; 
    background-color: #ff5c75;
}
.popover-danger .popover-body
{
    color: #fff;
}
.popover-danger .popover-header
{
    border-color: rgba(255, 255, 255, .2);
}
.popover-danger.bs-popover-top .arrow::after,
.popover-danger.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #ff5c75;
}
.popover-danger.bs-popover-right .arrow::after,
.popover-danger.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #ff5c75;
}
.popover-danger.bs-popover-bottom .arrow::after,
.popover-danger.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #ff5c75;
}
.popover-danger.bs-popover-left .arrow::after,
.popover-danger.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #ff5c75;
}

.popover-light
{
    background-color: #eff2f7;
}
.popover-light .popover-header
{
    color: #273444; 
    background-color: #eff2f7;
}
.popover-light .popover-body
{
    color: #273444;
}
.popover-light .popover-header
{
    border-color: rgba(39, 52, 68, .2);
}
.popover-light.bs-popover-top .arrow::after,
.popover-light.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #eff2f7;
}
.popover-light.bs-popover-right .arrow::after,
.popover-light.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #eff2f7;
}
.popover-light.bs-popover-bottom .arrow::after,
.popover-light.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #eff2f7;
}
.popover-light.bs-popover-left .arrow::after,
.popover-light.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #eff2f7;
}

.popover-dark
{
    background-color: #273444;
}
.popover-dark .popover-header
{
    color: #fff; 
    background-color: #273444;
}
.popover-dark .popover-body
{
    color: #fff;
}
.popover-dark .popover-header
{
    border-color: rgba(255, 255, 255, .2);
}
.popover-dark.bs-popover-top .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #273444;
}
.popover-dark.bs-popover-right .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #273444;
}
.popover-dark.bs-popover-bottom .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #273444;
}
.popover-dark.bs-popover-left .arrow::after,
.popover-dark.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #273444;
}

.popover-neutral
{
    background-color: #fff;
}
.popover-neutral .popover-header
{
    color: #273444; 
    background-color: #fff;
}
.popover-neutral .popover-body
{
    color: #273444;
}
.popover-neutral .popover-header
{
    border-color: rgba(39, 52, 68, .2);
}
.popover-neutral.bs-popover-top .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #fff;
}
.popover-neutral.bs-popover-right .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #fff;
}
.popover-neutral.bs-popover-bottom .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #fff;
}
.popover-neutral.bs-popover-left .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #fff;
}

.popover-white
{
    background-color: #fff;
}
.popover-white .popover-header
{
    color: #273444; 
    background-color: #fff;
}
.popover-white .popover-body
{
    color: #273444;
}
.popover-white .popover-header
{
    border-color: rgba(39, 52, 68, .2);
}
.popover-white.bs-popover-top .arrow::after,
.popover-white.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-top-color: #fff;
}
.popover-white.bs-popover-right .arrow::after,
.popover-white.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-right-color: #fff;
}
.popover-white.bs-popover-bottom .arrow::after,
.popover-white.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-bottom-color: #fff;
}
.popover-white.bs-popover-left .arrow::after,
.popover-white.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-left-color: #fff;
}

.progress-wrapper
{
    position: relative;

    padding-top: 1.5rem;
}
.progress-wrapper .progress
{
    margin-bottom: 1rem;
}

.progress-inverse
{
    background-color: inverse(#eff2f7);
}

.progress-heading
{
    font-size: .875rem;
    font-weight: 600;

    margin: 0 0 2px;
    padding: 0;
}

.progress-text
{
    margin-bottom: 0;
}

.progress-lg
{
    height: 1rem;
}

.progress-md
{
    height: .75rem;
}

.progress-sm
{
    height: .375rem;
}

.progress-xs
{
    height: .125rem;
}

.progress-group
{
    position: relative;
}

.progress-prepend-icon
{
    font-size: 20px;

    position: absolute;
    top: 50%; 

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.progress-prepend-icon:not(:first-child)
{
    right: -17px;
}
.progress-prepend-icon:not(:last-child)
{
    left: -17px;
}

.progress-tooltip
{
    font-size: .7rem;
    line-height: 1;

    position: relative;
    bottom: 8px;

    display: inline-block;

    margin-left: -15px; 
    padding: .25rem .375rem;

    color: #fff;
    border-radius: 3px;
    background: rgba(0, 0, 0, .8);
}
.progress-tooltip:after
{
    position: absolute;
    top: 100%;
    left: 10px;

    width: 0;
    height: 0;

    content: ' ';
    pointer-events: none;

    border: solid transparent;
    border-width: 5px; 
    border-color: rgba(51, 51, 51, 0);
    border-top-color: rgba(0, 0, 0, .8);
}

.progress-label
{
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;

    color: #3c4858;
}

.progress-percentage
{
    position: absolute;
    top: 0; 
    right: 0;

    display: inline-block;
}

.static-rating
{
    display: inline-block;
}
.static-rating .star
{
    color: #e0e6ed;
}
.static-rating .voted
{
    color: #fc0;
}

.static-rating-sm .star
{
    font-size: .75rem;
}

.rating
{
    display: inline-block;
}
.rating .star
{
    font-family: 'Font Awesome 5 Solid';
    font-weight: normal;
    font-style: normal;

    float: left;

    padding: 0 1px;

    cursor: pointer;
}
.rating .star:before
{
    font-size: 14px;

    display: block;

    content: '\f005';
    transition: all .2s linear; 

    color: #e0e6ed;
}
.rating:hover .star:before
{
    color: #e0e6ed;
}
.rating:hover .over:before
{
    color: #fc0;
}
.rating .voted:before
{
    color: #fc0;
}

.rating-lg .star:before
{
    font-size: 18px;
}

.rating-xl .star:before
{
    font-size: 24px;
}

.ribbon
{
    font-size: .75rem;
    font-weight: 600;

    position: absolute;
    z-index: 10; 
    top: 1rem;

    display: flex;

    width: 2.5rem;
    height: 2.5rem;

    align-items: center;
    justify-content: center;
}

.ribbon-left
{
    left: 1rem;
}

.ribbon-right
{
    right: 1rem;
}

.ribbon-primary
{
    color: #fff;
    background-color: #3f86ff;
}

.ribbon-secondary
{
    color: #273444;
    background-color: white;
}

.ribbon-success
{
    color: #fff;
    background-color: #52d8a3;
}

.ribbon-info
{
    color: #fff;
    background-color: #83cbff;
}

.ribbon-warning
{
    color: #273444;
    background-color: #ffcf70;
}

.ribbon-danger
{
    color: #fff;
    background-color: #ff8fa0;
}

.ribbon-light
{
    color: #273444;
    background-color: white;
}

.ribbon-dark
{
    color: #fff;
    background-color: #3a4d64;
}

.ribbon-neutral
{
    color: #273444;
    background-color: white;
}

.ribbon-white
{
    color: #273444;
    background-color: white;
}

.sidebar-sticky.is_stuck
{
    padding-top: 1.5rem;
}

.table thead th
{
    font-size: .75rem;

    padding-top: .75rem;
    padding-bottom: .75rem;

    letter-spacing: 0;
    text-transform: none;

    border-bottom-width: 1px; 
    background-color: transparent;
}

.table th
{
    font-weight: 600;
}

.table td .progress
{
    width: 120px;
    height: 3px;
    margin: 0;
}

.table td,
.table th
{
    font-size: .8125rem;

    white-space: nowrap;
}

.table.align-items-center td,
.table.align-items-center th
{
    vertical-align: middle;
}

.table.table-dark thead th,
.table .thead-dark th
{
    color: #708cad; 
    background-color: #2e3e51;
}
.table.table-dark thead th a,
.table .thead-dark th a
{
    color: #708cad;
}

.table .thead-light th
{
    color: #8492a6; 
    background-color: transparent;
}
.table .thead-light th a
{
    color: #8492a6;
}

.table-hover tr
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .table-hover tr
    {
        transition: none;
    }
}

.table-flush td,
.table-flush th
{
    border-right: 0; 
    border-left: 0;
}

.table-flush tbody tr:first-child td,
.table-flush tbody tr:first-child th
{
    border-top: 0;
}

.table-flush tbody tr:last-child td,
.table-flush tbody tr:last-child th
{
    border-bottom: 0;
}

.card .table
{
    margin-bottom: 0;
}
.card .table td,
.card .table th
{
    padding-right: 1.5rem; 
    padding-left: 1.5rem;
}

.table .custom-toggle
{
    display: block;
}

.table-flush th,
.table-flush td
{
    border-right: 0; 
    border-left: 0;
}

.table-flush tr:first-child th,
.table-flush tr:first-child td
{
    border-top: 0;
}

.table-flush tr:last-child th,
.table-flush tr:last-child td
{
    border-bottom: 0;
}

h1,
h2,
h3,
.h1,
.h2,
.h3
{
    font-weight: 400;
}

h4,
h5,
h6,
.h4,
.h5,
.h6
{
    font-weight: 600;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a
{
    color: #3c4858;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover,
.h5 a:hover,
.h6 a:hover
{
    color: #0c66ff;
}

a
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    a
    {
        transition: none;
    }
}

.surtitle
{
    font-size: .675rem;
    font-weight: 600;

    text-transform: uppercase; 

    color: #3c4858;
}

.fluid-paragraph
{
    position: relative; 

    width: 680px;
    margin: auto;
    padding: 0 1.5rem;
}

.fluid-paragraph-sm
{
    width: 580px;
}

@media (max-width: 767.98px)
{
    .fluid-paragraph
    {
        width: 100%;
    }
}

.text-highlight-primary
{
    background-image: linear-gradient(transparent 66%, rgba(12, 102, 255, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-secondary
{
    background-image: linear-gradient(transparent 66%, rgba(239, 242, 247, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-success
{
    background-image: linear-gradient(transparent 66%, rgba(45, 202, 140, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-info
{
    background-image: linear-gradient(transparent 66%, rgba(80, 181, 255, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-warning
{
    background-image: linear-gradient(transparent 66%, rgba(255, 190, 61, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-danger
{
    background-image: linear-gradient(transparent 66%, rgba(255, 92, 117, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-light
{
    background-image: linear-gradient(transparent 66%, rgba(239, 242, 247, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-dark
{
    background-image: linear-gradient(transparent 66%, rgba(39, 52, 68, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-neutral
{
    background-image: linear-gradient(transparent 66%, rgba(255, 255, 255, .15) 34%);
    background-repeat: repeat-x;
}

.text-highlight-white
{
    background-image: linear-gradient(transparent 66%, rgba(255, 255, 255, .15) 34%);
    background-repeat: repeat-x;
}

.link
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .link
    {
        transition: none;
    }
}

[class*='link-underline-']
{
    position: relative; 

    padding-bottom: 8px;
}
[class*='link-underline-']:after
{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 3px;

    content: '';
    transition: all .2s cubic-bezier(.77, 0, .2, 2.25); 

    border-radius: 50rem;
}
@media (prefers-reduced-motion: reduce)
{
    [class*='link-underline-']:after
    {
        transition: none;
    }
}
[class*='link-underline-']:hover:after
{
    left: -4px; 

    width: calc(100% + 8px);
}

.link-underline-primary
{
    color: #0c66ff;
}
.link-underline-primary:after
{
    background-color: #0c66ff;
}
.link-underline-primary:hover
{
    color: #0050d8;
}

.link-underline-secondary
{
    color: #eff2f7;
}
.link-underline-secondary:after
{
    background-color: #eff2f7;
}
.link-underline-secondary:hover
{
    color: #cdd6e6;
}

.link-underline-success
{
    color: #2dca8c;
}
.link-underline-success:after
{
    background-color: #2dca8c;
}
.link-underline-success:hover
{
    color: #24a06f;
}

.link-underline-info
{
    color: #50b5ff;
}
.link-underline-info:after
{
    background-color: #50b5ff;
}
.link-underline-info:hover
{
    color: #1d9fff;
}

.link-underline-warning
{
    color: #ffbe3d;
}
.link-underline-warning:after
{
    background-color: #ffbe3d;
}
.link-underline-warning:hover
{
    color: #ffad0a;
}

.link-underline-danger
{
    color: #ff5c75;
}
.link-underline-danger:after
{
    background-color: #ff5c75;
}
.link-underline-danger:hover
{
    color: #ff294a;
}

.link-underline-light
{
    color: #eff2f7;
}
.link-underline-light:after
{
    background-color: #eff2f7;
}
.link-underline-light:hover
{
    color: #cdd6e6;
}

.link-underline-dark
{
    color: #273444;
}
.link-underline-dark:after
{
    background-color: #273444;
}
.link-underline-dark:hover
{
    color: #141b24;
}

.link-underline-neutral
{
    color: #fff;
}
.link-underline-neutral:after
{
    background-color: #fff;
}
.link-underline-neutral:hover
{
    color: #e6e6e6;
}

.link-underline-white
{
    color: #fff;
}
.link-underline-white:after
{
    background-color: #fff;
}
.link-underline-white:hover
{
    color: #e6e6e6;
}

.blockquote .quote
{
    position: absolute;
}
.blockquote .quote:before
{
    font-family: Arial;
    font-size: 4em;
    line-height: 1; 

    content: '\201C';

    color: #0c66ff;
}

.blockquote .quote + .quote-text
{
    padding-left: 2.25rem;
}

.blockquote footer
{
    background: transparent;
}

.blockquote-border-left
{
    border-left: .5rem solid #0c66ff;
}

.blockquote-card
{
    position: relative; 

    padding: 1.2em 30px 1.2em 75px;

    border-left: .5rem solid #0c66ff;
}
.blockquote-card:before
{
    font-family: Arial;
    font-size: 4em;

    position: absolute;
    top: -.875rem; 
    left: .875rem;

    content: '\201C';

    color: #0c66ff;
}
.blockquote-card:after
{
    content: '';
}

.list-icons li i,
.list-icons li svg
{
    font-size: 8px;

    position: relative; 
    top: 2px;

    margin-right: 1rem;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

article h4:not(:first-child),
article h5:not(:first-child)
{
    margin-top: 3rem;
}

article h4,
article h5
{
    margin-bottom: 1.5rem;
}

article figure
{
    margin: 3rem 0;
}
article figure + h5
{
    margin-top: 0 !important;
}

article h5 + figure
{
    margin-top: 0;
}

article p
{
    line-height: 1.9;
}

.feather
{
    width: 1em;
    height: 1em;

    fill: none;
    stroke: currentcolor;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.code-blocks
{
    margin-top: 25px;
}

.section-webfont .section-footer
{
    margin-top: 90px; 

    text-align: center;
}

.webfont-step
{
    margin: 0 0 30px;
}

.webfont-step h4
{
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;

    margin: 0 0 24px; 

    color: #fff;
}

.webfont-step h4 strong,
.webfont-step h4 span
{
    font-weight: inherit; 

    display: inline-block;

    vertical-align: top;
}

.webfont-step h4 strong
{
    font-size: 16px;

    width: 36px;
    height: 36px;
    margin: 0 14px 0 0; 

    text-align: center;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, .1);
}

.webfont-step p
{
    font-size: 16px;
    line-height: 1.63;

    margin: 0; 

    color: #9ebef4;
}

.webfont-step p b
{
    font-weight: 500;

    color: #fff;
}

.code-blocks .code-block
{
    margin: 0 0 16px; 
    padding: 30px 32px;

    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 100px 75px 0 rgba(35, 44, 79, .08);
}

.code-blocks .code-block code
{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

    display: block;

    color: #134c81;
}

.code-blocks .code-block code + code
{
    margin-top: 24px;
}

.customizer
{
    position: fixed;
    z-index: 100; 
    bottom: 2rem;
    left: 2rem;

    width: 300px;
    height: 60px;
}
.customizer .btn-skins
{
    background: #6e00ff;
    background: linear-gradient(135deg, #6e00ff 0%, #00b8d9 18%, #00b8d9 18%, #36b37e 37%, #ff5630 50%, #ff5630 65%, #ffab00 83%, #ffab00 100%);
}

.card-translucent-dark
{
    background: rgba(255, 255, 255, .2);
}

.card-translucent-light
{
    background: rgba(0, 0, 0, .2);
}

.card-comment-box
{
    width: 100%;
}
.card-comment-box input,
.card-comment-box textarea
{
    font-size: .875rem;

    margin: 0;
    padding: .3125rem 0;

    resize: none; 

    color: #3c4858;
    border: 0;
    outline: none;
    background: transparent;
    box-shadow: none;
}
.card-comment-box input:focus,
.card-comment-box textarea:focus
{
    background: transparent;
    box-shadow: none;
}

.card-serial-number
{
    display: flex;

    justify-content: space-between;
}
.card-serial-number > div:not(:last-child)
{
    display: flex;

    flex: 1 1 auto;
}
.card-serial-number > div:not(:last-child):after
{
    position: relative;
    left: -2px; 

    content: '-';
    text-align: center;

    flex: 1 1 auto;
}
@media (max-width: 575.98px)
{
    .card-serial-number
    {
        font-size: 1.25rem;
    }
}

.card-profile .card-profile-image
{
    width: 7rem;
    height: 7rem;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); 

    border: .1875rem solid #fff;
}
.card-profile .card-profile-image:hover
{
    -webkit-transform: scale(1.1) translateY(-50%);
            transform: scale(1.1) translateY(-50%);
}

.card-profile .card-body
{
    position: relative;
    z-index: 2;

    margin-top: -3.5rem;
}

.card-profile-cover
{
    overflow: hidden; 

    max-height: 300px;
}

.card-product
{
    overflow: hidden;
}
.card-product .card-image
{
    padding: 1.5rem;
}
.card-product .card-image img
{
    transition: all .2s ease;
    transition-delay: .2s; 
    -webkit-transform: scale(1);
            transform: scale(1);
}
@media (prefers-reduced-motion: reduce)
{
    .card-product .card-image img
    {
        transition: none;
    }
}
.card-product:hover .card-image img
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.card-product .card-title
{
    font-size: 1rem;
    font-weight: 600;
}
.card-product .card-price
{
    font-size: 1rem;
    font-weight: 600;

    display: block;
}
.card-product .product-colors a
{
    display: inline-block;
    float: none !important;

    width: 1rem;
    height: 1rem;
    margin-right: 3px;
    margin-bottom: 0;

    transition: all .2s ease; 

    border-radius: 50%;
}
@media (prefers-reduced-motion: reduce)
{
    .card-product .product-colors a
    {
        transition: none;
    }
}
.card-product .product-colors a:hover
{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.card-product .card-product-actions
{
    position: absolute;
    top: 10px;
    left: 10px; 

    width: 80px;
    height: 40px;

    opacity: 0;
}
.card-product .card-product-actions.in
{
    opacity: 1;
}
.card-product .card-product-actions.animated
{
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
}
.card-product .card-product-actions .action-item
{
    line-height: 40px; 

    display: inline-block;
    float: left;

    width: 40px;
    height: 40px;
}

.card-browser
{
    position: relative; 

    background-color: #273444;
}
.card-browser:before
{
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    content: '';

    border-radius: 6px;
    background: -webkit-linear-gradient(40deg, transparent 0%, #273444 100%);

    will-change: transform;
}
.card-browser .card-header
{
    position: relative;

    border: 0; 
    background-color: rgba(58, 77, 100, .8);
}
.card-browser .card-header .dot
{
    display: inline-block;

    width: 8px;
    height: 8px;
    margin-right: 3px; 

    border-radius: 50%;
}
.card-browser .card-body
{
    position: relative;

    overflow-x: hidden; 

    min-height: 300px;
}
.card-browser .card-body pre
{
    margin: 0; 

    color: #fff;
}

.accordion .card
{
    margin-bottom: 0;
}

.accordion .card-header
{
    position: relative;

    cursor: pointer;
}
.accordion .card-header:after
{
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700; 

    position: absolute;
    top: 50%;
    right: 1.5rem;

    content: '+';
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.accordion .card-header[aria-expanded='false']:after
{
    content: '+';
}

.accordion .card-header[aria-expanded='true']:after
{
    content: '-';
}

.accordion .card-header[aria-expanded='true'] .heading
{
    color: #0c66ff;
}

.accordion-spaced .card
{
    margin-bottom: 1.5rem;

    border-radius: 1rem !important;
}

.client img
{
    width: 100%;
    max-width: 6rem;
    height: auto;
    margin-right: auto; 
    margin-left: auto;
}

.collage
{
    height: 600px;
}
.collage .bg-img-holder
{
    position: relative;
    z-index: 0; 

    width: 100%;
    height: 100%;
}
.collage .collage-bg
{
    z-index: 3;
}
.collage .collage-back
{
    position: absolute;
    z-index: 1; 
    top: 0;
    left: 0;
}
.collage .collage-back img
{
    max-width: 100%;
}
.collage .collage-front
{
    position: absolute;
    z-index: 2; 
    top: 0;
    left: 0;
}
.collage .collage-front img
{
    max-height: 100%;
}
@media (max-width: 991.98px)
{
    .collage .collage-front
    {
        height: 100% !important;
    }
}

.divider
{
    position: relative;

    width: 100%; 

    border: 0;
}

.divider-md
{
    width: 60%;
}

.divider-sm
{
    width: 40%;
}

.divider-icon
{
    height: 20px;

    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Ccircle fill=\'none\' stroke=\'%23dee4ef\' stroke-width=\'1\' cx=\'10\' cy=\'10\' r=\'7\'/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.divider-icon:before,
.divider-icon:after
{
    position: absolute;
    top: 50%;

    width: 100%;
    max-width: calc(50% - (50px / 2));

    content: '';

    border-bottom: 1px solid #dee4ef;
}
.divider-icon:before
{
    right: calc(50% + (50px / 2));
}
.divider-icon:after
{
    left: calc(50% + (50px / 2));
}

.divider-icon-fade:before,
.divider-icon-fade:after
{
    height: 1px; 

    border: 0;
}

.divider-icon-fade:before
{
    background: radial-gradient(ellipse at right, #dee4ef 0, rgba(255, 255, 255, 0) 75%);
}

.divider-icon-fade:after
{
    background: radial-gradient(ellipse at left, #dee4ef 0, rgba(255, 255, 255, 0) 75%);
}

.divider-fade:before
{
    position: relative;
    top: 0;
    left: 50%;

    display: block;

    width: 80%;
    height: 1px;

    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    background: radial-gradient(ellipse at center, #dee4ef 0, rgba(255, 255, 255, 0) 75%);
}

.divider-fade.divider-dark:before
{
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, .2) 0, rgba(31, 45, 61, 0) 75%);
}

.divider-vertical
{
    width: 1px;
    height: 100px;
    margin-right: auto;
    margin-left: auto;

    border-left: 1px solid #dee4ef;
}

.divider-vertical-fade
{
    border: 0;
    background: radial-gradient(ellipse at center, #dee4ef 0, rgba(255, 255, 255, 0) 75%);
}

.frame-laptop
{
    position: relative;
}
.frame-laptop .frame-inner
{
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;

    overflow: hidden;

    width: 74%;
    height: 82%;
    margin: 3% 13% 0 13%;
}

.frame-iphone
{
    position: relative;
    z-index: 100;
}
.frame-iphone .frame-inner
{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    overflow: hidden;

    width: 72%;
    height: 84%;
    margin: 8% 14% 0 14%;

    border-radius: 28px;
}

.list-group-emphasized .list-group-item
{
    transition: all .2s ease; 
    -webkit-transform: scale(1);
            transform: scale(1);
}
@media (prefers-reduced-motion: reduce)
{
    .list-group-emphasized .list-group-item
    {
        transition: none;
    }
}
.list-group-emphasized .list-group-item.active
{
    z-index: 11;

    -webkit-transform: scale(1.05);
            transform: scale(1.05);

    border-radius: .5rem; 
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
}
.list-group-emphasized .list-group-item.active .media a
{
    color: #0c66ff;
}

.media-comment + .media-comment
{
    margin-top: 1.25rem;
}

.media-comment-bubble
{
    position: relative;

    padding: 1rem 1.25rem; 

    border-radius: 20px;
    border-top-left-radius: 0;
    background-color: #f6f8fd;
}
.media-comment-bubble.left-top:after
{
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: -16px;

    width: 0;
    height: 0;

    content: ' ';

    border: 18px solid transparent;
    border-top-color: #f6f8fd;
}

.modal-vertical
{
    max-width: 350px;
    height: calc(100% - .5rem);
    margin: .5rem 0;
}
.modal-vertical.modal-lg
{
    max-width: 450px;
}
.modal-vertical .modal-content
{
    height: inherit;

    border-width: 0;
    border-radius: 1rem 0 0 1rem;
}
.modal-vertical .modal-body
{
    overflow-y: auto; 

    height: inherit;
}

.modal.fade .modal-dialog-vertical
{
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.modal.show .modal-dialog-vertical
{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.modal.fixed-right
{
    padding-right: 0 !important;
}
.modal.fixed-right .modal-vertical
{
    margin-left: auto;
}
.modal.fixed-right.fade .modal-vertical
{
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}
.modal.fixed-right.show .modal-vertical
{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.nav-dots
{
    position: fixed;
    z-index: 200;
    top: 50%;
    right: 2rem;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.nav-dots .nav-link
{
    position: relative; 

    display: block;

    margin: 5px;
}
.nav-dots .nav-link:before
{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;

    width: 6px;
    height: 6px;
    margin: -2px 0 0 -2px;

    content: '';
    transition: all .1s ease-in-out; 
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    border: 0;
    border-radius: 50%;
    background: #fff;
}
.nav-dots .nav-link:hover:before,
.nav-dots .nav-link.active:before
{
    width: 12px; 
    height: 12px;
}

.navbar
{
    position: relative;
    z-index: 101;
}
.navbar .container
{
    position: relative;
}

.navbar-nav .nav-link
{
    font-size: 1rem;
    font-weight: 600;

    transition: all .15s linear; 
    letter-spacing: 0;
    text-transform: none;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-nav .nav-link
    {
        transition: none;
    }
}
.navbar-nav .nav-link i,
.navbar-nav .nav-link svg,
.navbar-nav .nav-link img
{
    margin-right: .5rem;
}

.navbar-nav .media-pill .avatar
{
    width: 36px;
    height: 36px;
}

.nav-link > img,
.nav-link > svg
{
    position: relative;
    top: -1px; 

    height: 14px;
    margin-right: .5rem;
}

.nav-link .icon-flag
{
    width: 19px;
}

.navbar-text
{
    font-size: 1rem;
}

.navbar-user
{
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;
}

.navbar-shadow
{
    box-shadow: 0 0 10px rgba(31, 45, 61, .03);
}

.header,
.navbar,
.navbar-top
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .header,
    .navbar,
    .navbar-top
    {
        transition: none;
    }
}

.navbar-border.navbar-dark
{
    border-bottom: 1px solid rgba(255, 255, 255, .1) !important;
}
.navbar-border.navbar-dark .navbar-collapse.collapsing,
.navbar-border.navbar-dark .navbar-collapse.show
{
    border-top: 1px solid rgba(255, 255, 255, .1) !important;
}

.navbar-border.navbar-light
{
    border-bottom: 1px solid rgba(31, 45, 61, .04) !important;
}
.navbar-border.navbar-light .navbar-collapse.collapsing,
.navbar-border.navbar-light .navbar-collapse.show
{
    border-top: 1px solid rgba(31, 45, 61, .04) !important;
}

.header-transparent
{
    position: absolute;
    z-index: 1030; 
    top: 0;
    left: 0;

    width: 100%;
}
.header-transparent .navbar-top
{
    padding-right: 1rem; 
    padding-left: 1rem;

    border: 0;
    background-color: transparent !important;
}
.header-transparent .navbar:not(.sticky)
{
    border: 0;
    background-color: transparent !important;
    box-shadow: none;
}
.header-transparent + main section:first-child:not([data-spotlight]) .container
{
    padding-top: 5rem;
}

.header-collapse-show
{
    z-index: 1000; 
    top: 0;

    width: 100%;
    min-height: 100%;
}

.navbar-sticky
{
    position: -webkit-sticky;
}
.navbar-sticky.sticky
{
    position: fixed;
    z-index: 1030;
    top: -100px;
    left: 0;

    width: 100%;

    transition: all .2s ease; 
    -webkit-transform: translateY(100px);
            transform: translateY(100px);

    border-top: 0;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-sticky.sticky
    {
        transition: none;
    }
}

.navbar-top
{
    position: relative;

    display: flex;

    align-items: center;
}
.navbar-top .navbar-nav
{
    flex-direction: row;
}
.navbar-top .navbar-nav .nav-item:last-child
{
    margin-right: 0;
}
.navbar-top .navbar-nav .nav-link
{
    padding: .5rem .5rem;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-sm .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-sm .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing,
    .navbar-expand-sm .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-sm .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-sm .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-sm .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-sm.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-sm.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-sm .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-sm .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-sm .navbar-collapse .collapse-header
    {
        display: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-md .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-md .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing,
    .navbar-expand-md .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-md .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-md .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-md .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-md.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-md.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-md .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-md .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-md .navbar-collapse .collapse-header
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-lg .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-lg .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing,
    .navbar-expand-lg .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-lg .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-lg .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-lg .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-lg.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-lg.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-lg .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-lg .navbar-collapse .collapse-header
    {
        display: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-brand img
    {
        height: 1.5rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after
    {
        font-size: 1rem;

        float: none;

        opacity: .6;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:hover:after
    {
        opacity: 1;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .nav-link
    {
        padding: .625rem 0;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu
    {
        min-width: auto; 

        box-shadow: none;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu .media svg
    {
        width: 30px;
    }
    .navbar-expand-xl .navbar-nav.flex-row .nav-link
    {
        padding-right: .675rem; 
        padding-left: .675rem;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown
    {
        position: static;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown-menu
    {
        position: absolute;
        top: 60px;
        right: auto; 
        left: 15px;

        width: calc(100% - 30px);
    }
    .navbar-expand-xl .navbar-collapse-fade
    {
        z-index: 1050;

        height: auto !important;

        opacity: 0;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing,
    .navbar-expand-xl .navbar-collapse-fade.show
    {
        -webkit-animation: show-navbar-collapse .2s ease forwards;
                animation: show-navbar-collapse .2s ease forwards;
        -webkit-animation-delay: .15s;
                animation-delay: .15s;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing-out
    {
        transition: opacity .3s linear;
        -webkit-animation: none;
                animation: none; 
        -webkit-animation-delay: 0;
                animation-delay: 0;

        opacity: 0;
    }
    .navbar-expand-xl .navbar-collapse-overlay
    {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        overflow-y: scroll;

        height: 100vh !important;

        transition: all .2s ease-in-out;
        transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        transition-property: opacity,transform,-webkit-transform;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: bottom center;
                transform-origin: bottom center;

        opacity: 0;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .navbar-collapse-overlay
    {
        transition: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse-overlay.show
    {
        transition: all .2s ease-in-out; 
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .navbar-collapse-overlay.show
    {
        transition: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse-overlay .nav-item
    {
        padding: 0 1.5rem;
    }
    .navbar-expand-xl .navbar-collapse-overlay .collapse-header
    {
        display: flex;

        padding: 1rem .5rem;
    }
    .navbar-expand-xl .navbar-collapse-overlay .collapse-header .navbar-toggler
    {
        margin-right: -1rem;
    }
    .navbar-expand-xl.navbar-dark .navbar-collapse-overlay
    {
        background-color: #273444;
    }
    .navbar-expand-xl.navbar-light .navbar-collapse-overlay
    {
        background-color: #fff;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl .navbar-brand img
    {
        height: 2rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after
    {
        display: none;
    }
    .navbar-expand-xl .navbar-nav .nav-item
    {
        margin-right: .5rem;
    }
    .navbar-expand-xl .navbar-nav .nav-item:last-child
    {
        margin-right: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon
    {
        padding-right: .75rem;
        padding-left: .75rem;

        border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon i
    {
        margin-right: 0;
    }
    .navbar-expand-xl .navbar-collapse .collapse-header
    {
        display: none;
    }
}

.navbar-expand .navbar-brand img
{
    height: 1.5rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after
{
    font-size: 1rem;

    float: none;

    opacity: .6;
}

.navbar-expand .navbar-brand.dropdown-toggle:hover:after
{
    opacity: 1;
}

.navbar-expand .navbar-nav:not(.flex-row) .nav-link
{
    padding: .625rem 0;
}

.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu
{
    min-width: auto; 

    box-shadow: none;
}
.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu .media svg
{
    width: 30px;
}

.navbar-expand .navbar-nav.flex-row .nav-link
{
    padding-right: .675rem; 
    padding-left: .675rem;
}

.navbar-expand .navbar-nav.flex-row .dropdown
{
    position: static;
}

.navbar-expand .navbar-nav.flex-row .dropdown-menu
{
    position: absolute;
    top: 60px;
    right: auto; 
    left: 15px;

    width: calc(100% - 30px);
}

.navbar-expand .navbar-collapse-fade
{
    z-index: 1050;

    height: auto !important;

    opacity: 0;
}
.navbar-expand .navbar-collapse-fade.collapsing,
.navbar-expand .navbar-collapse-fade.show
{
    -webkit-animation: show-navbar-collapse .2s ease forwards;
            animation: show-navbar-collapse .2s ease forwards;
    -webkit-animation-delay: .15s;
            animation-delay: .15s;
}
.navbar-expand .navbar-collapse-fade.collapsing-out
{
    transition: opacity .3s linear;
    -webkit-animation: none;
            animation: none; 
    -webkit-animation-delay: 0;
            animation-delay: 0;

    opacity: 0;
}

.navbar-expand .navbar-collapse-overlay
{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    overflow-y: scroll;

    height: 100vh !important;

    transition: all .2s ease-in-out;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;

    opacity: 0;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08); 

    -webkit-overflow-scrolling: touch;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .navbar-collapse-overlay
    {
        transition: none;
    }
}
.navbar-expand .navbar-collapse-overlay.show
{
    transition: all .2s ease-in-out; 
    -webkit-transform: scale(1);
            transform: scale(1);

    opacity: 1;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .navbar-collapse-overlay.show
    {
        transition: none;
    }
}
.navbar-expand .navbar-collapse-overlay .nav-item
{
    padding: 0 1.5rem;
}
.navbar-expand .navbar-collapse-overlay .collapse-header
{
    display: flex;

    padding: 1rem .5rem;
}
.navbar-expand .navbar-collapse-overlay .collapse-header .navbar-toggler
{
    margin-right: -1rem;
}

.navbar-expand.navbar-dark .navbar-collapse-overlay
{
    background-color: #273444;
}

.navbar-expand.navbar-light .navbar-collapse-overlay
{
    background-color: #fff;
}

.navbar-expand .navbar-brand img
{
    height: 2rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after
{
    display: none;
}

.navbar-expand .navbar-nav .nav-item
{
    margin-right: .5rem;
}
.navbar-expand .navbar-nav .nav-item:last-child
{
    margin-right: 0;
}

.navbar-expand .navbar-nav .nav-link
{
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
}

.navbar-expand .navbar-nav .nav-link-icon
{
    padding-right: .75rem;
    padding-left: .75rem;

    border-radius: 0;
}
.navbar-expand .navbar-nav .nav-link-icon i
{
    margin-right: 0;
}

.navbar-expand .navbar-collapse .collapse-header
{
    display: none;
}

.navbar-toggler-icon
{
    width: 1.25em;
    height: 1.25em;
}

@keyframes show-navbar-collapse
{
    0%
    {
        -webkit-transform: translateX(30px);
                transform: translateX(30px); 

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0); 

        opacity: 1;
    }
}

.navbar-vertical
{
    padding-top: 0;

    border-width: 0 0 1px 0;
    border-style: solid;
    box-shadow: none !important;
}
.navbar-vertical.navbar-light
{
    border-color: #eff2f7; 
    background-color: #fff;
}
.navbar-vertical.navbar-dark
{
    border-color: rgba(255, 255, 255, .1); 
    background-color: #273444;
}
.navbar-vertical .navbar-brand
{
    margin-right: 0;
}
@media (min-width: 768px)
{
    .navbar-vertical .navbar-collapse
    {
        margin-right: -1rem; 
        margin-left: -1rem;
    }
}
.navbar-vertical .navbar-nav
{
    margin-right: -1rem; 
    margin-left: -1rem;
}
.navbar-vertical .navbar-nav .nav-link
{
    font-size: 1rem;
    font-weight: 600; 

    padding-right: 1rem;
    padding-left: 1rem;
}
.navbar-vertical .navbar-nav .nav-link.active
{
    position: relative;
}
.navbar-vertical .navbar-nav .nav-link > i
{
    font-size: .9375rem;
    line-height: 1.7rem; 

    min-width: 2rem;
    margin-right: 0;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu
{
    border: none;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu
{
    margin-left: .5rem;
}
.navbar-vertical .navbar-nav .nav-sm .nav-link
{
    font-size: .8125rem;
}
.navbar-vertical .navbar-nav .nav-link
{
    display: flex;

    align-items: center;
}
.navbar-vertical .navbar-nav .nav-link[data-toggle='collapse']:after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-style: normal;
    font-variant: normal;

    display: inline-block;

    margin-left: auto;

    content: '\f105';
    transition: all .2s ease; 

    color: #e0e6ed;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-vertical .navbar-nav .nav-link[data-toggle='collapse']:after
    {
        transition: none;
    }
}
.navbar-vertical .navbar-nav .nav-link[data-toggle='collapse'][aria-expanded='true']:after
{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); 

    color: #0c66ff;
}
.navbar-vertical .navbar-nav .nav .nav-link
{
    padding-left: 3rem;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link
{
    padding-left: 3.5rem;
}
.navbar-vertical .navbar-heading
{
    font-size: .75rem;

    padding-top: .25rem;
    padding-bottom: .25rem;

    letter-spacing: .04em; 
    text-transform: uppercase;
}
.navbar-vertical.navbar-expand-xs
{
    display: block;
    overflow-y: auto;

    width: 100%;
    max-width: 0;
    padding-right: 0; 
    padding-left: 0;
}
.navbar-vertical.navbar-expand-xs.fixed-left,
.navbar-vertical.navbar-expand-xs.fixed-right
{
    position: fixed;
    top: 0;
    bottom: 0;
}
.navbar-vertical.navbar-expand-xs .navbar-inner
{
    padding-right: 1.5rem; 
    padding-left: 1.5rem;
}
.navbar-vertical.navbar-expand-xs > [class*='container']
{
    flex-direction: column;

    min-height: 100%;
    padding-right: 0; 
    padding-left: 0;

    align-items: stretch;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
{
    .navbar-vertical.navbar-expand-xs > [class*='container']
    {
        height: 100%; 
        min-height: none;
    }
}
.navbar-vertical.navbar-expand-xs.fixed-left
{
    left: 0;

    border-width: 0 1px 0 0;
}
.navbar-vertical.navbar-expand-xs.fixed-right
{
    right: 0;

    border-width: 0 0 0 1px;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse
{
    display: flex;
    flex-direction: column;

    margin-right: -1.5rem;
    margin-left: -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    opacity: 1; 

    flex: 1 1;
    align-items: stretch;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse > *
{
    min-width: 100%;
}
.navbar-vertical.navbar-expand-xs .navbar-nav
{
    flex-direction: column;

    margin-right: -1.5rem; 
    margin-left: -1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link
{
    padding: .375rem 1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item
{
    margin-top: 2px;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active
{
    margin-right: .5rem;
    margin-left: .5rem;
    padding-right: 1rem;
    padding-left: 1rem;

    border-radius: .375rem; 
    background: #fafbfe;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link
{
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: 3.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav-link
{
    padding-left: 4.25rem;
}
@media (min-width: 576px)
{
    .navbar-vertical.navbar-expand-sm
    {
        display: block;
        overflow-y: auto;

        width: 100%;
        max-width: 0;
        padding-right: 0; 
        padding-left: 0;
    }
    .navbar-vertical.navbar-expand-sm.fixed-left,
    .navbar-vertical.navbar-expand-sm.fixed-right
    {
        position: fixed;
        top: 0;
        bottom: 0;
    }
    .navbar-vertical.navbar-expand-sm .navbar-inner
    {
        padding-right: 1.5rem; 
        padding-left: 1.5rem;
    }
    .navbar-vertical.navbar-expand-sm > [class*='container']
    {
        flex-direction: column;

        min-height: 100%;
        padding-right: 0; 
        padding-left: 0;

        align-items: stretch;
    }
}
@media all and (min-width: 576px) and (-ms-high-contrast: none), (min-width: 576px) and (-ms-high-contrast: active)
{
    .navbar-vertical.navbar-expand-sm > [class*='container']
    {
        height: 100%; 
        min-height: none;
    }
}
@media (min-width: 576px)
{
    .navbar-vertical.navbar-expand-sm.fixed-left
    {
        left: 0;

        border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-sm.fixed-right
    {
        right: 0;

        border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse
    {
        display: flex;
        flex-direction: column;

        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        opacity: 1; 

        flex: 1 1;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse > *
    {
        min-width: 100%;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav
    {
        flex-direction: column;

        margin-right: -1.5rem; 
        margin-left: -1.5rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link
    {
        padding: .375rem 1.5rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav > .nav-item
    {
        margin-top: 2px;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav > .nav-item > .nav-link.active
    {
        margin-right: .5rem;
        margin-left: .5rem;
        padding-right: 1rem;
        padding-left: 1rem;

        border-radius: .375rem; 
        background: #fafbfe;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link
    {
        padding-top: .25rem;
        padding-bottom: .25rem;
        padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav-link
    {
        padding-left: 4.25rem;
    }
}
@media (min-width: 768px)
{
    .navbar-vertical.navbar-expand-md
    {
        display: block;
        overflow-y: auto;

        width: 100%;
        max-width: 0;
        padding-right: 0; 
        padding-left: 0;
    }
    .navbar-vertical.navbar-expand-md.fixed-left,
    .navbar-vertical.navbar-expand-md.fixed-right
    {
        position: fixed;
        top: 0;
        bottom: 0;
    }
    .navbar-vertical.navbar-expand-md .navbar-inner
    {
        padding-right: 1.5rem; 
        padding-left: 1.5rem;
    }
    .navbar-vertical.navbar-expand-md > [class*='container']
    {
        flex-direction: column;

        min-height: 100%;
        padding-right: 0; 
        padding-left: 0;

        align-items: stretch;
    }
}
@media all and (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active)
{
    .navbar-vertical.navbar-expand-md > [class*='container']
    {
        height: 100%; 
        min-height: none;
    }
}
@media (min-width: 768px)
{
    .navbar-vertical.navbar-expand-md.fixed-left
    {
        left: 0;

        border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-md.fixed-right
    {
        right: 0;

        border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse
    {
        display: flex;
        flex-direction: column;

        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        opacity: 1; 

        flex: 1 1;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse > *
    {
        min-width: 100%;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav
    {
        flex-direction: column;

        margin-right: -1.5rem; 
        margin-left: -1.5rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link
    {
        padding: .375rem 1.5rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav > .nav-item
    {
        margin-top: 2px;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav > .nav-item > .nav-link.active
    {
        margin-right: .5rem;
        margin-left: .5rem;
        padding-right: 1rem;
        padding-left: 1rem;

        border-radius: .375rem; 
        background: #fafbfe;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link
    {
        padding-top: .25rem;
        padding-bottom: .25rem;
        padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav-link
    {
        padding-left: 4.25rem;
    }
}
@media (min-width: 992px)
{
    .navbar-vertical.navbar-expand-lg
    {
        display: block;
        overflow-y: auto;

        width: 100%;
        max-width: 0;
        padding-right: 0; 
        padding-left: 0;
    }
    .navbar-vertical.navbar-expand-lg.fixed-left,
    .navbar-vertical.navbar-expand-lg.fixed-right
    {
        position: fixed;
        top: 0;
        bottom: 0;
    }
    .navbar-vertical.navbar-expand-lg .navbar-inner
    {
        padding-right: 1.5rem; 
        padding-left: 1.5rem;
    }
    .navbar-vertical.navbar-expand-lg > [class*='container']
    {
        flex-direction: column;

        min-height: 100%;
        padding-right: 0; 
        padding-left: 0;

        align-items: stretch;
    }
}
@media all and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (-ms-high-contrast: active)
{
    .navbar-vertical.navbar-expand-lg > [class*='container']
    {
        height: 100%; 
        min-height: none;
    }
}
@media (min-width: 992px)
{
    .navbar-vertical.navbar-expand-lg.fixed-left
    {
        left: 0;

        border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-lg.fixed-right
    {
        right: 0;

        border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse
    {
        display: flex;
        flex-direction: column;

        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        opacity: 1; 

        flex: 1 1;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse > *
    {
        min-width: 100%;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav
    {
        flex-direction: column;

        margin-right: -1.5rem; 
        margin-left: -1.5rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link
    {
        padding: .375rem 1.5rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav > .nav-item
    {
        margin-top: 2px;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav > .nav-item > .nav-link.active
    {
        margin-right: .5rem;
        margin-left: .5rem;
        padding-right: 1rem;
        padding-left: 1rem;

        border-radius: .375rem; 
        background: #fafbfe;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link
    {
        padding-top: .25rem;
        padding-bottom: .25rem;
        padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav-link
    {
        padding-left: 4.25rem;
    }
}
@media (min-width: 1200px)
{
    .navbar-vertical.navbar-expand-xl
    {
        display: block;
        overflow-y: auto;

        width: 100%;
        max-width: 0;
        padding-right: 0; 
        padding-left: 0;
    }
    .navbar-vertical.navbar-expand-xl.fixed-left,
    .navbar-vertical.navbar-expand-xl.fixed-right
    {
        position: fixed;
        top: 0;
        bottom: 0;
    }
    .navbar-vertical.navbar-expand-xl .navbar-inner
    {
        padding-right: 1.5rem; 
        padding-left: 1.5rem;
    }
    .navbar-vertical.navbar-expand-xl > [class*='container']
    {
        flex-direction: column;

        min-height: 100%;
        padding-right: 0; 
        padding-left: 0;

        align-items: stretch;
    }
}
@media all and (min-width: 1200px) and (-ms-high-contrast: none), (min-width: 1200px) and (-ms-high-contrast: active)
{
    .navbar-vertical.navbar-expand-xl > [class*='container']
    {
        height: 100%; 
        min-height: none;
    }
}
@media (min-width: 1200px)
{
    .navbar-vertical.navbar-expand-xl.fixed-left
    {
        left: 0;

        border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-xl.fixed-right
    {
        right: 0;

        border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse
    {
        display: flex;
        flex-direction: column;

        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        opacity: 1; 

        flex: 1 1;
        align-items: stretch;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse > *
    {
        min-width: 100%;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav
    {
        flex-direction: column;

        margin-right: -1.5rem; 
        margin-left: -1.5rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link
    {
        padding: .375rem 1.5rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav > .nav-item
    {
        margin-top: 2px;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav > .nav-item > .nav-link.active
    {
        margin-right: .5rem;
        margin-left: .5rem;
        padding-right: 1rem;
        padding-left: 1rem;

        border-radius: .375rem; 
        background: #fafbfe;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link
    {
        padding-top: .25rem;
        padding-bottom: .25rem;
        padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav-link
    {
        padding-left: 4.25rem;
    }
}

.navbar .dropdown-menu
{
    min-width: 16rem;
    padding: 1.25rem 1.75rem;

    border: 0 solid rgba(31, 45, 61, 0);
    border-radius: .5rem;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(31, 45, 61, .15);
}
.navbar .dropdown-menu .list-group .list-group-item:first-child
{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.navbar .dropdown-menu .list-group .list-group-item:last-child
{
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
.navbar .dropdown-menu .list-group > li:first-child .list-group-item
{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.navbar .dropdown-menu .list-group > li:last-child .list-group-item
{
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.navbar .dropdown-header
{
    padding: 0;

    color: #4a596d;
}

.navbar .dropdown-item
{
    font-size: 1rem; 

    padding: .375rem 0;

    color: #3c4858;
}
.navbar .dropdown-item:first-child
{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.navbar .dropdown-item:last-child
{
    padding-bottom: 0;

    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus
{
    text-decoration: none;

    color: #657a95;
    background: transparent;
}
.navbar .dropdown-item.active,
.navbar .dropdown-item:active
{
    text-decoration: none;

    color: #657a95;
    background-color: transparent;
}

.navbar .dropdown-menu-links
{
    background-color: #fafafa;
}

.navbar .dropdown-img-left
{
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 1.625rem 1.75rem;

    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem; 
    background: no-repeat center center/cover;

    justify-content: center;
    align-items: center;
}
.navbar .dropdown-img-left:before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    border-radius: inherit; 
    background-color: rgba(12, 102, 255, .8);
}

.navbar .dropdown-menu-xl,
.navbar .dropdown-menu-lg
{
    padding: 2rem 2.5rem;
}
.navbar .dropdown-menu-xl .dropdown-header,
.navbar .dropdown-menu-lg .dropdown-header
{
    padding-top: .375rem;
    padding-bottom: .375rem;

    color: #0c66ff;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse .dropdown-menu
    {
        padding-top: 0;
        padding-right: 0; 
        padding-bottom: 0;
        padding-left: 1rem;

        background: transparent;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-menu-links
    {
        background: transparent;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-menu-links:before
    {
        background: transparent;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-item
    {
        font-size: 1rem;
        font-weight: 600; 

        position: relative;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-img-left
    {
        border-radius: .5rem;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: none;
    }
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse .delimiter
    {
        padding-top: 2.5rem; 

        border-top: 1px solid #eff2f7;
    }
    .navbar-expand-sm .navbar-collapse .navbar-btn
    {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 1.75rem;

        color: #0c66ff;
        border: 0;
        border-top: 1px solid #eff2f7;
        border-top-left-radius: 0;
        border-top-right-radius: 0; 
        background-color: transparent;
    }
    .navbar-expand-sm .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: none;
    }
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm .navbar-collapse .nav-item.dropdown .dropdown-toggle:after
    {
        font-size: .75rem;

        float: right;
    }
    .navbar-expand-sm .navbar-collapse .nav-item.dropdown.show .dropdown-toggle.nav-link:after
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    .navbar-expand-sm.navbar-dark .navbar-collapse .dropdown-item
    {
        color: rgba(255, 255, 255, .85);
    }
    .navbar-expand-sm.navbar-light .navbar-collapse .dropdown-item
    {
        color: rgba(31, 45, 61, .5);
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu:not(.dropdown-menu-right)
    {
        margin-left: -1.25rem;
    }
    .navbar-expand-sm .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu-right
    {
        margin-right: -1.25rem;
    }
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: all .2s ease; 
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: none;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: all .2s ease;
    }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: none;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li:hover .list-group-item
    {
        z-index: 11;

        -webkit-transform: scale(1.05);
                transform: scale(1.05);

        border-radius: .5rem; 
        background-color: #fafbfe;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
    }
    .navbar-expand-sm .dropdown-submenu .dropdown-menu
    {
        min-width: 16rem;
        margin-left: 1rem;
    }
    .navbar-expand-sm .dropdown-submenu .dropdown-menu:before
    {
        position: absolute;
        top: 0;
        left: -1rem;

        height: 100%; 

        content: '';

        border-left: 1.5rem solid transparent;
    }
    .navbar-expand-sm .dropdown-submenu .dropdown-item:after
    {
        right: 0;
    }
    .navbar-expand-sm .dropdown-body
    {
        padding: 2rem 2.5rem;
    }
    .navbar-expand-sm .delimiter
    {
        border-right: 1px solid #eff2f7;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse .dropdown-menu
    {
        padding-top: 0;
        padding-right: 0; 
        padding-bottom: 0;
        padding-left: 1rem;

        background: transparent;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu-links
    {
        background: transparent;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu-links:before
    {
        background: transparent;
    }
    .navbar-expand-md .navbar-collapse .dropdown-item
    {
        font-size: 1rem;
        font-weight: 600; 

        position: relative;
    }
    .navbar-expand-md .navbar-collapse .dropdown-img-left
    {
        border-radius: .5rem;
    }
    .navbar-expand-md .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse .delimiter
    {
        padding-top: 2.5rem; 

        border-top: 1px solid #eff2f7;
    }
    .navbar-expand-md .navbar-collapse .navbar-btn
    {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 1.75rem;

        color: #0c66ff;
        border: 0;
        border-top: 1px solid #eff2f7;
        border-top-left-radius: 0;
        border-top-right-radius: 0; 
        background-color: transparent;
    }
    .navbar-expand-md .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md .navbar-collapse .nav-item.dropdown .dropdown-toggle:after
    {
        font-size: .75rem;

        float: right;
    }
    .navbar-expand-md .navbar-collapse .nav-item.dropdown.show .dropdown-toggle.nav-link:after
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    .navbar-expand-md.navbar-dark .navbar-collapse .dropdown-item
    {
        color: rgba(255, 255, 255, .85);
    }
    .navbar-expand-md.navbar-light .navbar-collapse .dropdown-item
    {
        color: rgba(31, 45, 61, .5);
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu:not(.dropdown-menu-right)
    {
        margin-left: -1.25rem;
    }
    .navbar-expand-md .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu-right
    {
        margin-right: -1.25rem;
    }
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: all .2s ease; 
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: none;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: all .2s ease;
    }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: none;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li:hover .list-group-item
    {
        z-index: 11;

        -webkit-transform: scale(1.05);
                transform: scale(1.05);

        border-radius: .5rem; 
        background-color: #fafbfe;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
    }
    .navbar-expand-md .dropdown-submenu .dropdown-menu
    {
        min-width: 16rem;
        margin-left: 1rem;
    }
    .navbar-expand-md .dropdown-submenu .dropdown-menu:before
    {
        position: absolute;
        top: 0;
        left: -1rem;

        height: 100%; 

        content: '';

        border-left: 1.5rem solid transparent;
    }
    .navbar-expand-md .dropdown-submenu .dropdown-item:after
    {
        right: 0;
    }
    .navbar-expand-md .dropdown-body
    {
        padding: 2rem 2.5rem;
    }
    .navbar-expand-md .delimiter
    {
        border-right: 1px solid #eff2f7;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse .dropdown-menu
    {
        padding-top: 0;
        padding-right: 0; 
        padding-bottom: 0;
        padding-left: 1rem;

        background: transparent;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu-links
    {
        background: transparent;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu-links:before
    {
        background: transparent;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-item
    {
        font-size: 1rem;
        font-weight: 600; 

        position: relative;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-img-left
    {
        border-radius: .5rem;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse .delimiter
    {
        padding-top: 2.5rem; 

        border-top: 1px solid #eff2f7;
    }
    .navbar-expand-lg .navbar-collapse .navbar-btn
    {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 1.75rem;

        color: #0c66ff;
        border: 0;
        border-top: 1px solid #eff2f7;
        border-top-left-radius: 0;
        border-top-right-radius: 0; 
        background-color: transparent;
    }
    .navbar-expand-lg .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg .navbar-collapse .nav-item.dropdown .dropdown-toggle:after
    {
        font-size: .75rem;

        float: right;
    }
    .navbar-expand-lg .navbar-collapse .nav-item.dropdown.show .dropdown-toggle.nav-link:after
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    .navbar-expand-lg.navbar-dark .navbar-collapse .dropdown-item
    {
        color: rgba(255, 255, 255, .85);
    }
    .navbar-expand-lg.navbar-light .navbar-collapse .dropdown-item
    {
        color: rgba(31, 45, 61, .5);
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu:not(.dropdown-menu-right)
    {
        margin-left: -1.25rem;
    }
    .navbar-expand-lg .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu-right
    {
        margin-right: -1.25rem;
    }
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: all .2s ease; 
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: none;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: all .2s ease;
    }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: none;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li:hover .list-group-item
    {
        z-index: 11;

        -webkit-transform: scale(1.05);
                transform: scale(1.05);

        border-radius: .5rem; 
        background-color: #fafbfe;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
    }
    .navbar-expand-lg .dropdown-submenu .dropdown-menu
    {
        min-width: 16rem;
        margin-left: 1rem;
    }
    .navbar-expand-lg .dropdown-submenu .dropdown-menu:before
    {
        position: absolute;
        top: 0;
        left: -1rem;

        height: 100%; 

        content: '';

        border-left: 1.5rem solid transparent;
    }
    .navbar-expand-lg .dropdown-submenu .dropdown-item:after
    {
        right: 0;
    }
    .navbar-expand-lg .dropdown-body
    {
        padding: 2rem 2.5rem;
    }
    .navbar-expand-lg .delimiter
    {
        border-right: 1px solid #eff2f7;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse .dropdown-menu
    {
        padding-top: 0;
        padding-right: 0; 
        padding-bottom: 0;
        padding-left: 1rem;

        background: transparent;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-menu-links
    {
        background: transparent;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-menu-links:before
    {
        background: transparent;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-item
    {
        font-size: 1rem;
        font-weight: 600; 

        position: relative;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-img-left
    {
        border-radius: .5rem;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse .delimiter
    {
        padding-top: 2.5rem; 

        border-top: 1px solid #eff2f7;
    }
    .navbar-expand-xl .navbar-collapse .navbar-btn
    {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 1.75rem;

        color: #0c66ff;
        border: 0;
        border-top: 1px solid #eff2f7;
        border-top-left-radius: 0;
        border-top-right-radius: 0; 
        background-color: transparent;
    }
    .navbar-expand-xl .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: all .2s ease; 
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl .navbar-collapse .nav-item.dropdown .dropdown-toggle:after
    {
        font-size: .75rem;

        float: right;
    }
    .navbar-expand-xl .navbar-collapse .nav-item.dropdown.show .dropdown-toggle.nav-link:after
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    .navbar-expand-xl.navbar-dark .navbar-collapse .dropdown-item
    {
        color: rgba(255, 255, 255, .85);
    }
    .navbar-expand-xl.navbar-light .navbar-collapse .dropdown-item
    {
        color: rgba(31, 45, 61, .5);
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu:not(.dropdown-menu-right)
    {
        margin-left: -1.25rem;
    }
    .navbar-expand-xl .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu-right
    {
        margin-right: -1.25rem;
    }
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: all .2s ease; 
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: none;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: all .2s ease;
    }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce)
{
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: none;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li:hover .list-group-item
    {
        z-index: 11;

        -webkit-transform: scale(1.05);
                transform: scale(1.05);

        border-radius: .5rem; 
        background-color: #fafbfe;
        box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
    }
    .navbar-expand-xl .dropdown-submenu .dropdown-menu
    {
        min-width: 16rem;
        margin-left: 1rem;
    }
    .navbar-expand-xl .dropdown-submenu .dropdown-menu:before
    {
        position: absolute;
        top: 0;
        left: -1rem;

        height: 100%; 

        content: '';

        border-left: 1.5rem solid transparent;
    }
    .navbar-expand-xl .dropdown-submenu .dropdown-item:after
    {
        right: 0;
    }
    .navbar-expand-xl .dropdown-body
    {
        padding: 2rem 2.5rem;
    }
    .navbar-expand-xl .delimiter
    {
        border-right: 1px solid #eff2f7;
    }
}

.navbar-expand .navbar-collapse .dropdown-menu
{
    padding-top: 0;
    padding-right: 0; 
    padding-bottom: 0;
    padding-left: 1rem;

    background: transparent;
}

.navbar-expand .navbar-collapse .dropdown-menu-links
{
    background: transparent;
}
.navbar-expand .navbar-collapse .dropdown-menu-links:before
{
    background: transparent;
}

.navbar-expand .navbar-collapse .dropdown-item
{
    font-size: 1rem;
    font-weight: 600; 

    position: relative;
}

.navbar-expand .navbar-collapse .dropdown-img-left
{
    border-radius: .5rem;
}

.navbar-expand .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
{
    transition: all .2s ease; 
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after
    {
        transition: none;
    }
}

.navbar-expand .navbar-collapse .delimiter
{
    padding-top: 2.5rem; 

    border-top: 1px solid #eff2f7;
}

.navbar-expand .navbar-collapse .navbar-btn
{
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1.75rem;

    color: #0c66ff;
    border: 0;
    border-top: 1px solid #eff2f7;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    background-color: transparent;
}

.navbar-expand .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
{
    transition: all .2s ease; 
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after
    {
        transition: none;
    }
}

.navbar-expand .navbar-collapse .nav-item.dropdown .dropdown-toggle:after
{
    font-size: .75rem;

    float: right;
}

.navbar-expand .navbar-collapse .nav-item.dropdown.show .dropdown-toggle.nav-link:after
{
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

.navbar-expand.navbar-dark .navbar-collapse .dropdown-item
{
    color: rgba(255, 255, 255, .85);
}

.navbar-expand.navbar-light .navbar-collapse .dropdown-item
{
    color: rgba(31, 45, 61, .5);
}

.navbar-expand .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu:not(.dropdown-menu-right)
{
    margin-left: -1.25rem;
}

.navbar-expand .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) > .dropdown-menu-right
{
    margin-right: -1.25rem;
}

.navbar-expand .dropdown-menu .list-group-emphasized > li .list-group-item
{
    transition: all .2s ease; 
    -webkit-transform: scale(1);
            transform: scale(1);
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .dropdown-menu .list-group-emphasized > li .list-group-item
    {
        transition: none;
    }
}

.navbar-expand .dropdown-menu .list-group-emphasized > li .media-body *
{
    transition: all .2s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-expand .dropdown-menu .list-group-emphasized > li .media-body *
    {
        transition: none;
    }
}

.navbar-expand .dropdown-menu .list-group-emphasized > li:hover .list-group-item
{
    z-index: 11;

    -webkit-transform: scale(1.05);
            transform: scale(1.05);

    border-radius: .5rem; 
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
}

.navbar-expand .dropdown-submenu .dropdown-menu
{
    min-width: 16rem;
    margin-left: 1rem;
}
.navbar-expand .dropdown-submenu .dropdown-menu:before
{
    position: absolute;
    top: 0;
    left: -1rem;

    height: 100%; 

    content: '';

    border-left: 1.5rem solid transparent;
}

.navbar-expand .dropdown-submenu .dropdown-item:after
{
    right: 0;
}

.navbar-expand .dropdown-body
{
    padding: 2rem 2.5rem;
}

.navbar-expand .delimiter
{
    border-right: 1px solid #eff2f7;
}

.navbar-search .input-group
{
    transition: background-color .4s linear;
    transition-delay: .2s; 

    border: 0 solid;
    border-radius: 2rem;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-search .input-group
    {
        transition: none;
    }
}
.navbar-search .input-group .input-group-text
{
    padding-left: 1rem;

    border: 0; 
    background-color: transparent;
}

.navbar-search .form-control
{
    width: 250px;

    transition: all .2s cubic-bezier(.77, 0, .2, 2.25); 

    border: 0;
    background-color: transparent;
}
@media (prefers-reduced-motion: reduce)
{
    .navbar-search .form-control
    {
        transition: none;
    }
}

.navbar-search .focused .input-group .form-control
{
    width: 380px;
}

.navbar-search .close
{
    display: none;
}

.navbar-search-dark .input-group
{
    border-color: rgba(255, 255, 255, .6); 
    background-color: rgba(31, 45, 61, .8);
}

.navbar-search-dark .input-group-text
{
    color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .form-control
{
    color: rgba(255, 255, 255, .9);
}
.navbar-search-dark .form-control::-webkit-input-placeholder
{
    color: rgba(255, 255, 255, .6);
}
.navbar-search-dark .form-control::-moz-placeholder
{
    color: rgba(255, 255, 255, .6);
}
.navbar-search-dark .form-control:-ms-input-placeholder
{
    color: rgba(255, 255, 255, .6);
}
.navbar-search-dark .form-control::-ms-input-placeholder
{
    color: rgba(255, 255, 255, .6);
}
.navbar-search-dark .form-control::placeholder
{
    color: rgba(255, 255, 255, .6);
}

.navbar-search-dark .focused .input-group
{
    border-color: rgba(255, 255, 255, .9); 
    background-color: rgba(31, 45, 61, .9);
}

.navbar-search-light .input-group
{
    border-color: rgba(0, 0, 0, .6); 
    background-color: rgba(255, 255, 255, .9);
}

.navbar-search-light .input-group-text
{
    color: rgba(0, 0, 0, .6);
}

.navbar-search-light .form-control
{
    color: rgba(0, 0, 0, .9);
}
.navbar-search-light .form-control::-webkit-input-placeholder
{
    color: rgba(0, 0, 0, .6);
}
.navbar-search-light .form-control::-moz-placeholder
{
    color: rgba(0, 0, 0, .6);
}
.navbar-search-light .form-control:-ms-input-placeholder
{
    color: rgba(0, 0, 0, .6);
}
.navbar-search-light .form-control::-ms-input-placeholder
{
    color: rgba(0, 0, 0, .6);
}
.navbar-search-light .form-control::placeholder
{
    color: rgba(0, 0, 0, .6);
}

.navbar-search-light .focused .input-group
{
    border-color: rgba(0, 0, 0, .9); 
    background-color: white;
}

.omnisearch
{
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;

    width: 100%;
    margin-top: 1rem;

    transition: opacity .15s, -webkit-transform .3s;
    transition: transform .3s, opacity .15s;
    transition: transform .3s, opacity .15s, -webkit-transform .3s; 
    -webkit-transform: translateY(0);
            transform: translateY(0);
    pointer-events: none;

    opacity: 0;
    background-color: transparent;
}
@media (prefers-reduced-motion: reduce)
{
    .omnisearch
    {
        transition: none;
    }
}
.omnisearch-container
{
    margin: auto;
}
@media (max-width: 991.98px)
{
    .omnisearch-container
    {
        width: 100%;
    }
}
@media (min-width: 768px)
{
    .omnisearch-container
    {
        width: 580px;
    }
}
.omnisearch.show
{
    -webkit-transform: translateY(10%);
            transform: translateY(10%); 

    opacity: 1;
}
.omnisearch .container
{
    position: relative;

    height: 100%;
}
.omnisearch .omnisearch-form
{
    position: relative;
    z-index: 700;

    display: block;

    border-radius: .5rem; 
    background: #fff;
}
.omnisearch .omnisearch-form .input-group-text
{
    font-size: 1.25rem;

    background: transparent;
}
.omnisearch .omnisearch-form .form-control
{
    font-size: 1.25rem;

    display: block;

    height: 58px;

    color: #c0ccda;
    background-color: transparent;
    background-image: none;
}
.omnisearch .omnisearch-form .form-control::-webkit-input-placeholder
{
    color: #aabacd;
}
.omnisearch .omnisearch-form .form-control::-moz-placeholder
{
    color: #aabacd;
}
.omnisearch .omnisearch-form .form-control:-ms-input-placeholder
{
    color: #aabacd;
}
.omnisearch .omnisearch-form .form-control::-ms-input-placeholder
{
    color: #aabacd;
}
.omnisearch .omnisearch-form .form-control::placeholder
{
    color: #aabacd;
}
.omnisearch .omnisearch-suggestions
{
    position: relative;

    min-height: 150px;
    padding: 1.5rem;

    transition: opacity .3s;
    transition-delay: .21s; 

    opacity: 0;
    border-radius: .5rem;
    background: white;
}
@media (prefers-reduced-motion: reduce)
{
    .omnisearch .omnisearch-suggestions
    {
        transition: none;
    }
}
.omnisearch .omnisearch-suggestions:before
{
    position: absolute;
    z-index: -5;
    bottom: 100%;
    left: 20px;

    display: block;

    width: 16px;
    height: 16px;

    content: '';
    -webkit-transform: rotate(-45deg) translateY(1rem);
            transform: rotate(-45deg) translateY(1rem);

    border-radius: .2rem; 
    background: #fff;
    box-shadow: none;
}
.omnisearch .omnisearch-suggestions .heading
{
    color: #8492a6;
}
.omnisearch .omnisearch-suggestions .list-link span
{
    font-weight: 600;

    color: #3c4858;
}
.omnisearch .omnisearch-suggestions .list-link:hover
{
    color: #0c66ff;
}
.omnisearch .omnisearch-suggestions .list-link:hover span
{
    color: #0c66ff;
}
.omnisearch.show .omnisearch-form,
.omnisearch.show .omnisearch-suggestions
{
    pointer-events: auto;
}
.omnisearch.show .omnisearch-suggestions
{
    opacity: 1;
}

.omnisearch-open
{
    overflow: hidden;
}

section
{
    background-color: #fff;
}

.slice
{
    position: relative;

    padding-top: 4rem;
    padding-bottom: 4rem;
}

.slice-xl
{
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.slice-lg
{
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.slice-sm
{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.slice:not(.border-top):not(.border-bottom):not(.delimiter-bottom):not([class*='bg-']):not(.section-rotate) + .slice:not(.border-top):not(.border-bottom):not(.delimiter-top):not([class*='bg-'])
{
    padding-top: 0;
}

.slice-video
{
    min-height: 600px;
}

.section-rotate
{
    position: relative;
    z-index: 0; 

    overflow: hidden;

    padding-top: 4rem;
    padding-bottom: 4rem;

    background: transparent;
}
.section-rotate .section-inner
{
    position: absolute;
    z-index: -2; 
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

@media (min-width: 992px)
{
    .section-rotate
    {
        min-height: 880px;
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .section-rotate .section-inner
    {
        overflow: hidden; 

        background: inherit;
    }
    .section-rotate .section-inner + .container
    {
        position: relative;
        z-index: 0;
    }
    .section-rotate .section-inner-bg
    {
        position: absolute;

        width: 100%;
        height: 100%;
    }
}

@media (min-width: 1200px)
{
    .section-rotate
    {
        padding-top: 8rem;
        padding-bottom: 12rem;
    }
    .section-rotate .section-inner
    {
        top: -40%;
        left: -1rem;

        width: 130%;
        height: 124%;

        -webkit-transform: rotate(-7deg);
                transform: rotate(-7deg);

        border-bottom-left-radius: 3rem;
    }
    .section-rotate .section-inner + .container
    {
        padding-left: 2rem;
    }
    .section-rotate .container
    {
        position: relative;
    }
}

@media only screen and (min-width: 2000px)
{
    .section-rotate .section-inner
    {
        top: -40%;
        left: -1rem;

        width: 120%;
        height: 118%;
    }
    .section-rotate .container
    {
        position: relative;
    }
}

.section-half-rounded
{
    position: relative;
    z-index: 0; 

    overflow: hidden;

    padding-top: 7rem;
    padding-bottom: 7rem;

    background: transparent;
}
.section-half-rounded .section-inner
{
    position: absolute;
    z-index: -2; 
    top: 0;

    height: 100%;
}
@media (max-width: 991.98px)
{
    .section-half-rounded .section-inner
    {
        width: 100%;
    }
}

.section-process
{
    background: transparent;
}

.section-process-step
{
    position: relative;

    padding: 4.5rem 0;
}
.section-process-step:not(:last-child):before
{
    position: absolute;
    right: 0;
    bottom: -80px; 
    left: 0;

    display: block;

    width: 360px;
    height: 100px;
    margin: auto;

    content: '';
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg);

    background: url('data:image/svg+xml;charset=utf8,%3Csvg width=\'355px\' height=\'103px\' viewBox=\'0 0 355 103\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\' stroke-dasharray=\'6,12\' stroke-linecap=\'round\'%3E%3Cpath stroke=\'%23EFF2F7\' stroke-width=\'3\' transform=\'translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)\' d=\'M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035\'/%3E%3C/g%3E%3C/svg%3E') no-repeat center;
    background-size: 360px 100px;
}
@media (max-width: 991.98px)
{
    .section-process-step:not(:last-child):before
    {
        bottom: 20px; 

        -webkit-transform: rotate(90deg) scale(.7);
                transform: rotate(90deg) scale(.7);
    }
}
.section-process-step:nth-child(even):before
{
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg); 

    background: url('data:image/svg+xml;charset=utf8,%3Csvg width=\'355px\' height=\'103px\' viewBox=\'0 0 355 103\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\' stroke-dasharray=\'6,12\' stroke-linecap=\'round\'%3E%3Cpath stroke=\'%23EFF2F7\' stroke-width=\'3\' d=\'M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035\'/%3E%3C/g%3E%3C/svg%3E') no-repeat center;
    background-size: 360px 100px;
}
@media (max-width: 991.98px)
{
    .section-process-step:nth-child(even):before
    {
        bottom: 20px; 

        -webkit-transform: rotate(-90deg) scale(.7);
                transform: rotate(-90deg) scale(.7);
    }
}

.section-floating-icons
{
    --icon-size: 5rem;
    --icon-sm-size: 3.75rem;
    --gutter: 7rem;
}
.section-floating-icons .icons-container
{
    position: relative;

    max-width: 100%;
    height: 360px;
    margin: 0 auto;
}
.section-floating-icons .icons-container span
{
    position: absolute;
    z-index: 1;

    display: inline-flex;

    transition: all .2s cubic-bezier(.77, 0, .2, 2.25); 
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);

    align-items: center;
    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .section-floating-icons .icons-container span
    {
        transition: none;
    }
}
.section-floating-icons .icons-container span.icon
{
    width: 5rem;
    height: 5rem;
}
.section-floating-icons .icons-container span.icon i
{
    font-size: 2.3em;
}
.section-floating-icons .icons-container span.icon-sm
{
    width: 3.75rem;
    height: 3.75rem;
}
.section-floating-icons .icons-container span.icon-sm i
{
    font-size: 1.5em;
}
.section-floating-icons .icons-container span:nth-child(1)
{
    font-size: 42px;

    z-index: 2; 

    color: #ffbe3d;
}
.section-floating-icons .icons-container span img
{
    max-width: 100%;
}
.section-floating-icons .icons-container span
{
    opacity: 1;
}
.section-floating-icons .icons-container span:nth-child(1)
{
    font-size: 42px;

    top: 50%;
    left: 50%;

    color: #50b5ff;
}
.section-floating-icons .icons-container span:nth-child(2)
{
    top: 50%; 
    left: calc(50% + (7rem * 1.7));
}
.section-floating-icons .icons-container span:nth-child(3)
{
    top: calc(50% + 7rem); 
    left: calc(50% + 7rem);
}
.section-floating-icons .icons-container span:nth-child(4)
{
    top: calc(50% - 7rem); 
    left: calc(50% + 7rem);
}
.section-floating-icons .icons-container span:nth-child(5)
{
    top: 50%; 
    left: calc(50% + (7rem * 4));
}
.section-floating-icons .icons-container span:nth-child(6)
{
    top: calc(50% + (7rem * 1.5)); 
    left: calc(50% + (7rem * 2.7));
}
.section-floating-icons .icons-container span:nth-child(7)
{
    top: calc(50% - (7rem * 1.5)); 
    left: calc(50% + (7rem * 2.7));
}
.section-floating-icons .icons-container span:nth-child(8)
{
    top: 50%; 
    left: calc(50% - (7rem * 1.7));
}
.section-floating-icons .icons-container span:nth-child(9)
{
    top: calc(50% + 7rem); 
    left: calc(50% - 7rem);
}
.section-floating-icons .icons-container span:nth-child(10)
{
    top: calc(50% - 7rem); 
    left: calc(50% - 7rem);
}
.section-floating-icons .icons-container span:nth-child(11)
{
    top: 50%; 
    left: calc(50% - (7rem * 4));
}
.section-floating-icons .icons-container span:nth-child(12)
{
    top: calc(50% + (7rem * 1.5)); 
    left: calc(50% - (7rem * 2.7));
}
.section-floating-icons .icons-container span:nth-child(13)
{
    top: calc(50% - (7rem * 1.5)); 
    left: calc(50% - (7rem * 2.7));
}

.spotlight
{
    position: relative;
}
.spotlight .container
{
    height: 100%;
}
.spotlight .animated
{
    opacity: 0;
}
.spotlight .animated.animation-ended
{
    opacity: 1;
}

@media (min-width: 768px)
{
    .spotlight-overlay-img
    {
        position: relative;
    }
    .spotlight-overlay-img img
    {
        position: absolute;
        z-index: 10;
    }
}

.table-cards thead th,
.table-cards thead td,
.table-cards tbody th,
.table-cards tbody td
{
    position: relative;

    padding: 1rem;

    border: 0 !important;
    background-color: transparent;
}

.table-cards tbody tr:not(.table-divider)
{
    border-radius: 1rem;
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}

.table-cards tbody tr th
{
    border-radius: 1rem 0 0 1rem;
}
.table-cards tbody tr th:after
{
    border-left: 1px solid #eff2f7; 
    border-radius: 1rem 0 0 1rem;
}

.table-cards tbody tr td:last-child
{
    border-radius: 0 1rem 1rem 0;
}
.table-cards tbody tr td:last-child:after
{
    border-right: 1px solid #eff2f7; 
    border-radius: 0 1rem 1rem 0;
}

.table-cards tbody tr th,
.table-cards tbody tr td
{
    background-color: #fff;
}
.table-cards tbody tr th:after,
.table-cards tbody tr td:after
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    pointer-events: none; 

    border-top: 1px solid #eff2f7;
    border-bottom: 1px solid #eff2f7;
}

.table-cards tr.table-divider
{
    height: 1rem;
}

.table-cards.table-hover tbody > tr:hover th,
.table-cards.table-hover tbody > tr:hover td
{
    background-color: #fafbfe;
}

@media (min-width: 768px)
{
    .table-cards.table-scale--hover tr:hover
    {
        -webkit-transform: scale(1.02);
                transform: scale(1.02);
    }
}

.timeline
{
    position: relative;
}
.timeline:before
{
    position: absolute;
    top: 0;
    left: 1rem;

    height: 100%;

    content: '';

    border-right: 2px solid #eff2f7;
}

[data-timeline-axis-style='dashed']:before
{
    border-right-style: dashed !important;
}

[data-timeline-axis-style='dotted']:before
{
    border-right-style: dotted !important;
}

.timeline-block
{
    position: relative;

    margin: 2em 0;
}
.timeline-block:after
{
    display: table;
    clear: both; 

    content: '';
}
.timeline-block:first-child
{
    margin-top: 0;
}
.timeline-block:last-child
{
    margin-bottom: 0;
}

.timeline-step
{
    font-size: 1rem;
    font-weight: 600;

    position: absolute;
    z-index: 1; 
    left: 0;

    display: inline-flex;

    width: 33px;
    height: 33px;

    text-align: center;

    border: 2px solid #eff2f7;
    border-radius: 50%;
    background: #fff;

    align-items: center;
    justify-content: center;
}
.timeline-step svg,
.timeline-step i
{
    line-height: 1.4;
}

.timeline-step-icon
{
    border: 2px solid #eff2f7; 
    background: #fff;
}

.timeline-step-xs
{
    font-size: .75rem; 

    width: 17px;
    height: 17px;
}

.timeline-step-sm
{
    font-size: .75rem; 

    width: 23px;
    height: 23px;
}

.timeline-step-lg
{
    font-size: 1.75rem; 

    width: 47px;
    height: 47px;
}

.timeline-content
{
    position: relative;
    position: relative;
    top: -6px; 

    margin-right: 30px;
    margin-left: 60px;
}
.timeline-content:after
{
    display: table;
    clear: both; 

    content: '';
}

.timeline-body
{
    padding: 1.5rem;
}

@media (min-width: 992px)
{
    .timeline:before
    {
        left: 50%;

        margin-left: -2px;
    }
    .timeline-step
    {
        left: 50%;

        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    .timeline-content
    {
        width: 38%;
    }
    .timeline-body
    {
        padding: 1.5rem;
    }
    .timeline-block:nth-child(even) .timeline-content
    {
        float: right;
    }
    [data-timeline-axis-color='primary']:before
    {
        border-color: #0c66ff;
    }
    [data-timeline-axis-color='secondary']:before
    {
        border-color: #eff2f7;
    }
    [data-timeline-axis-color='success']:before
    {
        border-color: #2dca8c;
    }
    [data-timeline-axis-color='info']:before
    {
        border-color: #50b5ff;
    }
    [data-timeline-axis-color='warning']:before
    {
        border-color: #ffbe3d;
    }
    [data-timeline-axis-color='danger']:before
    {
        border-color: #ff5c75;
    }
    [data-timeline-axis-color='light']:before
    {
        border-color: #eff2f7;
    }
    [data-timeline-axis-color='dark']:before
    {
        border-color: #273444;
    }
    [data-timeline-axis-color='neutral']:before
    {
        border-color: #fff;
    }
    [data-timeline-axis-color='white']:before
    {
        border-color: #fff;
    }
}

.timeline-one-side:before
{
    left: 1rem;
}

.timeline-one-side .timeline-step
{
    left: 1rem; 

    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.timeline-one-side .timeline-content
{
    width: auto;
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content
{
    float: none;
}

.tongue
{
    line-height: 35px;

    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;

    display: inline-block;

    width: 138px;
    height: 35px;

    -webkit-transform: rotate(180deg) translateX(50%);
            transform: rotate(180deg) translateX(50%);
    text-align: center;

    color: #8492a6;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FFF\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E'); 
    background-repeat: no-repeat;
    background-size: 100%;
}
.tongue i
{
    -webkit-animation: floating-sm 2s ease infinite;
            animation: floating-sm 2s ease infinite;
}
.tongue:hover i
{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}

.tongue-primary
{
    color: #fff; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%230C66FF\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-primary:hover
{
    color: #fff;
}

.tongue-secondary
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23EFF2F7\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-secondary:hover
{
    color: #273444;
}

.tongue-success
{
    color: #fff; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%232DCA8C\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-success:hover
{
    color: #fff;
}

.tongue-info
{
    color: #fff; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%2350b5ff\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-info:hover
{
    color: #fff;
}

.tongue-warning
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FFBE3D\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-warning:hover
{
    color: #273444;
}

.tongue-danger
{
    color: #fff; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FF5C75\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-danger:hover
{
    color: #fff;
}

.tongue-light
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23EFF2F7\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-light:hover
{
    color: #273444;
}

.tongue-dark
{
    color: #fff; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23273444\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-dark:hover
{
    color: #fff;
}

.tongue-neutral
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FFF\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-neutral:hover
{
    color: #273444;
}

.tongue-white
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FFF\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-white:hover
{
    color: #273444;
}

.tongue-section-primary
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FFF\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-section-primary:hover
{
    color: #273444;
}

.tongue-section-secondary
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23FAFBFE\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-section-secondary:hover
{
    color: #273444;
}

.tongue-section-light
{
    color: #273444; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%23E0E6ED\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-section-light:hover
{
    color: #273444;
}

.tongue-section-dark
{
    color: #fff; 
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'138\' height=\'35\' viewBox=\'0 0 138 35\'%3E%3Cpath fill=\'%231a232d\' d=\'M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z\'/%3E%3C/svg%3E');
}
.tongue-section-dark:hover
{
    color: #fff;
}

.tongue-top
{
    top: -1px;
}

.tongue-bottom
{
    top: auto;
    bottom: -1px;

    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

@keyframes floating-sm
{
    0%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    50%
    {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    100%
    {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

.footer-rotate
{
    position: relative;

    overflow: hidden;
    overflow: hidden; 

    max-width: 1990px;
    margin-top: -26rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 26rem;
}
.footer-rotate:before
{
    position: absolute;
    z-index: 10;
    top: 0; 
    right: calc(50% - 1000px);

    display: block;

    width: calc(100% + 10px);
    min-width: 2000px;
    height: 1000px;

    content: '';
    pointer-events: none;

    background: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 2000 1000\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath fill=\'%23FFF\' d=\'M2000,0V923.45l-195.85-538.1a76.07875,76.07875,0,0,0-58.25-48.89L536.65,123.24a76.12151,76.12151,0,0,0-71.44,26L0,703.65V0Z\'/%3E%3C/svg%3E') no-repeat scroll center center/100% 100% border-box;
}

.countdown-item
{
    display: inline-block;
}

.countdown-label
{
    font-size: 1.2rem;

    padding: 0 10px;
}

.countdown-sm .countdown-digit,
.countdown-sm .countdown-label
{
    font-size: 1.4rem;
}

.countdown-sm .countdown-label
{
    font-size: .875rem;

    padding: 0 10px;
}

[data-countdown-label='hide'] .countdown-label:not(.countdown-days)
{
    display: none;
}

[data-countdown-label='show'] .countdown-separator
{
    display: none;
}

.countdown-hero .countdown-digit
{
    font-size: 3rem;
    font-weight: 400;

    display: block;

    padding: 0 1.5rem;

    cursor: default; 
    text-align: center;

    color: #3c4858;
}

.countdown-hero .countdown-label
{
    font-size: .875rem;
    font-weight: 600; 

    display: block;

    margin-top: 5px;

    text-align: center;
    text-transform: capitalize;
}

.countdown-hero .countdown-separator
{
    display: none;
}

@media (max-width: 991.98px)
{
    .countdown-hero .countdown-digit
    {
        font-size: 1.5rem;
    }
}

.countdown-hero-dark .countdown-digit,
.countdown-hero-dark .countdown-label
{
    color: #fff;
}

.countdown-blocks .countdown-item
{
    margin-right: 10px;
}
.countdown-blocks .countdown-item:last-child
{
    margin-right: 0;
}

.countdown-blocks .countdown-digit
{
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 60px;

    display: block;

    width: 60px;
    height: 60px;

    cursor: default; 
    text-align: center;

    color: #fff;
    border-radius: .375rem;
    background: #0c66ff;
}

.countdown-blocks .countdown-label
{
    font-size: .875rem;
    font-weight: 600; 

    display: block;

    margin-top: 5px;

    text-align: center;
    text-transform: capitalize;
}

.countdown-blocks .countdown-separator
{
    display: none;
}

.milestone-count-extra
{
    display: none;
}

.counting-finished + .counter-extra
{
    display: inline-block;
}

.flatpickr-calendar
{
    width: auto;
    margin-top: -2px;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    border: 0;
    border-radius: .375rem; 
    background: #fff;
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
}
.flatpickr-calendar:before,
.flatpickr-calendar:after
{
    display: none;
}

.flatpickr-months
{
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem; 
    background-color: #0c66ff;
}
.flatpickr-months .flatpickr-month
{
    height: 60px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month
{
    line-height: 100%;

    top: 13px;

    display: flex;

    width: 35px;
    height: 35px;
    padding: 0;

    transition: background-color .4s linear; 

    color: #fff;

    align-items: center;
    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month
    {
        transition: none;
    }
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg
{
    fill: rgba(255, 255, 255, .7);
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover
{
    color: #fff;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg
{
    fill: #fff;
}
.flatpickr-months .flatpickr-prev-month
{
    margin-left: 15px;
}
.flatpickr-months .flatpickr-next-month
{
    margin-right: 15px;
}

.flatpickr-current-month
{
    font-size: 1.125rem;

    padding-top: 18px; 

    color: #fff;
}
.flatpickr-current-month span.cur-month:hover
{
    background-color: transparent;
}
.flatpickr-current-month .numInputWrapper:hover
{
    background-color: transparent;
}
.flatpickr-current-month .numInputWrapper span
{
    right: -5px;

    padding: 0; 

    border: 0;
}
.flatpickr-current-month .numInputWrapper span:after
{
    left: 3px;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after
{
    border-bottom-color: rgba(255, 255, 255, .7);
}
.flatpickr-current-month .numInputWrapper span.arrowUp:hover:after
{
    border-bottom-color: #fff;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after
{
    border-top-color: rgba(255, 255, 255, .7);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:hover:after
{
    border-top-color: #fff;
}

span.flatpickr-weekday
{
    font-weight: 600;

    color: #8492a6;
}

.flatpickr-day
{
    font-size: .875rem;

    color: #8492a6;
    border: 0;
    border-radius: .375rem;
}
.flatpickr-day.today
{
    color: #0c66ff !important;
}
.flatpickr-day.today:hover
{
    color: #0c66ff; 
    background-color: transparent;
}
.flatpickr-day:hover
{
    color: #0c66ff; 
    background-color: transparent;
}
.flatpickr-day.selected
{
    color: #fff !important; 
    background-color: #0c66ff;
}
.flatpickr-day.selected:hover
{
    color: #fff; 
    background-color: #0c66ff;
}

.numInputWrapper span:hover
{
    background-color: transparent;
}

.flatpickr-time
{
    border-top: 1px solid #eff2f7;
}

.flatpickr-innerContainer
{
    padding: 15px;
}

.quick-view-container
{
    background: rgba(10, 10, 10, .85);
}

.quick-view-content
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: calc(100% - 40px); 
    max-width: 980px;
    height: calc(100% - 40px);
    max-height: 650px;
    margin: auto;
}

.quick-view-carousel
{
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 57%;

    border: 1px solid #eff2f7;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
}
.quick-view-carousel .fancybox-stage
{
    bottom: 30px;
}

.quick-view-aside
{
    position: absolute;
    top: 2rem;
    right: 0;
    bottom: 2rem;
    left: auto;

    width: 43%; 
    padding: 3rem 0 2rem 0;

    color: #8492a6;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: #fff;
}
.quick-view-aside > div
{
    overflow: auto;

    height: 100%;
    padding: 0 2rem;
}

.quick-view-close
{
    font-family: Arial;
    font-size: 14px;

    position: absolute;
    top: 2rem;
    right: 0;

    width: 2.75rem; 
    height: 44px;
    margin: 0;
    padding: 0;

    cursor: pointer;
    transition: all .2s;
    text-indent: -99999px;

    color: #222;
    border: 0;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background: #f0f0f0;
}
.quick-view-close:hover
{
    background: #e4e4e4;
}
.quick-view-close:before,
.quick-view-close:after
{
    position: absolute;
    top: 12px;
    left: 22px;

    width: 1px; 
    height: 18px;

    content: '';

    background-color: #222;
}
.quick-view-close:before
{
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.quick-view-close:after
{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.quick-view-bullets
{
    position: absolute;
    z-index: 99999; 
    bottom: 0;
    left: 0;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    text-align: center;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.quick-view-bullets li
{
    display: inline-block;

    vertical-align: top;
}
.quick-view-bullets li a
{
    position: relative;

    display: block;

    width: 20px; 
    height: 30px;
}
.quick-view-bullets li a span
{
    position: absolute;
    top: 50%;
    left: 50%;

    overflow: hidden;

    width: 10px; 
    height: 10px;

    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-indent: -99999px;

    border-radius: 99px;
    background: #d3dce6;
}
.quick-view-bullets li.active a span
{
    background: #0c66ff;
}

.fc-header-toolbar
{
    display: none;
}

.fc-scroller
{
    height: auto !important;
}

.fc th
{
    font-size: .75rem;
    font-weight: 600;

    padding: .75rem 1rem;

    text-transform: uppercase; 

    color: #8492a6;
}

.fc div.fc-row
{
    margin-right: 0;

    border: 0;
}

.fc button .fc-icon
{
    top: -5px;
}

.fc-unthemed td.fc-today
{
    background-color: transparent;
}
.fc-unthemed td.fc-today span
{
    color: #ffbe3d;
}

.fc-event
{
    font-size: .75rem;

    padding: 0;

    border: 0; 
    border-radius: .2rem;
}
.fc-event .fc-title
{
    font-weight: 600; 

    display: block;
    overflow: hidden;

    padding: .4rem .5rem;

    white-space: nowrap;
    text-overflow: ellipsis;

    color: #fff;
}
.fc-event .fc-time
{
    float: left;

    margin: 0 0 0 -1px; 
    padding: 2px 6px;

    background: rgba(31, 45, 61, .2);
}

.fc-view,
.fc-view > table
{
    overflow: hidden; 

    border: 0;
}

.fc-view > table > tbody > tr .ui-widget-content
{
    border-top: 0;
}

.fc-body
{
    border: 0;
}

.fc-icon
{
    font-size: 1rem;
    line-height: 35px; 

    width: 35px;
    height: 35px;

    border-radius: 50%;
}
.fc-icon:hover
{
    color: #0c66ff;
}

.fc-button
{
    border: 0;
    background: transparent;
    box-shadow: none;
}
.fc-button:focus,
.fc-button:active
{
    outline: 0;
}

.calendar
{
    z-index: 0;
}
.calendar td,
.calendar th
{
    border-color: #f6f8fa;
}
.calendar .fc-toolbar
{
    position: relative;
    z-index: 2; 

    height: 250px;
    margin-bottom: -2px;

    border-radius: .375rem .375rem 0 0;
    background-color: #fff;
}
@media (max-width: 575.98px)
{
    .calendar .fc-toolbar
    {
        height: 135px;
    }
}
.calendar .fc-day-number
{
    box-sizing: border-box; 
    width: 100%;
    padding: .5rem 1rem;
}
@media (min-width: 576px)
{
    .calendar .fc-day-number
    {
        font-size: .875rem;
        font-weight: 600;

        color: #a2acbb;
    }
}
.calendar .fc-day-header
{
    text-align: left;
}
.calendar .fc-day-grid-event
{
    margin: 1px 9px;
}

.card-calendar .card-header
{
    border-bottom: 0;
}

.card-calendar table
{
    background: transparent;
}
.card-calendar table tr > td:first-child
{
    border-left-width: 0;
}
.card-calendar table tr > td:last-child
{
    border-right-width: 0;
}

.widget-calendar
{
    position: relative;
    z-index: 0;
}
.widget-calendar td,
.widget-calendar th
{
    text-align: center; 

    border-color: transparent;
}
.widget-calendar .fc-toolbar
{
    margin-top: 1.25rem;
}
.widget-calendar .fc-toolbar h2
{
    font-size: 1rem;
}
.widget-calendar .fc-day-number
{
    width: 100%;
    padding: 0; 

    text-align: center;
}
.widget-calendar .fc table
{
    font-size: .875rem;
}
.widget-calendar .fc th
{
    font-size: .75rem; 

    padding: .75rem .5rem;
}

.highlight
{
    margin-bottom: 1rem;
    padding: 1.25rem;

    border: 1px solid #eff2f7;
    border-radius: .375rem; 
    background-color: #212d3a;
}

.highlight pre
{
    margin-bottom: 0;
    padding: 1.25rem;
}

.hljs
{
    display: block;
    overflow-x: auto;

    padding: .5em;

    color: #dcdcdc;
}
.hljs pre code
{
    color: #dcdcdc;
}

.hljs-keyword,
.hljs-literal,
.hljs-symbol,
.hljs-name
{
    color: #569cd6;
}

.hljs-link
{
    text-decoration: underline; 

    color: #569cd6;
}

.hljs-built_in,
.hljs-type
{
    color: #4ec9b0;
}

.hljs-number,
.hljs-class
{
    color: #b8d7a3;
}

.hljs-string,
.hljs-meta-string
{
    color: #d69d85;
}

.hljs-regexp,
.hljs-template-tag
{
    color: #9a5334;
}

.hljs-subst,
.hljs-function,
.hljs-title,
.hljs-params,
.hljs-formula
{
    color: #dcdcdc;
}

.hljs-comment,
.hljs-quote
{
    font-style: italic; 

    color: #57a64a;
}

.hljs-doctag
{
    color: #608b4e;
}

.hljs-meta,
.hljs-meta-keyword,
.hljs-tag
{
    color: #9b9b9b;
}

.hljs-variable,
.hljs-template-variable
{
    color: #bd63c5;
}

.hljs-attr,
.hljs-attribute,
.hljs-builtin-name
{
    color: #9cdcfe;
}

.hljs-section
{
    color: gold;
}

.hljs-emphasis
{
    font-style: italic;
}

.hljs-strong
{
    font-weight: bold;
}

.hljs-bullet,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo
{
    color: #d7ba7d;
}

.hljs-addition
{
    display: inline-block;

    width: 100%; 

    background-color: #144212;
}

.hljs-deletion
{
    display: inline-block;

    width: 100%; 

    background-color: #600;
}

.masonry-filter
{
    cursor: pointer;
}

.scroll-wrapper
{
    position: relative; 

    overflow: hidden !important;

    padding: 0 !important;
}

.scroll-wrapper > .scroll-content
{
    position: relative !important;
    top: 0;
    left: 0;

    overflow: scroll !important;

    box-sizing: content-box !important;
    width: auto !important; 
    max-width: none !important;
    height: auto;
    max-height: none;
    margin: 0;
    padding: 0;

    border: none !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar
{
    width: 0; 
    height: 0;
}

.scroll-wrapper.scroll--rtl
{
    direction: rtl;
}

.scroll-element
{
    display: none; 

    box-sizing: content-box;
}

.scroll-element div
{
    box-sizing: content-box;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow
{
    cursor: default;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible
{
    display: block;
}

.scroll-textarea
{
    border: 1px solid #ccc;
    border-top-color: #999;
}

.scroll-textarea > .scroll-content
{
    overflow: hidden !important;
}

.scroll-textarea > .scroll-content > textarea
{
    position: relative !important;
    top: 0;

    overflow: scroll !important;

    box-sizing: border-box;
    width: 100% !important; 
    max-width: none !important;
    height: 100% !important;
    max-height: none !important;
    margin: 0;
    padding: 2px;

    border: none !important;
    outline: none;
}

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar
{
    width: 0; 
    height: 0;
}

/*************** SIMPLE INNER SCROLLBAR ***************/
.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    margin: 0;
    padding: 0;

    border: none;
}

.scrollbar-inner > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-inner > .scroll-element.scroll-x
{
    bottom: 2px;
    left: 0;

    width: 100%; 
    height: 8px;
}

.scrollbar-inner > .scroll-element.scroll-y
{
    top: 0;
    right: 2px;

    width: 8px; 
    height: 100%;
}

.scrollbar-inner > .scroll-element .scroll-element_outer
{
    overflow: hidden;
}

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar
{
    border-radius: 8px;
}

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar
{
    opacity: .4; 

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
        filter: alpha(opacity=40);
}

.scrollbar-inner > .scroll-element .scroll-element_track
{
    background-color: #e0e0e0;
}

.scrollbar-inner > .scroll-element .scroll-bar
{
    background-color: #c2c2c2;
}

.scrollbar-inner > .scroll-element:hover .scroll-bar
{
    background-color: #919191;
}

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar
{
    background-color: #919191;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track
{
    left: -12px;
}

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track
{
    top: -12px;
}

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -12px;
}

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -12px;
}

/*************** SIMPLE OUTER SCROLLBAR ***************/
.scrollbar-outer > .scroll-element,
.scrollbar-outer > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    margin: 0;
    padding: 0;

    border: none;
}

.scrollbar-outer > .scroll-element
{
    background-color: #fff;
}

.scrollbar-outer > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-outer > .scroll-element.scroll-x
{
    bottom: 0;
    left: 0;

    width: 100%; 
    height: 12px;
}

.scrollbar-outer > .scroll-element.scroll-y
{
    top: 0;
    right: 0;

    width: 12px; 
    height: 100%;
}

.scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer
{
    top: 2px; 

    height: 8px;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer
{
    left: 2px;

    width: 8px;
}

.scrollbar-outer > .scroll-element .scroll-element_outer
{
    overflow: hidden;
}

.scrollbar-outer > .scroll-element .scroll-element_track
{
    background-color: #eee;
}

.scrollbar-outer > .scroll-element .scroll-element_outer,
.scrollbar-outer > .scroll-element .scroll-element_track,
.scrollbar-outer > .scroll-element .scroll-bar
{
    border-radius: 8px;
}

.scrollbar-outer > .scroll-element .scroll-bar
{
    background-color: #d9d9d9;
}

.scrollbar-outer > .scroll-element .scroll-bar:hover
{
    background-color: #c2c2c2;
}

.scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar
{
    background-color: #919191;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-outer > .scroll-content.scroll-scrolly_visible
{
    left: -12px;

    margin-left: 12px;
}

.scrollbar-outer > .scroll-content.scroll-scrollx_visible
{
    top: -12px;

    margin-top: 12px;
}

.scrollbar-outer > .scroll-element.scroll-x .scroll-bar
{
    min-width: 10px;
}

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar
{
    min-height: 10px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track
{
    left: -14px;
}

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track
{
    top: -14px;
}

.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -14px;
}

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -14px;
}

/*************** SCROLLBAR MAC OS X ***************/
.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    margin: 0;
    padding: 0;

    border: none;
    background: none;
}

.scrollbar-macosx > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track
{
    display: none;
}

.scrollbar-macosx > .scroll-element .scroll-bar
{
    display: block;

    transition: opacity .2s linear; 

    opacity: 0;
    border-radius: 7px;
    background-color: #6c6e71;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        filter: alpha(opacity=0);
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar
{
    opacity: .7; 

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
        filter: alpha(opacity=70);
}

.scrollbar-macosx > .scroll-element.scroll-x
{
    bottom: 0;
    left: 0;

    overflow: visible;

    width: 100%; 
    min-width: 100%;
    height: 0;
}

.scrollbar-macosx > .scroll-element.scroll-y
{
    top: 0;
    right: 0;

    width: 0; 
    height: 100%;
    min-height: 100%;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar
{
    top: -9px; 

    min-width: 10px;
    height: 7px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar
{
    left: -9px;

    width: 7px; 
    min-height: 10px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer
{
    left: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size
{
    left: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer
{
    top: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size
{
    top: -4px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -11px;
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -11px;
}

/*************** SCROLLBAR LIGHT ***************/
.scrollbar-light > .scroll-element,
.scrollbar-light > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    overflow: hidden;

    margin: 0;
    padding: 0;

    border: none;
}

.scrollbar-light > .scroll-element
{
    background-color: #fff;
}

.scrollbar-light > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-light > .scroll-element .scroll-element_outer
{
    border-radius: 10px;
}

.scrollbar-light > .scroll-element .scroll-element_size
{
    border-radius: 10px; 
    background: #dbdbdb;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiZGJkYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOGU4ZTgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
    background: linear-gradient(to right, #dbdbdb 0%, #e8e8e8 100%);
}

.scrollbar-light > .scroll-element.scroll-x
{
    bottom: 0;
    left: 0;

    width: 100%; 
    min-width: 100%;
    height: 17px;
}

.scrollbar-light > .scroll-element.scroll-y
{
    top: 0;
    right: 0;

    width: 17px; 
    height: 100%;
    min-height: 100%;
}

.scrollbar-light > .scroll-element .scroll-bar
{
    border: 1px solid #dbdbdb;
    border-radius: 10px; 
    background: #fefefe;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZlZmVmZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY1ZjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
    background: linear-gradient(to right, #fefefe 0%, #f5f5f5 100%);
}

/* scrollbar height/width & offset from container borders */
.scrollbar-light > .scroll-content.scroll-scrolly_visible
{
    left: -17px;

    margin-left: 17px;
}

.scrollbar-light > .scroll-content.scroll-scrollx_visible
{
    top: -17px;

    margin-top: 17px;
}

.scrollbar-light > .scroll-element.scroll-x .scroll-bar
{
    top: 0; 

    min-width: 10px;
    height: 10px;
}

.scrollbar-light > .scroll-element.scroll-y .scroll-bar
{
    left: 0;

    width: 10px; 
    min-height: 10px;
}

.scrollbar-light > .scroll-element.scroll-x .scroll-element_outer
{
    top: 2px; 
    left: 2px;

    height: 12px;
}

.scrollbar-light > .scroll-element.scroll-x .scroll-element_size
{
    left: -4px;
}

.scrollbar-light > .scroll-element.scroll-y .scroll-element_outer
{
    top: 2px;
    left: 2px;

    width: 12px;
}

.scrollbar-light > .scroll-element.scroll-y .scroll-element_size
{
    top: -4px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -19px;
}

.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -19px;
}

.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track
{
    left: -19px;
}

.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track
{
    top: -19px;
}

/*************** SCROLLBAR RAIL ***************/
.scrollbar-rail > .scroll-element,
.scrollbar-rail > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    overflow: hidden;

    margin: 0;
    padding: 0;

    border: none;
}

.scrollbar-rail > .scroll-element
{
    background-color: #fff;
}

.scrollbar-rail > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-rail > .scroll-element .scroll-element_size
{
    background-color: #999;
    background-color: rgba(0, 0, 0, .3);
}

.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-element_size
{
    background-color: #666;
    background-color: rgba(0, 0, 0, .5);
}

.scrollbar-rail > .scroll-element.scroll-x
{
    bottom: 0;
    left: 0;

    width: 100%; 
    min-width: 100%;
    height: 12px;
    padding: 3px 0 2px;
}

.scrollbar-rail > .scroll-element.scroll-y
{
    top: 0;
    right: 0;

    width: 12px; 
    height: 100%;
    min-height: 100%;
    padding: 0 2px 0 3px;
}

.scrollbar-rail > .scroll-element .scroll-bar
{
    border-radius: 2px;
    background-color: #d0b9a0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
}

.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-bar
{
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .6);
}

/* scrollbar height/width & offset from container borders */
.scrollbar-rail > .scroll-content.scroll-scrolly_visible
{
    left: -17px;

    margin-left: 17px;
}

.scrollbar-rail > .scroll-content.scroll-scrollx_visible
{
    top: -17px; 

    margin-top: 17px;
}

.scrollbar-rail > .scroll-element.scroll-x .scroll-bar
{
    top: 1px; 

    min-width: 10px;
    height: 10px;
}

.scrollbar-rail > .scroll-element.scroll-y .scroll-bar
{
    left: 1px;

    width: 10px; 
    min-height: 10px;
}

.scrollbar-rail > .scroll-element.scroll-x .scroll-element_outer
{
    left: 5px; 

    height: 15px;
}

.scrollbar-rail > .scroll-element.scroll-x .scroll-element_size
{
    top: 5px; 
    left: -10px;

    height: 2px;
}

.scrollbar-rail > .scroll-element.scroll-y .scroll-element_outer
{
    top: 5px;

    width: 15px;
}

.scrollbar-rail > .scroll-element.scroll-y .scroll-element_size
{
    top: -10px;
    left: 5px;

    width: 2px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -25px;
}

.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -25px;
}

.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track
{
    left: -25px;
}

.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track
{
    top: -25px;
}

/*************** SCROLLBAR DYNAMIC ***************/
.scrollbar-dynamic > .scroll-element,
.scrollbar-dynamic > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    margin: 0;
    padding: 0;

    border: none;
    background: none;
}

.scrollbar-dynamic > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-dynamic > .scroll-element.scroll-x
{
    bottom: 2px;
    left: 0;

    width: 100%; 
    min-width: 100%;
    height: 7px;
}

.scrollbar-dynamic > .scroll-element.scroll-y
{
    top: 0;
    right: 2px;

    width: 7px; 
    height: 100%;
    min-height: 100%;
}

.scrollbar-dynamic > .scroll-element .scroll-element_outer
{
    opacity: .3;
    border-radius: 12px;
}

.scrollbar-dynamic > .scroll-element .scroll-element_size
{
    transition: opacity .2s; 

    opacity: 0;
    border-radius: 12px;
    background-color: #ccc;
}

.scrollbar-dynamic > .scroll-element .scroll-bar
{
    border-radius: 7px; 
    background-color: #6c6e71;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar
{
    top: auto; 
    bottom: 0;

    min-width: 24px;
    height: 7px;
}

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar
{
    right: 0;
    left: auto;

    width: 7px; 
    min-height: 24px;
}

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer
{
    top: auto;
    bottom: 0;
    left: 2px;

    transition: height .2s;
}

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer
{
    top: 2px;
    right: 0;
    left: auto;

    transition: width .2s;
}

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size
{
    left: -4px;
}

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size
{
    top: -4px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -11px;
}

.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -11px;
}

/* hover & drag */
.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer
{
    overflow: hidden;

    opacity: .7; 

    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
        filter: alpha(opacity=70);
}

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size
{
    opacity: 1;
}

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar
{
    width: 100%;
    height: 100%;

    border-radius: 12px;
}

.scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer
{
    height: 20px;
    min-height: 7px;
}

.scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer
{
    width: 20px; 
    min-width: 7px;
}

/*************** SCROLLBAR GOOGLE CHROME ***************/
.scrollbar-chrome > .scroll-element,
.scrollbar-chrome > .scroll-element div
{
    position: absolute;
    z-index: 10; 

    overflow: hidden;

    margin: 0;
    padding: 0;

    border: none;
}

.scrollbar-chrome > .scroll-element
{
    background-color: #fff;
}

.scrollbar-chrome > .scroll-element div
{
    top: 0;
    left: 0;

    display: block;

    width: 100%; 
    height: 100%;
}

.scrollbar-chrome > .scroll-element .scroll-element_track
{
    border: 1px solid #dbdbdb; 
    background: #f1f1f1;
}

.scrollbar-chrome > .scroll-element.scroll-x
{
    bottom: 0;
    left: 0;

    width: 100%; 
    min-width: 100%;
    height: 16px;
}

.scrollbar-chrome > .scroll-element.scroll-y
{
    top: 0;
    right: 0;

    width: 16px; 
    height: 100%;
    min-height: 100%;
}

.scrollbar-chrome > .scroll-element .scroll-bar
{
    cursor: default;

    border: 1px solid #bdbdbd;
    border-radius: 2px; 
    background-color: #d9d9d9;
}

.scrollbar-chrome > .scroll-element .scroll-bar:hover
{
    border-color: #a9a9a9; 
    background-color: #c2c2c2;
}

.scrollbar-chrome > .scroll-element.scroll-draggable .scroll-bar
{
    border-color: #7e7e7e; 
    background-color: #919191;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-chrome > .scroll-content.scroll-scrolly_visible
{
    left: -16px;

    margin-left: 16px;
}

.scrollbar-chrome > .scroll-content.scroll-scrollx_visible
{
    top: -16px;

    margin-top: 16px;
}

.scrollbar-chrome > .scroll-element.scroll-x .scroll-bar
{
    top: 3px; 

    min-width: 10px;
    height: 8px;
}

.scrollbar-chrome > .scroll-element.scroll-y .scroll-bar
{
    left: 3px;

    width: 8px; 
    min-height: 10px;
}

.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_outer
{
    border-left: 1px solid #dbdbdb;
}

.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_track
{
    left: -3px; 

    height: 14px;
}

.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_size
{
    left: -4px; 

    height: 14px;
}

.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_outer
{
    border-top: 1px solid #dbdbdb;
}

.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_track
{
    top: -3px;

    width: 14px;
}

.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_size
{
    top: -4px;

    width: 14px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size
{
    left: -19px;
}

.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size
{
    top: -19px;
}

.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track
{
    left: -19px;
}

.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track
{
    top: -19px;
}

.scrollbar-inner
{
    height: 100%;
}
.scrollbar-inner:not(:hover) .scroll-element
{
    opacity: 0;
}
.scrollbar-inner .scroll-element
{
    margin-right: 2px; 

    transition: opacity 300ms;
}
.scrollbar-inner .scroll-element .scroll-bar,
.scrollbar-inner .scroll-element .scroll-element_track
{
    transition: background-color 300ms;
}
.scrollbar-inner .scroll-element .scroll-element_track
{
    background-color: transparent;
}
.scrollbar-inner .scroll-element:hover
{
    width: 4px;
}
.scrollbar-inner .scroll-element.scroll-y
{
    right: 0; 

    width: 3px;
}
.scrollbar-inner .scroll-element.scroll-x
{
    bottom: 0; 

    height: 3px;
}

.select2-selection__arrow
{
    display: none;
}

.select2.select2-container
{
    width: 100% !important;
}

.select2-container .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field
{
    font-size: 1rem;
    line-height: 1.5;

    display: block;

    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.25rem;

    transition: all .2s ease; 

    color: #8492a6;
    border: 1px solid #e0e6ed;
    border-radius: .375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075);
}
@media (prefers-reduced-motion: reduce)
{
    .select2-container .select2-selection--single,
    .select2-container--default.select2-container--focus .select2-selection--multiple,
    .select2-container--default .select2-selection--multiple,
    .select2-container--default .select2-search--dropdown .select2-search__field
    {
        transition: none;
    }
}
.select2-container .select2-selection--single::-ms-expand,
.select2-container--default.select2-container--focus .select2-selection--multiple::-ms-expand,
.select2-container--default .select2-selection--multiple::-ms-expand,
.select2-container--default .select2-search--dropdown .select2-search__field::-ms-expand
{
    border: 0; 
    background-color: transparent;
}
.select2-container .select2-selection--single:focus,
.select2-container--default.select2-container--focus .select2-selection--multiple:focus,
.select2-container--default .select2-selection--multiple:focus,
.select2-container--default .select2-search--dropdown .select2-search__field:focus
{
    color: #8492a6;
    border-color: rgba(12, 102, 255, .5);
    outline: 0;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, .075), 0 0 20px rgba(12, 102, 255, .1);
}
.select2-container .select2-selection--single::-webkit-input-placeholder,
.select2-container--default.select2-container--focus .select2-selection--multiple::-webkit-input-placeholder,
.select2-container--default .select2-selection--multiple::-webkit-input-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field::-webkit-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.select2-container .select2-selection--single::-moz-placeholder,
.select2-container--default.select2-container--focus .select2-selection--multiple::-moz-placeholder,
.select2-container--default .select2-selection--multiple::-moz-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field::-moz-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.select2-container .select2-selection--single:-ms-input-placeholder,
.select2-container--default.select2-container--focus .select2-selection--multiple:-ms-input-placeholder,
.select2-container--default .select2-selection--multiple:-ms-input-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field:-ms-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.select2-container .select2-selection--single::-ms-input-placeholder,
.select2-container--default.select2-container--focus .select2-selection--multiple::-ms-input-placeholder,
.select2-container--default .select2-selection--multiple::-ms-input-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field::-ms-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.select2-container .select2-selection--single::placeholder,
.select2-container--default.select2-container--focus .select2-selection--multiple::placeholder,
.select2-container--default .select2-selection--multiple::placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field::placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.select2-container .select2-selection--single:disabled,
.select2-container .select2-selection--single[readonly],
.select2-container--default.select2-container--focus .select2-selection--multiple:disabled,
.select2-container--default.select2-container--focus .select2-selection--multiple[readonly],
.select2-container--default .select2-selection--multiple:disabled,
.select2-container--default .select2-selection--multiple[readonly],
.select2-container--default .select2-search--dropdown .select2-search__field:disabled,
.select2-container--default .select2-search--dropdown .select2-search__field[readonly]
{
    opacity: 1; 
    background-color: #eff2f7;
}

.select2-container .select2-selection--single .select2-selection__rendered
{
    overflow: inherit;

    padding: 0;

    white-space: inherit; 
    text-overflow: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__rendered
{
    line-height: inherit; 

    color: inherit;
}

.select2-dropdown
{
    padding: .35rem 0;

    border: 1px solid #e0e6ed;
    border-radius: .5rem; 
    background-color: #fff;
}

.select2-results__option
{
    font-size: 1rem; 

    padding: .25rem 1.25rem;

    color: #8492a6;
    background-color: #fff;
}
.select2-results__option:hover
{
    color: #0c66ff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected='true']
{
    color: #0c66ff; 
    background-color: transparent;
}

.select2-container--default .select2-results__option[aria-disabled=true]
{
    color: #c0ccda;
}

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-selection--multiple
{
    height: auto;
    min-height: calc(1.5em + 1.5rem + 2px);
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered
{
    display: block;

    margin: 0 0 -.25rem -.25rem;
    padding: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice
{
    font-size: 1rem;
    line-height: 1.5rem;

    display: inline-flex;

    margin: 0 0 .25rem .25rem;
    padding: 0 .5rem;

    color: #8492a6; 
    border: none;
    border-radius: .2rem;
    background-color: #eff2f7;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove
{
    margin-left: .5rem;

    color: #8492a6; 

    order: 2;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover
{
    color: #8492a6;
}

.select2-container .select2-search--inline
{
    display: none;
}

.select2-selection[aria-expanded='true']
{
    border-bottom-right-radius: 0 !important; 
    border-bottom-left-radius: 0 !important;
}

.select2-search--dropdown
{
    padding: .25rem 1.25rem;
}

.select2-container--default .select2-search--dropdown .select2-search__field
{
    font-size: 1rem;
    line-height: 1.5;

    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1.25rem;

    border-radius: .2rem;
}

.form-control-sm + .select2-container .select2-selection--single,
.form-control-sm + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-sm + .select2-container--default .select2-selection--multiple
{
    font-size: .875rem;
    line-height: 1.5;

    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1.25rem;

    border-radius: .2rem;
}

.form-control-sm + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-sm + .select2-container--default .select2-selection--multiple
{
    min-height: calc(1.5em + 1rem + 2px);
}

.form-control-sm + .select2-container--default .select2-selection--multiple .select2-selection__choice
{
    line-height: 1.3125rem;
}

.form-control-lg + .select2-container .select2-selection--single,
.form-control-lg + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-lg + .select2-container--default .select2-selection--multiple
{
    font-size: 1rem;
    line-height: 1.5;

    height: calc(1.5em + 2rem + 2px);
    padding: 1rem 1.875rem;

    border-radius: .5rem;
}

.form-control-lg + .select2-container--default.select2-container--focus .select2-selection--multiple,
.form-control-lg + .select2-container--default .select2-selection--multiple
{
    min-height: calc(1.5em + 2rem + 2px);
}

.form-control-lg + .select2-container--default .select2-selection--multiple .select2-selection__choice
{
    line-height: 1.5rem;
}

.swal2-popup
{
    padding: 1.5rem;
}
.swal2-popup .swal2-title
{
    font-size: 1.5rem;
}
.swal2-popup .swal2-content
{
    font-size: .875rem;
}
.swal2-popup .swal2-image
{
    max-width: 200px;
}

.swiper-slide
{
    transition: opacity .3s; 

    opacity: 1;
}
@media (prefers-reduced-motion: reduce)
{
    .swiper-slide
    {
        transition: none;
    }
}
.swiper-slide .card
{
    margin: 0;
}

.swiper-fade .swiper-slide-prev,
.swiper-fade .swiper-slide-next
{
    opacity: 0;
}

.swiper-container-vertical
{
    width: 100%;
    height: 100%;
}
.swiper-container-vertical .swiper-slide
{
    display: flex;

    text-align: center;

    justify-content: center;
    align-items: center;
}

.swiper-pagination
{
    left: 0;
}

.swiper-pagination-bullet
{
    width: .65rem;
    height: .65rem;
    margin-right: .45rem;

    transition: all .2s ease; 

    opacity: .5;
    outline: none;
    background: #d3dce6;
}
.swiper-pagination-bullet:hover
{
    opacity: 1;
}

.swiper-pagination-bullet-active
{
    -webkit-transform: scale(1.4);
            transform: scale(1.4);

    opacity: 1; 
    background: #0c66ff;
}

.swiper-button,
.swiper-button
{
    line-height: 44px;

    position: absolute;
    z-index: 10;
    top: 50%;

    width: 44px;
    height: 44px;
    margin-top: 0;

    cursor: pointer;
    transition: all .2s ease;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;

    border-radius: 50%;
    outline: none;
    background-image: none;
    box-shadow: 0 .125rem .25rem rgba(31, 45, 61, .08);
}
@media (prefers-reduced-motion: reduce)
{
    .swiper-button,
    .swiper-button
    {
        transition: none;
    }
}
.swiper-button:after,
.swiper-button:after
{
    font-family: 'Font Awesome 5 Free';
    font-weight: 700; 
    font-style: normal;
    font-variant: normal;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.swiper-button-sm
{
    line-height: 34px; 

    width: 34px;
    height: 34px;
}

.swiper-button-next
{
    right: -44px;
}
.swiper-button-next.swiper-button-sm
{
    right: -14px;
}
.swiper-button-next:after
{
    content: '\f105';
}
@media (max-width: 767.98px)
{
    .swiper-button-next
    {
        right: -20px;
    }
}

.swiper-button-prev
{
    left: 0;
}
.swiper-button-prev:after
{
    content: '\f104';
}
@media (max-width: 767.98px)
{
    .swiper-button-prev
    {
        left: 24px;
    }
}

.swiper-button-disabled
{
    opacity: 0 !important;
}

.bootstrap-tagsinput
{
    display: block;

    max-width: 100%;

    cursor: default; 
    vertical-align: middle;

    color: #c0ccda;
    border: 0 solid transparent;
    border-radius: .375rem;
    background-color: transparent;
}
.bootstrap-tagsinput input
{
    display: block;

    width: auto;
    max-width: inherit; 
    margin: 0;
    padding: 0;

    color: #8492a6;
    border: 0;
    outline: none;
    background-color: transparent;
    box-shadow: none;
}
.bootstrap-tagsinput input::-webkit-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.bootstrap-tagsinput input::-moz-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.bootstrap-tagsinput input:-ms-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.bootstrap-tagsinput input::-ms-input-placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.bootstrap-tagsinput input::placeholder
{
    opacity: 1; 
    color: #aabacd;
}
.bootstrap-tagsinput input:focus
{
    border: none;
    box-shadow: none;
}
.bootstrap-tagsinput [data-role='remove']
{
    position: absolute;
    top: 50%;

    margin-left: 10px;

    cursor: pointer;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);

    opacity: 0; 
    color: #fff;
}
.bootstrap-tagsinput [data-role='remove']:after
{
    font-size: 16px; 

    content: '×';
}
.bootstrap-tagsinput .badge
{
    line-height: 1.5;

    position: relative;

    display: inline-block;
    overflow: hidden;

    margin: .125rem;
    padding: .625rem .625rem .5rem;

    transition: all .2s ease; 

    color: #fff;
    border-radius: .375rem;
    background: #0c66ff;
    box-shadow: 0 1px 2px rgba(31, 45, 61, .25);
}
@media (prefers-reduced-motion: reduce)
{
    .bootstrap-tagsinput .badge
    {
        transition: none;
    }
}
.bootstrap-tagsinput .badge:hover
{
    padding-right: 1.675rem;
}
.bootstrap-tagsinput .badge:hover [data-role='remove']
{
    opacity: 1;
}

.w-85
{
    width: 85% !important;
}

@media (min-width: 768px)
{
    #header-main + section
    {
        padding-top: 7rem !important;
    }
}

@media (max-width: 991.98px)
{
    #header-main + section
    {
        padding-top: 2rem !important;
    }
}

/*# sourceMappingURL=quick-website.css.map */
